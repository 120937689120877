




































































































































































































































import Vue, { PropType } from 'vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import GroupOfTime from '@/components/organisms/GroupOfTime/GroupOfTime.vue';
import * as Config from '@/config';

import { PassableTime } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';

export interface DataType {
  selectedItem: PassableTime[];
  textEdit: string;
  textDelete: string;
  selectedGroupIds: string[];
}
export interface ActionItem {
  title: string;
}
export interface DataType {
  tab: null;
}
export default Vue.extend({
  name: 'PassableTimeSettingList',
  components: {
    ShowPerPage,
    Pagination,
    SelectPerPage,
    GroupOfTime,
  },
  props: {
    dataPassables: {
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    successMessageLogsModal: {
      type: String,
      default: '',
    },
    errorMessageLogsModal: {
      type: String,
      default: '',
    },
    showConfirmDelete: {
      type: Boolean,
      default: false,
    },
    loadingGroup: {
      type: Boolean,
      default: false,
    },
    passableTotalCount: {
      type: Number,
      default: 0,
    },
    pageGroup: {
      type: Number,
      default: 1,
    },
    isEdit: {
      type: Number,
      default: 0,
    },
    pagePassable: {
      type: Number,
      default: 1,
    },
    perPagePassable: {
      type: Number,
      default: 25,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    ExpandedGroup: {
      default: [],
    },
  },
  data: (): DataType => ({
    tab: null,
    selectedItem: [],
    textEdit: Config.TEXT_EDIT,
    textDelete: Config.TEXT_DELETE,
    selectedGroupIds: [],
  }),
  computed: {
    headers: {
        get(): DataTableHeader[] {
          const headers: DataTableHeader[] = [
            {
              text: '時間帯設定名',
              align: 'start',
              sortable: true,
              value: 'name',
            },
          ];
          headers.push({
          text: 'グループ一覧',
          align: 'start',
          sortable: false,
          width: '220px',
          value: 'data-table-expand',
        });
          headers.push({
            text: '',
            align: 'center',
            sortable: false,
            width: '150px',
            value: 'icons',
          });
          return headers;
        },
        set(): void {
          return;
        },
    },
    innerPagePassable: {
      get(): number {
        return this.pagePassable;
      },
      set(val: number): void {
        this.$emit('change-page-passable', val);
        return;
      },
    },
    innerPerPagePassable: {
      get(): number {
        return this.perPagePassable;
      },
      set(val: number): void {
        this.$emit('change-per-page-passable', val);
        return;
      },
    },
    filteredItems: {
      get(): any {
        return this.dataPassables.filter((i: any) => {
          return (i.isDefault === false);
        });
      },
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        console.log(a);
        if (index[0] === 'isDefault') {
          const tmp1 = this.convertStatus(a.timeSettings);
          const tmp2 = this.convertStatus(b.timeSettings);
          if (!isDescending[0]) {
              return (tmp1 < tmp2) ? -1 : 1;
          } else {
              return (tmp2 < tmp1) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    convertStatus(data: any) {
      let check = 0;
      for (const item of data) {
        if (item.allDay === 1) {
          check++;
        }
      }
      if (check === data.length) {
        return '有効';
      } else {
        return '無効';
      }
    },
    clickJoinGroup(ID: any) {
      this.$router.push({ path: Config.TIME_ADD_GROUP, query: {passableTimeSettingId: ID}});
    },
  },
});
