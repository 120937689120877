var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _vm.errorMessageLogs !== ""
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold"
                },
                [_vm._v("\n        鍵共有キー発行\n        ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("key-add-form", {
            attrs: {
              loading: _vm.loading,
              existGateId: _vm.existGateId,
              listTimes: _vm.listTimes,
              passagesData: _vm.passagesData
            },
            on: {
              "generate-key": function($event) {
                return _vm.$emit("generate-key", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }