






















































































import Vue, { PropType } from 'vue';
import { Gate, PassableTime, SafieRelationGateDevice, TbEyeRelationGateDevice } from '@/types/alligate';
import { TbeyeLoginInfo } from '@/types/tbeye';
import { Group } from '@/types/alligate';
import VerifyLogList from '@/components/organisms/VerifyLogList/VerifyLogList.vue';
import GateLogList from '@/components/organisms/GateLogList/GateLogList.vue';
import OperationLogList from '@/components/organisms/OperationLogList/OperationLogList.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';

export interface ActionItem {
  title: string;
}
export interface DataType {
  tab: number;
  selectedItem: PassableTime[];
  showModal: boolean;
  messageErrorDialog: string;
}

export default Vue.extend({
  name: 'ManuallyScheduledTemplate',
  components: {
    VerifyLogList,
    GateLogList,
    OperationLogList,
    Breadcrumbs,
  },
  props: {
    data: {
      type: Array as PropType<PassableTime[]>,
      default: () => [],
    },
    gatePassageTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    disableNextOwner: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    disableNextGate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    actionItems: {
      type: Array as PropType<ActionItem[]>,
      default: [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: [],
    },
    safieRelationsGateDevice: {
      type: Array as PropType<SafieRelationGateDevice[]>,
      default: () => [],
    },
    tbeyeRelationsGateDevice: {
      type: Array as PropType<TbEyeRelationGateDevice[]>,
      default: () => [],
    },
    tbeyeLoginInfos: {
      type: Array as PropType<TbeyeLoginInfo[]>,
      default: () => [],
    },
    logGates: {
      type: Array,
      default: () => [],
    },
    logOwner: {
      type: Array,
      default: () => [],
    },
    optionGate: {
      default: () => [],
    },
    optionOwner: {
      default: () => [],
    },
    maxRecordGate: {
      type: Number,
      default: 0,
    },
    maxRecordOwner: {
      type: Number,
      default: 0,
    },
    errorMessageLogsGate: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    goBack: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    tab: 0,
    selectedItem: [],
    showModal: false,
    messageErrorDialog: '',
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ログ情報', disabled: true },
        ];
      },
    },
    gateIdToSafieDeviceIds(): any {
      const result: any = {};
      this.safieRelationsGateDevice.forEach((val) => {
        if (typeof result[val.gateId] === 'undefined') {
          result[val.gateId] = [];
        }
        const data = { deviceId: val.deviceId, notes: val.notes };
        result[val.gateId].push(data);
      });
      return result;
    },
    gateIdToTbeyeDeviceIds(): any {
      const result: any = {};
      this.tbeyeRelationsGateDevice.forEach((val) => {
        if (typeof result[val.gateId] === 'undefined') {
          result[val.gateId] = [];
        }
        const data: any = val;
        const logininfo = this.tbeyeLoginInfos.find((loginInfo) => loginInfo.relationId === val.relationId);
        if (logininfo) {
          data.loginInfo = logininfo;
        } else {
          data.loginInfo = { username: '', password: '' };
        }
        result[val.gateId].push(data);
      });
      return result;
    },
  },
  watch: {
  },
  methods: {
    goPage() {
      this.$router.push({ path: this.goBack});
    },
  },
});
