










































































































































import Vue from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import UserOfGateTable from '@/components/organisms/UserOfGateTable/UserOfGateTable.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';

export interface DataType {
  selectedItem: any;
}

export default Vue.extend({
  name: 'GroupGateTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
    UserOfGateTable,
  },
  props: {
    gateExpanedGroup: {
      type: Map,
      default: new Map(),
    },
    gateId: {
      default: '',
    },
    isReRenderGroup: {
      default: 0,
    },
    isRemoveGroupSelected: {
      default: 0,
    },

    // user
    gateExpanedUser: {
      type: Map,
      default: new Map(),
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
  },
  data: (): DataType => ({
    selectedItem: [],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'グループ名',
            align: 'start',
            sortable: true,
            value: 'group.name',
          },
          {
            text: ' 所属ユーザー数',
            align: 'center',
            width: '150px',
            sortable: false,
            value: 'data-table-expand',
          },
        ];
        return headers;
      },
    },
  },
  watch: {
    isReRenderGroup(value) {
      this.$forceUpdate();
    },
    isRemoveGroupSelected(value) {
      this.selectedItem = [];
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'group.name') {
          if (!isDescending[0]) {
              return (a.group.name < b.group.name) ? -1 : 1;
          } else {
              return (b.group.name < a.group.name) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    changePage(val: any) {
      this.$emit('change-page-group', {page: val, gateId: this.gateId});
    },
    clickDetail(ID: string) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: ID, goBack: 'gates'}});
    },
    clickJoinGroup(ID: any) {
      this.$router.push({ path: Config.GATE_ADD_GROUP, query: {gateId: ID}});
    },
  },
});
