






















































































































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';

import {Group} from '@/types/alligate';
export interface TypeItem {
  title: string;
}
export interface DataType {
  check: boolean;
  input: null;
  lableButtonLogin: object;
  newUser: any;
  searchTargetItems: SearchTargetItem[];
  selectedDisplayOption: string[];
  displayOptionItems: DisplayOptionItem[];
  isShowModal: boolean;
  relationId: string;
  currentPage: number;
  pageSize: number;
  queryOptions: any;
  searchKey: string;
  files: any;
}
interface Type {
  code: string;
  name: string;
  user: string;
}
export default Vue.extend({
  name: 'RegisterLoginUserTemplate',
  components: {
    CancelButton,
    SuccessButton,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    userTotalCount: {
      type: Number,
      default: 0,
    },
    listID: {
      type: Array,
      default: () => [],
    },
    gatePassageTotalCount: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    pageSizeList: {
      type: Number,
      default: 25,
    },
    page: {
      type: Number,
      default: 25,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    cognitoIdProviderName: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    userList: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    realtionsUserList: {
      type: Array,
      default: () => [],
    },
    isFederatedLoginService: {
      type: String,
      default: 'false',
    },
    isSamlFederatedLoginService: {
      type: String,
      default: 'false',
    },
    sucessStatus: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    maxRecord: {
      type: Number,
      default: 0,
    },
    successItem: {
      type: Number,
      default: 0,
    },
    totalDataRow: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    isExternalLogin: {
      type: String,
      default: '',
    },
    showModalImport: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    federatedLoginServiceId: {
      type: String,
      default: '',
    },
    errorMessageExcel: {
      default: [],
    },
    errorMessageParams: {
      default: [],
    },
  },
  computed: {
    progressWidth() {
      return 'width:' + this.progress + '%';
    },
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [];
        if (this.selectedDisplayOption.includes('federatedLoginEmail')) {
          headers.push({
            text: 'メールアドレス',
            align: 'start',
            sortable: true,
            value: 'federatedLoginEmail',
          });
        }
        if (this.selectedDisplayOption.includes('userId')) {
          headers.push({
            text: 'ログインに使用するID',
            align: 'start',
            sortable: true,
            value: 'userId',
          });
        }
        headers.push({
          text: '',
          align: 'start',
          sortable: true,
          value: 'edit',
        });
        return headers;
      },
      set(): void {
        return;
      },
    },
    groupNames: {
      get(): Array<{text: string, value: string }> {
        return this.newUser.map((user: any) => ({
          text: user.userName,
          value: user.relationId,
        }));
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page-login', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.pageSizeList;
      },
      set(val: number): void {
        this.$emit('change-per-page-login', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    check: true,
    input: null,
    lableButtonLogin: {
      oneLogin: 'SAML ログインユーザー登録',
      googleLogin: 'Google ログインユーザー登録',
      appleLogin: 'AppleID ログインユーザー登録',
    },
    newUser: [],
    searchTargetItems: [
      { label: 'メールアドレス', value: 'email' },
    ],
    selectedDisplayOption: [
      'federatedLoginEmail', 'name', 'userId',
    ],
    displayOptionItems: [],
    isShowModal: false,
    relationId: '',
    currentPage: 1,
    pageSize: 25,
    queryOptions: {},
    searchKey: '',
    files: [],
  }),
  watch: {
    sucessStatus(newVal: boolean) {
      if (newVal) {
        this.newUser = [];
      }
    },
    message() {
      if (this.isShowModal) {
        this.isShowModal = false;
      }
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    showModal(relationId: string) {
      this.relationId = relationId;
      this.isShowModal = true;
    },
    deleteRelation() {
      this.$emit('deleteRelation', this.relationId);
      this.isShowModal = false;
    },
    previewFiles(event: any) {
      console.log(event.target.files[0]);
      this.files = event.target.files[0];
    },
  },
});
