











































import Vue, { PropType } from 'vue';
import GateDetailTable from '@/components/organisms/GateDetailTable/GateDetailTable.vue';
import { Group } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import * as Config from '@/config';
export interface TypeItem {
  title: string;
}
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface DataType {
  Message: any;
}
export default Vue.extend({
  name: 'GateEditTemplate',
  components: {
    GateDetailTable,
    Breadcrumbs,
  },
  props: {
    unlockTimes: {
      type: Array as PropType<TypeDessert[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    successMessageModal : {
      type: String,
      default: '',
    },
    errorMessageModal : {
      type: String,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    goBack: {
      type: String,
      default: '',
    },
    gateDetail: {
      default: {},
    },
    groupsAddFail: {
      default: [],
    },
    groupList: {
      default: [],
    },
    dataUnlockingTime: {
      default: [],
    },
    dayTypes: {
      default: [],
    },
    isFinishEditGate: {
      default: false,
    },
    loading: {
      default: false,
    },
  },
  data: (): DataType => ({
    Message : Config,
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'name',
            class: 'grey',
          },
          {
            text: '詳細',
            align: 'start',
            sortable: true,
            value: 'isValid',
            class: 'grey',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ゲート', disabled: true },
          { text: 'ゲート詳細', disabled: true },
        ];
      },
    },
  },
});
