var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-gate-table" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            {
              staticClass: "mt-2 mb-1 ml-5 mr-5",
              attrs: { justify: "space-between" }
            },
            [
              _vm.groupExpanedUser.has(_vm.groupId)
                ? _c("div", { staticClass: "allig-title mt-1" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          "ユーザー一覧(全" +
                            _vm.groupExpanedUser.get(_vm.groupId).data.length +
                            "件)"
                        ) +
                        "\n      "
                    )
                  ])
                : _c("div", { staticClass: "allig-title mt-1" }, [
                    _vm._v(
                      "\n        " + _vm._s("ユーザー一覧(全0件)") + "\n      "
                    )
                  ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.clickJoinUser(_vm.groupId)
                            }
                          }
                        },
                        [_vm._v("\n            関連付け追加\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { disabled: _vm.selectedItem.length === 0 },
                          on: {
                            click: function($event) {
                              return _vm.$emit("show-dialog-confirm", {
                                data: _vm.selectedItem,
                                type: 4,
                                groupId: _vm.groupId
                              })
                            }
                          }
                        },
                        [_vm._v("\n            関連付け解除\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-between" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("show-per-page", {
                staticClass: "mt-4 ml-4 mb-2",
                attrs: {
                  "per-page": 25,
                  page: _vm.groupExpanedUser.has(_vm.groupId)
                    ? _vm.groupExpanedUser.get(_vm.groupId).currPageUser
                    : 1,
                  "items-length": _vm.groupExpanedUser.has(_vm.groupId)
                    ? _vm.groupExpanedUser.get(_vm.groupId).totalUserCount
                    : 0
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "border-table scroll-table",
            attrs: {
              "must-sort": "",
              "custom-sort": _vm.customSort,
              loading: _vm.groupExpanedUser.has(_vm.groupId)
                ? _vm.groupExpanedUser.get(_vm.groupId).isGetDataUser
                : false,
              headers: _vm.headers,
              items: _vm.groupExpanedUser.has(_vm.groupId)
                ? _vm.groupExpanedUser.get(_vm.groupId).data
                : [],
              "items-per-page": 25,
              "no-data-text": "該当するユーザーがありません",
              "loading-text": "データを読み込中です",
              "hide-default-footer": "",
              dense: "",
              "show-select": true,
              "item-key": "groupPassageId"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.user.userId",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("text-link", {
                        staticClass: "item-user-name",
                        attrs: { text: item.user.userId },
                        on: {
                          click: function($event) {
                            return _vm.clickDetail(item.user.userId)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.user.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "item-user-id" }, [
                        _vm._v(_vm._s(item.user.name))
                      ])
                    ]
                  }
                },
                {
                  key: "item.user.isValid",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.user.isValid === 1
                        ? _c("span", [_vm._v("有効")])
                        : _c("span", [_vm._v("無効")])
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItem,
              callback: function($$v) {
                _vm.selectedItem = $$v
              },
              expression: "selectedItem"
            }
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-3",
              attrs: { "no-gutters": "", justify: "space-between" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("pagination", {
                staticClass: "mb-1",
                attrs: {
                  "page-length": Math.ceil(
                    (_vm.groupExpanedUser.has(_vm.groupId)
                      ? _vm.groupExpanedUser.get(_vm.groupId).totalUserCount
                      : 0) / 25
                  ),
                  disabled: _vm.groupExpanedUser.has(_vm.groupId)
                    ? _vm.groupExpanedUser.get(_vm.groupId).isGetDataUser
                    : false
                },
                on: {
                  input: function($event) {
                    _vm.changePage(
                      _vm.groupExpanedUser.get(_vm.groupId).currPageUser
                    )
                  }
                },
                model: {
                  value: _vm.groupExpanedUser.get(_vm.groupId).currPageUser,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.groupExpanedUser.get(_vm.groupId),
                      "currPageUser",
                      $$v
                    )
                  },
                  expression: "groupExpanedUser.get(groupId).currPageUser"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }