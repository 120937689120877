








































































































































































































































































































































import Vue, { PropType } from 'vue';
export interface DataType {
  files: any;
  fileName: string;
  isCheckData: boolean;
}
export default Vue.extend({
  name: 'CardImport',
  components: {
  },
  props: {
    showProgressExportCSV: {
      type: Boolean,
      default: false,
    },
    showModalImport: {
      type: Boolean,
      default: false,
    },
    progressCsvXlsx: {
      type: Number,
      default: 0,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageCsvXlsx: {
      type: String,
      default: '',
    },
    isShowViewCSV: {
      type: Boolean,
      default: false,
    },
    showProgressImport: {
      type: Boolean,
      default: false,
    },
    successItemImport: {
      type: Number,
      default: 0,
    },
    totalDataRowImport: {
      type: Number,
      default: 0,
    },
    errorMessageImport: {
      default: [],
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    sheetUserName: {
      type: String,
      default: '',
    },
    sheet2Name: {
      type: String,
      default: '',
    },
    successItemSheet2: {
      type: Number,
      default: 0,
    },
    progressSheet2: {
      type: Number,
      default: 0,
    },
    totalDataRowSheet2: {
      type: Number,
      default: 0,
    },
  },
  watch: {
  },
  computed: {
    showProgressImportCSV: {
      get(): boolean {
        return (this.showProgressImport && this.isShowViewCSV);
      },
    },

    showProgressImportExcel: {
      get(): boolean {
        return (this.showProgressImport && !this.isShowViewCSV);
      },
    },
  },
  data: (): DataType => ({
    files: [],
    fileName: '',
    isCheckData: false,
  }),
  methods: {
    previewFiles(event: any) {
      console.log(event.target.files[0]);
      this.files = event.target.files[0];
      this.fileName = event.target.files[0] ? event.target.files[0].name : '';
    },
    openFile() {
      const element: HTMLElement = document.getElementsByClassName('custom-file-input')[0] as HTMLElement;
      element.click();
    },
    closeDialogImport() {
      const element: HTMLInputElement = document.getElementsByClassName('custom-file-input')[0] as HTMLInputElement;
      element.value = '';
      this.files = null;
      this.fileName = '';
      this.$emit('closeImportCSV');
    },
  },
});
