var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    attrs: { value: _vm.isVisible, title: _vm.verificationTitle },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "modal-body",
        fn: function() {
          return [
            _vm.isLoadBlockingVerification
              ? _c("loading", {
                  attrs: { text: _vm.loadBlockingTextVerification }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-form",
              {
                ref: "formElmoDevice",
                staticClass: "modal-body-top",
                attrs: { "lazy-validation": "" }
              },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { staticClass: "font-weight-bold" }, [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.verificationMessage) +
                            "\n          "
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c("v-col", { staticClass: "font-weight-bold" }, [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.verificationMessage2) +
                            "\n          "
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c("v-col", { staticClass: "font-weight-bold" }, [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.verificationMessage3) +
                            "\n          "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            _c("success-button", {
              attrs: { text: "登録する" },
              on: {
                click: function($event) {
                  return _vm.clickVerificationSave()
                }
              }
            }),
            _vm._v(" "),
            _c("cancel-button", {
              attrs: { text: "キャンセル" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }