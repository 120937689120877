var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-key-token-setting" },
    [
      _c(
        "v-container",
        [
          _vm.successMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-success" },
                [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-form",
            { ref: "KeyTokenSettingForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "span",
                            { staticClass: "col-2 pl-0 pr-0 mt-1" },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "justify-start title-sub",
                                  staticStyle: { "padding-left": "12px" }
                                },
                                [_vm._v("オフラインでの鍵有効期間")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("input-form", {
                            staticClass: "v-input--reverse swicth-all",
                            attrs: { inputType: "switch" },
                            model: {
                              value: _vm.settingInfor.keyTokenRefreshable,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.settingInfor,
                                  "keyTokenRefreshable",
                                  $$v
                                )
                              },
                              expression: "settingInfor.keyTokenRefreshable"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "v-subheader theme--light no-padding"
                          },
                          [
                            _vm._v(
                              "\n              オフラインでの鍵有効期間\n            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.listKeyTokenLifetime,
                              "item-text": "text",
                              "item-value": "value",
                              "single-line": "",
                              color: "green",
                              "hide-details": "auto",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.settingInfor.keyTokenLifetime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.settingInfor,
                                  "keyTokenLifetime",
                                  $$v
                                )
                              },
                              expression: "settingInfor.keyTokenLifetime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "v-subheader theme--light no-padding"
                          },
                          [_vm._v("\n              更新間隔\n            ")]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.listKeyTokenRefreshRate,
                              "item-text": "text",
                              "item-value": "value",
                              "single-line": "",
                              color: "green",
                              "hide-details": "auto",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.settingInfor.keyTokenRefreshRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.settingInfor,
                                  "keyTokenRefreshRate",
                                  $$v
                                )
                              },
                              expression: "settingInfor.keyTokenRefreshRate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "messageRow",
                      attrs: { cols: "10", align: "right" }
                    },
                    [
                      _vm.successMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "mr-5 mb-4 font-weight-bold mt-3 bg-success bg-no messageRow",
                              attrs: { cols: "9", align: "right" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.successMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "mr-5 mb-4 font-weight-bold mt-3 bg-error bg-no messageRow",
                              attrs: { cols: "9", align: "right" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errorMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("saveKeyToken")
                            }
                          }
                        },
                        [_vm._v("\n            設定を保存\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }