







































































import { Vue } from 'vue-property-decorator';
import ZenescanTemplate from '@/components/templates/ZenescanTemplate/ZenescanTemplate.vue';
import * as Config from '@/config';
import { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import { PCode, User, ZenescanFaceDb, FaceDb, FaceImage, ZenescanRequestLogin } from '@/types/alligate-zenescan';
import {
  getAllFaceImages, postFaceImage, putFaceImage, deleteFaceImage,
  PostFaceImageBody, getFaceImagesLimitPage } from '@/utils/alligate/face-image';
import { getAllUsers } from '@/utils/alligate/user';
import { getAllPcodes, postPcode, putPcode, PostPcodeBody,
  PutPcodeBody, getTargetPcodes, getAllPcodesEmbedUser } from '@/utils/alligate/pcode';
import { getFaceImage, postFaceImageFile } from '@/utils/alligate/face-image-file';
import { FaceImageFile, ProgressModalInfo, EditFaceImage} from '@/types/alligate-zenescan';
import { FaceImageTemplateExcel } from '@/utils/excel/face-image-template';
import { SearchValue } from '@/components/molecules/Search/Search.vue';
import ProgressModal from '@/components/molecules/ProgressModal/ProgressModal.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import FaceImageInputVerification from '@/components/organisms/FaceImageInputVerification/FaceImageInputVerification.vue';
import ZenescanFaceImageTarget from '@/components/organisms/ZenescanFaceImageTarget/ZenescanFaceImageTarget.vue';

export interface DataType {
  loading: boolean;
  zenescanFaceImageLoading: boolean;
  successMessages: string[];
  errorMessages: string[];
  tab: string;
  faceImages: FaceImage[];
  showSelectZenescanFaceImage: boolean;
  typeItems: SelectItem[];
  pCodes: PCode[];
  users: User[];
  faceImageProgressModalInfo: ProgressModalInfo;
  imageTablePerPage: number;
  imageTablePage: number;
  faceImageSearchValue: SearchValue;
  isSelectedClearFaceImage: boolean;
  showInputVerificationModal: boolean;
  inputFaceImage: FaceImageFile[];
  verificationTitle: string;
  verificationMessage1: string;
  verificationMessage2: string;
  verificationMessage3: string;
  showEditFaceImage: boolean;
  editFaceImage: EditFaceImage;
  faceImageTotalCount: number;
  faceImagePage: number;
  faceImagePerPage: number;
  isDisabledPaginationFaceImage: boolean;
  userItems: SelectItem[];
  pCodeItems: SelectItem[];
}

export default Vue.extend({
  name: 'FaceAuthenticationPage',
  components: {
    ZenescanTemplate,
    ProgressModal,
    Alert,
    FaceImageInputVerification,
    ZenescanFaceImageTarget,
  },
  data: (): DataType => ({
    successMessages: [],
    errorMessages: [],
    loading: false,
    zenescanFaceImageLoading: false,
    pCodes: [],
    users: [],
    faceImages: [],
    tab: 'zenescan-face-image',
    showSelectZenescanFaceImage: true,
    typeItems: [
      { value: 2, text: '顔のみ認証' },
      { value: 0, text: '顔＋カード認証' },
    ],
    faceImageProgressModalInfo: {
      showModal: false,
      indeterminate: false,
      title: '',
      message: '',
      errorMessage: '',
      totalCount: 0,
      finishedCount: 0,
      errorCount: 0,
      errorContents: [],
    },
    imageTablePerPage: 100,
    imageTablePage: 1,
    faceImageSearchValue: {
      text: '',
      targets: ['pCodeId', 'userId', 'userName'],
    },
    isSelectedClearFaceImage: false,
    showInputVerificationModal: false,
    inputFaceImage: [],
    verificationTitle: '確認',
    verificationMessage1: 'ファイルサイズが4MB以上の顔写真が、含まれています。',
    verificationMessage2: 'ファイルサイズが4MB以上の顔写真は、登録時に圧縮されます。',
    verificationMessage3: '継続する場合は、「登録する」ボタンをクリックしてください。',
    showEditFaceImage: false,
    editFaceImage: {
      faceImageId: '',
      pCodeId: '',
      pCodeIdOld: '',
      pCodeType: 0,
      pCodeTypeOld: 0,
      pCodeIsValid: true,
      userId: '',
      userIdOld: '',
      userName: '',
      imageBase64: '',
    },
    faceImageTotalCount: 0,
    faceImagePage: 1,
    faceImagePerPage: 25,
    isDisabledPaginationFaceImage: true,
    userItems: [],
    pCodeItems: [],
  }),
  created() {
    this.loading = true;
    Promise.all([
      this.load(),
    ])
    .catch((err) => {
      window.console.log(`created-load-error:${err.message}`);
    })
    .finally(() => {
      this.loading = false;
    });
  },
  mounted() {
    this.$emit('activeMenu', 'zenescan');
  },
  computed: {
  },
  methods: {
    async load(): Promise<void> {
      try {
        this.faceImageLoad();
      } catch (err) {
        this.errorMessages = (err as any).message;
      }
    },
    async faceImageLoad() {
      try {
        this.zenescanFaceImageLoading = true;
        this.isDisabledPaginationFaceImage = true;
        await this.getFaceImages(false, null);
        this.pCodes = await this.getTargetPcodes();
        await this.updatePcodesToFaceImages();
        this.users = await this.getTargetUsers();
        await this.addPcodeItems();
        await this.addUserItems();
        this.zenescanFaceImageLoading = false;
        this.isDisabledPaginationFaceImage = false;
      } catch (err) {
        this.zenescanFaceImageLoading = false;
        this.isDisabledPaginationFaceImage = false;
      }
    },
    formUpdateFaceImage(event: any) {
      // inputイベント受け取り用
      this.$set(this.faceImages, event.index, event.value);
    },
    async delFaceImage(faceImageId: string): Promise<void> {
      try {
        const targetIndex = this.faceImages.findIndex((f) => f.faceImageId === faceImageId);
        // 見た目上、最初に消しておく(API実行中に実行される可能性があるため)
        this.faceImages.splice(targetIndex, 1);
        await deleteFaceImage(this, faceImageId);
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async updateImageOfPcode(index: number, body: FaceImage) {
      try {
        await putFaceImage(this, body.faceImageId, body.pCodeId);
        this.$set(this.faceImages, index, body);
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async getFaceImages(isControlLoading: boolean, pCodeId: string | undefined | null): Promise<void> {
      try {
        if (isControlLoading) {
          this.zenescanFaceImageLoading = true;
          this.isDisabledPaginationFaceImage = true;
        }
        await this.getFaceImageOnePage(pCodeId);
        // 表示件数が1000件以外は、顔画像を取得
        if (this.faceImagePerPage !== 1000) {
          await this.getFaceImageFile();
        }
        if (isControlLoading) {
          this.zenescanFaceImageLoading = false;
          this.isDisabledPaginationFaceImage = false;
        }
        return;
      } catch (err) {
        if (isControlLoading) {
          this.zenescanFaceImageLoading = false;
          this.isDisabledPaginationFaceImage = false;
        }
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async getFaceImageOnePage(pCodeId: string | undefined | null): Promise<void> {
      try {
        this.faceImages = [];
        const [tempfaceImages, maxRecords]: [FaceImage[], number] =
          await getFaceImagesLimitPage(this, this.faceImagePerPage, this.faceImagePage, pCodeId);
        this.faceImageTotalCount = maxRecords;
        for (const [index, faceImage] of tempfaceImages.entries()) {
          this.faceImages.push({
            faceImageId: faceImage.faceImageId,
            md5: faceImage.md5,
            pCodeId: faceImage.pCodeId,
            pCode: faceImage.pCode,
          });
        }
        return;
      } catch (err) {
        throw err;
      }
    },
    async getFaceImageFile(): Promise<void> {
      try {
        // 25件ずつ画像を取得
        const concurrentSize = 25;
        const paramses = [];
        for (let i = 0; i < this.faceImages.length; i += concurrentSize) {
          paramses.push(this.faceImages.slice(i, i + concurrentSize));
        }
        for (const [index, params] of paramses.entries()) {
          const subPromises = [];
          for (const [count, faceImage] of params.entries()) {
            subPromises.push(
              getFaceImage(this, faceImage.faceImageId)
              .then((faceImageBase64) => {
                const targetIndex = index * concurrentSize + count;
                if (faceImageBase64 && faceImageBase64.search(/^data:image\/[a-z,*]+;base64,/) === -1) {
                  this.faceImages[targetIndex].faceImageBase64 = 'data:image/*;base64,' + faceImageBase64;
                } else {
                  this.faceImages[targetIndex].faceImageBase64 = faceImageBase64;
                }
                this.$set(this.faceImages, targetIndex, faceImage);
                return Promise.resolve();
              }),
            );
          }
          await Promise.all(subPromises);
        }
        return;
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async getTargetPcodes(): Promise<PCode[]> {
      try {
        // const allPcodes = await getAllPcodes(this);
        const allPcodes = await getAllPcodesEmbedUser(this);
        const targetPcodes: PCode[] = allPcodes.filter((p) => (p.type === 0 || p.type === 2));
        return targetPcodes;
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async getTargetUsers(): Promise<User[]> {
      try {
        return await getAllUsers(this);
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    // 個人コードの追加
    async addPcodes(bodys: PostPcodeBody[]) {
      try {
        await Promise.all(bodys.map((b) => postPcode(this, b)));
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    // 個人コードの変更
    async updatePcode(pcodeId: string, body: PutPcodeBody) {
      try {
        await putPcode(this, pcodeId, body);
      } catch (err) {
        throw err;
      }
    },
    // 顔写真の一時保存
    async addFaceImageFiles(faceImageFiles: File[]): Promise<string[]> {
      try {
        const faceImageIds: string[] = await Promise.all(faceImageFiles.map((f) => postFaceImageFile(this, f)));
        return faceImageIds;
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    // 顔写真の登録
    async postFaceImages(faceImages: PostFaceImageBody[]) {
      try {
        await Promise.all(faceImages.map((f) => postFaceImage(this, f)));
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    // 顔写真の一時保存(1件)
    async addFaceImageFile(faceImageFile: File): Promise<string> {
      try {
        const faceImageId: string = await postFaceImageFile(this, faceImageFile);
        return faceImageId;
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    // 顔写真の登録(1件)
    async postFaceImage(faceImage: PostFaceImageBody) {
      try {
        await postFaceImage(this, faceImage);
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    // 個人コードの追加(1件)
    async addPcode(body: PostPcodeBody) {
      try {
        await postPcode(this, body);
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    // 一括登録のテンプレートのダウンロード
    async downloadFaceImageTemplateExcel() {
      const excel = new FaceImageTemplateExcel();
      try {
        await excel.downloadExcelFromCloud(this);
        await excel.downloadExcel();
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async deleteFaceImageById(faceImageId: string) {
      try {
        const targetFaceImage = this.faceImages.find((f) => f.faceImageId === faceImageId);
        if (targetFaceImage) {
          // 顔のみ認証の場合、無効にする
          if (targetFaceImage.pCodeType === 2) {
            const body: PutPcodeBody = {
              // ユーザーの紐付けは解除しない
              // userId: '',
              isValid: false,
            };
            await this.updatePcode(targetFaceImage.pCodeId, body);
          }
          await this.delFaceImage(faceImageId);
        }
      } catch (err) {
        throw err;
      }
    },
    async updateIsValidFaceImage(target: any) {
      try {
        if (target.faceImageId
          && target.pCodeId
          && target.isValid !== undefined
          && typeof(target.isValid) === 'boolean') {
          await this.updateFaceImage(target.faceImageId, target.pCodeId, target.isValid);
        }
      } catch (err) {
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async updateFaceImage(faceImageId: string, pCodeId: string, isValid: boolean) {
      try {
        const targetIndex = this.faceImages.findIndex((f) => f.faceImageId === faceImageId);
        const targetPcodeIndex = this.pCodes.findIndex((p) => p.pCodeId === pCodeId);
        // 先に見た目上のisValidを変更してから、APIでisValidを更新する
        if (targetIndex !== -1) {
          this.faceImages[targetIndex].pCodeIsValid = isValid;
        }
        if (targetPcodeIndex !== -1) {
          this.pCodes[targetPcodeIndex].isValid = isValid;
        }
        const body: PutPcodeBody = {
          isValid: isValid ? true : false,
        };
        await this.updatePcode(pCodeId, body);
      } catch (err) {
        throw err;
      }
    },
    async bulkAddFaceImages(faceImages: FaceImageFile[]) {
      // 最大ファイルサイズ(4MB)
      const maxFileSize = 4 * 1024 * 1024;
      // 画像のサイズチェックして、確認ダイアログを表示する
      const filterCount = faceImages.filter((f) => f.imageFile.size > maxFileSize).length;
      if (filterCount > 0) {
        this.showInputVerificationModal = true;
        this.inputFaceImage = faceImages;
      } else {
        await this.addFaceImages(faceImages);
      }
    },
    async verificationSaveFaceImage(faceImages: FaceImageFile[]) {
        this.showInputVerificationModal = false;
        await this.addFaceImages(faceImages);
    },
    async addFaceImages(faceImages: FaceImageFile[]) {
      if (faceImages.length > 0) {
        // プログレスバーを表示
        this.faceImageProgressModalInfo.finishedCount = 0;
        this.faceImageProgressModalInfo.errorCount = 0;
        this.faceImageProgressModalInfo.errorContents = [];
        this.faceImageProgressModalInfo.errorMessage = '';
        this.faceImageProgressModalInfo.showModal = true;
        this.faceImageProgressModalInfo.indeterminate = true;
        this.faceImageProgressModalInfo.title = '顔写真を一括登録中です...';
        this.faceImageProgressModalInfo.totalCount = faceImages.length;
        for (const [index, item] of faceImages.entries()) {
          try {
            // 顔写真の一時保存
            this.faceImageProgressModalInfo.message =
              `${index + 1}件目の顔写真を一時保存中です(${index + 1}/${faceImages.length})...`;
            const faceImageId = await this.addFaceImageFile(item.imageFile);
            // 個人コードの登録(種別2のみ)
            if (item.pCodeType === 2) {
              this.faceImageProgressModalInfo.message =
                `${index + 1}件目のカードを登録中です(${index + 1}/${faceImages.length})...`;
              await this.addPcode({
                pCodeId: item.pCodeId,
                name: item.pCodeId,
                type: item.pCodeType,
                isValid: true,
                userId: item.userId,
              });
            }
            this.faceImageProgressModalInfo.message =
              `${index + 1}件目の顔写真情報を登録中です(${index + 1}/${faceImages.length})...`;
            await this.postFaceImage({
              imageFileId: faceImageId,
              pCodeId: item.pCodeId,
            });
          } catch (err) {
            this.faceImageProgressModalInfo.errorCount += 1;
            this.faceImageProgressModalInfo.errorContents.push(`${index + 1}件目: ${(err as any).message}`);
          }
          this.faceImageProgressModalInfo.finishedCount = index + 1;
        }
        this.faceImageProgressModalInfo.indeterminate = false;
        this.faceImageProgressModalInfo.title = '顔写真の登録が完了しました';
        this.faceImageProgressModalInfo.message = '顔写真の登録が完了しました';
        // 1件でも登録が成功していたらリロードを実行する
        if (this.faceImageProgressModalInfo.errorCount !== faceImages.length) {
          // リロード
          this.faceImagePage = 1;
          this.pCodes = await this.getTargetPcodes();
          await this.getFaceImages(true, null);
          await this.updatePcodesToFaceImages();
        }
      }
    },
    async progressModalClose() {
      this.faceImageProgressModalInfo.showModal = false;
    },
    async showDialogConfirm(selectImem: any) {
      try {
        if (selectImem.faceImageIds
          && selectImem.pCodeIds
          && selectImem.type !== undefined
          && typeof(selectImem.type) === 'number') {
          // 有効化
          if (selectImem.type === 0) {
            this.faceImageProgressModalInfo.errorCount = 0;
            this.faceImageProgressModalInfo.errorContents = [];
            this.faceImageProgressModalInfo.errorMessage = '';
            this.faceImageProgressModalInfo.showModal = true;
            this.faceImageProgressModalInfo.indeterminate = true;
            this.faceImageProgressModalInfo.title = '顔写真を有効化中です...';
            this.faceImageProgressModalInfo.totalCount = selectImem.faceImageIds.length;
            this.faceImageProgressModalInfo.finishedCount = 0;
            for (const [index, faceImageId] of selectImem.faceImageIds.entries()) {
              this.faceImageProgressModalInfo.message = `${index + 1}件目の顔写真を有効化中です...(${index + 1}/${selectImem.faceImageIds.length})...`;
              await this.updateFaceImage(faceImageId, selectImem.pCodeIds[index], true);
              this.faceImageProgressModalInfo.finishedCount += 1;
            }
            this.faceImageProgressModalInfo.indeterminate = false;
            this.faceImageProgressModalInfo.title = '顔写真の有効化が完了しました';
            this.faceImageProgressModalInfo.message = '顔写真の有効化が完了しました';
            this.isSelectedClearFaceImage = true;
          // 無効化
          } else if (selectImem.type === 1) {
            this.faceImageProgressModalInfo.errorCount = 0;
            this.faceImageProgressModalInfo.errorContents = [];
            this.faceImageProgressModalInfo.errorMessage = '';
            this.faceImageProgressModalInfo.showModal = true;
            this.faceImageProgressModalInfo.indeterminate = true;
            this.faceImageProgressModalInfo.title = '顔写真を無効化中です...';
            this.faceImageProgressModalInfo.totalCount = selectImem.faceImageIds.length;
            this.faceImageProgressModalInfo.finishedCount = 0;
            for (const [index, faceImageId] of selectImem.faceImageIds.entries()) {
              this.faceImageProgressModalInfo.message = `${index + 1}件目の顔写真を無効化中です...(${index + 1}/${selectImem.faceImageIds.length})...`;
              await this.updateFaceImage(faceImageId, selectImem.pCodeIds[index], false);
              this.faceImageProgressModalInfo.finishedCount += 1;
            }
            this.faceImageProgressModalInfo.indeterminate = false;
            this.faceImageProgressModalInfo.title = '顔写真の無効化が完了しました';
            this.faceImageProgressModalInfo.message = '顔写真の無効化が完了しました';
            this.isSelectedClearFaceImage = true;
          // 削除
          } else if (selectImem.type === 2) {
            this.faceImageProgressModalInfo.errorCount = 0;
            this.faceImageProgressModalInfo.errorContents = [];
            this.faceImageProgressModalInfo.errorMessage = '';
            this.faceImageProgressModalInfo.showModal = true;
            this.faceImageProgressModalInfo.indeterminate = true;
            this.faceImageProgressModalInfo.title = '顔写真を削除中です...';
            this.faceImageProgressModalInfo.totalCount = selectImem.faceImageIds.length;
            this.faceImageProgressModalInfo.finishedCount = 0;
            for (const [index, faceImageId] of selectImem.faceImageIds.entries()) {
              this.faceImageProgressModalInfo.message = `${index + 1}件目の顔写真を削除中です...(${index + 1}/${selectImem.faceImageIds.length})...`;
              await this.deleteFaceImageById(faceImageId);
              this.faceImageProgressModalInfo.finishedCount += 1;
            }
            this.faceImageProgressModalInfo.indeterminate = false;
            this.faceImageProgressModalInfo.title = '顔写真の削除が完了しました';
            this.faceImageProgressModalInfo.message = '顔写真の削除が完了しました';
          }
        }
      } catch (err) {
        this.faceImageProgressModalInfo.showModal = false;
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async singleDeleteFaceImage(faceImageId: string) {
      try {
        this.faceImageProgressModalInfo.errorCount = 0;
        this.faceImageProgressModalInfo.errorContents = [];
        this.faceImageProgressModalInfo.errorMessage = '';
        this.faceImageProgressModalInfo.showModal = true;
        this.faceImageProgressModalInfo.indeterminate = true;
        this.faceImageProgressModalInfo.title = '顔写真を削除中です...';
        this.faceImageProgressModalInfo.totalCount = 1;
        this.faceImageProgressModalInfo.finishedCount = 0;
        this.faceImageProgressModalInfo.message = '顔写真を削除中です...';
        await this.deleteFaceImageById(faceImageId);
        this.faceImageProgressModalInfo.finishedCount = 1;
        this.faceImageProgressModalInfo.indeterminate = false;
        this.faceImageProgressModalInfo.title = '顔写真の削除が完了しました';
        this.faceImageProgressModalInfo.message = '顔写真の削除が完了しました';
      } catch (err) {
        this.faceImageProgressModalInfo.showModal = false;
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async imageTableUpdateItemsPerPage(val: number) {
      this.imageTablePerPage = val;
    },
    async imageTableUpdatePage(val: number) {
      this.imageTablePage = val;
    },
    /**
     * 検索が実行された場合
     */
    async searchFaceImage(searchVal: SearchValue) {
      try {
        this.faceImageSearchValue = searchVal;
        this.faceImagePage = 1;
        if (this.faceImageSearchValue.text && this.faceImageSearchValue.text.length > 0) {
          await this.getFaceImages(true, this.faceImageSearchValue.text);
        } else {
          await this.getFaceImages(true, null);
        }
        await this.updatePcodesToFaceImages();
      } catch (err) {
        throw err;
      }
    },
    selectedClearFaceImage() {
      this.isSelectedClearFaceImage = false;
    },
    noMatchFaceImage() {
      this.errorMessages.push(`追加処理にて、テンプレートの値と実際のファイル名やカード（種別を含む）が不一致のため、処理できるデータがありませんでした`);
    },
    openEditFaceImage(faceImageId: string) {
      const targetFaceImage = this.faceImages.find((f) => f.faceImageId === faceImageId);
      if (targetFaceImage) {
          this.editFaceImage.faceImageId = targetFaceImage.faceImageId;
          this.editFaceImage.imageBase64 = targetFaceImage.faceImageBase64 ? targetFaceImage.faceImageBase64 : '';
          this.editFaceImage.pCodeId = targetFaceImage.pCodeId;
          this.editFaceImage.pCodeIdOld = targetFaceImage.pCodeId;
          if (targetFaceImage.pCodeType) {
            this.editFaceImage.pCodeType = targetFaceImage.pCodeType;
            this.editFaceImage.pCodeTypeOld = targetFaceImage.pCodeType;
          }
          if (targetFaceImage.pCodeIsValid !== undefined) {
            this.editFaceImage.pCodeIsValid = targetFaceImage.pCodeIsValid;
          }
          this.editFaceImage.userId = targetFaceImage.userId;
          this.editFaceImage.userIdOld = targetFaceImage.userId;
          this.showEditFaceImage = true;
      }
    },
    editSaveFaceImage(editValue: any) {
      this.showEditFaceImage = false;
      if (editValue.faceImageId === this.editFaceImage.faceImageId) {
        if (editValue.pCodeType === this.editFaceImage.pCodeTypeOld) {
          if (editValue.pCodeType === 2 && editValue.userId !== this.editFaceImage.userIdOld) {
            // 個人コードのユーザーを変更
            this.updatePcodeTypeFaceImage(editValue.faceImageId, editValue.pCodeId,
              this.editFaceImage.pCodeIsValid, editValue.userId);
          } else if (editValue.pCodeType === 0 && editValue.pCodeId !== this.editFaceImage.pCodeIdOld) {
            // 顔写真の個人コードを変更
            this.updateFaceImagePcodeId(editValue.faceImageId, editValue.pCodeId);
          }
        } else {
          if (editValue.pCodeType === 2) {
            // 照合タイプが顔のみに変更なった場合
            // 個人コードを追加
            // 顔写真の個人コードを変更
            this.addPocdeAndUpdateFaceImage(editValue.faceImageId, editValue.pCodeId, editValue.userId);
          } else if (editValue.pCodeType === 0) {
            // 照合タイプが顔＋カードに変更なった場合
            // 元の個人コードを無効化
            // 顔写真の個人コードを変更
            this.invalidPocdeAndUpdateFaceImage(editValue.faceImageId, editValue.pCodeId, this.editFaceImage.pCodeId);
          }
        }
      }
    },
    async changePageFaceImage(page: number) {
      try {
        this.faceImagePage = page;
        if (this.faceImageSearchValue.text && this.faceImageSearchValue.text.length > 0) {
          await this.getFaceImages(true, this.faceImageSearchValue.text);
        } else {
          await this.getFaceImages(true, null);
        }
        await this.updatePcodesToFaceImages();
      } catch (err) {
        throw err;
      }
    },
    async changePerPageFaceImage(perPage: number) {
      try {
        this.faceImagePerPage = perPage;
        this.faceImagePage = 1;
        if (this.faceImageSearchValue.text && this.faceImageSearchValue.text.length > 0) {
          await this.getFaceImages(true, this.faceImageSearchValue.text);
        } else {
          await this.getFaceImages(true, null);
        }
        await this.updatePcodesToFaceImages();
      } catch (err) {
        throw err;
      }
    },
    // 個人コードのユーザーを変更
    async updatePcodeTypeFaceImage(faceImageId: string, pCodeId: string, pCodeIsValid: boolean, userId: string) {
      try {
        const body: PutPcodeBody = {
          userId,
          isValid: pCodeIsValid,
        };
        this.faceImageProgressModalInfo.errorCount = 0;
        this.faceImageProgressModalInfo.errorContents = [];
        this.faceImageProgressModalInfo.errorMessage = '';
        this.faceImageProgressModalInfo.showModal = true;
        this.faceImageProgressModalInfo.indeterminate = true;
        this.faceImageProgressModalInfo.title = '顔写真に紐ついているカードを更新中です...';
        this.faceImageProgressModalInfo.totalCount = 1;
        this.faceImageProgressModalInfo.finishedCount = 0;
        this.faceImageProgressModalInfo.message = '顔写真に紐ついているカードを更新中です...';
        await putPcode(this, pCodeId, body);
        const targetFaceImageIndex = this.faceImages.findIndex((f) => f.faceImageId === faceImageId);
        if (targetFaceImageIndex !== -1) {
          this.faceImages[targetFaceImageIndex].userId = userId;
          const targetPcodeIndex = this.pCodes.findIndex((p) => p.pCodeId === pCodeId);
          if (targetPcodeIndex !== -1) {
            this.pCodes[targetPcodeIndex].userId = userId;
            const targetUser = this.users.find((u) => u.userId === userId);
            if (targetUser) {
              this.pCodes[targetPcodeIndex].user = targetUser;
              this.faceImages[targetFaceImageIndex].userName = targetUser.name;
            }
          }
        }
        this.faceImageProgressModalInfo.finishedCount = 1;
        this.faceImageProgressModalInfo.indeterminate = false;
        this.faceImageProgressModalInfo.title = '顔写真に紐ついているカードの更新が完了しました';
        this.faceImageProgressModalInfo.message = '顔写真に紐ついているカードの更新が完了しました';
      } catch (err) {
        this.faceImageProgressModalInfo.showModal = false;
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    // 顔写真の個人コードを変更
    async updateFaceImagePcodeId(faceImageId: string, pCodeId: string) {
      try {
        this.faceImageProgressModalInfo.errorCount = 0;
        this.faceImageProgressModalInfo.errorContents = [];
        this.faceImageProgressModalInfo.errorMessage = '';
        this.faceImageProgressModalInfo.showModal = true;
        this.faceImageProgressModalInfo.indeterminate = true;
        this.faceImageProgressModalInfo.title = '顔写真に紐ついているカードを更新中です...';
        this.faceImageProgressModalInfo.totalCount = 1;
        this.faceImageProgressModalInfo.finishedCount = 0;
        this.faceImageProgressModalInfo.message = '顔写真に紐ついているカードを更新中です...';
        await putFaceImage(this, faceImageId, pCodeId);
        const targetFaceImageIndex = this.faceImages.findIndex((f) => f.faceImageId === faceImageId);
        if (targetFaceImageIndex !== -1) {
          this.faceImages[targetFaceImageIndex].pCodeId = pCodeId;
          const targetPcode = this.pCodes.find((p) => p.pCodeId === pCodeId);
          if (targetPcode) {
            this.faceImages[targetFaceImageIndex].pCodeIsValid = targetPcode.isValid;
            this.faceImages[targetFaceImageIndex].userId = targetPcode.userId;
            if (targetPcode.user) {
              this.faceImages[targetFaceImageIndex].userName = targetPcode.user.name;
            }
          }
        }
        this.faceImageProgressModalInfo.finishedCount = 1;
        this.faceImageProgressModalInfo.indeterminate = false;
        this.faceImageProgressModalInfo.title = '顔写真に紐ついているカードの更新が完了しました';
        this.faceImageProgressModalInfo.message = '顔写真に紐ついているカードの更新が完了しました';
      } catch (err) {
        this.faceImageProgressModalInfo.showModal = false;
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    // 個人コードを追加し、顔写真の個人コードを変更
    async addPocdeAndUpdateFaceImage(faceImageId: string, pCodeId: string, userId: string) {
      try {
        const body: PCode = {
          pCodeId,
          name: pCodeId,
          type: 2,
          isValid: true,
          userId,
        };
        this.faceImageProgressModalInfo.errorCount = 0;
        this.faceImageProgressModalInfo.errorContents = [];
        this.faceImageProgressModalInfo.errorMessage = '';
        this.faceImageProgressModalInfo.showModal = true;
        this.faceImageProgressModalInfo.indeterminate = true;
        this.faceImageProgressModalInfo.title = '顔写真を更新中です...';
        this.faceImageProgressModalInfo.totalCount = 1;
        this.faceImageProgressModalInfo.finishedCount = 0;
        this.faceImageProgressModalInfo.message = '顔のみ認証の個人コードを追加中です...';
        await postPcode(this, body);
        this.faceImageProgressModalInfo.message = '顔写真の更新中です...';
        await putFaceImage(this, faceImageId, pCodeId);
        const targetFaceImageIndex = this.faceImages.findIndex((f) => f.faceImageId === faceImageId);
        if (targetFaceImageIndex !== -1) {
          const targetUser = this.users.find((u) => u.userId === userId);
          if (targetUser) {
            body.user = targetUser;
          }
          // 先頭に追加
          this.pCodes.unshift(body);
          this.faceImages[targetFaceImageIndex].pCodeId = pCodeId;
          this.faceImages[targetFaceImageIndex].pCodeType = 2;
          this.faceImages[targetFaceImageIndex].pCodeTypeName = '顔のみ認証';
          this.faceImages[targetFaceImageIndex].pCodeIsValid = true;
          this.faceImages[targetFaceImageIndex].userId = userId;
          this.faceImages[targetFaceImageIndex].userName = targetUser ? targetUser.name : '';
        }
        this.faceImageProgressModalInfo.finishedCount = 1;
        this.faceImageProgressModalInfo.indeterminate = false;
        this.faceImageProgressModalInfo.title = '顔写真を更新が完了しました';
        this.faceImageProgressModalInfo.message = '顔写真を更新が完了しました';
      } catch (err) {
        this.faceImageProgressModalInfo.showModal = false;
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    // 元の個人コードを無効化し、顔写真の個人コードを変更
    async invalidPocdeAndUpdateFaceImage(faceImageId: string, pCodeIdNew: string, pCodeIdOld: string) {
      try {
        const body: PutPcodeBody = {
          isValid: false,
        };
        this.faceImageProgressModalInfo.errorCount = 0;
        this.faceImageProgressModalInfo.errorContents = [];
        this.faceImageProgressModalInfo.errorMessage = '';
        this.faceImageProgressModalInfo.showModal = true;
        this.faceImageProgressModalInfo.indeterminate = true;
        this.faceImageProgressModalInfo.title = '顔写真を更新中です...';
        this.faceImageProgressModalInfo.totalCount = 1;
        this.faceImageProgressModalInfo.finishedCount = 0;
        this.faceImageProgressModalInfo.message = '顔のみ認証の個人コードを無効化中です...';
        await putPcode(this, pCodeIdOld, body);
        this.faceImageProgressModalInfo.message = '顔写真の更新中です...';
        await putFaceImage(this, faceImageId, pCodeIdNew);
        const targetPcodeIndex = this.pCodes.findIndex((p) => p.pCodeId === pCodeIdOld);
        if (targetPcodeIndex !== -1) {
          this.pCodes[targetPcodeIndex].isValid = false;
        }
        const targetFaceImageIndex = this.faceImages.findIndex((f) => f.faceImageId === faceImageId);
        if (targetFaceImageIndex !== -1) {
          this.faceImages[targetFaceImageIndex].pCodeId = pCodeIdNew;
          const targetPcode = this.pCodes.find((p) => p.pCodeId === pCodeIdNew);
          if (targetPcode) {
            this.faceImages[targetFaceImageIndex].pCodeType = 0;
            this.faceImages[targetFaceImageIndex].pCodeTypeName = '顔＋カード認証';
            this.faceImages[targetFaceImageIndex].pCodeIsValid = targetPcode.isValid;
            this.faceImages[targetFaceImageIndex].userId = targetPcode.userId;
            this.faceImages[targetFaceImageIndex].userName = targetPcode.user ? targetPcode.user.name : '';
          }
        }
        this.faceImageProgressModalInfo.finishedCount = 1;
        this.faceImageProgressModalInfo.indeterminate = false;
        this.faceImageProgressModalInfo.title = '顔写真を更新が完了しました';
        this.faceImageProgressModalInfo.message = '顔写真を更新が完了しました';
      } catch (err) {
        this.faceImageProgressModalInfo.showModal = false;
        this.errorMessages.push((err as any).message);
        throw err;
      }
    },
    async updatePcodesToFaceImages() {
      try {
        const ENUM_PCODE_TYPE = [
            '顔＋カード認証',
            'テンキー認証',
            '顔のみ認証',
        ];
        for (const [index, faceImage] of this.faceImages.entries()) {
          const targetPcode = this.pCodes.find((p) => p.pCodeId === faceImage.pCodeId);
          if (targetPcode) {
            this.faceImages[index].pCodeType = targetPcode.type;
            this.faceImages[index].pCodeTypeName = ENUM_PCODE_TYPE[targetPcode.type];
            this.faceImages[index].pCodeIsValid = targetPcode.isValid;
            this.faceImages[index].userId = targetPcode.userId;
            if (targetPcode.user) {
              this.faceImages[index].userName = targetPcode.user.name;
            }
            this.$set(this.faceImages, index, this.faceImages[index]);
          }
        }
      } catch (err) {
        throw err;
      }
    },
    async addUserItems() {
      try {
        this.userItems = this.users.filter((f) => f.isValid === true).map((user) => {
          return {
            text: user.name === '' ? user.userId : `${user.name}(${user.userId})`,
            value: user.userId,
          };
        });
      } catch (err) {
        throw err;
      }
    },
    async addPcodeItems() {
      try {
        this.pCodeItems = this.pCodes.filter((f) => f.type === 0 && f.userId !== '').map((pcode) => {
          let userInfo = '';
          if (pcode.user) {
            if (pcode.user.name && pcode.user.name.length > 0) {
              userInfo = ` - ユーザー：${pcode.user.name}(${pcode.userId})`;
            } else {
              userInfo = ` - ユーザー：${pcode.userId}`;
            }
          }
          return {
            text: pcode.name === '' ? `カード：${pcode.pCodeId}${userInfo}` : `カード：${pcode.name}(${pcode.pCodeId})${userInfo}`,
            value: pcode.pCodeId,
          };
        });
      } catch (err) {
        throw err;
      }
    },
    async clickFaceImage(faceImageId: string) {
      try {
        await this.getSingleFaceImage(faceImageId);
      } catch (err) {
        throw err;
      }
    },
    async getSingleFaceImage(faceImageId: string) {
      try {
        const targetIndex = this.faceImages.findIndex((f) => f.faceImageId === faceImageId);
        if (targetIndex !== -1) {
          const faceImageBase64 = await getFaceImage(this, faceImageId);
          if (faceImageBase64 && faceImageBase64.search(/^data:image\/[a-z,*]+;base64,/) === -1) {
            this.faceImages[targetIndex].faceImageBase64 = 'data:image/*;base64,' + faceImageBase64;
          } else {
            this.faceImages[targetIndex].faceImageBase64 = faceImageBase64;
          }
          this.$set(this.faceImages, targetIndex, this.faceImages[targetIndex]);
        }
      } catch (err) {
        throw err;
      }
    },
  },
});
