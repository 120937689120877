






















































































































































































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import moment from 'moment';
import UsersAllowedTable from '@/components/organisms/UsersAllowedTable/UsersAllowedTable.vue';
import GateGroupListTable from '@/components/organisms/UsersAllowedTable/GateGroupListTable.vue';
import GateListTable from '@/components/organisms/AccessPermissionGroupTable/GateListTable.vue';
import GroupListTable from '@/components/organisms/UsersAllowedTable/GroupListTable.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';

import * as Config from '@/config';
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  groupSelected: [];
  isVaildOnly: boolean;
  showOption: boolean;
  expanded: any;
  tab: any;
  actionItems: any;
  type: string;
  showProgress: boolean;
  isShowGroup: boolean;
  showConfirm: boolean;
  isReloadData: boolean;
  searchTargetItems: SearchTargetItem[];
}

export default Vue.extend({
  name: 'AccessPermissionGroupTable',
  components: {
    ShowPerPage,
    Pagination,
    DisplayOption,
    SelectPerPage,
    TextLink,
    UsersAllowedTable,
    GateListTable,
    GateGroupListTable,
    GroupListTable,
    Searchbox,
    Modal,
    SuccessButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    dataGroupsModal: {
      type: Object,
      default: {
        data: [],
        total: 0,
      },
    },
    gateGroup: {
      type: Object,
      default: {
        data: [],
        total: 0,
        loading: false,
      },
    },
    dataRelation: {
      type: Object,
      default: () => ({}),
    },
    groups: {
      type: Object,
      default: () => ({}),
    },
    authority: {
      type: Object,
      default: ({}),
    },
    isPage: {
      type: String,
      default: '',
    },
    isActionPage: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (typeof localStorage.displayOptionGroupModal === 'undefined') {
      localStorage.setItem('displayOptionGroupModal', JSON.stringify(this.selectedDisplayOption));
    } else {
      this.selectedDisplayOption = JSON.parse(localStorage.displayOptionGroupModal);
    }
  },
  watch: {
    groupSelected(val: any) {
      if (this.isPage === 'affiliation') {
        this.$emit('select-Ids', val.map((item: any) => item.relationId));
      } else if (this.isPage === 'affiliation-add') {
        this.$emit('select-Ids', val.map((item: any) => item));
      } else {
        this.$emit('select-Ids', val.map((item: any) => item.groupId));
      }
    },
    isRemoveGroupSelected(value) {
        this.groupSelected = [];
    },
    selectedDisplayOption(value) {
      localStorage.setItem('displayOptionGroupModal', JSON.stringify(value));
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: '通行権限グループ名', value: 'name' },
    ],
    actionItems: [
      { title: '所属ユーザー', tabname: 'tab1' },
      { title: '設定されている通行権限グループ', tabname: 'tab2' },
    ],
    tab: '',
    expanded: [],
    groupSelected: [],
    selectedDisplayOption: [
      'name',
      'gateCount',
    ],
    displayOptionItems: [
      { label: '所属名', value: 'name', isDisabled: true },
    ],
    isVaildOnly: localStorage.isVaildDataGroup === 'true' ? true : false,
    showOption: false,
    type: 'add',
    showProgress: false,
    isShowGroup: false,
    showConfirm: false,
    isReloadData: false,
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '適用されている通行権限グループ',
            align: 'start',
            value: 'name',
            width: '265px',
          },
        ];
        headers.push({
            text: 'ゲート',
            align: 'start',
            value: 'data-table-expand',
            width: '190px',
          });
        headers.push({
            text: '通行可能時間帯',
            align: 'start',
            value: 'passableTimeSettingName',
            width: '150px',
          });
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.expanded = [];
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  methods: {
    loadShowGroup() {
      this.isShowGroup = true;
      this.$emit('load-list-groups', 1);
    },
    clickGroupDetail(ID: any) {
      if (this.$route.fullPath === '/groups') {
        this.$emit('back-group-detail', ID);
      } else {
        this.$router.push({ path: Config.GROUP, query: {groupId: ID}});
        this.$emit('click-group-detail', ID);
      }
    },
    closeModalGroup(isReloadData: any) {
      this.showProgress = false;
      this.groupSelected = [];
      if (isReloadData === true) {
        if (this.type === 'del') {
          this.$emit('reload-data-group');
        } else {
          this.$emit('reload-data-group-again');
          this.$emit('load-list-groups', 1);
        }
      }
    },
    removeGroup() {
      this.type = 'del';
      this.showConfirm = true;
      // this.$emit('remove-group', data);
    },
    yesChoice() {
      this.type = 'del';
      if (this.isActionPage === 'add') {
        this.showProgress = false;
        this.showConfirm = false;
        this.$emit('remove-group', this.groupSelected);
      } else {
        if (this.isPage === 'affiliation-add-new') {
          this.$emit('remove-group', this.groupSelected);
          this.showProgress = true;
          this.showConfirm = false;
        } else {
          this.showProgress = true;
          this.showConfirm = false;
          this.$emit('remove-group', this.groupSelected);
        }
      }
    },
    addGroup(data: any) {
      if (this.isPage === 'affiliation-add-new') {
        this.$emit('add-group', data);
        this.isShowGroup = false;
        this.showConfirm = false;
      } else {
        this.type = 'add';
        this.showProgress = true;
        this.$emit('add-group', data);
      }
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
      });
      return items;
    },
    convertDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm');
    },
    checkPassableTimeSetting(item: any) {
      if (item.passableTimeSetting !== undefined && item.passableTimeSetting.timeSettings !== undefined
      && item.passableTimeSetting.timeSettings !== null && item.passableTimeSetting.timeSettings.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getReload(val: any) {
      this.isReloadData = true;
    },
    closeModal(val: any) {
      if (val) {
        this.$emit('close-modal');
      }
    },
  },
});
