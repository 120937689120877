var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-layout blur-login" },
    [
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.loading, color: "white" } },
        [
          _c("v-progress-circular", {
            staticClass: "user-loading-progress",
            attrs: { indeterminate: "", color: "green" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        {
          style: [
            _vm.step === 1 || _vm.step === 3
              ? { "max-width": "600px" }
              : { "max-width": "780px" }
          ]
        },
        [
          _c(
            "v-card",
            { staticClass: "background-green" },
            [
              _vm.step === 0
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("formSubmit", {
                            ownerId: _vm.credentials.ownerId,
                            username: _vm.username,
                            password: _vm.password
                          })
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12",
                              attrs: { cols: "6", "align-self": "center" }
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-10" },
                                  [
                                    !_vm.$vuetify.breakpoint.xs
                                      ? _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "200px"
                                          }
                                        })
                                      : _c("v-img", {
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "150px",
                                            width:
                                              _vm.$vuetify.breakpoint.width -
                                              150
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.ownerId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-account",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "事業者ID",
                                          autocomplete: "off",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        model: {
                                          value: _vm.credentials.ownerId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "ownerId",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.ownerId"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.ownerId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.ownerId)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.userId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          autocomplete: "off",
                                          "prepend-inner-icon":
                                            "mdi-account-circle",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "ユーザーID",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        model: {
                                          value: _vm.username,
                                          callback: function($$v) {
                                            _vm.username = $$v
                                          },
                                          expression: "username"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.userId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.userId)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.password !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-lock",
                                          "append-icon": _vm.show1
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.show1 ? "text" : "password",
                                          label: "パスワード",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          disabled: _vm.loading,
                                          autocomplete: "off",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            _vm.show1 = !_vm.show1
                                          }
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.errorMessage.password !== ""
                                    ? _c("p", { staticClass: "txt-error" }, [
                                        _vm._v(
                                          _vm._s(_vm.errorMessage.password)
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "allig-text-link text-center col-12",
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("forgot-password")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                パスワードがわからない場合\n              "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "submit",
                                            color: "green",
                                            elevation: "1",
                                            disabled: _vm.disabled
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("signIn", {
                                                ownerId:
                                                  _vm.credentials.ownerId,
                                                username: _vm.username,
                                                password: _vm.password
                                              })
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  ログイン\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { "align-self": "center" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "support-icon",
                                  attrs: {
                                    justify: "center",
                                    "align-content": "center"
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://support.alligate.me/hc/ja",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "center",
                                            "align-content": "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "icon-help" },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-help-circle")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "center",
                                            "align-content": "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-help" },
                                            [_vm._v("ヘルプ")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    justify: "center",
                                    "align-content": "center"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "title-sso" }, [
                                    _vm._v("SSOログインに遷移")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "input-login",
                                          attrs: {
                                            block: "",
                                            type: "submit",
                                            color: "white",
                                            elevation: "2",
                                            disabled: _vm.disabled
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.step = 1
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  SSO ユーザー\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step === 1
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("next", _vm.credentials.ownerId)
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12 mr-12",
                              attrs: { "align-self": "center" }
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-10" },
                                  [
                                    !_vm.$vuetify.breakpoint.xs
                                      ? _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "200px"
                                          }
                                        })
                                      : _c("v-img", {
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "150px",
                                            width:
                                              _vm.$vuetify.breakpoint.width -
                                              150
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.ownerId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-account",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "事業者ID",
                                          autocomplete: "off",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        model: {
                                          value: _vm.credentials.ownerId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "ownerId",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.ownerId"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.ownerId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.ownerId)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "submit",
                                            color: "green",
                                            elevation: "2",
                                            disabled: _vm.disabled
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.$emit(
                                                "next",
                                                _vm.credentials.ownerId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                次へ\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "allig-text-link mr-5",
                                      on: {
                                        click: function($event) {
                                          return _vm.PageLogin()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Alligateログインに戻る\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step === 3
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("confirmSignin", {
                            mfaCode: _vm.credentials.mfaCode
                          })
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12 mr-12",
                              attrs: { "align-self": "center" }
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-10" },
                                  [
                                    !_vm.$vuetify.breakpoint.xs
                                      ? _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "200px"
                                          }
                                        })
                                      : _c("v-img", {
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "150px",
                                            width:
                                              _vm.$vuetify.breakpoint.width -
                                              150
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.mfaCode !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-account",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "認証コード",
                                          autocomplete: "off",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        model: {
                                          value: _vm.credentials.mfaCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "mfaCode",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.mfaCode"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.mfaCode !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.mfaCode)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "button",
                                            color: "green",
                                            elevation: "2",
                                            disabled: _vm.disabled
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "confirmSignin",
                                                {
                                                  mfaCode:
                                                    _vm.credentials.mfaCode
                                                }
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                次へ\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "allig-text-link mr-5",
                                      on: {
                                        click: function($event) {
                                          return _vm.PageLogin()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Alligateログインに戻る\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step === 2
                ? _c(
                    "v-row",
                    { staticClass: "background-white ml-0 mr-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { "align-self": "center" } },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              { staticClass: "logo-text mt-10 mb-10" },
                              [
                                !_vm.$vuetify.breakpoint.xs
                                  ? _c("v-img", {
                                      staticClass: "ml-2",
                                      attrs: {
                                        src: require("../../../assets/image/logo.png"),
                                        "max-height": "100%",
                                        "max-width": "200px"
                                      }
                                    })
                                  : _c("v-img", {
                                      attrs: {
                                        src: require("../../../assets/image/logo.png"),
                                        "max-height": "100%",
                                        "max-width": "150px",
                                        width:
                                          _vm.$vuetify.breakpoint.width - 150
                                      }
                                    })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { justify: "center row-icon-provider" } },
                            [
                              _vm.oneLoginInfo.isValid
                                ? _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "pa-2 my-row",
                                          attrs: { outlined: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "white--text input-login",
                                              attrs: {
                                                tile: "",
                                                color: "#394557",
                                                width: "200px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.$emit(
                                                    "signInWithIdentityProvider",
                                                    _vm.oneLoginInfo
                                                      .cognitoIdProviderName
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  SAML でログイン\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.appleInfo.isValid
                                ? _c(
                                    "v-col",
                                    { attrs: { order: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "pa-2 my-row",
                                          attrs: { outlined: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "white--text input-login",
                                              attrs: {
                                                tile: "",
                                                color: "#000000",
                                                width: "200px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.$emit(
                                                    "signInWithIdentityProvider",
                                                    _vm.appleInfo
                                                      .cognitoIdProviderName
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "icon-provider",
                                                  attrs: { left: "" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-apple\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                  Appleでサインイン\n                "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.googleInfo.isValid
                                ? _c(
                                    "v-col",
                                    { attrs: { order: "1" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "pa-2 my-row",
                                          attrs: { outlined: "", tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "white--text input-login",
                                              attrs: {
                                                tile: "",
                                                color: "#4285f4",
                                                width: "200px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.$emit(
                                                    "signInWithIdentityProvider",
                                                    _vm.googleInfo
                                                      .cognitoIdProviderName
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "logo" },
                                                [
                                                  _c("img", {
                                                    staticClass: "img-google",
                                                    attrs: {
                                                      src: require("@/assets/image/google-icon.svg")
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                  Googleでログイン\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mb-2", attrs: { justify: "end" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "allig-text-link mr-5",
                                  on: {
                                    click: function($event) {
                                      return _vm.PageLogin()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Alligateログインに戻る\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step === 4
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("formSubmitForgot", {
                            ownerId: _vm.credentials.ownerId,
                            username: _vm.username
                          })
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12",
                              attrs: { cols: "12", "align-self": "center" }
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-10" },
                                  [
                                    !_vm.$vuetify.breakpoint.xs
                                      ? _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "200px"
                                          }
                                        })
                                      : _c("v-img", {
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "150px",
                                            width:
                                              _vm.$vuetify.breakpoint.width -
                                              150
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.ownerId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-account",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "事業者ID",
                                          autocomplete: "off",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        model: {
                                          value: _vm.credentials.ownerId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "ownerId",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.ownerId"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.ownerId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.ownerId)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.userId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon":
                                            "mdi-account-circle",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "ユーザーID",
                                          autocomplete: "off",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        model: {
                                          value: _vm.username,
                                          callback: function($$v) {
                                            _vm.username = $$v
                                          },
                                          expression: "username"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.userId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.userId)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "submit",
                                            color: "green",
                                            elevation: "1",
                                            disabled: _vm.disabled
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("forgot", {
                                                ownerId:
                                                  _vm.credentials.ownerId,
                                                username: _vm.username
                                              })
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  送信\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "allig-text-link text-center col-12",
                                      on: {
                                        click: function($event) {
                                          return _vm.PageLogin()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Alligateログインに戻る\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step === 5
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("formSubmitForgot", {
                            credentials: _vm.credentials
                          })
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12",
                              attrs: { cols: "12", "align-self": "center" }
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-10" },
                                  [
                                    !_vm.$vuetify.breakpoint.xs
                                      ? _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "200px"
                                          }
                                        })
                                      : _c("v-img", {
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "150px",
                                            width:
                                              _vm.$vuetify.breakpoint.width -
                                              150
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _vm.error !== ""
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-center col-8 txt-error"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.error) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.ownerId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-account",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "事業者ID",
                                          disabled: "",
                                          autocomplete: "off"
                                        },
                                        model: {
                                          value: _vm.credentials.ownerId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "ownerId",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.ownerId"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.ownerId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.ownerId)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.userId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon":
                                            "mdi-account-circle",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "ユーザーID",
                                          autocomplete: "off",
                                          disabled: ""
                                        },
                                        model: {
                                          value: _vm.credentials.username,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "username",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.username"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.userId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.userId)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.resetCode !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon":
                                            "mdi-account-circle",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label:
                                            "メールにて通知されたリセットコードを入力",
                                          type: "text",
                                          autocomplete: "off",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        model: {
                                          value: _vm.credentials.resetCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "resetCode",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.resetCode"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.resetCode !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errorMessage.resetCode
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "allig-text-link text-center col-8 txt-error"
                                    },
                                    [
                                      _vm._v(
                                        "\n                パスワードは英大文字、英小文字、数字を組み合わせた8文字以上に設定してください。\n              "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.password !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-lock",
                                          type: "password",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "パスワード",
                                          autocomplete: "off",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        model: {
                                          value: _vm.credentials.password,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.password"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.password !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errorMessage.password
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.confirmPassword !==
                                          ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-lock",
                                          type: "password",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "再記入新しいパスワード",
                                          autocomplete: "off",
                                          readonly: "",
                                          onfocus:
                                            "this.removeAttribute('readonly');"
                                        },
                                        model: {
                                          value:
                                            _vm.credentials.confirmPassword,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "confirmPassword",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "credentials.confirmPassword"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errorMessage.confirmPassword !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errorMessage
                                                    .confirmPassword
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "submit",
                                            color: "green",
                                            elevation: "1",
                                            disabled: _vm.disabled
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("resetPass", {
                                                credentials: _vm.credentials
                                              })
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  送信\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "allig-text-link text-center col-12",
                                      on: {
                                        click: function($event) {
                                          return _vm.PageLogin()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Alligateログインに戻る\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step === 6
                ? _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12",
                              attrs: { cols: "12", "align-self": "center" }
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-10" },
                                  [
                                    !_vm.$vuetify.breakpoint.xs
                                      ? _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "200px"
                                          }
                                        })
                                      : _c("v-img", {
                                          attrs: {
                                            src: require("../../../assets/image/logo.png"),
                                            "max-height": "100%",
                                            "max-width": "150px",
                                            width:
                                              _vm.$vuetify.breakpoint.width -
                                              150
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c("p", { staticClass: "txt-success" }, [
                                    _vm._v("パスワードがリセットされました。")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "allig-text-link text-center col-12 mt-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.PageLogin()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Alligateログインに戻る\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }