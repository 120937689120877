var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "form", staticClass: "allig-user-change-password-form" },
    [
      _vm.successMessageLogs !== ""
        ? _c(
            "v-col",
            { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-success" },
            [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.errorMessageLogs !== ""
        ? _c(
            "v-col",
            { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
            [_vm._v("\n    " + _vm._s(_vm.errorMessageLogs) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-container", [
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass:
                      "text-subtitle-2 border-left-blue font-weight-bold"
                  },
                  [_vm._v("\n          パスワード変更\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-card",
              { staticClass: "pa-6 pr-10 mb-5" },
              [
                _c(
                  "v-form",
                  {
                    ref: "UserChangePasswordForm",
                    attrs: { "lazy-validation": "" }
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "text-subtitle-2 border-left-blue font-weight-bold"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "subtitleChangePassword" },
                              [
                                _vm._v(
                                  "\n                現在のログインパスワードと、新しいログインパスワードを入力してください。\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(
                              "\n              ログインパスワードを変更する：\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("input-form", {
                      attrs: {
                        disabled: _vm.loading,
                        title:
                          "現在のパスワード <span class='required'>※必須</span>",
                        inputType: "password",
                        rules: [
                          _vm.rulesValidate.validatePassword(
                            _vm.userInfo.oldPassword
                          )
                        ]
                      },
                      model: {
                        value: _vm.userInfo.oldPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.userInfo, "oldPassword", $$v)
                        },
                        expression: "userInfo.oldPassword"
                      }
                    }),
                    _vm._v(" "),
                    _c("input-form", {
                      attrs: {
                        disabled: _vm.loading,
                        title:
                          "新しいパスワード <span class='required'>※必須</span>",
                        inputType: "password",
                        rules: [
                          _vm.rulesValidate.validatePassword(
                            _vm.userInfo.newPassword
                          )
                        ]
                      },
                      model: {
                        value: _vm.userInfo.newPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.userInfo, "newPassword", $$v)
                        },
                        expression: "userInfo.newPassword"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "col col-2" }),
                        _vm._v(" "),
                        _c("v-col", { staticClass: "col note" }, [
                          _vm._v(
                            "半角英大文字・半角英小文字・数字を組み合わせた8文字以上"
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("input-form", {
                      attrs: {
                        disabled: _vm.loading,
                        title:
                          "新しいパスワード再入力 <span class='required'>※必須</span>",
                        inputType: "password",
                        rules: [
                          _vm.rulesValidate.validatePassword(
                            _vm.userInfo.reNewPassword
                          ),
                          _vm.rulesValidate.validateMatchPassword(
                            _vm.userInfo.newPassword,
                            _vm.userInfo.reNewPassword
                          )
                        ]
                      },
                      model: {
                        value: _vm.userInfo.reNewPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.userInfo, "reNewPassword", $$v)
                        },
                        expression: "userInfo.reNewPassword"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-primary",
                    attrs: { outlined: "" },
                    on: {
                      click: function($event) {
                        return _vm.clickChangePassword()
                      }
                    }
                  },
                  [_vm._v("\n          パスワードを変更 ")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }