























































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import DateTimePickerFull from '@/components/molecules/DateTimePickerFull/DateTimePickerFull.vue';
import { Gate, GatePassage } from '@/types/alligate';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import { User } from '@/types/alligate';
interface Type {
  userId: string;
  name: string;
  gateId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  begin?: string;
  end?: string;
}
interface ArrayDefault {
  text: string;
  value: string;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
const today = new Date();
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  searchTargetItems: SearchTargetItem[];
  selectedItem: Type[];
  dateStart: string;
  second: string;
  hour: string;
  minute: string;
  time: string;
}

export default Vue.extend({
  name: 'GateLockUserListTable',
  components: {
    Searchbox,
    ShowPerPage,
    SuccessButton,
    Pagination,
    DisplayOption,
    SelectPerPage,
    DateTimePickerFull,
  },
  props: {
    userTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    dataUsers: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    gateList: {
      default: () => [],
    },
    dataTimes: {
      type: Array as PropType<ArrayTime[]>,
      default: () => [],
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: 'グループ名', value: 'name' },
    ],
    selectedItem: [],
    selectedDisplayOption: [
      'name',
      'registered',
    ],
    displayOptionItems: [
      { label: 'グループ名', value: 'name', isDisabled: true },
    ],
    dateStart: new Date().toISOString().substr(0, 10),
    second: '',
    hour: '',
    minute: '',
    time: '',
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ユーザーID',
            align: 'start',
            sortable: true,
            value: 'userId',
          },
          {
            text: 'ユーザー名',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: '開始日時',
            align: 'start',
            value: 'begin',
          },
          {
            text: '終了日時',
            align: 'start',
            value: 'end',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
    innerValue: {
      get(): any {
        return this.dataUsers.map((item, index) => {
          const ret = {
            name: item.name,
            userId: item.userId,
            begin: item.begin,
            end: item.end,
            index,
          };
          return ret;
        });
      },
    },
  },
  watch: {
    selectedItem(val: User[]) {
      console.log(val);
      this.$emit('select-users', val.map((item) => item));
    },
  },
  methods: {
    getBeginDateTime(time: string, index: any) {
      this.innerValue[index].begin = time;
    },
    getEndDateTime(time: string, index: any) {
      this.innerValue[index].end = time;
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    formatDate(date: string): string {
      const [year, month, day] = date.split('-');
      return `${year}/${month}/${day}`;
    },
  },
});
