var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("zenescan-template", {
        attrs: {
          loading: _vm.loading,
          zenescanFaceImageLoading: _vm.zenescanFaceImageLoading,
          successMessages: _vm.successMessages,
          errorMessages: _vm.errorMessages,
          tab: _vm.tab,
          faceImages: _vm.faceImages,
          showSelectZenescanFaceImage: _vm.showSelectZenescanFaceImage,
          typeItems: _vm.typeItems,
          pCodeItems: _vm.pCodeItems,
          userItems: _vm.userItems,
          pCodes: _vm.pCodes,
          users: _vm.users.filter(function(u) {
            return u.isValid === true
          }),
          faceImageProgressModalInfo: _vm.faceImageProgressModalInfo,
          faceImageSearchValue: _vm.faceImageSearchValue,
          isSelectedClearFaceImage: _vm.isSelectedClearFaceImage,
          faceImageTotalCount: _vm.faceImageTotalCount,
          faceImagePage: _vm.faceImagePage,
          faceImagePerPage: _vm.faceImagePerPage,
          isDisabledPaginationFaceImage: _vm.isDisabledPaginationFaceImage
        },
        on: {
          "click-download-template-excel": _vm.downloadFaceImageTemplateExcel,
          "confirm-action": _vm.updateIsValidFaceImage,
          "click-delete-face-image": _vm.singleDeleteFaceImage,
          "edit-face-image": _vm.bulkAddFaceImages,
          "show-dialog-confirm": _vm.showDialogConfirm,
          "progress-modal-close": _vm.progressModalClose,
          "update-items-per-page": _vm.imageTableUpdateItemsPerPage,
          "click-tab": function($event) {
            _vm.tab = $event
          },
          "input-search-value": function($event) {
            _vm.faceImageSearchValue = $event
          },
          search: _vm.searchFaceImage,
          "selected-clear-face-image": _vm.selectedClearFaceImage,
          "update-page": _vm.imageTableUpdatePage,
          "end-no-match-face-image": _vm.noMatchFaceImage,
          "click-open-edit-face-image": _vm.openEditFaceImage,
          "change-page-face-image": _vm.changePageFaceImage,
          "change-per-page-face-image": _vm.changePerPageFaceImage,
          "click-open-face-image": _vm.clickFaceImage
        }
      }),
      _vm._v(" "),
      _vm.showInputVerificationModal
        ? _c("face-image-input-verification", {
            attrs: {
              isVisible: _vm.showInputVerificationModal,
              isLoadBlockingVerification: false,
              verificationTitle: _vm.verificationTitle,
              verificationMessage: _vm.verificationMessage1,
              verificationMessage2: _vm.verificationMessage2,
              verificationMessage3: _vm.verificationMessage3
            },
            on: {
              "click-verification-save": _vm.verificationSaveFaceImage,
              close: function($event) {
                _vm.showInputVerificationModal = false
              }
            },
            model: {
              value: _vm.inputFaceImage,
              callback: function($$v) {
                _vm.inputFaceImage = $$v
              },
              expression: "inputFaceImage"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditFaceImage
        ? _c("zenescan-face-image-target", {
            attrs: {
              isVisible: _vm.showEditFaceImage,
              isLoadBlockingZenescanFaceImage: false,
              mode: "edit",
              typeItems: _vm.typeItems,
              pCodeItems: _vm.pCodeItems,
              userItems: _vm.userItems,
              pCodes: _vm.pCodes,
              users: _vm.users.filter(function(u) {
                return u.isValid === true
              })
            },
            on: {
              "click-edit-zenescan-face-image": _vm.editSaveFaceImage,
              close: function($event) {
                _vm.showEditFaceImage = false
              }
            },
            model: {
              value: _vm.editFaceImage,
              callback: function($$v) {
                _vm.editFaceImage = $$v
              },
              expression: "editFaceImage"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }