var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("time-add-group-template", {
    attrs: {
      dataGroups: _vm.dataGroups,
      groupTotalCount: _vm.groupTotalCount,
      page: _vm.currPage,
      perPage: _vm.perPage,
      loading: _vm.loading,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      errorMessageProcess: _vm.errorMessageProcess,
      successItemProcess: _vm.successItemProcess,
      totalDataRowProcess: _vm.totalDataRowProcess,
      showProgress: _vm.showProgress,
      showConfirm: _vm.showConfirm,
      isFinishProcess: _vm.isFinishProcess,
      groupOfTime: _vm.groupOfTime,
      progress: _vm.progress
    },
    on: {
      clickConfirm: function($event) {
        return _vm.clickConfirm($event)
      },
      closeDialogConfirm: function($event) {
        return _vm.closeDialogConfirm($event)
      },
      changePerPage: function($event) {
        return _vm.changePerPage($event)
      },
      clickAddGroup: function($event) {
        return _vm.clickAddGroup($event)
      },
      changePage: function($event) {
        return _vm.changePage($event)
      },
      closeModalProgress: function($event) {
        return _vm.closeModalProgress()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }