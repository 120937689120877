


























import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'SelectPerPage',
  props: {
    value: {
      type: Number,
      default: 25,
    },
    perPageItems: {
      type: Array as PropType<number[]>,
      default: [25, 50, 100],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get(): number {
        return this.value;
      },
      set(value: number): void {
        this.$emit('input', value);
        return;
      },
    },
    items: {
      get(): Array<{ text: number, value: number }> {
        return this.perPageItems.map((item: number) => {
          return {
            text: item,
            value: item,
          };
        });
      },
      set(): void {
        return;
      },
    },
  },
});
