


































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import { CardDetail } from '@/types/alligate';

export default Vue.extend({
  name: 'CardDetailForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cardDetail: {
      default: {},
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
});
