var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { justify: "start" } },
            [
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-subheader", [
                        _vm._v("\n          カードの絞り込み\n        ")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "area-search-box" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "space-between" } },
                [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-subheader", [
                            _vm._v("\n            カードの絞り込み\n          ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("searchbox", {
                        staticClass: "input-search",
                        attrs: {
                          title: "内容で絞り込み",
                          searchTargetItems: _vm.searchTargetItems
                        },
                        on: {
                          search: function($event) {
                            return _vm.$emit("search", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "overflow-x": "scroll" } },
            [
              _c("v-data-table", {
                staticClass: "border-table",
                attrs: {
                  loading: _vm.loading,
                  headers: _vm.headers,
                  items: _vm.data,
                  "items-per-page": _vm.perPage,
                  "item-key": "timeSetting",
                  "no-data-text": "データーはありません",
                  "loading-text": "データを読み込中です",
                  "hide-default-footer": "",
                  "hide-default-header": "",
                  dense: ""
                },
                on: {
                  "update:options": function($event) {
                    return _vm.$emit("change-sort", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function(ref) {
                      return [
                        _c("thead", { staticClass: "v-data-table-header" }, [
                          _c("tr", [
                            _c("th", { attrs: { colspan: "1", rowspan: "2" } }),
                            _vm._v(" "),
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Group Header 2")
                            ]),
                            _vm._v(" "),
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Group Header 3")
                            ]),
                            _vm._v(" "),
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Group Header 4")
                            ]),
                            _vm._v(" "),
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Group Header 5")
                            ]),
                            _vm._v(" "),
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Group Header 6")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 2")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 3")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 4")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 5")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 6")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 7")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 8")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 9")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 10")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticStyle: { width: "1000px" } }, [
                              _vm._v("Header 11")
                            ])
                          ])
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedItem,
                  callback: function($$v) {
                    _vm.selectedItem = $$v
                  },
                  expression: "selectedItem"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }