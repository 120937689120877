







import {Vue } from 'vue-property-decorator';
import ExternalListTemplate, { ExternalInfo } from '@/components/templates/ExternalListTemplate/ExternalListTemplate.vue';
import {
  JOBCAN_WEB_APP,
  KINGOFTIME_WEB_APP,
  SAFIE_RELATIONS_GATE,
  TBEYE_RELATIONS_GATE,
  SMARTHR_WEB_APP,
  TEAMSPIRIT_WEB_APP,
  FACE_AUTHENTICATION,
} from '@/config';

interface ExternalLinkEx extends ExternalInfo {
  /** trueで別窓で開く */
  isAbountLink?: boolean;
  /** 内部遷移向けrouter-link用path */
  path?: string;
  /** 主に外部アプリ向け window.location(fullPath) */
  fullPath?: string;
}

interface DataType {
  externalList: ExternalLinkEx[];
}

export default Vue.extend({
  name: 'ExternalListPage',
  components: {
    ExternalListTemplate,
  },
  data(): DataType {
    return {
      externalList: [{
        fullPath: TEAMSPIRIT_WEB_APP,
        isAbountLink: true,
        name: 'TeamSpirit連携',
        imgSrc: require('@/assets/image/teamspirit_logo.jpg'),
        isComingSoon: false,
      }, {
        fullPath: JOBCAN_WEB_APP,
        isAbountLink: true,
        name: 'ジョブカン連携',
        imgSrc: require('@/assets/image/jobcan_logo.jpg'),
        isComingSoon: false,
      }, {
        fullPath: KINGOFTIME_WEB_APP,
        isAbountLink: true,
        name: 'KING OF TIME連携',
        imgSrc: require('@/assets/image/kingoftime_logo.jpg'),
        isComingSoon: false,
      }, {
        // fullPath: SMARTHR_WEB_APP,
        fullPath: '',
        isAbountLink: true,
        // name: 'SmartHR連携',
        name: '準備中',
        imgSrc: require('@/assets/image/SmartHR_Logo.png'),
        isComingSoon: false,
      }, {
        path: SAFIE_RELATIONS_GATE,
        isAbountLink: false,
        name: 'Safie連携',
        imgSrc: require('@/assets/image/safie_logo.jpg'),
        isComingSoon: false,
      }, {
        path: TBEYE_RELATIONS_GATE,
        isAbountLink: false,
        name: 'TBEYE社製カメラレコーダーNVR連携',
        imgSrc: require('@/assets/image/tbeye_logo.png'),
        isComingSoon: false,
      }, {
        path: FACE_AUTHENTICATION,
        isAbountLink: false,
        name: `顔認証連携`,
        // imgSrc: require('@/assets/image/zenesque_logo.png'),
        // imgSrc2: require('@/assets/image/zenesque_logo.png'),
        isComingSoon: false,
      }],
    };
  },
  methods: {
    cardClick(external: ExternalLinkEx): void {
      if (external.fullPath && external.isAbountLink === true) {
        window.open(external.fullPath, '_blank');
      } else if (external.fullPath && external.isAbountLink === false) {
        window.open(external.fullPath);
      } else if (external.path && external.isAbountLink === true) {
        window.open(external.path, '_blank');
      } else {
        this.$router.push({path: external.path});
      }
    },
  },
});
