var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm._l(_vm.results, function(result, index) {
        return _c(
          "v-row",
          { key: index },
          [
            _c("alert", {
              staticClass: "col-12",
              attrs: {
                text: result.message ? result.message : "",
                type: result.type ? result.type : "success",
                value: _vm.isDisplayMessage[index]
              },
              on: {
                input: function($event) {
                  _vm.isDisplayMessage[index] = $event
                }
              }
            })
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-col", [_vm._v("カメラ名称")]),
          _vm._v(" "),
          _c("v-col", [_vm._v("SafieデバイスID")]),
          _vm._v(" "),
          _c("v-col", [_vm._v("紐づけるゲート")]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: 1 } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition-group",
        { attrs: { name: "fade" } },
        _vm._l(_vm.safieRelationGateDevices, function(val, index) {
          return _c("relation-safie-target", {
            key: val.relationId,
            attrs: {
              configurableGates: _vm.configurableGates,
              loading: _vm.putDelLoading[index],
              index: index,
              value: val
            },
            on: {
              input: function($event) {
                return _vm.$emit("form-update", { index: index, value: $event })
              },
              "put-record": function($event) {
                return _vm.$emit("put-record", index)
              },
              "delete-record": function($event) {
                return _vm.$emit("delete-record", index)
              }
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.addNewRelationGateDevices, function(val, index) {
        return _c("relation-safie-target", {
          key: _vm.newRecordKey[index],
          attrs: {
            configurableGates: _vm.configurableGates,
            loading: _vm.postLoading[index],
            index: index,
            value: val
          },
          on: {
            input: function($event) {
              return _vm.$emit("new-form-update", {
                index: index,
                value: $event
              })
            },
            "post-record": function($event) {
              _vm.$emit("post-record", index)
              _vm.del(index)
            },
            "delete-record": function($event) {
              _vm.$emit("del-new-record", index)
              _vm.del(index)
            }
          }
        })
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { offset: 1 } },
            [
              _c("icon-button", {
                attrs: { text: "追加", icon: "mdi-plus mdi-18px" },
                on: {
                  click: function($event) {
                    _vm.$emit("add-new-record")
                    _vm.add()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loading ? _c("loading") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }