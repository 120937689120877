


















































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import * as Config from '@/config';
import { user } from '@/store/getters';

interface TypeSelect {
  text: string;
  value: string;
}

export interface DataType {
  rulesValidate: any;
  isHasLowerCaseCardNumber: boolean;
}

export default Vue.extend({
  name: 'CardAddForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
  },
  props: {
    confirm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    typeItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    userItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    cardDetail: {
      type: Object,
      default: {},
    },
    isResetData: {
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  watch: {
    isResetData(value) {
      if (value) {
        (this.$refs.CardAddForm as Vue & { resetValidation: () => boolean }).resetValidation();
      }
    },
  },
  data: (): DataType => ({
    rulesValidate: {
      requiredPcodeId: (pCodeId: string) => !!pCodeId || Config.PCODE_REQUIRED,
      validatePcodeId: (pCodeId: string, type: number) => {
        let pattern;
        if (type === 1) {
          pattern = /^[0-9]{4,20}$/;
        } else {
          pattern = /^[a-zA-Z0-9]{4,20}$/;
        }
        return pattern.test(pCodeId) || (type === 1 ? Config.PCODE_VALIDATE_NUMERIC : Config.PCODE_VALIDATE_NFC);
      },
      validateUserId: (userId: string) => {
        if (userId !== null && userId !== undefined && userId !== '') {
          const pattern = /^[a-zA-Z0-9.!@#$%&'*+/=?^_`{|}~-]{4,96}$/;
          return pattern.test(userId) || Config.USERID_VALIDATE;
        } else {
          return true;
        }
      },
    },
    isHasLowerCaseCardNumber: false,
  }),
  methods: {
    checkCard() {
      const result = (this.$refs.CardAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
          if (this.cardDetail.pCodeId.toUpperCase() !== this.cardDetail.pCodeId) {
            this.isHasLowerCaseCardNumber = true;
          } else {
            this.saveCard();
          }
      }
    },
    saveCard() {
      const result = (this.$refs.CardAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('clickSaveCard', {
          data: this.cardDetail,
        });
      }
      this.closeDialogConfirm();
    },
    updateCard() {
      this.$emit('clickUpdateCard', {
        data: this.cardDetail,
      });
    },
    closeDialogConfirm() {
      this.isHasLowerCaseCardNumber = false;
    },
  },
});
