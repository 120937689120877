import { render, staticRenderFns } from "./InputForm.vue?vue&type=template&id=1c935aea&"
import script from "./InputForm.vue?vue&type=script&lang=ts&"
export * from "./InputForm.vue?vue&type=script&lang=ts&"
import style0 from "./InputForm.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VCheckbox,VChip,VCol,VFileInput,VRow,VSubheader,VSwitch,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\003742\\Documents\\Alligate_join\\03_code\\stg\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c935aea')) {
      api.createRecord('1c935aea', component.options)
    } else {
      api.reload('1c935aea', component.options)
    }
    module.hot.accept("./InputForm.vue?vue&type=template&id=1c935aea&", function () {
      api.rerender('1c935aea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/InputForm/InputForm.vue"
export default component.exports