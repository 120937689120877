




















































































































































































































































































import Vue, { PropType } from 'vue';
import { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import PassableTimeSettingList from '@/components/organisms/PassableTimeSettingList/PassableTimeSettingList.vue';
import PassableTimeSettingEdit from '@/components/organisms/PassableTimeSettingEdit/PassableTimeSettingEdit.vue';
import NfcSetting from '@/components/organisms/NFCSetting/NFCSetting.vue';
import ExternalLoginSetting from '@/components/organisms/ExternalLoginSetting/ExternalLoginSetting.vue';
import NoticeSettingTypeList from '@/components/organisms/NoticeSettingTypeList/NoticeSettingTypeList.vue';
import RegisterLoginUserTemplate from '@/components/templates/RegisterLoginUserTemplate/RegisterLoginUserTemplate.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import { PassableTime, LoginService, SettingInfo, NoticeSettingType, NoticeSetting, Gate, PCode, User, ConfigLog } from '@/types/alligate';
import ExternalLoginImport from '@/components/organisms/ExternalLoginImport/ExternalLoginImport.vue';
import HolidaySetting from '@/components/organisms/HolidaySetting/HolidaySetting.vue';
import KeyTokenSetting from '@/components/organisms/KeyTokenSetting/KeyTokenSetting.vue';
import SmartphoneAutoApproval from '@/components/organisms/SmartphoneAutoApproval/SmartphoneAutoApproval.vue';
import LogLabelSetting from '@/components/organisms/LogLabelSetting/LogLabelSetting.vue';
import LogLabelSettingEdit from '@/components/organisms/LogLabelSettingEdit/LogLabelSettingEdit.vue';

export interface DataType {
  tabItems: Tabs[];
  tab: string;
  configurableConnectStatus: SelectItem[];
}
export interface Tabs {
  title: string;
  tabname: string;
}
interface PassableType {
  passableTimeSettingId: string;
  name: string;
  isDefault: boolean;
  timeSettings: any;
  updated: number;
}
export default Vue.extend({
  components: {
    PassableTimeSettingList,
    NfcSetting,
    ExternalLoginSetting,
    NoticeSettingTypeList,
    Breadcrumbs,
    PassableTimeSettingEdit,
    RegisterLoginUserTemplate,
    ExternalLoginImport,
    HolidaySetting,
    KeyTokenSetting,
    SmartphoneAutoApproval,
    LogLabelSetting,
    LogLabelSettingEdit,
  },
  props: {
    dataPassables: {
      default: () => [],
    },
    dataPassableDetail: {
      default: () => [],
    },
    timeAdd: {
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isEdit: {
      type: Number,
      default: 0,
    },
    isSaveDate: {
      type: Number,
      default: 0,
    },
    oneLoginLoginService: {
      type: Object as PropType<LoginService>,
      default: () => {
        return {};
      },
    },
    appleIdLoginService: {
      type: Object as PropType<LoginService>,
      default: () => {
        return {};
      },
    },
    googleLoginService: {
      type: Object as PropType<LoginService>,
      default: () => {
        return {};
      },
    },
    noticeSettingTypes: {
      type: Array as PropType<NoticeSettingType[]>,
      default: () => {
        return [];
      },
    },
    noticeSettings: {
      type: Array as PropType<NoticeSetting[]>,
      default: () => {
        return [];
      },
    },
    isLoadingNoticeSetting: {
      type: Boolean,
      default: () => false,
    },
    isSamlFederatedLoginService: {
      type: String,
      default: 'false',
    },
    isFederatedLoginService: {
      type: String,
      default: 'false',
    },
    allowSubmit: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    saveFinish: {
      type: Boolean,
      default: false,
    },
    showProgressExportCSV: {
      type: Boolean,
      default: false,
    },
    showProgressImport: {
      type: Boolean,
      default: false,
    },
    message_success: {
      type: String,
      default: '',
    },
    message_error: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    // settingInfor: {
    //   type: Object as PropType<SettingInfo>,
    //   default: () => {
    //     return {};
    //   },
    // },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    blockCount: {
      type: Boolean,
      default: false,
    },
    isLoadingAdd: {
      type: Boolean,
      default: false,
    },
    isExternalLogin: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    userList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    months: {
      type: Array,
      default: () => {
        return [];
      },
    },
    groupListAdd: {
      type: Array,
      default: () => {
        return [];
      },
    },
    groupListEdit: {
      type: Array,
      default: () => {
        return [];
      },
    },
    groupListByPassableTime: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dayTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    realtionsUserList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dayTypesCalendar: {
      default: () => {
        return [];
      },
    },
    timeSettings: {
      default: () => {
        return [];
      },
    },
    cognitoIdProviderName: {
      type: String,
      default: '',
    },
    successMessageLogsModal: {
      type: String,
      default: '',
    },
    errorMessageLogsModal: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    ExpandedGroup: {
      default: [],
    },
    errorMessageParams: {
      default: [],
    },
    logLabelErr: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    sucessStatus: {
      type: Boolean,
      default: false,
    },
    showConfirmDelete: {
      type: Boolean,
      default: false,
    },
    loadingDate: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxRecord: {
      type: Number,
      default: 0,
    },
    passableTotalCount: {
      type: Number,
      default: 0,
    },
    pagePassable: {
      type: Number,
      default: 1,
    },
    pageSizeList: {
      type: Number,
      default: 25,
    },
    pageLogin: {
      type: Number,
      default: 1,
    },
    perPagePassable: {
      type: Number,
      default: 25,
    },
    showModalImport: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    loadingGroup: {
      type: Boolean,
      default: false,
    },
    isFinishDate: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    errorMessageExcel: {
      default: [],
    },
    successItem: {
      type: Number,
      default: 0,
    },
    totalDataRow: {
      type: Number,
      default: 0,
    },
    federatedLoginServiceId: {
      type: String,
      default: '',
    },
    pageGroup: {
      type: Number,
      default: 0,
    },
    userFullList: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    gateFullList: {
      type: Array as PropType<Gate[]>,
      default: () => [],
    },
    pcodeFullList: {
      type: Array as PropType<PCode[]>,
      default: () => [],
    },
    configLogFullList: {
      type: Array as PropType<ConfigLog[]>,
      default: () => [],
    },
    isLoadBlockingNoticeSettingType: {
      type: Boolean,
      default: () => true,
    },
    isLoadBlockingNoticeSetting: {
      type: Boolean,
      default: () => true,
    },
    loadBlockingTextNoticeSetting: {
      type: String,
      default: () => '',
    },
    editStatusException: {
      type: Boolean,
      default: false,
    },
    editStatus: {
      type: Boolean,
      default: false,
    },
    settingInfor: {
      type: Object,
      default: {},
    },
    listNfcSettingSelect: {
      default: [],
    },
    isFelica: {
      type: Boolean,
      default: false,
    },
    isMifare: {
      type: Boolean,
      default: false,
    },
    listConfifLog: {
      default: [],
    },
    isEditLogLabel: {
      type: Boolean,
      default: false,
    },
    dataEditLogLabel: {
      type: Object,
      default: {},
    },
    pages: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'システム設定', disabled: true },
        ];
      },
    },
    configurableUsers: {
      get(): SelectItem[] {
        return this.userFullList.map((user) => {
          return {
            text: `${user.name}(${user.userId})`,
            value: user.userId,
          };
        });
      },
    },
    configurablePcodes: {
      get(): SelectItem[] {
        return this.pcodeFullList.map((pcode) => {
          return {
            text: `${pcode.name}(${pcode.pCodeId})/${pcode.userId ? pcode.userId : ' [ユーザ紐づけなし]'}`,
            value: pcode.pCodeId,
          };
        });
      },
    },
    configurableGates: {
      get(): SelectItem[] {
        return this.gateFullList.map((gate) => {
          return {
            text: `${gate.gateName}(${gate.gateId})`,
            value: gate.gateId,
          };
        });
      },
    },
    configurableLogs: {
      get(): SelectItem[] {
        return this.configLogFullList.map((config) => {
          return {
            text: `${config.logLabel}(${config.accessLogId})`,
            value: config.accessLogId,
          };
        });
      },
    },
  },
  methods: {
    isGetFederatedLoginService() {
      this.$emit('isGetFederatedLoginService');
    },
    goRelation(loginService: LoginService) {
      this.$emit('goRelation', loginService);
    },
    addRelation(user: any) {
      this.$emit('addRelation', user);
    },
    deleteRelation(relationId: string) {
      this.$emit('deleteRelation', relationId);
    },
    clickTab(tabname: string): void {
      // 通知設定のタブクリック時のみ通知設定の取得を行う
      if (tabname === 'tab5') {
        this.$emit('initialize-notice-setting');
      // } else if (tabname === 'tabNFCSetting') {
      //   this.$emit('initialize-nfc-setting');
      } else if (tabname === 'tabLogLabelSetting') {
        this.$emit('initialize-log-label-setting');
      }
    },
  },
  data: (): DataType => ({
    tabItems: [
      { title: '通行可能時間設定', tabname: 'tab1' },
      { title: 'カレンダー設定', tabname: 'tab2' },
      // { title: 'NFCカード設定', tabname: 'tab3' },
      { title: '外部ログイン設定', tabname: 'tab4' },
      { title: '通知設定', tabname: 'tab5' },
      { title: 'ゲートログ名称設定', tabname: 'tabLogLabelSetting' },
      { title: 'スマートフォン自動承認設定', tabname: 'tabPhoneAutoApproval' },
      { title: 'キートークン有効期間設定', tabname: 'tabKeyTokenSetting' },
      { title: 'NFC読み出し位置設定', tabname: 'tabNFCSetting' },
    ],
    tab: 'tab1',
    configurableConnectStatus: [{
      text: '接続状態になった時',
      value: 'connected',
    }, {
      text: '未接続状態になった時',
      value: 'disconnected',
    }],
  }),
});
