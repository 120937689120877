var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("group-list-template", {
    attrs: {
      dataGroups: _vm.dataGroups,
      groupTotalCount: _vm.groupTotalCount,
      page: _vm.currPage,
      perPage: _vm.perPage,
      loading: _vm.loading,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      typeAction: _vm.typeAction,
      showProgress: _vm.showProgress,
      errorMessageProcess: _vm.errorMessageProcess,
      successItemProcess: _vm.successItemProcess,
      totalDataRowProcess: _vm.totalDataRowProcess,
      progress: _vm.progress,
      isFinishProcess: _vm.isFinishProcess,
      showConfirm: _vm.showConfirm,
      isRemoveGroupSelected: _vm.isRemoveGroupSelected,
      isGetDataList: _vm.isGetDataList,
      groupExpanedGate: _vm.groupExpanedGate,
      isReRenderGate: _vm.isReRenderGate,
      isRemoveGateSelected: _vm.isRemoveGateSelected,
      groupExpanedUser: _vm.groupExpanedUser,
      isReRenderUser: _vm.isReRenderUser,
      isRemoveUserSelected: _vm.isRemoveUserSelected
    },
    on: {
      changePerPage: function($event) {
        return _vm.changePerPage($event)
      },
      clickAddGroup: function($event) {
        return _vm.clickAddGroup($event)
      },
      clickEditGroup: function($event) {
        return _vm.clickEditGroup($event)
      },
      changePage: function($event) {
        return _vm.changePage($event)
      },
      "search-group": function($event) {
        return _vm.searchGroup($event)
      },
      clickGroupDetail: function($event) {
        return _vm.clickGroupDetail($event)
      },
      "click-groups-action": function($event) {
        return _vm.clickGroupsAction($event)
      },
      closeModalProgress: function($event) {
        return _vm.closeModalProgress()
      },
      "show-dialog-confirm": function($event) {
        return _vm.showDialogConfirm($event)
      },
      "close-dialog-confirm": function($event) {
        return _vm.closeDialogConfirm()
      },
      "change-expanded-group": function($event) {
        return _vm.changeExpandedGroup($event)
      },
      "change-page-gate": function($event) {
        return _vm.changePageGate($event)
      },
      "change-page-user": function($event) {
        return _vm.changePageUser($event)
      },
      "show-only-isVaild": function($event) {
        return _vm.ShowOnlyIsVaild($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }