



































































import Vue, { PropType } from 'vue';
import GroupAddForm from '@/components/organisms/GroupAddForm/GroupAddForm.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';

interface TypeSelect {
  text: string;
  value: string;
}
interface GroupDetail {
  pCodeId: string;
  userId: string;
  type: number;
  isValid: boolean;
}
export default Vue.extend({
  name: 'GroupAddTemplate',
  components: {
    GroupAddForm,
    Breadcrumbs,
  },
  props: {
    gateItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    userItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    expand: {
      type: Number,
      default: 0,
    },
    GateSubmittedFail: {
      default: [],
    },
    UserSubmittedFail: {
      default: [],
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupDetail: {
      type: Object,
      default: [],
    },
    dataAllUses: {
      default: [],
    },
    dataAllGates: {
      default: [],
    },
    dataAllTimes: {
      default: [],
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'グループ', disabled: true },
          { text: this.isEdit ? 'グループ編集' : '新規グループ登録', disabled: true },
        ];
      },
    },
  },
});
