










































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';

export interface DataType {
  input: null;
  credentials: { ownerId: string; username: string; password: string;
  mfaCode: string, resetCode: string, confirmPassword: string};
  username: string;
  password: string;
  step: number;
  nextStep: boolean;
  show1: boolean;
  formLogin: boolean;
}
interface InfoLoginWithProvider {
  isValid: boolean;
  cognitoIdProviderName: string;
}
export default Vue.extend({
  name: 'Login',
  components: {
    InputForm,
    TextLink,
    SuccessButton,
  },
  props: {
    loginProcess: {
      type: Number,
      default: 0,
    },
    googleInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    appleInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    oneLoginInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      default: {
        ownerId: '',
        userId: '',
        password: '',
        mfaCode: '',
        resetCode: '',
        confirmPassword: '',
      },
    },
  },
  watch: {
    loginProcess(val) {
      this.step = val;
      if (val === 5) {
        this.credentials.ownerId = localStorage.ownerId;
        this.credentials.username = localStorage.userId;
      }
    },
  },
  data: (): DataType => ({
    input: null,
    credentials: {
      ownerId: '',
      username: '',
      password: '',
      mfaCode: '',
      resetCode: '',
      confirmPassword: '',
    },
    username: '',
    password: '',
    step: 0,
    nextStep: false,
    show1: false,
    formLogin: false,
  }),
  methods: {
    PageLogin() {
      this.step = 0;
      this.$emit('goPageLogin');
    },
  },
});
