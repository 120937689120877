var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-users-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "div",
            { staticClass: "container-user" },
            [
              _c(
                "div",
                { staticClass: "round-action" },
                [
                  _c(
                    "v-row",
                    { staticClass: "btn-div", attrs: { justify: "end" } },
                    [
                      _c("v-col", { attrs: { cols: "9" } }, [
                        _c(
                          "div",
                          { staticClass: "area-search-box" },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "", justify: "start" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "input-search-user pr-0",
                                    attrs: { xl: "7", sm: "8" }
                                  },
                                  [
                                    _c("searchbox", {
                                      staticClass:
                                        "input-search  btn-user-search",
                                      attrs: {
                                        title: "選択項目にチェックして検索",
                                        searchTargetItems:
                                          _vm.searchTargetItems,
                                        disabled: _vm.isGetDataList
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.$emit("searchUser", $event)
                                        }
                                      },
                                      model: {
                                        value: _vm.searchItem,
                                        callback: function($$v) {
                                          _vm.searchItem = $$v
                                        },
                                        expression: "searchItem"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "ml-8",
                                    attrs: { cols: "12", sm: "3", xl: "2" }
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "input-search",
                                      attrs: {
                                        clearable: "",
                                        items: _vm.groupToSearch,
                                        label: "グループで絞リ込み",
                                        solo: "",
                                        "hide-details": "",
                                        "no-data-text": "",
                                        disabled: _vm.isGetDataList
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$emit(
                                            "search-user-group",
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.groupIdSearch,
                                        callback: function($$v) {
                                          _vm.groupIdSearch = $$v
                                        },
                                        expression: "groupIdSearch"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "btn-div mr-3",
                              attrs: { justify: "end" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center mr-1" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "open-on-hover": "",
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "btn-primary"
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                      ユーザー登録\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: { link: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "clickAddUser"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v("ユーザー登録")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: { link: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "showDialogImport",
                                                    { exportType: 0, type: "" }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v("一斉登録")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: { link: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "showDialogImport",
                                                    { exportType: 1, type: "" }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  "期限付き解錠可能ゲート登録"
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: { link: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "showDialogImport",
                                                    {
                                                      exportType: 2,
                                                      type: "all-card"
                                                    }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v("カードも併せて一斉登録")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("action-button", {
                                    attrs: {
                                      text: "アクション ▼",
                                      "action-items": _vm.actionItems,
                                      disabled: _vm.selectedUserIds.length === 0
                                    },
                                    on: {
                                      "click-enable": function($event) {
                                        return _vm.$emit("confirmAction", {
                                          action: 0
                                        })
                                      },
                                      "click-disable": function($event) {
                                        return _vm.$emit("confirmAction", {
                                          action: 1
                                        })
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("user-table", {
                attrs: {
                  users: _vm.users,
                  listGroupPassages: _vm.listGroupPassages,
                  gateList: _vm.gateList,
                  isGetDataList: _vm.isGetDataList,
                  ExpandedGroup: _vm.ExpandedGroup,
                  listphones: _vm.listphones,
                  listGatePassages: _vm.listGatePassages,
                  tempGroupPassages: _vm.tempGroupPassages,
                  cardItems: _vm.cardItems,
                  phoneItems: _vm.phoneItems,
                  successMessageLogs: _vm.successMessageLogs,
                  errorMessageLogs: _vm.errorMessageLogs,
                  page: _vm.page,
                  perPage: _vm.perPage,
                  pageGroup: _vm.pageGroup,
                  groupDetail: _vm.groupDetail,
                  "show-select": true,
                  loadingGroup: _vm.loadingGroup,
                  pageGate: _vm.pageGate,
                  isExport: _vm.isExport,
                  groupId: _vm.groupId,
                  loadingGate: _vm.loadingGate,
                  maxRecord: _vm.maxRecord,
                  groupToSearch: _vm.groupToSearch
                },
                on: {
                  clickEditUser: function($event) {
                    return _vm.$emit("clickEditUser", $event)
                  },
                  "change-per-page": function($event) {
                    return _vm.$emit("changePerPage", $event)
                  },
                  "change-page": function($event) {
                    return _vm.$emit("change-page", $event)
                  },
                  "change-page-group-gate": function($event) {
                    return _vm.$emit("change-page", $event)
                  },
                  "change-expanded-group": function($event) {
                    return _vm.$emit("change-expanded", $event)
                  },
                  "change-expanded-user": function($event) {
                    return _vm.$emit("change-expanded", $event)
                  },
                  "select-users": function(userIds) {
                    _vm.selectedUserIds = userIds
                  },
                  "click-leave-group": function($event) {
                    return _vm.$emit("click-leave-group", $event)
                  },
                  "confirm-action": function($event) {
                    return _vm.$emit("confirmAction", $event)
                  },
                  "show-only-isVaild": function($event) {
                    return _vm.$emit("show-only-isVaild", $event)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("user-import", {
            attrs: {
              showModalImport: _vm.showModalImport,
              showModalProcess: _vm.showModalProcess,
              progress: _vm.progress,
              success: _vm.success,
              totalDataRowCSV: _vm.totalDataRowCSV,
              successItemCSV: _vm.successItemCSV,
              errorMessage: _vm.errorMessage,
              errorMessageLogs: _vm.errorMessageLogs,
              successMessageLogs: _vm.successMessageLogs,
              showProcessImportExcel: _vm.showProcessImportExcel,
              successItem: _vm.successItem,
              totalDataRow: _vm.totalDataRow,
              isExport: _vm.isExport,
              errorMessageExcel: _vm.errorMessageExcel,
              isFinishImportExcel: _vm.isFinishImportExcel,
              sheetUserName: _vm.sheetUserName,
              exportType: _vm.exportType,
              isType: _vm.isType,
              showModalConfirmEmail: _vm.showModalConfirmEmail,
              sheet1Name: _vm.sheet1Name,
              sheet2Name: _vm.sheet2Name,
              progressSheet1: _vm.progressSheet1,
              successItemSheet1: _vm.successItemSheet1,
              totalDataRowSheet1: _vm.totalDataRowSheet1,
              progressSheet2: _vm.progressSheet2,
              successItemSheet2: _vm.successItemSheet2,
              totalDataRowSheet2: _vm.totalDataRowSheet2,
              typeConfirm: _vm.typeConfirm,
              isHasLowerCaseCardNumberExcel: _vm.isHasLowerCaseCardNumberExcel
            },
            on: {
              clickExportCSV: function($event) {
                return _vm.$emit("clickExportCSV", $event)
              },
              clickExportCSVTimeUnlockGate: function($event) {
                return _vm.$emit("clickExportCSVTimeUnlockGate")
              },
              clickConfirmEmail: function($event) {
                return _vm.$emit("clickConfirmEmail", $event)
              },
              closeModalProcessCSV: function($event) {
                return _vm.$emit("closeModalProcessCSV", $event)
              },
              CancelProcessCSV: function($event) {
                return _vm.$emit("CancelProcessCSV", $event)
              },
              closeImportCSV: function($event) {
                return _vm.$emit("closeImportCSV", $event)
              },
              clickImportCSV: function($event) {
                return _vm.$emit("clickImportCSV", $event)
              },
              clickImportCSVTimeUnlockGate: function($event) {
                return _vm.$emit("clickImportCSVTimeUnlockGate", $event)
              },
              clickExportExcel: function($event) {
                return _vm.$emit("clickExportExcel", $event)
              },
              clickImportExcel: function($event) {
                return _vm.$emit("clickImportExcel", $event)
              },
              closeModalProcessImportExcel: function($event) {
                return _vm.$emit("closeModalProcessImportExcel", $event)
              },
              CancelProcessImportExcel: function($event) {
                return _vm.$emit("CancelProcessImportExcel", $event)
              },
              "close-dialog-check-card-excel": function($event) {
                return _vm.$emit("close-dialog-check-card-excel", $event)
              },
              "accept-dialog-check-card-excel": function($event) {
                return _vm.$emit("accept-dialog-check-card-excel", $event)
              },
              clickCheckBoxExportUserInfoCSV: function($event) {
                return _vm.$emit("clickCheckBoxExportUserInfoCSV", $event)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "600", persistent: "" },
              model: {
                value: _vm.showConfirm,
                callback: function($$v) {
                  _vm.showConfirm = $$v
                },
                expression: "showConfirm"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.action === 0 || _vm.action === 2
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("ユーザーを有効化")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 1 || _vm.action === 3
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("ユーザーを無効化")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 4
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("関連付け削除")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 5
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("関連付け削除")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-row", {
                    staticStyle: { height: "5px" },
                    attrs: { "no-gutters": "" }
                  }),
                  _vm._v(" "),
                  _vm.action === 0 || _vm.action === 2
                    ? _c("v-card-text", { staticClass: "pa-4" }, [
                        _c("p", [
                          _vm._v("チェックしたユーザーを有効化します。")
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 1 || _vm.action === 3
                    ? _c("v-card-text", { staticClass: "pa-4" }, [
                        _c("p", [
                          _vm._v("チェックしたユーザーを無効化します。")
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 4
                    ? _c("v-card-text", { staticClass: "pa-4" }, [
                        _c("p", [
                          _vm._v("このグループとの関連付けを解除しますか？")
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 5
                    ? _c("v-card-text", { staticClass: "pa-4" }, [
                        _c("p", [
                          _vm._v("このゲートとの関連付けを解除しますか？")
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("closeDialogConfirm")
                                }
                              }
                            },
                            [_vm._v("\n              キャンセル\n            ")]
                          ),
                          _vm._v(" "),
                          _vm.action === 0
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "changeMutiUserIsvalid",
                                        {
                                          UserIds: _vm.selectedUserIds,
                                          isValid: true,
                                          action: _vm.action
                                        }
                                      )
                                    }
                                  }
                                },
                                [_vm._v("OK")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.action === 1
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "changeMutiUserIsvalid",
                                        {
                                          UserIds: _vm.selectedUserIds,
                                          isValid: false,
                                          action: _vm.action
                                        }
                                      )
                                    }
                                  }
                                },
                                [_vm._v("OK")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.action === 2 || _vm.action === 3
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("changeUserIsvalid", {
                                        userId: _vm.userId,
                                        isValid: _vm.isValid,
                                        action: _vm.action
                                      })
                                    }
                                  }
                                },
                                [_vm._v("OK")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.action === 4
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("click-leave-action", {
                                        leave: "group",
                                        action: _vm.action
                                      })
                                    }
                                  }
                                },
                                [_vm._v("OK")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.action === 5
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("click-leave-action", {
                                        leave: "gate",
                                        action: _vm.action
                                      })
                                    }
                                  }
                                },
                                [_vm._v("OK")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "600", persistent: "" },
              model: {
                value: _vm.showProgress,
                callback: function($$v) {
                  _vm.showProgress = $$v
                },
                expression: "showProgress"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.action === 0 || _vm.action === 2
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("ユーザーを有効化")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 1 || _vm.action === 3
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("ユーザーを無効化")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 4
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("関連付け削除")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.action === 5
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("関連付け削除")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-row", {
                    staticStyle: { height: "5px" },
                    attrs: { "no-gutters": "" }
                  }),
                  _vm._v(" "),
                  _vm.totalDataRowProcess > 0
                    ? _c(
                        "v-card-text",
                        { staticClass: "pa-4" },
                        [
                          _c(
                            "v-progress-linear",
                            {
                              attrs: {
                                height: "25",
                                color: "green darken-1",
                                striped: ""
                              },
                              model: {
                                value: _vm.progress,
                                callback: function($$v) {
                                  _vm.progress = $$v
                                },
                                expression: "progress"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.successItemProcess > 0 &&
                          (_vm.action === 0 || _vm.action === 2)
                            ? _c("span", { staticClass: "success-label" }, [
                                _vm._v(
                                  _vm._s(_vm.totalDataRowProcess) +
                                    "件中" +
                                    _vm._s(_vm.successItemProcess) +
                                    "件を有効しました。"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successItemProcess > 0 &&
                          (_vm.action === 1 || _vm.action === 3)
                            ? _c("span", { staticClass: "success-label" }, [
                                _vm._v(
                                  _vm._s(_vm.totalDataRowProcess) +
                                    "件中" +
                                    _vm._s(_vm.successItemProcess) +
                                    "件を無効しました。"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successItemProcess > 0 &&
                          (_vm.action === 4 || _vm.action === 5)
                            ? _c("span", { staticClass: "success-label" }, [
                                _vm._v(
                                  _vm._s(_vm.totalDataRowProcess) +
                                    "件中" +
                                    _vm._s(_vm.successItemProcess) +
                                    "件を削除しました。"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageProcess.length > 0
                            ? _c("div", [
                                _vm.action === 0 || _vm.action === 2
                                  ? _c(
                                      "span",
                                      { staticClass: "error-box-title" },
                                      [_vm._v("有効化失敗")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.action === 1 || _vm.action === 3
                                  ? _c(
                                      "span",
                                      { staticClass: "error-box-title" },
                                      [_vm._v("無効化失敗")]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "error-box-title" },
                                      [_vm._v("削除失敗")]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "error-box" },
                                  [
                                    _vm._l(_vm.errorMessageProcess, function(
                                      item
                                    ) {
                                      return [
                                        _c("p", {
                                          key: item,
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFinishProcess
                    ? _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-2", attrs: { justify: "end" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("closeModalProgress", {
                                        action: _vm.action
                                      })
                                    }
                                  }
                                },
                                [_vm._v("OK")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }