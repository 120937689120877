










import { Vue } from 'vue-property-decorator';
import UserChangePasswordTemplate from '@/components/templates/UserChangePasswordTemplate/UserChangePasswordTemplate.vue';
import * as Config from '@/config';
export interface DataType {
  // Props
  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;
  userInfo: {
    oldPassword: string,
    newPassword: string,
    reNewPassword: string,
  };
}
export default Vue.extend({
  name: 'UserChangePasswordPage',
  components: {
    UserChangePasswordTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    userInfo: {
      oldPassword: '',
      newPassword: '',
      reNewPassword: '',
    },
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  methods: {
    async clickChangePassword() {
      this.loading = true;
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      const data = {
        oldPassword: this.userInfo.oldPassword,
        newPassword: this.userInfo.newPassword,
      };
      await this.userChangePassword(data);
    },
    userChangePassword(data: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.PERSONAL_CHANGE_PASSWORD_API, JSON.stringify(data))
        .then((response: any) => {
          this.loading = false;
          this.resetData();
          if (response.body.message === 'Success') {
            this.successMessageLogs = 'パスワードが変更されました。';
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .then(null, (error: any) => {
          if (error.body) {
            switch (error.status) {
              case 401:
                if (error.body.message === 'The incoming token has expired') {
                  this.errorMessageLogs = '';
                } else {
                  this.errorMessageLogs = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 400:
                this.errorMessageLogs = '現在のパスワードが間違っています。';
                break;
              case 404:
                this.errorMessageLogs = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 500:
                this.errorMessageLogs = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              case 503:
                this.errorMessageLogs = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
                break;
              default:
                this.errorMessageLogs = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
            }
          } else {
            this.errorMessageLogs = Config.SERVER_CONNECT_ERROR;
          }
          this.loading = false;
          resolve(false);
        });
      });
    },
    resetData() {
      this.userInfo.oldPassword = '';
      this.userInfo.newPassword = '';
      this.userInfo.reNewPassword = '';
    },
  },
});
