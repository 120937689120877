




















































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import { Group } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import * as Config from '@/config';
export interface TypeItem {
  title: string;
}
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface TimeSettings {
  dayTypeId: number;
  dayTypeName: string;
  isAllDay: number;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GateDetailTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    TextLink,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    unlockTimes: {
      type: Array as PropType<TypeDessert[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    successMessageModal : {
      type: String,
      default: '',
    },
    errorMessageModal : {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    gateDetail: {
      default: {},
    },
    groupList: {
      default: [],
    },
    dayTypes: {
      type: Array,
      default: [],
    },
    dataUnlockingTime: {
      type: Array as PropType<TimeSettings[]>,
      default: [],
    },
    isFinishEditGate: {
      default: false,
    },
    showModal: {
      default: false,
    },
    showConfirm: {
      default: false,
    },
  },
  watch: {
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '曜日',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          {
            text: '期間指定',
            align: 'start',
            sortable: false,
            value: 'time',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
  },
  methods: {
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    clickGroup(ID: any, gateId: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: ID, goBack: 'gate/detail?gateId=' + gateId}});
    },
    clickEditGate(ID: any) {
      this.$router.push({ path: Config.GATE_EDIT, query: {gateId: ID, goBack: 'gate/detail?gateId=' + ID}});
    },
  },
});
