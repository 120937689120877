





















import Vue from 'vue';
export default Vue.extend({
  name: 'AlertIcon',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
});
