var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { justify: "end" } },
            [
              _c(
                "v-col",
                { staticClass: "btn-add-controlPanel" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("clickBackControlPanel")
                        }
                      }
                    },
                    [_vm._v("\n        戻る\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.successMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-success" },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.successMessageLogs) + "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-error" },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errorMessageLogs) + "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("control-panel-add-form", {
            attrs: {
              typeItems: _vm.typeItems,
              dataAllGates: _vm.dataAllGates,
              dataControlPanels: _vm.dataControlPanels,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              loading: _vm.loading,
              controllerModel: _vm.controllerModel,
              isEdit: _vm.isEdit,
              gateList: _vm.gateList
            },
            on: {
              clickSaveControlPanel: function($event) {
                return _vm.$emit("clickSaveControlPanel", $event)
              },
              clickUpdateControlPanel: function($event) {
                return _vm.$emit("clickUpdateControlPanel", $event)
              },
              changeModel: function($event) {
                return _vm.$emit("changeModel", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }