






































// GateLogList、OperationLogListでは使われていないので大幅に書き換えてOK
import Vue from 'vue';

export interface DataType {
  show: boolean;
}

export default Vue.extend({
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
  },
  data: (): DataType => ({
    show: false,
  }),
  computed: {
    innerValue: {
      get(): string {
        return this.value;
      },
      set(val: string): void {
        this.$emit('input', val);
        return;
      },
    },
  },
});
