var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-log-label-setting" },
    [
      _c(
        "v-container",
        [
          _vm.successMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-success" },
                [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "border-table shadow-table scroll-log-table ",
            attrs: {
              "must-sort": "",
              headers: _vm.headers,
              loading: _vm.loading,
              items: _vm.listConfifLog,
              "items-per-page": -1,
              "item-key": "accessLogId",
              "no-data-text": "該当するデーターがありません。",
              "loading-text": "データを読み込中です",
              "hide-default-footer": ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.accessLogId",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "item-user-id" }, [
                        _vm._v(" " + _vm._s(item.accessLogId) + "\n    ")
                      ])
                    ]
                  }
                },
                {
                  key: "item.logLabel",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "item-user-id" }, [
                        _vm._v(" " + _vm._s(item.logLabel) + "\n    ")
                      ])
                    ]
                  }
                },
                {
                  key: "item.actionEdit",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickEditLogLabel", {
                                accessLogId: item.accessLogId,
                                logLabel: ""
                              })
                            }
                          }
                        },
                        [_vm._v("\n          編集\n        ")]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _vm.pages > 0
            ? _c(
                "v-row",
                {
                  staticClass: "mt-3",
                  attrs: { "no-gutters": "", justify: "space-between" }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mr-5 mb-2" },
                    [
                      _c("select-per-page", {
                        attrs: {
                          page: _vm.page,
                          "per-page-items": [25, 50, 100, 1000],
                          "items-length": _vm.maxRecord,
                          disabled: _vm.isGetDataList
                        },
                        model: {
                          value: _vm.innerPerPage,
                          callback: function($$v) {
                            _vm.innerPerPage = $$v
                          },
                          expression: "innerPerPage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    staticClass: "mb-1",
                    attrs: {
                      "page-length": Math.ceil(_vm.maxRecord / _vm.perPage),
                      disabled: _vm.isGetDataList
                    },
                    model: {
                      value: _vm.innerPage,
                      callback: function($$v) {
                        _vm.innerPage = $$v
                      },
                      expression: "innerPage"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }