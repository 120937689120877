








import {Vue } from 'vue-property-decorator';
import PersonalSettingTemplate from '@/components/templates/PersonalSettingTemplate/PersonalSettingTemplate.vue';
import * as Config from '@/config';
import { User} from '@/types/alligate';
export interface DataType {
  // Props
  page: number;
  loading: boolean;
  tempGroupPassages: any;
  cardItems: any;
  phoneItems: any;
  errorMessageLogs: string;
  listphones: any;
  listGatePassages: any;
  userData: User[];
  goBack: string;

}
export default Vue.extend({
  name: 'PersonalSettingPage',
  components: {
    PersonalSettingTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    page: 1,
    tempGroupPassages: [],
    cardItems: [],
    phoneItems: [],
    errorMessageLogs: '',
    listphones: [],
    listGatePassages: [],
    userData: [],
    goBack: 'user',
  }),
  mounted() {
    this.$emit('activeMenu', 'user');
  },
  created() {
    this.getInFoUser();
  },
  methods: {
    getInFoUser() {
      const userId = localStorage.userProfileId;
      this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
        .then((response: any) => {
          this.userData = response.body.users[0];
          console.log(this.userData);
        })
        .then(null, (error: any) => {
          switch (error.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (error.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        });
    },
  },
});
