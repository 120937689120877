var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _vm.goBack !== ""
            ? _c(
                "v-row",
                { staticClass: "ml-5", attrs: { justify: "start" } },
                [
                  _c(
                    "div",
                    { staticClass: "mt-4 mb-3 mr-9" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.goPage()
                                }
                              }
                            },
                            [_vm._v("\n      戻る\n      ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "round-tab" },
          [
            [
              _c(
                "v-tabs",
                {
                  attrs: { transition: false },
                  on: {
                    change: function($event) {
                      return _vm.$emit("changeTab", $event)
                    }
                  },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.actionItems, function(item) {
                  return _c(
                    "v-tab",
                    { key: item.tabname, staticClass: "log-tab" },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            _vm._v(" "),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              _vm._l(_vm.actionItems, function(item) {
                return _c(
                  "v-tab-item",
                  {
                    key: item.tabname,
                    attrs: { transition: false, "reverse-transition": false }
                  },
                  [
                    item.tabname === "tab2"
                      ? _c(
                          "v-card",
                          [
                            _c("gate-log-list", {
                              attrs: {
                                tabName: item.tabname,
                                activeTab: _vm.activeTab,
                                logGates: _vm.logGates,
                                goBack: _vm.goBack,
                                groups: _vm.groups,
                                isGetDataList: _vm.isGetDataList,
                                gateIdToSafieDeviceIds:
                                  _vm.gateIdToSafieDeviceIds,
                                gateIdToTbeyeDeviceIds:
                                  _vm.gateIdToTbeyeDeviceIds,
                                errorMessageLogs: _vm.errorMessageLogsGate,
                                gatePassageTotalCount:
                                  _vm.gatePassageTotalCount,
                                maxRecord: _vm.maxRecordGate,
                                disableNextGate: _vm.disableNextGate,
                                optionGate: _vm.optionGate
                              },
                              on: {
                                getGateLogList: function($event) {
                                  return _vm.$emit("getGateLogList", $event)
                                },
                                searchGateLog: function($event) {
                                  return _vm.$emit("searchGateLog", $event)
                                },
                                clickExportLog: function($event) {
                                  return _vm.$emit("clickExportLog", $event)
                                },
                                "update-login-info": function($event) {
                                  return _vm.$emit("update-login-info", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.tabname === "tab3"
                      ? _c(
                          "v-card",
                          [
                            _c("operation-log-list", {
                              attrs: {
                                goBack: _vm.goBack,
                                tabName: item.tabname,
                                errorMessageLogs: _vm.errorMessageLogs,
                                data: _vm.data,
                                isGetDataList: _vm.isGetDataList,
                                gatePassageTotalCount:
                                  _vm.gatePassageTotalCount,
                                groups: _vm.groups,
                                logOwner: _vm.logOwner,
                                maxRecord: _vm.maxRecordOwner,
                                disableNextOwner: _vm.disableNextOwner,
                                optionOwner: _vm.optionOwner
                              },
                              on: {
                                searchOperationLog: function($event) {
                                  return _vm.$emit("searchOperationLog", $event)
                                },
                                getOperationLogList: function($event) {
                                  return _vm.$emit(
                                    "getOperationLogList",
                                    $event
                                  )
                                },
                                clickExportOperationLog: function($event) {
                                  return _vm.$emit(
                                    "clickExportOperationLog",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              1
            )
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }