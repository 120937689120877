var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("key-add-template", {
    attrs: {
      loading: _vm.loading,
      existGateId: _vm.existGateId,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      listTimes: _vm.listTimes,
      passagesData: _vm.passagesData
    },
    on: {
      "generate-key": function($event) {
        return _vm.generateKey($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }