import Vue from 'vue';
import Vuex from 'vuex';
// @ts-ignore
import { state } from './states';
// @ts-ignore
import * as getters from './getters';
// @ts-ignore
import * as actions from './actions';
// @ts-ignore
import * as mutations from './mutations';
// @ts-ignore
import plugins from './plugins';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins,
});
