import Vue from 'vue';
import * as Config from '@/config';
import { ConfigLog } from '@/types/alligate';

/**
 * 全ログラベルを取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<ConfigLog[]>} 取得した全ログラベル
 */
export async function getAllConfigLogs(vue: Vue): Promise<ConfigLog[]> {
  try {
    const response = await vue.$axios.get(Config.CONFIG_LOG_API);
    return response.data.logConfigs;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('ログラベル一覧情報の取得に失敗しました。ログラベル情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}
