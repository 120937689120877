var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-gate-table" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-between" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("show-per-page", {
                staticClass: "ml-4 mb-2",
                attrs: {
                  "per-page": 25,
                  page: _vm.pageGroup,
                  "items-length": _vm.groupTotalCount
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "border-table",
            attrs: {
              "must-sort": "",
              "custom-sort": _vm.customSort,
              loading: _vm.loading,
              headers: _vm.headers,
              items: _vm.loadGroups,
              "items-per-page": 25,
              "no-data-text": "   該当するグループがありません",
              "loading-text": "データを読み込中です",
              "hide-default-footer": "",
              dense: "",
              "show-select": _vm.loadGroups.length > 0 ? true : false,
              "show-expand": "",
              "single-expand": "",
              "item-key": "groupId"
            },
            on: {
              "item-expanded": function($event) {
                return _vm.$emit("change-expanded-group", {
                  event: $event,
                  expanded: "group"
                })
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.groupName",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("text-link", {
                        staticClass: "item-user-name",
                        attrs: { text: item.groupName },
                        on: {
                          click: function($event) {
                            return _vm.clickDetail(item.groupId)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.data-table-expand",
                  fn: function(ref) {
                    var expand = ref.expand
                    var item = ref.item
                    var isExpanded = ref.isExpanded
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", disabled: isExpanded },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "white",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                return expand(!isExpanded)
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "item-count" },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-door-closed")
                                            ]),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.gateCount) +
                                                "\n              "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", { staticClass: "text-caption" }, [
                            _vm._v("ゲート一覧を開く")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "transition",
                        { attrs: { name: "expand-icon" } },
                        [
                          isExpanded
                            ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "expanded-item",
                  fn: function(ref) {
                    var headers = ref.headers
                    var item = ref.item
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: headers.length } },
                        [
                          _c(
                            "v-container",
                            { staticClass: "mb-4" },
                            [
                              _c("gate-list", {
                                staticStyle: {
                                  "max-height": "400px",
                                  "overflow-y": "scroll"
                                },
                                attrs: {
                                  gates:
                                    _vm.gateList[item.groupId] &&
                                    _vm.gateList[item.groupId].gates
                                      ? _vm.gateList[item.groupId].gates
                                      : [],
                                  page: _vm.pageGate,
                                  groupId: _vm.groupId,
                                  loading: _vm.loadingGate,
                                  gateTotalCount:
                                    _vm.gateList[item.groupId] &&
                                    _vm.gateList[item.groupId].maxRecord
                                      ? _vm.gateList[item.groupId].maxRecord
                                      : 0
                                },
                                on: {
                                  "click-gate": function($event) {
                                    return _vm.$emit("click-gate", $event)
                                  },
                                  "confirm-action": function($event) {
                                    return _vm.$emit("confirm-action", $event)
                                  },
                                  "change-page-gate": function($event) {
                                    return _vm.$emit("change-page-gate", $event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItem,
              callback: function($$v) {
                _vm.selectedItem = $$v
              },
              expression: "selectedItem"
            }
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-3",
              attrs: { "no-gutters": "", justify: "space-between" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("pagination", {
                staticClass: "mb-1",
                attrs: {
                  "page-length": Math.ceil(_vm.groupTotalCount / 25),
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.innerPage,
                  callback: function($$v) {
                    _vm.innerPage = $$v
                  },
                  expression: "innerPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }