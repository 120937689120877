var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageLength > 0
    ? _c(
        "div",
        { staticClass: "allig-pagination" },
        [
          _c("v-pagination", {
            attrs: {
              length: _vm.pageLength,
              disabled: _vm.disabled,
              color: "green",
              "total-visible": "7"
            },
            model: {
              value: _vm.innerValue,
              callback: function($$v) {
                _vm.innerValue = $$v
              },
              expression: "innerValue"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }