














import { Vue } from 'vue-property-decorator';
import GroupDetailTemplate from '@/components/templates/GroupDetailTemplate/GroupDetailTemplate.vue';
import * as Config from '@/config';
export interface DataType {
  loading: boolean;
  errorMessageLogs: string;
  goBack: string;
  groupDetail: any;
  userList: any;
  gateList: any;
}
export default Vue.extend({
  name: 'GroupDetailPage',
  components: {
    GroupDetailTemplate,
  },
  mounted() {
    const dataQuery = this.$route.query;
    if (dataQuery.groupId !== undefined) {
      this.getGroupDetail(dataQuery.groupId);
      this.getUserByGroupId(1, dataQuery.groupId);
      this.getGateByGroupId(1, dataQuery.groupId);
    }
    if (dataQuery.goBack !== undefined && dataQuery.goBack !== '') {
      this.goBack = dataQuery.goBack.toString();
    }
    this.$emit('activeMenu', 'groups');
  },
  data: (): DataType => ({
    loading: false,
    errorMessageLogs: '',
    groupDetail: {},
    userList: [],
    gateList: [],
    goBack: 'groups',
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  methods: {
    clickBackGroup() {
      this.$router.push({ path: '/' + this.goBack});
    },
    clickEditGroup(value: any) {
      this.$router.push({ path: Config.GROUP_EDIT, query: {groupId: value}});
    },
    clickUser(value: any) {
      this.$router.push({ path: Config.USER_DETAIL,
      query: {userId: value.userId, goBack: 'group/detail?groupId=' + value.groupId}});
    },
    clickGate(value: any) {
      // this.$router.push({ path: Config.USER_DETAIL, query: {userId: value}});
    },
    getGroupDetail(groupId: any) {
      this.loading = true;
      const apiLink = Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(groupId) + '&embed=passableTimeSetting';
      this.$http.get(apiLink)
        .then((response: any) => {
          this.loading = false;
          // // quocdat - dataTest
          // response.body = {
          //   message: 'Success',
          //   statementId: 'DEFA7140-1DEC-4642-BAA2-BB0B5B74D212',
          //   startRecord: 1,
          //   endRecord: 1,
          //   maxRecords: 1,
          //   groups: [
          //     {
          //       groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //       groupName: '社員用',
          //       registered: 1531445882,
          //       updated: 1531445882,
          //       isValid: true,
          //       passableTimeSettingId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E1DEF',
          //       gateCount: 5,
          //       userCount: 3,
          //       passableTimeSetting: {
          //         passableTimeSettingId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E1ABC',
          //         name: '設定１4556',
          //         isDefault: true,
          //         timeSettings: [
          //           {
          //             dayTypeId: 0,
          //             dayTypeName: '日曜',
          //             allDay: false,
          //             startTime: '11:30',
          //             endTime: '17:00',
          //           },
          //           {
          //             dayTypeId: 1,
          //             dayTypeName: '日曜1',
          //             allDay: false,
          //             startTime: '11:30',
          //             endTime: '17:00',
          //           },
          //         ],
          //         registered: 1531445882,
          //         updated: 1531445882,
          //       },
          //     },
          //   ],
          // };
          if (!this.isEmpty(response.body.groups) && response.body.groups.length > 0) {
            this.groupDetail = response.body.groups[0];
          } else {
            this.errorMessageLogs = Config.ERROR_DEFAULT;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },

    getUserByGroupId(page: any, groupId: any) {
      this.$http.get(Config.GROUP_PASSAGE + '?groupId=' + encodeURIComponent(groupId) + '&limit=1000&page=' + page + '&sortKey=desc:userId&embed=user')
        .then((response: any) => {
          // // quocdat - dataTest
          // response.body = {
          //   message: 'Success',
          //   statementId: 'DEFA7140-1DEC-4642-BAA2-BB0B5B74D212',
          //   startRecord: 1,
          //   endRecord: 1,
          //   maxRecords: 1,
          //   groupPassages: [
          //     {
          //       groupPassageId: 'CA79A902-F046-4023-8481-EE20BC9769E9',
          //       userId: 'alligator',
          //       groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //       group: {
          //         groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //         groupName: '社員用',
          //         registered: 1531445882,
          //         updated: 1531445882,
          //         isValid: true,
          //       },
          //       user: {
          //         userId: 'alligator',
          //         name: '蟻　芸太',
          //       },
          //     },
          //     {
          //       groupPassageId: 'CA79A902-F046-4023-8481-EE20BC971111',
          //       userId: 'alligator',
          //       groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //       group: {
          //         groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //         groupName: '社員用',
          //         registered: 1531445882,
          //         updated: 1531445882,
          //         isValid: true,
          //       },
          //       user: {
          //         userId: 'alligator1',
          //         name: '蟻　芸太12',
          //       },
          //     },
          //     {
          //       groupPassageId: 'CA79A902-F046-4023-8481-EE20BC971111',
          //       userId: 'alligator',
          //       groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //       group: {
          //         groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //         groupName: '社員用',
          //         registered: 1531445882,
          //         updated: 1531445882,
          //         isValid: true,
          //       },
          //       user: {
          //         userId: 'alligator2',
          //         name: '蟻　芸太12',
          //       },
          //     },
          //     {
          //       groupPassageId: 'CA79A902-F046-4023-8481-EE20BC971111',
          //       userId: 'alligator',
          //       groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //       group: {
          //         groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //         groupName: '社員用',
          //         registered: 1531445882,
          //         updated: 1531445882,
          //         isValid: true,
          //       },
          //       user: {
          //         userId: 'alligator3',
          //         name: '蟻　芸太2',
          //       },
          //     },
          //     {
          //       groupPassageId: 'CA79A902-F046-4023-8481-EE20BC971111',
          //       userId: 'alligator',
          //       groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //       group: {
          //         groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //         groupName: '社員用',
          //         registered: 1531445882,
          //         updated: 1531445882,
          //         isValid: true,
          //       },
          //       user: {
          //         userId: 'alligator4',
          //         name: '蟻　芸太2',
          //       },
          //     },
          //   ],
          // };
          if (response.body.maxRecords > 0) {
            response.body.groupPassages.forEach((element: any) => {
              if (element.user !== null && element.user !== undefined) {
                this.userList.push({text: element.user.name, value: element.user.userId});
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getUserByGroupId(page + 1, groupId);
            }
          } else {
            this.userList = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        }); // catch
    },
    getGateByGroupId(page: any, groupId: any) {
      this.$http.get(Config.RELATION_GATE_GROUP +
      '?groupId=' + encodeURIComponent(groupId) + '&limit=1000&page=' + page + '&sortKey=desc:gateId&embed=gate&isExceptOptionalGate=true')
        .then((response: any) => {
          // // quocdat - dataTest
          // response.body = {
          //   message: 'Success',
          //   statementId: 'DEFA7140-1DEC-4642-BAA2-BB0B5B74D212',
          //   startRecord: 1,
          //   endRecord: 1,
          //   maxRecords: 1,
          //   relationsGateGroup: [
          //     {
          //       relationId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E42',
          //       gateId: 'zpsHmwSwxfSw',
          //       groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //       gate: {
          //         gateId: 'zpsHmwSwxfSw',
          //         gateName: 'ロッカー1',
          //       },
          //     },
          //     {
          //       relationId: 'EA2CE971-F55F-45A6-835B-DE8C5D526111',
          //       gateId: 'zpsHmwSwxfSw',
          //       groupId: 'BFF4DC75-FDE2-4580-8281-A62CBD8E10B1',
          //       gate: {
          //         gateId: 'zpsHmwSwxfas',
          //         gateName: 'ロッカー1222',
          //       },
          //     },
          //   ],
          // };
          if (response.body.maxRecords > 0) {
            response.body.relationsGateGroup.forEach((element: any) => {
              if (element.gate !== null && element.gate !== undefined) {
                this.gateList.push({text: element.gate.name, value: element.gate.gateId});
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGateByGroupId(page + 1, groupId);
            }
          } else {
            this.gateList = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
