












import Vue from 'vue';
export default Vue.extend({
  name: 'CardLink',
  props: {
    text: {
      type: String,
      default: () => '',
    },
    width: {
      type: Number,
      default: () => 400,
    },
  },
});
