var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("safie-template", {
    attrs: {
      loading: _vm.loading,
      putDelLoading: _vm.putDelLoading,
      postLoading: _vm.postLoading,
      safieRelationGateDevices: _vm.safieRelationGateDevices,
      addNewRelationGateDevices: _vm.newSafieRelationGateDevices,
      gateFullList: _vm.gateFullList,
      results: _vm.results
    },
    on: {
      "add-new-record": function($event) {
        return _vm.addNewRecord()
      },
      "del-new-record": _vm.delNewRecord,
      "form-update": _vm.formUpdate,
      "new-form-update": _vm.formUpdateNew,
      "put-record": _vm.putSafieRelationGateDevice,
      "post-record": _vm.postSafieRelationGateDevice,
      "delete-record": _vm.deleteSafieRelationGateDevice
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }