var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("user-gate-add-group-template", {
    attrs: {
      dataGates: _vm.dataGates,
      gateTotalCount: _vm.gateTotalCount,
      page: _vm.currPage,
      perPage: _vm.perPage,
      goBack: _vm.goBack,
      loading: _vm.loading,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      errorMessageProcess: _vm.errorMessageProcess,
      successItemProcess: _vm.successItemProcess,
      totalDataRowProcess: _vm.totalDataRowProcess,
      showProgress: _vm.showProgress,
      isFinishProcess: _vm.isFinishProcess,
      showConfirm: _vm.showConfirm,
      gateOfGroup: _vm.gateOfGroup,
      progress: _vm.progress
    },
    on: {
      changePerPage: function($event) {
        return _vm.changePerPage($event)
      },
      clickAddGate: function($event) {
        return _vm.clickAddGate($event)
      },
      clickDelete: function($event) {
        return _vm.clickDelete($event)
      },
      changePage: function($event) {
        return _vm.changePage($event)
      },
      clickConfirm: function($event) {
        return _vm.clickConfirm($event)
      },
      closeModalProgress: function($event) {
        return _vm.closeModalProgress()
      },
      closeDialogConfirm: function($event) {
        return _vm.closeModalProgress()
      },
      closeDialog: function($event) {
        return _vm.closeDialog()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }