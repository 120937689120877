



























































































































































































import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import { DataTableHeader } from 'vuetify';
import { ZenescanFaceDb, FaceDb, ZenescanRequestLogin } from '@/types/alligate-zenescan';
import ZenescanLogin, { DisplayZenescanLogin } from '@/components/organisms/ZenescanLogin/ZenescanLogin.vue';

export interface DisplayZenescanFaceDb {
  faceDbId: string; // 顔DbID
  zenescanLibName: string; // 顔Db名
  zenescanIpAddress: string; // 顔DbのIPアドレス
  zenescanLibId: number; // 顔Dbコード
  notes: string; // 自由記載欄
  status: string; // ステータス：ALLIGATE(クラウド)とZENESCANのDBとの差分があるかどうか
  isConnected: boolean;
}

interface DataType {
  deleteTargetZenescanFaceDbId: string;
  syncTargetZenescanFaceDbId: string;
  isDisplaySuccessMessage: boolean;
  isDisplayErrorMessage: boolean;
  isDisplayDeleteConfirm: boolean;
  isDisplaySyncConfirm: boolean;
  perPageItemLength: number;
  page: number;
  headers: DataTableHeader[];
  selectedItem: DisplayZenescanFaceDb[];
  selectedFaceDbIds: string[];
  isDisplayLoginForm: boolean;
  isOtherError: boolean;
  loginInfo: DisplayZenescanLogin;
}

export default Vue.extend({
  name: 'ZenescanFaceDbList',
  components: {
    InputForm,
    SuccessButton,
    CancelButton,
    Modal,
    Alert,
    ZenescanLogin,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => true,
    },
    isLoadingZenescanFaceDb: {
      type: Boolean,
      default: () => false,
    },
    successMessageLogsModal: {
      type: String,
      default: '',
    },
    errorMessageLogsModal: {
      type: String,
      default: '',
    },
    zenescanFaceDb: {
      type: Object as PropType<ZenescanFaceDb>,
      default: () => {
        return {
          faceDbId: '',
          zenescanSerialId: '',
          zenescanIpAddress: '',
          zenescanLibId: '',
          zenescanLibType: '',
          zenescanLibName: '',
          notes: '',
          status: '-',
        };
      },
    },
    zenescanFaceDbs: {
      type: Array as PropType<ZenescanFaceDb[]>,
      default: () => [],
    },
    faceDbs: {
      type: Array as PropType<FaceDb[]>,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => {
    return {
      deleteTargetZenescanFaceDbId: '',
      syncTargetZenescanFaceDbId: '',
      isDisplaySuccessMessage: false,
      isDisplayErrorMessage: false,
      isDisplayDeleteConfirm: false,
      isDisplaySyncConfirm: false,
      /** 1page毎の表示件数 */
      perPageItemLength: 10,
      page: 1,
      headers: [{
        text: '顔DB名',
        align: 'start',
        sortable: true,
        value: 'zenescanLibName',
      }, {
        text: 'IPアドレス',
        align: 'start',
        sortable: false,
        value: 'zenescanIpAddress',
      }, {
        text: '備考',
        align: 'start',
        sortable: false,
        value: 'notes',
      }, {
        text: '',
        align: 'end',
        sortable: false,
        value: 'icons',
      }],
      selectedFaceDbIds: [],
      selectedItem: [],
      isDisplayLoginForm: false,
      isOtherError: false,
      loginInfo: {
        faceDbId: '',
        ipAddress: '',
        userName: '',
        userPwd: '',
      },
    };
  },
  watch: {
    successMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplaySuccessMessage = true;
      } else {
        this.isDisplaySuccessMessage = false;
      }
    },
    errorMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplayErrorMessage = true;
      } else {
        this.isDisplayErrorMessage = false;
      }
    },
    selectedItem(val: DisplayZenescanFaceDb[]) {
      this.selectedFaceDbIds = val.map((item) => item.faceDbId);
      this.$emit('select-face-db', this.selectedFaceDbIds);
    },
  },
  computed: {
    displayZenescanFaceDbs: {
      get(): DisplayZenescanFaceDb[] {
        const retZenescanFaceDb: DisplayZenescanFaceDb[] = this.zenescanFaceDbs.map((item) => {
          const targetFaceDbIndex = this.faceDbs.findIndex((f) => item.zenescanLibId === f.lib_id);
          const ret: DisplayZenescanFaceDb = {
            faceDbId: item.faceDbId,
            zenescanLibName: targetFaceDbIndex === -1 ? item.zenescanLibName : this.faceDbs[targetFaceDbIndex].lib_name,
            zenescanIpAddress: item.zenescanIpAddress,
            zenescanLibId: item.zenescanLibId,
            isConnected: Boolean(item.loginInfo && item.loginInfo.sessionid),
            notes: item.notes,
            status: item.status ? item.status : '-',
          };
          return ret;
        });
        return retZenescanFaceDb;
      },
      set(): void {
        return;
      },
    },
  },
  methods: {
    clickConfirmDeleteZenescanFaceDb(ID: string): void {
      this.$emit('reset-msg');
      this.deleteTargetZenescanFaceDbId = ID;
      this.isDisplayDeleteConfirm = true;
    },
    deleteComfirmZenescanFaceDb(isConfirm: boolean): void {
      if (isConfirm === true) {
        this.$emit('click-delete-zenescan-face-db', this.deleteTargetZenescanFaceDbId);
        this.$emit('reload-zenescan-face-dbs');
      }
      this.deleteTargetZenescanFaceDbId = '';
      this.isDisplayDeleteConfirm = false;
    },
    clickConfirmSyncZenescanFaceDb(ID: string): void {
      this.$emit('reset-msg');
      this.syncTargetZenescanFaceDbId = ID;
      this.isDisplaySyncConfirm = true;
    },
    syncComfirmZenescanFaceDb(isConfirm: boolean): void {
      if (isConfirm === true) {
        this.$emit('click-sync-zenescan-face-db', this.syncTargetZenescanFaceDbId);
      }
      this.syncTargetZenescanFaceDbId = '';
      this.isDisplaySyncConfirm = false;
    },
    openLoginInfoEdit(faceDbId: string): void {
      const zenescanFaceDb = this.zenescanFaceDbs.find((faceDb) => faceDb.faceDbId === faceDbId);
      if (zenescanFaceDb) {
        this.loginInfo = {
          faceDbId,
          ipAddress: zenescanFaceDb.zenescanIpAddress,
          userName: zenescanFaceDb.loginInfo ? zenescanFaceDb.loginInfo.userName : '',
          userPwd: zenescanFaceDb.loginInfo ? zenescanFaceDb.loginInfo.userPwd : '',
        };
      }
      this.isDisplayLoginForm = true;
    },
    saveLoginInfo(event: DisplayZenescanLogin): void {
      this.$emit('click-zenescan-face-db-access-and-save', event);
      this.isDisplayLoginForm = false;
    },
  },
});
