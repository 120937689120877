












































import Vue from 'vue';
export default Vue.extend({
  name: 'MultipleLineHelpIcon',
  props: {
    line1: {
      type: String,
      default: '',
    },
    line2: {
      type: String,
      default: '',
    },
    line3: {
      type: String,
      default: '',
    },
    line4: {
      type: String,
      default: '',
    },
    line5: {
      type: String,
      default: '',
    },
    line6: {
      type: String,
      default: '',
    },
    line7: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: '',
    },
  },
});
