var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-setting-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "round-tab" },
          [
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.tabItems, function(item) {
                  return _c(
                    "v-tab",
                    {
                      key: item.tabname,
                      staticClass: "setting-tab",
                      on: {
                        click: function($event) {
                          return _vm.clickTab(item.tabname)
                        },
                        change: function($event) {
                          return _vm.$emit("changeTab", $event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            _vm._v(" "),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  {
                    key: "tab1",
                    attrs: { transition: false, "reverse-transition": false }
                  },
                  [
                    _vm.isEdit === 0
                      ? _c(
                          "v-card",
                          [
                            _c("passable-time-setting-list", {
                              attrs: {
                                passableTotalCount: _vm.passableTotalCount,
                                ExpandedGroup: _vm.ExpandedGroup,
                                loadingGroup: _vm.loadingGroup,
                                pageGroup: _vm.pageGroup,
                                dataPassables: _vm.dataPassables,
                                pagePassable: _vm.pagePassable,
                                perPagePassable: _vm.perPagePassable,
                                errorMessageLogs: _vm.errorMessageLogs,
                                successMessageLogs: _vm.successMessageLogs,
                                errorMessageLogsModal:
                                  _vm.errorMessageLogsModal,
                                successMessageLogsModal:
                                  _vm.successMessageLogsModal,
                                loading: _vm.loading,
                                isEdit: _vm.isEdit,
                                isGetDataList: _vm.isGetDataList,
                                showConfirmDelete: _vm.showConfirmDelete
                              },
                              on: {
                                "change-expanded-time": function($event) {
                                  return _vm.$emit("change-expanded", $event)
                                },
                                clickDetailPassable: function($event) {
                                  return _vm.$emit(
                                    "clickDetailPassable",
                                    $event
                                  )
                                },
                                "change-per-page-passable": function($event) {
                                  return _vm.$emit(
                                    "changePerPagePassable",
                                    $event
                                  )
                                },
                                "change-page-group": function($event) {
                                  return _vm.$emit("change-page-group", $event)
                                },
                                "change-page-passable": function($event) {
                                  return _vm.$emit("changePagePassable", $event)
                                },
                                clickEditPassable: function($event) {
                                  return _vm.$emit("clickEditPassable", $event)
                                },
                                clickAddPassable: function($event) {
                                  return _vm.$emit("clickAddPassable", $event)
                                },
                                clickDeletePassable: function($event) {
                                  return _vm.$emit(
                                    "clickDeletePassable",
                                    $event
                                  )
                                },
                                clickConfirmDeletePassable: function($event) {
                                  return _vm.$emit(
                                    "clickConfirmDeletePassable",
                                    $event
                                  )
                                },
                                clickCloseConfirmDeletePassable: function(
                                  $event
                                ) {
                                  return _vm.$emit(
                                    "clickCloseConfirmDeletePassable",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "v-card",
                          [
                            _c("passable-time-setting-edit", {
                              attrs: {
                                timeSettings: _vm.timeSettings,
                                dataPassableDetail: _vm.dataPassableDetail,
                                groupListAdd: _vm.groupListAdd,
                                groupListEdit: _vm.groupListEdit,
                                dayTypes: _vm.dayTypes,
                                isEdit: _vm.isEdit,
                                loading: _vm.loading,
                                loadingDate: _vm.loadingDate,
                                errorMessageLogs: _vm.errorMessageLogs,
                                successMessageLogs: _vm.successMessageLogs,
                                groupListByPassableTime:
                                  _vm.groupListByPassableTime
                              },
                              on: {
                                clickEditPassable: function($event) {
                                  return _vm.$emit("clickEditPassable", $event)
                                },
                                clickSavePassable: function($event) {
                                  return _vm.$emit("clickSavePassable", $event)
                                },
                                clickBackPassable: function($event) {
                                  return _vm.$emit("clickBackPassable", $event)
                                },
                                clickRegisterPassable: function($event) {
                                  return _vm.$emit(
                                    "clickRegisterPassable",
                                    $event
                                  )
                                },
                                resetMsg: function($event) {
                                  return _vm.$emit("resetMsg", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  {
                    key: "tab2",
                    attrs: { transition: false, "reverse-transition": false }
                  },
                  [
                    _c("HolidaySetting", {
                      attrs: {
                        errorMessageLogs: _vm.errorMessageLogs,
                        isFinishProcess: _vm.isFinishProcess,
                        saveFinish: _vm.saveFinish,
                        successMessageLogs: _vm.successMessageLogs,
                        errorMessageLogsModal: _vm.errorMessageLogsModal,
                        successMessageLogsModal: _vm.successMessageLogsModal,
                        months: _vm.months,
                        timeAdd: _vm.timeAdd,
                        dayTypesCalendar: _vm.dayTypesCalendar,
                        isFinishDate: _vm.isFinishDate,
                        isSaveDate: _vm.isSaveDate,
                        loading: _vm.loading
                      },
                      on: {
                        isGetCalendar: function($event) {
                          return _vm.$emit("isGetCalendar", $event)
                        },
                        saveDate: function($event) {
                          return _vm.$emit("saveDate", $event)
                        },
                        closeDate: function($event) {
                          return _vm.$emit("closeDate", $event)
                        },
                        closeDateFinish: function($event) {
                          return _vm.$emit("closeDateFinish", $event)
                        },
                        saveAllDate: function($event) {
                          return _vm.$emit("saveAllDate", $event)
                        },
                        setDateOne: function($event) {
                          return _vm.$emit("setDateOne", $event)
                        },
                        setTypeDate: function($event) {
                          return _vm.$emit("setTypeDate", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  {
                    key: "tab4",
                    attrs: { transition: false, "reverse-transition": false }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _vm.isExternalLogin === "setting"
                          ? _c("ExternalLoginSetting", {
                              attrs: {
                                oneLoginLoginService: _vm.oneLoginLoginService,
                                appleIdLoginService: _vm.appleIdLoginService,
                                googleLoginService: _vm.googleLoginService,
                                errorMessageLogs: _vm.errorMessageLogs,
                                successMessageLogs: _vm.successMessageLogs,
                                loading: _vm.loading,
                                showModalImport: _vm.showModalImport,
                                isFederatedLoginService:
                                  _vm.isFederatedLoginService,
                                isSamlFederatedLoginService:
                                  _vm.isSamlFederatedLoginService
                              },
                              on: {
                                isGetFederatedLoginService:
                                  _vm.isGetFederatedLoginService,
                                goRelation: _vm.goRelation,
                                saveConfig: function($event) {
                                  return _vm.$emit("saveConfig")
                                }
                              }
                            })
                          : _c("RegisterLoginUserTemplate", {
                              attrs: {
                                errorMessageLogs: _vm.errorMessageLogs,
                                successMessageLogs: _vm.successMessageLogs,
                                oneLoginLoginService: _vm.oneLoginLoginService,
                                appleIdLoginService: _vm.appleIdLoginService,
                                googleLoginService: _vm.googleLoginService,
                                isDisabled: _vm.isDisabled,
                                userList: _vm.userList,
                                page: _vm.pageLogin,
                                pageSizeList: _vm.pageSizeList,
                                realtionsUserList: _vm.realtionsUserList,
                                cognitoIdProviderName:
                                  _vm.cognitoIdProviderName,
                                isFederatedLoginService:
                                  _vm.isFederatedLoginService,
                                isSamlFederatedLoginService:
                                  _vm.isSamlFederatedLoginService,
                                federatedLoginServiceId:
                                  _vm.federatedLoginServiceId,
                                sucessStatus: _vm.sucessStatus,
                                message: _vm.message,
                                loading: _vm.loading,
                                maxRecord: _vm.maxRecord,
                                isExternalLogin: _vm.isExternalLogin,
                                showModalImport: _vm.showModalImport,
                                progress: _vm.progress,
                                errorMessageExcel: _vm.errorMessageExcel,
                                successItem: _vm.successItem,
                                totalDataRow: _vm.totalDataRow,
                                success: _vm.success,
                                errorMessage: _vm.errorMessage,
                                errorMessageParams: _vm.errorMessageParams
                              },
                              on: {
                                "change-per-page-login": function($event) {
                                  return _vm.$emit("changePerPageLogin", $event)
                                },
                                "change-page-login": function($event) {
                                  return _vm.$emit("changePageLogin", $event)
                                },
                                isGetFederatedLoginService:
                                  _vm.isGetFederatedLoginService,
                                goRelation: _vm.goRelation,
                                goPageSetting: function($event) {
                                  return _vm.$emit("goPageSetting")
                                },
                                "search-login": function($event) {
                                  return _vm.$emit("search-login", $event)
                                },
                                "select-user-login": function($event) {
                                  return _vm.$emit("select-user-login", $event)
                                },
                                goPageExport: function($event) {
                                  return _vm.$emit("goPageExport", $event)
                                },
                                closeModalImport: function($event) {
                                  return _vm.$emit("closeModalImport", $event)
                                },
                                addRelation: _vm.addRelation,
                                deleteRelation: _vm.deleteRelation
                              }
                            }),
                        _vm._v(" "),
                        _c("ExternalLoginImport", {
                          attrs: {
                            isFederatedLoginService:
                              _vm.isFederatedLoginService,
                            isSamlFederatedLoginService:
                              _vm.isSamlFederatedLoginService,
                            federatedLoginServiceId:
                              _vm.federatedLoginServiceId,
                            showModalImport: _vm.showModalImport,
                            showProgressImport: _vm.showProgressImport,
                            progress: _vm.progress,
                            isFinishProcess: _vm.isFinishProcess,
                            cognitoIdProviderName: _vm.cognitoIdProviderName,
                            showProgressExportCSV: _vm.showProgressExportCSV,
                            errorMessageExcel: _vm.errorMessageExcel,
                            successItem: _vm.successItem,
                            totalDataRow: _vm.totalDataRow,
                            success: _vm.success,
                            errorMessage: _vm.errorMessage,
                            errorMessageParams: _vm.errorMessageParams
                          },
                          on: {
                            clickExport: function($event) {
                              return _vm.$emit("clickExport", $event)
                            },
                            clickImport: function($event) {
                              return _vm.$emit("clickImport", $event)
                            },
                            closeModalProcess: function($event) {
                              return _vm.$emit("closeModalProcess", $event)
                            },
                            closeModalProcessImport: function($event) {
                              return _vm.$emit(
                                "closeModalProcessImport",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  {
                    key: "tab5",
                    attrs: { transition: false, "reverse-transition": false }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("NoticeSettingTypeList", {
                          attrs: {
                            noticeSettingTypes: _vm.noticeSettingTypes,
                            noticeSettings: _vm.noticeSettings,
                            errorMessageLogsModal: _vm.errorMessageLogsModal,
                            successMessageLogsModal:
                              _vm.successMessageLogsModal,
                            isLoadBlockingNoticeSettingType:
                              _vm.isLoadBlockingNoticeSettingType,
                            isLoadBlockingNoticeSetting:
                              _vm.isLoadBlockingNoticeSetting,
                            isLoadingNoticeSetting: _vm.isLoadingNoticeSetting,
                            loadBlockingTextNoticeSetting:
                              _vm.loadBlockingTextNoticeSetting,
                            configurableConnectStatus:
                              _vm.configurableConnectStatus,
                            configurableGates: _vm.configurableGates,
                            configurablePcodes: _vm.configurablePcodes,
                            configurableUsers: _vm.configurableUsers,
                            configurableLogs: _vm.configurableLogs
                          },
                          on: {
                            "click-delete-notice-setting": function($event) {
                              return _vm.$emit(
                                "click-delete-notice-setting",
                                $event
                              )
                            },
                            "click-add-notice-setting": function($event) {
                              return _vm.$emit(
                                "click-add-notice-setting",
                                $event
                              )
                            },
                            "click-edit-notice-setting": function($event) {
                              return _vm.$emit(
                                "click-edit-notice-setting",
                                $event
                              )
                            },
                            "reset-msg": function($event) {
                              return _vm.$emit("resetMsg")
                            },
                            "reload-notice-settings": function($event) {
                              return _vm.$emit("reload-notice-settings")
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  {
                    key: "tabLogLabelSetting",
                    attrs: { transition: false, "reverse-transition": false }
                  },
                  [
                    _vm.isEditLogLabel === false
                      ? _c("LogLabelSetting", {
                          attrs: {
                            listConfifLog: _vm.listConfifLog,
                            loading: _vm.loading,
                            isGetDataList: _vm.isGetDataList,
                            pages: _vm.pages,
                            page: _vm.page,
                            perPage: _vm.perPage,
                            maxRecord: _vm.maxRecord,
                            errorMessageLogs: _vm.errorMessageLogs,
                            successMessageLogs: _vm.successMessageLogs
                          },
                          on: {
                            "change-per-page": function($event) {
                              return _vm.$emit("changePerPage", $event)
                            },
                            "change-page": function($event) {
                              return _vm.$emit("change-page", $event)
                            },
                            clickEditLogLabel: function($event) {
                              return _vm.$emit("clickEditLogLabel", $event)
                            }
                          }
                        })
                      : _c("LogLabelSettingEdit", {
                          attrs: {
                            dataEditLogLabel: _vm.dataEditLogLabel,
                            logLabelErr: _vm.logLabelErr,
                            errorMessageLogs: _vm.errorMessageLogs,
                            successMessageLogs: _vm.successMessageLogs,
                            loading: _vm.loading
                          },
                          on: {
                            clickBackLogLabel: function($event) {
                              return _vm.$emit("clickBackLogLabel")
                            },
                            clickSaveLogLabel: function($event) {
                              return _vm.$emit("clickSaveLogLabel", $event)
                            },
                            clickDeleteLogLabel: function($event) {
                              return _vm.$emit("clickDeleteLogLabel", $event)
                            }
                          }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  {
                    key: "tabPhoneAutoApproval",
                    attrs: { transition: false, "reverse-transition": false }
                  },
                  [
                    _c("smartphone-auto-approval", {
                      attrs: {
                        settingInfor: _vm.settingInfor,
                        errorMessageLogs: _vm.errorMessageLogs,
                        successMessageLogs: _vm.successMessageLogs
                      },
                      on: {
                        savePhoneStatus: function($event) {
                          return _vm.$emit("savePhoneStatus")
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  {
                    key: "tabKeyTokenSetting",
                    attrs: { transition: false, "reverse-transition": false }
                  },
                  [
                    _c("key-token-setting", {
                      attrs: {
                        settingInfor: _vm.settingInfor,
                        errorMessageLogs: _vm.errorMessageLogs,
                        successMessageLogs: _vm.successMessageLogs
                      },
                      on: {
                        saveKeyToken: function($event) {
                          return _vm.$emit("saveKeyToken")
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  {
                    key: "tabNFCSetting",
                    attrs: { transition: false, "reverse-transition": false }
                  },
                  [
                    _c("NfcSetting", {
                      attrs: {
                        editStatusException: _vm.editStatusException,
                        editStatus: _vm.editStatus,
                        settingInfor: _vm.settingInfor,
                        isFelica: _vm.isFelica,
                        isMifare: _vm.isMifare,
                        listNfcSettingSelect: _vm.listNfcSettingSelect,
                        errorMessageLogs: _vm.errorMessageLogs,
                        successMessageLogs: _vm.successMessageLogs
                      },
                      on: {
                        saveNfcSetting: function($event) {
                          return _vm.$emit("saveNfcSetting")
                        },
                        checkEditStatus: function($event) {
                          return _vm.$emit("checkEditStatus")
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }