var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-container",
        { staticClass: "tab-gate-log-list" },
        [
          _c(
            "div",
            { staticClass: "round-action" },
            [
              _c(
                "v-row",
                { staticClass: "btn-div", attrs: { justify: "start" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        xl: "6",
                        sm: "12",
                        lg: "6",
                        xxl: "6",
                        md: "12"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "area-search-box" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "row-date-search" },
                            [
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "pl-0 pr-0 mt-3",
                                      class: {
                                        "row-date-search":
                                          _vm.$vuetify.breakpoint.mdAndUp
                                      }
                                    },
                                    [
                                      _c(
                                        "v-subheader",
                                        {
                                          staticClass: "justify-start title-sub"
                                        },
                                        [_vm._v("時間指定")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pr-0 mr-1",
                                  class: {
                                    "pl-0 pr-0 mt-3":
                                      _vm.$vuetify.breakpoint.mdAndUp
                                  },
                                  attrs: { cols: "10" }
                                },
                                [
                                  _c("DateTimePicker", {
                                    attrs: {
                                      dateStartValue: _vm.dateStart,
                                      dateEndValue: _vm.dateEnd,
                                      timeSValue: "00:00",
                                      timeEValue: "23:59",
                                      isDisable: _vm.isDisableLog
                                    },
                                    on: {
                                      getNewTimeStart: _vm.getNewTimeStart,
                                      getNewTimeEnd: _vm.getNewTimeEnd,
                                      getNewDateStart: _vm.getNewDateStart,
                                      getNewDateEnd: _vm.getNewDateEnd
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "div-logType",
                      attrs: {
                        cols: "12",
                        xl: "5",
                        sm: "11",
                        lg: "5",
                        xxl: "5",
                        md: "11"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "span",
                                {
                                  staticClass: "pl-0 pr-0 pt-2",
                                  attrs: { md: "3", xl: "2" }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticClass:
                                        "justify-start pt-0 ml-5 pr-1"
                                    },
                                    [_vm._v("表示種別")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-0 pr-0 pt-0",
                              attrs: { cols: "9" }
                            },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { row: "" },
                                  on: {
                                    change: function($event) {
                                      return _vm.ChangeValue()
                                    }
                                  },
                                  model: {
                                    value: _vm.logType,
                                    callback: function($$v) {
                                      _vm.logType = $$v
                                    },
                                    expression: "logType"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "radio-item ml-2",
                                    attrs: {
                                      color: "green-primary",
                                      label: "照合ログ",
                                      value: "1",
                                      disabled: _vm.isDisableLog
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    staticClass: "radio-item",
                                    attrs: {
                                      color: "green-primary",
                                      label: "ゲートログ",
                                      value: "2",
                                      disabled: _vm.isDisableLog
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    staticClass: "radio-item",
                                    attrs: {
                                      color: "green-primary",
                                      label: "すベて",
                                      value: "0",
                                      disabled: _vm.isDisableLog
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        xl: "1",
                        sm: "1",
                        lg: "1",
                        xxl: "1",
                        md: "1"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-3 mb-3" },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "end" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-primary",
                                  attrs: {
                                    outlined: "",
                                    disabled: _vm.isDisableLog
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickModal()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            工クスボート\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "800" },
                                  model: {
                                    value: _vm.showModal,
                                    callback: function($$v) {
                                      _vm.showModal = $$v
                                    },
                                    expression: "showModal"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "background-color-green"
                                        },
                                        [_vm._v(" CSVファイルエクスポート ")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                      _vm._v(" "),
                                      _c(
                                        "v-alert",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.messageErrorDialog !== "",
                                              expression:
                                                "messageErrorDialog !== ''"
                                            }
                                          ],
                                          attrs: { dense: "", color: "green" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.messageErrorDialog) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-4" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "row-date-search-modal"
                                            },
                                            [
                                              _vm.$vuetify.breakpoint.mdAndUp
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "title-export",
                                                      attrs: { cols: "2" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-subheader",
                                                        {
                                                          staticClass:
                                                            "justify-start mt-1 pa-0"
                                                        },
                                                        [_vm._v("時間指定")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "9" } },
                                                [
                                                  _c("DateTimePicker", {
                                                    attrs: {
                                                      dateStartValue:
                                                        _vm.dateStart,
                                                      dateEndValue: _vm.dateEnd,
                                                      timeSValue: _vm.timeStart,
                                                      timeEValue: _vm.timeEnd,
                                                      isDisable: _vm.isDisable,
                                                      isExportCSVWorkTimeType:
                                                        _vm.isExportCSVWorkTimeType
                                                    },
                                                    on: {
                                                      getNewTimeStart:
                                                        _vm.getNewTimeStartCSV,
                                                      getNewTimeEnd:
                                                        _vm.getNewTimeEndCSV,
                                                      getNewDateStart:
                                                        _vm.getNewDateStartCSV,
                                                      getNewDateEnd:
                                                        _vm.getNewDateEndCSV
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.typeExportCSV === "0"
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass: "rowWorktimeTime"
                                                },
                                                [
                                                  _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "title-export-worktime-time",
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-subheader",
                                                            {
                                                              staticClass:
                                                                "justify-start mt-4 pa-0"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "日付変更線"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.typeExportCSV === "0"
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-worktime-time",
                                                          attrs: {
                                                            cols: "3",
                                                            pa: "0"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                "content-class":
                                                                  "background-white",
                                                                "close-on-content-click": false,
                                                                transition:
                                                                  "scale-transition",
                                                                "offset-y": "",
                                                                "min-width":
                                                                  "auto"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "v-text-field",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "input-add",
                                                                                attrs: {
                                                                                  color:
                                                                                    "green",
                                                                                  outlined:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  "prepend-inner-icon":
                                                                                    "mdi-clock-time-four-outline",
                                                                                  "hide-details":
                                                                                    "auto",
                                                                                  disabled:
                                                                                    _vm.isDisable,
                                                                                  autocomplete:
                                                                                    "off",
                                                                                  readonly:
                                                                                    ""
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm.timeWorktime,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.timeWorktime = $$v
                                                                                  },
                                                                                  expression:
                                                                                    "timeWorktime"
                                                                                }
                                                                              },
                                                                              "v-text-field",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                3759102574
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "allig-select"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            items:
                                                                              _vm.hours,
                                                                            color:
                                                                              "green",
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            "hide-details":
                                                                              "auto",
                                                                            disabled:
                                                                              _vm.isDisable
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.hourWorktime,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.hourWorktime = $$v
                                                                            },
                                                                            expression:
                                                                              "hourWorktime"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2 mr-2 ml-2 font-weight-bold"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        ":"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "allig-select"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            items:
                                                                              _vm.minutes,
                                                                            color:
                                                                              "green",
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            "full-width":
                                                                              "",
                                                                            "hide-details":
                                                                              "auto",
                                                                            disabled:
                                                                              _vm.isDisable
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.minuteWorktime,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.minuteWorktime = $$v
                                                                            },
                                                                            expression:
                                                                              "minuteWorktime"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-row",
                                                {
                                                  staticClass: "rowWorktimeTime"
                                                },
                                                [
                                                  _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "title-export-worktime-time",
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _c("v-subheader", {
                                                            staticClass:
                                                              "justify-start mt-4 pa-0"
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            { staticClass: "row-export-type" },
                                            [
                                              _vm.$vuetify.breakpoint.mdAndUp
                                                ? _c(
                                                    "v-col",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _c(
                                                        "v-subheader",
                                                        {
                                                          staticClass:
                                                            "justify-start mt-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "出カ形式を選択してください"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "margin-row-option",
                                              attrs: { justify: "center" }
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { row: "" },
                                                  model: {
                                                    value: _vm.typeExportCSV,
                                                    callback: function($$v) {
                                                      _vm.typeExportCSV = $$v
                                                    },
                                                    expression: "typeExportCSV"
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    staticClass:
                                                      "radio-item ml-2",
                                                    attrs: {
                                                      color: "green-primary",
                                                      label: "照合ログ",
                                                      value: "1"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    staticClass:
                                                      "radio-item ml-2",
                                                    attrs: {
                                                      color: "green-primary",
                                                      label: "ゲートログ",
                                                      value: "2"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    staticClass: "radio-item",
                                                    attrs: {
                                                      color: "green-primary",
                                                      label: "勤怠表形式",
                                                      value: "0"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "ma-2",
                                              attrs: { justify: "end" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "btn-secondary",
                                                  attrs: { outlined: "" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.showModal = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    キャンセル\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "btn-primary",
                                                  attrs: { outlined: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$emit(
                                                        "clickExportLog",
                                                        {
                                                          typeExportCSV:
                                                            _vm.typeExportCSV,
                                                          dateStartExportCSV:
                                                            _vm.dateStartGateCSV +
                                                            " " +
                                                            _vm.timeStartGateCSV,
                                                          dateEndExportCSV:
                                                            _vm.dateEndGateCSV +
                                                            " " +
                                                            _vm.timeEndGateCSV,
                                                          worktimeTime:
                                                            _vm.timeWorktime
                                                        }
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    エクスポート\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c("v-col", { attrs: { md: "5", xl: "4" } }, [
                    _c(
                      "div",
                      { staticClass: "area-search-box" },
                      [
                        _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": "",
                              justify: "space-between"
                            }
                          },
                          [
                            _c("searchbox", {
                              staticClass:
                                "input-search col-sm-12 col-xl-12 col btn-user-search",
                              attrs: {
                                title:
                                  "ユーザー名・ゲート名・ログ名称で絞り込み",
                                searchTargetItems: _vm.searchTargetItems,
                                disabled: _vm.isDisableSearch,
                                goBack: _vm.goBack,
                                isPageLog: true
                              },
                              on: {
                                search: function($event) {
                                  return _vm.searchGateLog($event)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("v-spacer")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isDisableLog
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "v-subheader justify-start pt-0 ml-5 pr-1 theme--light display-inline title-log"
                          },
                          [
                            _vm._v(
                              " 組織管理者権限が有効なユーザーのみ期間指定/検索が可能"
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mt-0 border-bottom-bold  col-sm-12 col-12 pa-0",
                      attrs: {
                        "no-gutters": "",
                        justify: "space-between",
                        "align-content": "center"
                      }
                    },
                    [
                      _c("show-per-page", {
                        staticClass: "ml-8 mb-3",
                        attrs: {
                          "per-page": _vm.perPage,
                          page: _vm.page,
                          "items-length": _vm.maxRecord,
                          type: "log"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-8",
                          on: {
                            click: function() {
                              _vm.showOption = !_vm.showOption
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "display-option-title" }, [
                            _vm._v("\n          表示オプション\n        ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "mb-3" },
                            [
                              _c("v-icon", { attrs: { dense: "" } }, [
                                _vm._v("mdi-chevron-down")
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pt-0 bg-display-option" },
                    [
                      _c("display-option", {
                        attrs: {
                          isShowOption: _vm.showOption,
                          "display-option-items": _vm.displayOptionItems,
                          disabled: _vm.loading,
                          isVaildOnlyShow: false,
                          isLog: "gate"
                        },
                        on: {
                          input: function($event) {
                            return _vm.CheckColumn($event)
                          },
                          "change-per-page-log": function($event) {
                            return _vm.changePerPageLog($event)
                          }
                        },
                        model: {
                          value: _vm.selectedDisplayOption,
                          callback: function($$v) {
                            _vm.selectedDisplayOption = $$v
                          },
                          expression: "selectedDisplayOption"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.errorMessageLogs !== ""
            ? _c(
                "v-col",
                { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errorMessageLogs) + "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "round-data" },
            [
              _c("v-data-table", {
                staticClass: "border-table log-table scroll-table log-table",
                attrs: {
                  "must-sort": "",
                  headers: _vm.headers,
                  items: _vm.logGates,
                  loading: _vm.isGetDataList,
                  "items-per-page": _vm.perPage,
                  "item-key": "timeSetting",
                  "no-data-text": "データーはありません",
                  "loading-text": "データを読み込中です",
                  "hide-default-footer": "",
                  "custom-sort": _vm.customSort
                },
                on: {
                  "update:options": function($event) {
                    return _vm.$emit("change-sort", $event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.timestamp",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [_vm._v(_vm._s(item.timestamp) + " ")]),
                          _vm._v(" "),
                          _vm.gateIdToTbeyeDeviceIds[item.gateId]
                            ? _vm._l(
                                _vm.gateIdToTbeyeDeviceIds[item.gateId],
                                function(gateDevice) {
                                  return _c(
                                    "v-tooltip",
                                    {
                                      key: gateDevice.relaltionId,
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.tbeyeDeviceAndTimestamp(
                                                              gateDevice,
                                                              item.unixtimestamp -
                                                                5
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-video-outline"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            gateDevice.notes
                                              ? gateDevice.notes
                                              : "<<名称未設定>>"
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.gateIdToSafieDeviceIds[item.gateId]
                            ? _vm._l(
                                _vm.gateIdToSafieDeviceIds[item.gateId],
                                function(gateDevice) {
                                  return _c(
                                    "v-tooltip",
                                    {
                                      key: gateDevice.deviceId,
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.safieDeviceAndTimestampLinkTo(
                                                              gateDevice.deviceId,
                                                              item.unixtimestamp -
                                                                5
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-video-outline"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            gateDevice.notes
                                              ? gateDevice.notes
                                              : "<<名称未設定>>"
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                }
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.location",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.location
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    item.location.latitude +
                                      "," +
                                      item.location.longitude
                                  ) + " "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.userId",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", { staticClass: "item-user-id" }, [
                            _vm._v(_vm._s(item.userId) + " ")
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.userName",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", { staticClass: "item-user-id" }, [
                            _vm._v(_vm._s(item.userName) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedItem,
                  callback: function($$v) {
                    _vm.selectedItem = $$v
                  },
                  expression: "selectedItem"
                }
              }),
              _vm._v(" "),
              _vm.disableNextGate
                ? _c(
                    "v-row",
                    { attrs: { justify: "start" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary margin-button-load-more",
                          attrs: { outlined: "" },
                          on: {
                            click: function() {
                              _vm.page++
                            }
                          }
                        },
                        [_vm._v("\n        更に読み込む\n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("modal", {
        attrs: { noTitle: true },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _vm.isRerender
                  ? _c("tb-eye", {
                      attrs: {
                        videoId: _vm.showTbeyeVideo.relationId,
                        hostname: _vm.showTbeyeVideo.hostName,
                        port: _vm.showTbeyeVideo.port,
                        channel: _vm.showTbeyeVideo.channelNo,
                        device: _vm.showTbeyeVideo.deviceType,
                        username: _vm.showTbeyeVideo.loginInfo.username,
                        password: _vm.showTbeyeVideo.loginInfo.password,
                        replayStartDate: _vm.replayStartDate
                      },
                      on: {
                        close: function($event) {
                          _vm.showTbeyeModal = false
                        },
                        rerender: function($event) {
                          return _vm.rerender()
                        },
                        "update-login-info": function($event) {
                          return _vm.$emit("update-login-info", {
                            relationId: _vm.showTbeyeVideo.relationId,
                            username: $event.username,
                            password: $event.password
                          })
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c("success-button", {
                  attrs: { text: "TBEYE管理画面" },
                  on: {
                    click: function($event) {
                      return _vm.openToLink(
                        "https://" +
                          _vm.showTbeyeVideo.loginInfo.username +
                          ":" +
                          _vm.showTbeyeVideo.loginInfo.password +
                          "@" +
                          _vm.showTbeyeVideo.hostName +
                          ":" +
                          _vm.showTbeyeVideo.port
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("cancel-button", {
                  attrs: { text: "閉じる" },
                  on: {
                    click: function($event) {
                      _vm.showTbeyeModal = false
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.showTbeyeModal,
          callback: function($$v) {
            _vm.showTbeyeModal = $$v
          },
          expression: "showTbeyeModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }