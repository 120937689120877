var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-progress-modal" },
    [
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "" },
          model: {
            value: _vm.innerValue,
            callback: function($$v) {
              _vm.innerValue = $$v
            },
            expression: "innerValue"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "space-between" } },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.title) + "\n          "
                      ),
                      _vm.finished
                        ? _c(
                            "v-icon",
                            {
                              on: {
                                click: function($event) {
                                  _vm.innerValue = false
                                }
                              }
                            },
                            [_vm._v("\n            mdi-close\n          ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "overflow-y-auto" },
                [
                  _vm.message === ""
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.totalCount) +
                            "件中" +
                            _vm._s(_vm.finishedCount) +
                            "件の処理が完了しました。"
                        )
                      ])
                    : _c("div", [_vm._v(" " + _vm._s(_vm.message) + " ")]),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    attrs: {
                      value: _vm.percent,
                      color: "green",
                      height: "40",
                      striped: !_vm.finished,
                      indeterminate: _vm.indeterminate
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _vm.showPercentage
                              ? _c("strong", [_vm._v(_vm._s(value) + "%")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _vm.errorCount > 0 || _vm.errorMessage !== ""
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "mt-4",
                          attrs: { type: "error", text: "" }
                        },
                        [
                          _vm.errorMessage === ""
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.errorCount) +
                                    "件の処理に失敗しました。"
                                )
                              ])
                            : _c("div", [
                                _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                              ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(_vm.errorContents, function(content, index) {
                              return _c("li", { key: index }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(content) +
                                    "\n            "
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.finished
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("cancel-button", {
                        attrs: { text: "閉じる" },
                        on: {
                          click: function($event) {
                            _vm.innerValue = false
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }