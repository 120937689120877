var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-modal" },
    [
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: _vm.persistent },
          model: {
            value: _vm.innerValue,
            callback: function($$v) {
              _vm.innerValue = $$v
            },
            expression: "innerValue"
          }
        },
        [
          _c(
            "v-card",
            [
              !_vm.noTitle
                ? _c(
                    "v-card-title",
                    { staticClass: "green white--text font-weight-bold" },
                    [
                      _vm._t("modal-title", [
                        _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": "",
                              justify: "space-between"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.title) +
                                "\n            "
                            ),
                            _c(
                              "v-icon",
                              {
                                attrs: { color: "white" },
                                on: {
                                  click: function($event) {
                                    _vm.innerValue = false
                                  }
                                }
                              },
                              [_vm._v("mdi-close")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-text",
                {
                  staticClass: "overflow-y-auto",
                  style: { background: _vm.backgroundColor }
                },
                [
                  _vm._t("modal-body", [
                    _vm._v("\n          " + _vm._s(_vm.text) + "\n        ")
                  ])
                ],
                2
              ),
              _vm._v(" "),
              !_vm.noFooter
                ? _c(
                    "v-card-actions",
                    [
                      _vm._t("modal-footer", [
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c("cancel-button", {
                          attrs: { text: "閉じる" },
                          on: {
                            click: function($event) {
                              _vm.innerValue = false
                            }
                          }
                        })
                      ])
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }