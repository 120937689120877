


































































































































































import Vue, { PropType } from 'vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import DragAndDropFileInputForm from '@/components/molecules/DragAndDropInputForm/DragAndDropFileInputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import { FaceImageFile } from '@/types/alligate-zenescan';
import Crypto from 'crypto';
import { User, PCode } from '@/types/alligate-zenescan';
import MultipleLineHelpIcon from '@/components/molecules/MultipleLineHelpIcon/MultipleLineHelpIcon.vue';

export interface ImportFileData {
  fileName: string;
  pCodeId: string;
  pCodeType: number | undefined;
  userId: string;
  userName: string;
  user: string;
  noDataFlag: boolean;
}

interface DataType {
  imageData: File[];
  importFile: any;
  maxFileSize: number;
}

export interface ImageInfo {
  fileName: string;
  fileSrc: string;
  imageFile: File;
  pCodeId: string;
  pCodeType: number;
  userId: string;
  userName: string;
  user: string;
  options: string;
  noDataFlag: boolean;
}

interface TypeSelect {
  text: string;
  value: string;
}

export default Vue.extend({
  name: 'ImageListForm',
  components: {
    Modal,
    InputForm,
    DragAndDropFileInputForm,
    SuccessButton,
    CancelButton,
    MultipleLineHelpIcon,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => true,
    },
    isVisibleInput: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Array as PropType<FaceImageFile[]>,
      default: () => [],
    },
    typeItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    pCodeItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    userItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    pCodes: {
      type: Array as PropType<PCode[]>,
      default: () => [],
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
  },
  data: (): DataType => {
    return {
      imageData: [],
      importFile: null,
      maxFileSize: 4 * 1024 * 1024,
    };
  },
  computed: {
    imageFiles: {
      get(): ImageInfo[] {
        const retImageFiles: ImageInfo[] = this.value.map((item) => {
          return {
            fileName: item.imageFile.name,
            fileSrc: URL.createObjectURL(item.imageFile),
            imageFile: item.imageFile,
            pCodeId: item.pCodeId,
            pCodeType: item.pCodeType,
            userId: item.userId,
            userName: item.userName,
            user: item.user,
            options: item.options,
            noDataFlag: item.noDataFlag,
          };
        });
        return retImageFiles;
      },
      set(): void {
        return;
      },
    },
  },
  methods: {
    close(): void {
      this.$emit('reset-msg');
      this.$emit('close');
      return ;
    },
    switchPcodeType(index: number): void {
      if (this.value[index].pCodeType === 0) {
        this.value[index].pCodeType = 2;
        // this.value[index].pCodeId = 'Z' + Crypto.randomBytes(9).toString('hex').toUpperCase();
        this.value[index].pCodeId = Crypto.randomBytes(10).toString('hex').slice(0, 19).toUpperCase();
        this.value[index].userId = '';
        this.value[index].userName = '';
        this.value[index].user = '';
      } else {
        this.value[index].pCodeType = 0;
        this.value[index].pCodeId = '';
        this.value[index].userId = '';
        this.value[index].userName = '';
        this.value[index].user = '';
      }
    },
    changeTargetPCode(index: number, pCodeId: string): void {
      this.value[index].pCodeId = pCodeId;
      const targetPcode = this.pCodes.find((u) => u.pCodeId === pCodeId);
      if (targetPcode && targetPcode.user) {
        this.value[index].userId = targetPcode.user.userId;
        this.value[index].userName = targetPcode.user.name;
        this.value[index].user = targetPcode.user.name === '' ? targetPcode.user.userId : `${targetPcode.user.name}(${targetPcode.user.userId})`;
      } else if (targetPcode) {
        const targetUser = this.users.find((u) => u.userId === targetPcode.userId);
        if (targetUser) {
          this.value[index].userId = targetUser.userId;
          this.value[index].userName = targetUser.name;
          this.value[index].user = targetUser.name === '' ? targetUser.userId : `${targetUser.name}(${targetUser.userId})`;
        }
      }
    },
    changeTargetUser(index: number, userId: string): void {
      this.value[index].userId = userId;
      const targetUser = this.users.find((u) => u.userId === userId);
      if (targetUser) {
        this.value[index].userName = targetUser.name;
        this.value[index].user = targetUser.name === '' ?
          targetUser.userId : `${targetUser.name}(${targetUser.userId})`;
      }
    },
    isRequired(value: string): string | boolean {
      if (value && value.length) {
        return true;
      } else {
        return '必須項目のため、入力が必須です。';
      }
    },
    clickAddPostFaceImage(): void {
      if (this.value.length > 0) {
        const validate = (this.$refs.formFaceImage as Vue & { validate: () => boolean }).validate();
        if (validate) {
          this.$emit('edit-face-image', this.value);
        }
      }
    },
    clickAddImage(): void {
      if (this.imageData && this.imageData.length > 0) {
        let importData: ImportFileData[] = [];
        this.importExcelData().then((res) => importData = res)
        .then(() => {
          let retImageFiles: FaceImageFile[] = [];
          if (importData.length === 0) {
            retImageFiles = this.imageData.map((item) => {
              return {
                imageFile: item,
                faceImageId: '',
                // pCodeId: 'Z' + Crypto.randomBytes(9).toString('hex').toUpperCase(),
                pCodeId: Crypto.randomBytes(10).toString('hex').slice(0, 19).toUpperCase(),
                pCodeType: 2,
                userId: '',
                userName: '',
                user: '',
                options: '',
                noDataFlag: false,
              };
            });
          } else {
            importData.forEach((d) => {
              const imageFile = this.imageData.find((item) => item.name === d.fileName);
              if (imageFile) {
                retImageFiles.push({
                  imageFile,
                  faceImageId: '',
                  pCodeId: d.pCodeId,
                  pCodeType: d.pCodeType ? d.pCodeType : 0,
                  userId: d.userId,
                  userName: d.userName,
                  user: d.user,
                  options: '',
                  noDataFlag: d.noDataFlag,
                });
              }
            });
          }
          this.$emit('input', retImageFiles);
        });
      }
    },
    importExcelData(): Promise<ImportFileData[]> {
      return new Promise((resolve) => {
        const importFileData: ImportFileData[] = [];
        if (this.importFile !== null) {
          const reader = new FileReader();
          reader.onload = (): void => {
            const read =  reader.result as string;
            const XLSX = require('xlsx');
            const workbook = XLSX.read(read, {type: 'array'});
            const sheet1name = workbook.SheetNames[0];
            const faceImageData = workbook.Sheets[sheet1name];
            const faceImageSheetArray = XLSX.utils.sheet_to_json(faceImageData, {header: 1});
            for (const [index, rows] of faceImageSheetArray.entries()) {
              let fileName;
              let pCodeType;
              let pCodeId;
              let userId;
              let userName;
              let user;
              let noDataFlag = false;
              if (rows.length > 2 && rows[0] !== undefined && rows[1] !== undefined) {
                fileName = rows[0];
                if (rows[1] === 0 || rows[1] === 2) {
                  pCodeType = rows[1];
                }
                if (rows[1] === 0 && rows[2] !== undefined) {
                  const targetPcode = this.pCodes.find((p) => p.pCodeId === rows[2]);
                  if (targetPcode && targetPcode.user) {
                    // 個人コードが存在し、入力値と実際の個人コード種別が一致したものが有効
                    if (targetPcode.type === 0) {
                      pCodeId = rows[2];
                      userId = targetPcode.user.userId;
                      userName = targetPcode.user.name;
                      user = targetPcode.user.name === '' ? targetPcode.user.userId : `${targetPcode.user.name}(${targetPcode.user.userId})`;
                    }
                  } else {
                    noDataFlag = true;
                    pCodeId = rows[2];
                    userId = '-';
                    userName = '-';
                    user = '-';
                  }
                } else if (rows[1] === 2) {
                  // pCodeId = 'Z' + Crypto.randomBytes(9).toString('hex').toUpperCase();
                  pCodeId = Crypto.randomBytes(10).toString('hex').slice(0, 19).toUpperCase();
                  if (rows[3]) {
                    const targetUser = this.users.find((u) => u.userId === rows[3]);
                    userId = '';
                    userName = '';
                    user = '';
                    if (targetUser) {
                      userId = targetUser.userId;
                      userName = targetUser.name;
                      user = targetUser.name === '' ? targetUser.userId : `${targetUser.name}(${targetUser.userId})`;
                    } else {
                      noDataFlag = true;
                      userId = rows[3];
                      userName = '-';
                      user = userId;
                    }
                  }
                }
                if (fileName && (pCodeType === 0 || pCodeType === 2) && pCodeId && userId && userName && user) {
                  importFileData.push({
                    fileName,
                    pCodeType,
                    pCodeId,
                    userId,
                    userName,
                    user,
                    noDataFlag,
                  });
                }
              }
            }
            resolve(importFileData);
          };
          reader.readAsArrayBuffer(this.importFile);
        } else {
          resolve(importFileData);
        }
      });
    },
  },
});
