import { Excel } from '.';
import * as Config from '@/config';
import { RoomLog, Room, RoomRoomLog } from '@/types/alligate';

export class InRoomSummaryByRoomExcel extends Excel {
  constructor() {
    super();
    const date = new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10).split('-').join('');
    const time = new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(11, 19).split(':').join('');
    this.fileName = `in-room-summary_by-room_${date}${time}.xlsx`;
    this.url = Config.DOWNLOAD_IN_ROOM_SUMMARY_BY_ROOM_TEMPLATE_API;
  }

  /**
   * データを入力する
   * @param {string} targetDate 出力対象の日付
   * @param {Room} room 出力対象の部屋
   * @param {RoomRoomLog[]} roomRoomLogs Excelに入力するログデータ
   */
  public async inputData(targetDate: string, room: Room, roomRoomLogs: RoomRoomLog[]) {
    // シートを取得
    const sheet = this.workbook.sheet(0);
    // 日付を記入
    const [year, month, day] = targetDate.split('-');
    const now = new Date(Date.now() + 9 * 60 * 60 * 1000);
    const [nowYear, nowMonth, nowDay] = now.toISOString().slice(0, 10).split('-');
    const [nowHour, nowMinute] = now.toISOString().slice(11, 16).split(':');
    sheet.cell('A2').value(`${year}年${month}月${day}日 (${nowYear}年${nowMonth}月${nowDay}日${nowHour}時${nowMinute}分出力)`);

    // 部屋情報を記入
    sheet.cell('C4').value(room.name);
    sheet.cell('A7').value(roomRoomLogs.length);

    // スタイルを取得
    const fontFamily = sheet.cell('B11').style('fontFamily');
    const fontSize = sheet.cell('B11').style('fontSize');
    const style = {
      border: 'thin',
      horizontalAlignment: 'center',
      fontFamily,
      fontSize,
    };

    // ユーザ情報を記入
    let logs: Array<{ userId: string, userName: string, roomLog: RoomLog }> = [];
    roomRoomLogs.forEach((user, index) => {
      const row = index + 11;
      // セルを結合し、スタイルを設定
      sheet.cell(`Y${row}`)
        .style(style);
      sheet.range(`Z${row}:AD${row}`)
        .merged(true)
        .style(style);
      sheet.range(`AE${row}:AH${row}`)
        .merged(true)
        .style(style);
      sheet.range(`AI${row}:AK${row}`)
        .merged(true)
        .style(style);
      // 値を代入
      sheet.cell(`Y${row}`).value(index + 1);
      sheet.cell(`Z${row}`).value(user.userId);
      sheet.cell(`AE${row}`).value(user.userName);
      logs = logs.concat(user.roomLogs.map((roomLog) => ({
        userId: user.userId,
        userName: user.userName,
        roomLog,
      })));

      // 計算式を代入
      sheet.cell(`AI${row}`)
        .formula(`SUMIF(B11:B65524,Z${row},Q11:Q65524)`)
        .style('numberFormat', 'h:mm');
    });

    // ログ情報を並び替え
    logs.sort((first, second) => {
      const f = first.roomLog.entry
        ? first.roomLog.entry.timestamp
        : first.roomLog.exit ? first.roomLog.exit.timestamp : 0;
      const s = second.roomLog.entry
        ? second.roomLog.entry.timestamp
        : second.roomLog.exit ? second.roomLog.exit.timestamp : 0;
      return f < s ? - 1 : 1;
    });


    // ログ情報を記入
    logs.forEach((log, index) => {
      const row = index + 11;
      // セルを結合し、スタイルを設定
      sheet.cell(`A${row}`)
      .style(style);
      sheet.range(`B${row}:F${row}`)
        .merged(true)
        .style(style);
      sheet.range(`G${row}:J${row}`)
        .merged(true)
        .style(style);
      sheet.range(`K${row}:M${row}`)
        .merged(true)
        .style(style);
      sheet.range(`N${row}:P${row}`)
        .merged(true)
        .style(style);
      sheet.range(`Q${row}:S${row}`)
        .merged(true)
        .style(style);
      sheet.range(`T${row}:W${row}`)
        .merged(true)
        .style(style);

      // 値を代入
      sheet.cell(`A${row}`).value(index + 1);
      sheet.cell(`B${row}`).value(log.userId);
      sheet.cell(`G${row}`).value(log.userName);
      if (log.roomLog.entry) {
        const timestamp = log.roomLog.entry.timestamp * 1000;
        const time = new Date(timestamp + 9 * 60 * 60 * 1000).toISOString().slice(11, 16);
        sheet.cell(`K${row}`).value(time).style('numberFormat', 'h:mm');
      }
      if (log.roomLog.exit) {
        const timestamp = log.roomLog.exit.timestamp * 1000;
        const time = new Date(timestamp + 9 * 60 * 60 * 1000).toISOString().slice(11, 16);
        sheet.cell(`N${row}`).value(time).style('numberFormat', 'h:mm');
      }

      // 片方しか値がない場合の計算式
      let orFormula = `"-"`;
      if (index === 0) {
        orFormula = `IF(N${row}<>"", N${row} - "0:00", "-")`;
      } else if (index === logs.length - 1) {
        if (now.toISOString().slice(0, 10) === targetDate) {
          orFormula = `IF(K${row}<>"", "${nowHour}:${nowMinute}" - K${row}, "-")`;
        } else {
          orFormula = `IF(K${row}<>"","24:00" - K${row}, "-")`;
        }
      }
      // 計算式を代入
      sheet.cell(`Q${row}`)
          .formula(`IF(AND(K${row}<>"",N${row}<>""),N${row}-K${row},IF(OR(K${row}<>"",N${row}<>""),${orFormula},""))`)
          .style('numberFormat', 'h:mm');
    });
  }
}
