














































































































































































import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import GroupListTable from '@/components/organisms/GroupListTable/GroupListTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';

export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedGroupIds: string[];
  actionItems: ActionItem[];
}
interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GroupListTemplate',
  components: {
    Searchbox,
    SuccessButton,
    GroupListTable,
    Breadcrumbs,
    ActionButton,
  },
  props: {
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    dataGroups: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageProcess: {
      default: [],
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    typeAction: {
      type: Number,
      default: 0,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },

    groupExpanedGate: {
      default: [],
    },
    isReRenderGate: {
      default: 0,
    },

    groupExpanedUser: {
      default: [],
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveGateSelected: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
    isRemoveGroupSelected: {
      default: 0,
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: 'グループ名', value: 'name', isDisabled: true },
    ],
    selectedGroupIds: [],
    actionItems: [
      { title: '有効化する', emitName: 'click-enable' },
      { title: '無効化する', emitName: 'click-disable' },
      { title: '関連付けを解除して無効化', emitName: 'click-delete' },
    ],
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'グループ', disabled: true },
        ];
      },
    },
  },
});
