


































import Vue, { PropType } from 'vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import CardLink from '@/components/molecules/CardLink/CardLink.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
import RelationsTbEyeList, { Resulted } from '@/components/organisms/RelationsTbEyeList/RelationsTbEyeList.vue';
import { TbEyeRelationGateDevice, Gate } from '@/types/alligate';
import { TbeyeLoginInfo } from '@/types/tbeye';

export default Vue.extend({
  name: 'TbEyeTemplate',
  components: {
    InputForm,
    TextLink,
    SuccessButton,
    CancelButton,
    CardLink,
    IconButton,
    RelationsTbEyeList,
  },
  props: {
    tbeyeRelationGateDevices: {
      type: Array as PropType<TbEyeRelationGateDevice[]>,
      default: () => [],
    },
    addNewRelationGateDevices: {
      type: Array as PropType<TbEyeRelationGateDevice[]>,
      default: () => [],
    },
    gateFullList: {
      type: Array as PropType<Gate[]>,
      default: () => [],
    },
    tbeyeLoginInfos: {
      type: Array as PropType<TbeyeLoginInfo[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    putDelLoading: {
      type: Array as PropType<boolean[]>,
      default: () => [],
    },
    postLoading: {
      type: Array as PropType<boolean[]>,
      default: () => [],
    },
    results: {
      type: Array as PropType<Resulted[]>,
      default: () => [],
    },
  },
  computed: {
    configurableGates(): SelectItem[] {
      if (this.gateFullList.length > 0) {
        return this.gateFullList
        // 選択できないようにスマホ用ゲートの除外。
        .filter((gate, index, gates) => {
          return !(gate.gateName.match(/　$/)
          && gates.filter((gate2) => gate.gateName.slice(0, -1) === gate2.gateName).length === 1);
        })
        .map((gate) => {
          return {
            text: `${gate.gateName}(${gate.gateId})`,
            value: gate.gateId,
          };
        });
      } else {
        return [{ text: 'ロード中', value: 'select1' }];
      }
    },
  },
  methods: {
  },
});
