import Vue from 'vue';
import * as Config from '@/config';
/**
 * 顔画像を取得する
 * @param {Vue} vue Vueインスタンス
 * @param {string} faceImageId 顔画像ID
 * @return {Promise<String>} 顔画像情報Base64
 */
export async function getFaceImage(vue: Vue, faceImageId: string): Promise<string> {
  try {
    const response = await vue.$axios.get(`${Config.FACE_IMAGE_API}/${faceImageId}/image`);
    return response.data.image;
  } catch (err) {
    switch ((err as any).response.status) {
      case 400:
        throw(new Error('顔画像の取得に失敗しました。'));
      case 403:
        throw(new Error('顔画像の取得に失敗しました。権限がありません。'));
      case 404:
        throw(new Error('顔画像の取得に失敗しました。存在しないか既に削除されています。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 顔画像の一時保存領域(S3)へのアップロードを行う。
 * @param {Vue} vue Vueインスタンス
 * @param {File} file 登録する顔画像
 * @returns {Promise<String>} POST /face-image/temp のレスポンス.一時保存ID
 */
export async function postFaceImageFile(vue: Vue, file: File): Promise<string> {
  try {
    const config = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const response = await vue.$axios.post(`${Config.FACE_IMAGE_API}/image/temp`, file, config);
    return response.data.imageFileId;
  } catch (err) {
    throw(new Error(`顔画像の一時アップロードに失敗しました。実際のファイル種別が異なる可能性があります。または、ファイルサイズが10MBを超えている可能性があります`));
  }
}
