











































































































import Vue, { PropType } from 'vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import ErrorButton from '@/components/molecules/ErrorButton/ErrorButton.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
import Alert, { AlertType } from '@/components/molecules/Alert/Alert.vue';
import TbEye from '@/components/molecules/TbEyeVideo/TbEyeVideo.vue';
import { TbEyeRelationGateDevice } from '@/types/alligate';
import { TbeyeLoginInfo } from '@/types/tbeye';

interface DataType {
  beforeNotes: string;
  beforeGateId: string;
  beforeDeviceType: string;
  beforeChannelNo: number;
  beforeHostName: string;
  beforePort: number;
  showPreviewTbEye: boolean;
  isRerender: boolean;
}


export default Vue.extend({
  name: 'RelationTbEyeTarget',
  components: {
    Modal,
    Loading,
    InputForm,
    SuccessButton,
    CancelButton,
    ErrorButton,
    IconButton,
    Alert,
    TbEye,
  },
  data: (): DataType => {
    return {
      beforeDeviceType: '',
      beforeGateId: '',
      beforeNotes: '',
      beforeChannelNo: 0,
      beforeHostName: '',
      beforePort: 0,
      showPreviewTbEye: false,
      isRerender: false,
    };
  },
  props: {
    index: {
      type: Number,
      default: () => 0,
    },
    value: {
      type: Object as PropType<TbEyeRelationGateDevice>,
      default: () => {
        return {
          relationId: '',
          gateId: '',
          hostName: '',
          port: 443,
          deviceType: 'nvr',
          channelNo: 1,
          notes: '',
        };
      },
    },
    loginInfo: {
      type: Object as PropType<TbeyeLoginInfo>,
      default: () => {
        return {
          relationdId: '',
          username: '',
          password: '',
        };
      },
    },
    configurableGates: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  created(): void {
    // 変更前かどうかチェックするためにマウント時に値の保存
    this.beforeGateId = JSON.parse(JSON.stringify(this.value.gateId));
    this.beforeDeviceType = JSON.parse(JSON.stringify(this.value.deviceType));
    this.beforeNotes = JSON.parse(JSON.stringify(this.value.notes));
    this.beforeHostName = JSON.parse(JSON.stringify(this.value.hostName));
    this.beforePort = Number(this.value.port);
    this.beforeChannelNo = Number(this.value.channelNo);
    // this.showPreviewTbEye = false;
  },
  computed: {
    isRelationIdLengthZero(): boolean {
      return this.value.relationId.length === 0;
    },
    appendIcon(): string {
      return this.isLengthOneMore(this.value.hostName) ? 'mdi-video' : '';
    },
    gateId: {
      get(): string {
        return this.value.gateId;
      },
      set(val): void {
        this.$emit('input', { ...this.value, gateId: val });
      },
    },
    notes: {
      get(): string {
        return this.value.notes;
      },
      set(val): void {
        this.$emit('input', { ...this.value, notes: val });
      },
    },
    deviceType: {
      get(): string {
        return this.value.deviceType;
      },
      set(val) {
        this.$emit('input', { ...this.value, device: val });
      },
    },
    hostName: {
      get(): string {
        return this.value.hostName;
      },
      set(val): void {
        this.$emit('input', { ...this.value, hostName: val });
      },
    },
    channelNo: {
      get(): number {
        return Number(this.value.channelNo);
      },
      set(val): void {
        this.$emit('input', { ...this.value, channelNo: Number(val) });
      },
    },
    port: {
      get(): number {
        return Number(this.value.port);
      },
      set(val): void {
        this.$emit('input', { ...this.value, port: Number(val) });
      },
    },
    isModified(): boolean {
      return Boolean(
        this.beforeNotes !== this.value.notes
        || this.beforeGateId !== this.value.gateId
        || this.beforeDeviceType !== this.value.deviceType
        || this.beforeChannelNo !== this.value.channelNo
        || this.beforeHostName !== this.value.hostName
        || this.beforePort !== this.value.port,
      );
    },
  },
  watch: {
    showPreviewTbEye(newVal) {
      if (newVal === true) {
        setTimeout(() => {
          this.isRerender = true;
        }, 100);
      } else {
        this.isRerender = false;
      }
    },
  },
  methods: {
    isValidate(): boolean {
      return this.isLengthOneMore(this.value.deviceType) === true
        && this.value.channelNo > 0
        && this.value.port > 0
        && this.isLengthOneMore(this.value.hostName) === true
        && this.isGateIdChoice() === true;
    },
    isLengthOneMore(value: string): boolean {
      if (value && value.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isUUID(uuid: string): boolean {
      const reg: RegExp = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/);

      return Boolean(reg.exec(uuid));
    },
    isValidateLengthOneMore(value: string): string | boolean {
      if (this.isLengthOneMore(value)) {
        return true;
      } else {
        return '必須項目のため、一つ以上の指定が必須です。';
      }
    },
    isValidateOneMore(value: number): string | boolean {
      if (Number(value) > 0) {
        return true;
      } else {
        return '必須項目のため、一つ以上の指定が必須です。';
      }
    },
    // 現在指定している値が選択肢のどれか選択しているかどうか
    isGateIdChoice(): boolean {
      return this.configurableGates.map((item) => item.value).includes(this.value.gateId) === true;
    },
    postRecord(): void {
      if (this.isGateIdChoice() && this.isLengthOneMore(this.value.deviceType)) {
        this.$emit('post-record', this.value);
      }
    },
    putRecord(): void {
      if (this.isModified === true && this.isValidate()) {
        this.$emit('put-record', this.value);
        this.beforeDeviceType = this.value.deviceType;
        this.beforeGateId = this.value.gateId;
        this.beforeNotes = this.value.notes;
        this.beforePort = this.value.port;
        this.beforeChannelNo = this.value.channelNo;
        this.beforeHostName = this.value.hostName;
      }
    },
    delRecord(e: any): void {
      const selectedGate: SelectItem | undefined = this.configurableGates
        .find((gate) => gate.value === this.beforeGateId);
      if (selectedGate === undefined
        && window.confirm('追加せずに削除しますか？')
      ) {
        this.$emit('delete-record', this.value.relationId);
      } else if (selectedGate
        && window.confirm(`TbeyeDevice(${this.beforeDeviceType})と${selectedGate.text}の紐づけを解除しますか？`)
      ) {
        this.$emit('delete-record', this.value.relationId);
      }
    },
    closeTbEye(): void {
      this.showPreviewTbEye = false;
    },
    rerender(): void {
      this.isRerender = false;
      setTimeout(() => {
        this.isRerender = true;
      }, 100);
    },
    openToLink(url: string): void {
      window.open(url, '_blank');
    },
  },
});
