



































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import { Group } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import { config } from '@vue/test-utils';
import * as Config from '@/config';
const min: string[] = [];
for (let i = 0; i <= 59 ; i++) {
  let value = '';
  if (i < 10) {
    value = '0' + i;
  } else {
    value = i.toString();
  }
  min.push(value);
}
export interface TypeItem {
  title: string;
}
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface TimeSettings {
  dayTypeId: number;
  dayTypeName: string;
  allDay: number;
  startTime: string;
  endTime: string;
  pos?: number;
  action: string;
}
export interface DataType {
  groupValue: string;
  inputSpecialday: any;
  hours: any[];
  minutes: any[];
  timeS: string;
  hourStart: string;
  minuteStart: string;
  hourEnd: string;
  minuteEnd: string;
  hoursStartSpecial: string;
  hoursEndSpecial: string;
  errorAddListTime: string;
  errorGate: string;
  errorTime: string;
  allDay: boolean;
  arrayAddSpecialTime: any;
  arrayDelSpecialTime: any;
  rulesValidate: any;
  tmpUnlockingTime: any;
  Message: any;
}
export default Vue.extend({
  name: 'GateEditForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    unlockTimes: {
      type: Array as PropType<TypeDessert[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    gateDetail: {
      default: {},
    },
    groupList: {
      default: [],
    },
    dayTypes: {
      type: Array,
      default: [],
    },
    dataUnlockingTimeConvert: {
      type: Array as PropType<TimeSettings[]>,
      default: [],
    },
    dataUnlockingTime: {
      type: Array as PropType<TimeSettings[]>,
      default: [],
    },
    isFinishEditGate: {
      default: false,
    },
    groupsAddFail: {
      default: [],
    },
    loading: {
      default: false,
    },
    errorMessageTime: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    groupValue: '',
    inputSpecialday: [],
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: min,
    timeS: '00:00',
    hourStart: '',
    minuteStart: '',
    hourEnd: '',
    minuteEnd: '',
    hoursStartSpecial: '',
    hoursEndSpecial: '',
    errorAddListTime: '',
    errorGate: '',
    errorTime: '',
    allDay: false,
    arrayAddSpecialTime: [],
    arrayDelSpecialTime: [],
    tmpUnlockingTime: [],
    rulesValidate: {
      requiredName: (value: string) => !!value || 'ゲート名は64文字以内で入力してください',
      validateName: (name: string) => {
        return name.length <= 64 || 'ゲート名は64文字以内で入力してください。';
      },
      validateOption1: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考1は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateOption2: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考2は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
    },
    Message : Config,
  }),
  watch: {
    groupList(value) {
      this.groupValue = this.groupId;
    },
    dataUnlockingTimeConvert(value) {
      this.tmpUnlockingTime = this.dataUnlockingTimeConvert;
    },
    isFinishEditGate(value) {
      if (value) {
        this.arrayAddSpecialTime = [];
        this.arrayDelSpecialTime = [];
      }
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '曜日',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          {
            text: '期間指定',
            align: 'start',
            sortable: false,
            value: 'time',
          },
          {
            text: '',
            align: 'center',
            sortable: false,
            value: 'delete',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    filteredItems: {
      get(): any {
        return this.tmpUnlockingTime.filter((i: any) => {
          return (i.action !== 'del');
        });
      },
    },
  },
  methods: {
    clickUpdateGate() {
      const result = (this.$refs.GateAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('clickUpdateGate', {
          dataUnlockingTime: this.dataUnlockingTime,
          tmpUnlockingTime: this.tmpUnlockingTime,
          gateDetail: this.gateDetail,
          groupId: this.groupValue,
        });
      }
    },
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    addSpecialTime() {
      let isExist: boolean = false;
      this.errorAddListTime = '';
      this.errorGate = '';
      this.errorTime = '';
      for (const item of this.dataUnlockingTimeConvert) {
        const check  =  this.dataUnlockingTimeConvert.filter((o1: { dayTypeId: any ; action: string }) => o1.dayTypeId
        === item.dayTypeId && o1.action !== 'del');
        if (item.dayTypeId === this.inputSpecialday.value && check.length >= 5) {
          this.errorAddListTime = '同一の曜日での設定が上限を達しております。追加する場合は、一度削除してから実施してください。';
          this.errorGate = '';
          this.errorTime = '';
          isExist = true;
          break ;
        }
      }
      const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
      const hoursStartError =  pattern.test(this.hoursStartSpecial);
      const hoursEndError =  pattern.test(this.hoursEndSpecial);
      if (!isExist && hoursStartError === true && hoursEndError === true &&
      this.hoursStartSpecial < this.hoursEndSpecial && this.inputSpecialday.length !== 0
      || (!isExist && this.allDay === true && this.inputSpecialday.length !== 0)) {
        const i = this.tmpUnlockingTime.findIndex((e: { dayTypeId: any; startTime: string; endTime: string;
        action: string; }) => e.dayTypeId === this.inputSpecialday.value && e.action === 'del');
        console.log('i', i);
        if (i >= 0) {
          this.tmpUnlockingTime[i].action = 'edit';
          this.tmpUnlockingTime[i].allDay = this.allDay === true ? 1 : 0;
          this.tmpUnlockingTime[i].startTime = this.allDay === true ? '00:00' : this.hoursStartSpecial;
          this.tmpUnlockingTime[i].endTime = this.allDay === true ? '00:00' : this.hoursEndSpecial;
        } else {
          const ii = this.tmpUnlockingTime.findLastIndex((e: { dayTypeId: any; startTime: string; endTime: string;
          action: string; }) => e.dayTypeId === this.inputSpecialday.value);
          this.tmpUnlockingTime.unshift(
          {
            gateId: '',
            dayTypeId: this.inputSpecialday.value,
            dayTypeName: this.inputSpecialday.text,
            allDay: this.allDay === true ? 1 : 0,
            startTime: this.allDay === true ? '00:00' : this.hoursStartSpecial,
            endTime: this.allDay === true ? '00:00' : this.hoursEndSpecial,
            pos: 0,
            action: 'add',
            index: ii > 0 ? this.tmpUnlockingTime[ii].index + 1 : this.tmpUnlockingTime.length,
          });
        }
        this.hoursStartSpecial = '';
        this.hoursEndSpecial = '';
        this.allDay = false;
        this.inputSpecialday = [];
        this.hourEnd = '';
        this.hourStart = '';
        this.minuteStart = '';
        this.minuteEnd = '';
      } else {
        if (this.inputSpecialday.length === 0 || this.inputSpecialday.value === '') {
          this.errorGate = '曜日を指定してください。';
          this.errorAddListTime = '';
        }
        if ((hoursStartError === true && hoursEndError === true) && this.hoursEndSpecial <= this.hoursStartSpecial &&
        this.errorAddListTime === '' && this.allDay === false) {
          this.errorTime = Config.ERROR_END_TIME;
          this.errorAddListTime = '';
        }
        if ((hoursStartError === false || hoursEndError === false) &&
        this.errorAddListTime === '' && this.allDay === false) {
          this.errorTime = '時間を指定してください。';
          this.errorAddListTime = '';
        }
      }
    },
    deleteSpecialTime(index: any) {
      console.log(index);
      // this.dataUnlockingTime.splice(parseInt(index), 1);
      const i = this.tmpUnlockingTime.map((e: { index: any; }) => e.index).indexOf(index);
      if (this.tmpUnlockingTime[parseInt(i)].action === 'add') {
        this.tmpUnlockingTime.splice(parseInt(i), 1);
      } else {
        this.tmpUnlockingTime[parseInt(i)].action = 'del';
      }
    },
  },
});
