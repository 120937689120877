
































































































import Vue from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';

export default Vue.extend({
  name: 'KeyTokenSetting',
  components: {
    InputForm,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    settingInfor: {
      type: Object,
      default: {},
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    listKeyTokenLifetime: [
      { text: '1時間', value: 60 },
      { text: '3時間', value: 180 },
      { text: '6時間', value: 360 },
      { text: '12時間', value: 720 },
      { text: '1日', value: 1440 },
      { text: '3日', value: 4320 },
      { text: '5日', value: 7200 },
      { text: '10日', value: 14400 },
      { text: '30日', value: 43200 },
    ],
    listKeyTokenRefreshRate: [
      { text: '30分', value: 30 },
      { text: '60分', value: 60 },
      { text: '90分', value: 90 },
      { text: '120分', value: 120 },
      { text: '150分', value: 150 },
      { text: '180分', value: 180 },
    ],
  }),
});
