var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "div",
        { staticClass: "allig-group-table" },
        [
          _c(
            "div",
            { staticClass: "round-action" },
            [
              _vm.isPage === "affiliation-add"
                ? _c("v-row")
                : _c(
                    "v-row",
                    { staticClass: "btn-div", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0 pb-0" },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "btn-div",
                              attrs: { justify: "end" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center mr-10" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "btn-add pt-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _vm.authority.isAdmin === true
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn-second",
                                              attrs: {
                                                outlined: "",
                                                disabled:
                                                  _vm.groupSelected.length === 0
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeGroup()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        チェックした通行権限グループから抜ける\n                    "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "btn-add pt-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _vm.authority.isAdmin === true
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn-primary",
                                              attrs: { outlined: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.loadShowGroup()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        通行権限グループを追加する\n                    "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "btn-div", attrs: { justify: "end" } },
                [
                  _c("v-col", { attrs: { md: "12", xl: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "area-search-box" },
                      [
                        _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": "",
                              justify: "space-between"
                            }
                          },
                          [
                            _c(
                              "v-col",
                              [
                                _c("searchbox", {
                                  staticClass: "input-search btn-user-search",
                                  attrs: {
                                    searchTargetItems: _vm.searchTargetItems,
                                    title: "通行権限グループ名を検索",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    search: function($event) {
                                      return _vm.$emit("search-group", $event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "mt-3 ",
                  attrs: {
                    "no-gutters": "",
                    justify: "space-between",
                    "align-content": "center"
                  }
                },
                [
                  _c("show-per-page", {
                    staticClass: "ml-3 ",
                    attrs: {
                      "per-page": _vm.perPage,
                      page: _vm.page,
                      "items-length": _vm.dataGroupsModal.total
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", { staticClass: "pt-0 pb-0 mt-0" })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "pa-3" },
            [
              _c("v-col", { attrs: { sm: "12" } }, [
                _c(
                  "div",
                  { staticClass: "round-data table-affiliation" },
                  [
                    _c("v-data-table", {
                      staticClass: "border-table table-no-bg",
                      attrs: {
                        "must-sort": "",
                        headers: _vm.headers,
                        items: _vm.dataGroupsModal.data,
                        "items-per-page": _vm.perPage,
                        "item-key": "groupId",
                        "no-data-text": "データーはありません",
                        loading: _vm.loading,
                        "loading-text": "データを読み込中です",
                        "hide-default-footer": "",
                        "custom-sort": _vm.customSort,
                        "show-expand": "",
                        "single-expand": "",
                        "show-select": true
                      },
                      on: {
                        "item-expanded": function($event) {
                          return _vm.$emit("expand-gate-group", $event)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.name",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm.authority.isAdmin === true ||
                                _vm.authority.isAdminOfGroupPassages === true
                                  ? _c("text-link", {
                                      staticClass: "item-user-name",
                                      attrs: { text: item.group.name },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickGroupDetail(
                                            item.group.groupId
                                          )
                                        }
                                      }
                                    })
                                  : _c(
                                      "span",
                                      { staticClass: "item-user-name" },
                                      [_vm._v(_vm._s(item.group.name))]
                                    )
                              ]
                            }
                          },
                          {
                            key: "item.passableTimeSettingName",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              item.group.passableTimeSetting !==
                                                undefined &&
                                              item.group.passableTimeSetting
                                                .name !== undefined
                                                ? _c(
                                                    "div",
                                                    _vm._g(
                                                      _vm._b(
                                                        {},
                                                        "div",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n          " +
                                                          _vm._s(
                                                            item.group
                                                              .passableTimeSetting
                                                              .name
                                                          ) +
                                                          "\n          "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _vm.checkPassableTimeSetting(item.group)
                                      ? _c(
                                          "span",
                                          _vm._l(
                                            item.group.passableTimeSetting
                                              .timeSettings,
                                            function(row, index) {
                                              return _c("div", { key: index }, [
                                                _vm._v(
                                                  _vm._s(row.dayTypeName) +
                                                    "　" +
                                                    _vm._s(row.startTime) +
                                                    "〜" +
                                                    _vm._s(row.endTime)
                                                )
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "item.data-table-expand",
                            fn: function(ref) {
                              var expand = ref.expand
                              var item = ref.item
                              var isExpanded = ref.isExpanded
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "white", elevation: "0" },
                                    on: {
                                      click: function($event) {
                                        return expand(!isExpanded)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mr-5 item-count" },
                                      [
                                        _c("v-icon", [_vm._v("mdi-door")]),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.group.gateCount) +
                                            "\n                "
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "transition",
                                  { attrs: { name: "expand-icon" } },
                                  [
                                    isExpanded
                                      ? _c("v-icon", [
                                          _vm._v("mdi-chevron-down")
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item.icons",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$emit(
                                                            "clickEditGroup",
                                                            item.groupId
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-square-edit-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [_vm._v("通行権限グループ編集")])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$emit(
                                                            "clickEditGroup",
                                                            item.groupId
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-trash-can-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [_vm._v("通行権限グループ編集")])
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "expanded-item",
                            fn: function(ref) {
                              var headers = ref.headers
                              var item = ref.item
                              return [
                                _c(
                                  "td",
                                  { attrs: { colspan: headers.length } },
                                  [
                                    _c("GateGroupListTable", {
                                      attrs: {
                                        dataGates: _vm.gateGroup.data,
                                        loading: _vm.gateGroup.loading,
                                        gateTotalCount: _vm.gateGroup.total,
                                        page: _vm.gateGroup.page
                                      },
                                      on: {
                                        "change-page": function($event) {
                                          return _vm.$emit(
                                            "change-page-gate-group",
                                            {
                                              groupId: item.groupId,
                                              page: $event
                                            }
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.groupSelected,
                        callback: function($$v) {
                          _vm.groupSelected = $$v
                        },
                        expression: "groupSelected"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-between" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-col", { staticClass: "mr-5 mb-2" }),
              _vm._v(" "),
              _c("pagination", {
                staticClass: "mb-1",
                attrs: {
                  "page-length": Math.ceil(
                    _vm.dataGroupsModal.total / _vm.perPage
                  ),
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.innerPage,
                  callback: function($$v) {
                    _vm.innerPage = $$v
                  },
                  expression: "innerPage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "通行権限グループを追加",
          "no-footer": "",
          smallScreen: "800px",
          className: "common-padding"
        },
        on: {
          close: function($event) {
            _vm.isShowGroup = false
            _vm.closeModal(_vm.dataRelation.successItem > 0 ? true : false)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _vm.isShowGroup
                  ? _c("GroupListTable", {
                      attrs: {
                        dataList: _vm.groups.data,
                        perPage: _vm.groups.perPage,
                        page: _vm.groups.page,
                        totalCount: _vm.groups.total,
                        loading: _vm.groups.loading,
                        dataAdded:
                          _vm.isPage === "affiliation-add-new"
                            ? _vm.dataGroupsModal.data
                            : [],
                        isPage:
                          _vm.isPage === "affiliation-add-new"
                            ? _vm.isPage
                            : "",
                        relationsDepartmentGroup: _vm.dataGroupsModal.data
                      },
                      on: {
                        getReload: function($event) {
                          return _vm.getReload($event)
                        },
                        "change-page": function($event) {
                          return _vm.$emit("change-page-group-all", $event)
                        },
                        "change-per-page": function($event) {
                          return _vm.$emit("change-per-page-group-all", $event)
                        },
                        "add-group": function($event) {
                          return _vm.addGroup($event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isShowGroup,
          callback: function($$v) {
            _vm.isShowGroup = $$v
          },
          expression: "isShowGroup"
        }
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("設定されている通行権限グループ")
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.dataRelation.total >= 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          staticStyle: { "pointer-events": "none" },
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.dataRelation.progressLoad,
                            callback: function($$v) {
                              _vm.$set(_vm.dataRelation, "progressLoad", $$v)
                            },
                            expression: "dataRelation.progressLoad"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                Math.round(_vm.dataRelation.progressLoad)
                              ) + "%"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.dataRelation.successItem > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.dataRelation.total) +
                                "件中" +
                                _vm._s(_vm.dataRelation.successItem) +
                                " "
                            ),
                            _vm.type === "add"
                              ? _c("span", [_vm._v("件を追加しました。")])
                              : _c("span", [_vm._v("件を削除しました。")])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataRelation.errorMessage.length > 0
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(_vm.dataRelation.errorMessage, function(
                                  item
                                ) {
                                  return [
                                    _c("p", {
                                      key: item,
                                      domProps: { innerHTML: _vm._s(item) }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dataRelation.isFinish
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c("success-button", {
                            attrs: { text: "OK" },
                            on: {
                              click: function($event) {
                                return _vm.closeModalGroup(
                                  _vm.dataRelation.successItem > 0
                                    ? true
                                    : false
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        attrs: {
          title: "チェックした通行権限グループから抜ける",
          smallScreen: "600",
          text: "チェックした通行権限グループから抜けますか ?",
          className: "common-modal"
        },
        on: {
          close: function($event) {
            _vm.showConfirm = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c("success-button", {
                  attrs: { text: "削除" },
                  on: {
                    click: function($event) {
                      return _vm.yesChoice()
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.showConfirm,
          callback: function($$v) {
            _vm.showConfirm = $$v
          },
          expression: "showConfirm"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }