









































































































import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import GateUserListTable from '@/components/organisms/GateUserListTable/GateUserListTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import * as Config from '@/config';

export interface DataType {
  selectedUserIds: string[];
}
interface Type {
  userId: string;
  userName: string;
  gateId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
}
interface ArrayDefault {
  text: string;
  value: string;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'UserListTemplate',
  components: {
    SuccessButton,
    GateUserListTable,
    Breadcrumbs,
  },
  props: {
    userTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    errorMessageProcess: {
      default: [],
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataUsers: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    userOfGate: {
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ユーザー', disabled: true },
        ];
      },
    },
  },
  methods: {
    clickBackGate() {
      this.$router.push({ path: Config.USER_LIST});
    },
  },
  data: (): DataType => ({
    selectedUserIds: [],
  }),
});
