import { Excel } from '.';
import * as Config from '@/config';
import { RoomLog, User, UserRoomLog } from '@/types/alligate';

export class InRoomSummaryByUserExcel extends Excel {
  constructor() {
    super();
    const date = new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10).split('-').join('');
    const time = new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(11, 19).split(':').join('');
    this.fileName = `in-room-summary_by-user_${date}${time}.xlsx`;
    this.url = Config.DOWNLOAD_IN_ROOM_SUMMARY_BY_USER_TEMPLATE_API;
  }

  /**
   * データを入力する
   * @param {string} targetDate 出力対象の日付
   * @param {User} user 出力対象のユーザ
   * @param {UserRoomLog[]} userRoomLogs Excelに入力するログデータ
   */
  public async inputData(targetDate: string, user: User, userRoomLogs: UserRoomLog[]) {
    // シートを取得
    const sheet = this.workbook.sheet(0);
    // 日付を記入
    const [year, month, day] = targetDate.split('-');
    const now = new Date(Date.now() + 9 * 60 * 60 * 1000);
    const [nowYear, nowMonth, nowDay] = now.toISOString().slice(0, 10).split('-');
    const [nowHour, nowMinute] = now.toISOString().slice(11, 16).split(':');
    sheet.cell('A2').value(`${year}年${month}月${day}日 (${nowYear}年${nowMonth}月${nowDay}日${nowHour}時${nowMinute}分出力)`);

    // ユーザ情報を記入
    sheet.cell('C4').value(user.userId);
    sheet.cell('L4').value(user.name);

    // スタイルを取得
    const fontFamily = sheet.cell('B11').style('fontFamily');
    const fontSize = sheet.cell('B11').style('fontSize');
    const style = {
      border: 'thin',
      horizontalAlignment: 'center',
      fontFamily,
      fontSize,
    };

    // 部屋情報を記入
    let logs: Array<{ roomName: string, roomLog: RoomLog }> = [];
    userRoomLogs.forEach((room, index) => {
      const row = index + 11;
      // セルを結合し、スタイルを設定
      sheet.cell(`Y${row}`)
        .style(style);
      sheet.range(`Z${row}:AD${row}`)
        .merged(true)
        .style(style);
      sheet.range(`AE${row}:AG${row}`)
        .merged(true)
        .style(style);

      // 値を代入
      sheet.cell(`Y${row}`).value(index + 1);
      sheet.cell(`Z${row}`).value(room.roomName);
      logs = logs.concat(room.roomLogs.map((roomLog) => ({
        roomName: room.roomName,
        roomLog,
      })));

      // 計算式を代入
      sheet.cell(`AE${row}`)
        .formula(`IF(Z${row}<>"",SUMIF(B${row}:B65515, Z${row}, M${row}:M65515),"")`)
        .style('numberFormat', 'h:mm');
    });

    // ログ情報を並び替え
    logs.sort((first, second) => {
      const f: number = first.roomLog.entry
        ? first.roomLog.entry.timestamp
        : first.roomLog.exit ? first.roomLog.exit.timestamp : 0;
      const s: number = second.roomLog.entry
        ? second.roomLog.entry.timestamp
        : second.roomLog.exit ? second.roomLog.exit.timestamp : 0;
      return f < s ? - 1 : 1;
    });

    // ログ情報を記入
    logs.forEach((log, index) => {
      const row = index + 11;
      // セルを結合し、スタイルを設定
      sheet.cell(`A${row}`)
        .style(style);
      sheet.range(`B${row}:F${row}`)
        .merged(true)
        .style(style);
      sheet.range(`G${row}:I${row}`)
        .merged(true)
        .style(style);
      sheet.range(`J${row}:L${row}`)
        .merged(true)
        .style(style);
      sheet.range(`M${row}:O${row}`)
        .merged(true)
        .style(style);
      sheet.range(`P${row}:R${row}`)
        .merged(true)
        .style(style);
      sheet.range(`S${row}:W${row}`)
        .merged(true)
        .style(style);

      // 値を代入
      sheet.cell(`A${row}`).value(index + 1);
      sheet.cell(`B${row}`).value(log.roomName);
      if (log.roomLog.entry) {
        const timestamp = log.roomLog.entry.timestamp * 1000;
        const time = new Date(timestamp + 9 * 60 * 60 * 1000).toISOString().slice(11, 16);
        sheet.cell(`G${row}`).value(time).style('numberFormat', 'h:mm');
      }
      if (log.roomLog.exit) {
        const timestamp = log.roomLog.exit.timestamp * 1000;
        const time = new Date(timestamp + 9 * 60 * 60 * 1000).toISOString().slice(11, 16);
        sheet.cell(`J${row}`).value(time).style('numberFormat', 'h:mm');
      }

      // 片方しか値がない場合の計算式
      let orFormula = `"-"`;
      if (index === 0) {
        orFormula = `IF(J${row}<>"", J${row} - "0:00", "-")`;
      } else if (index === logs.length - 1) {
        if (now.toISOString().slice(0, 10) === targetDate) {
          orFormula = `IF(G${row}<>"", "${nowHour}:${nowMinute}" - G${row}, "-")`;
        } else {
          orFormula = `IF(G${row}<>"","24:00" - G${row}, "-")`;
        }
      }
      // 計算式を代入
      sheet.cell(`M${row}`)
        .formula(`IF(AND(G${row}<>"",J${row}<>""), J${row}-G${row},IF(OR(G${row}<>"",J${row}<>""),${orFormula},""))`)
        .style('numberFormat', 'h:mm');
      if (index !== 0) {
        sheet.cell(`P${row}`)
          // tslint:disable-next-line:max-line-length
          .formula(`IF(AND(G${row}<>"",J${row - 1}<>""),G${row}-J${row - 1},IF(OR(J${row - 1}<>"",G${row}<>""),"-",""))`)
          .style('numberFormat', 'h:mm');
      }
    });
  }
}
