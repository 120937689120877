










































































































































































































import Vue, { PropType } from 'vue';
const min: string[] = [];
for (let i = 0; i <= 59 ; i++) {
  let value = '';
  if (i < 10) {
    value = '0' + i;
  } else {
    value = i.toString();
  }
  min.push(value);
}
export default Vue.extend({
  props: {
    dateStartValue: {
      default: '',
    },
    dateEndValue: {
      default: '',
    },
    timeSValue: {
      default: '',
    },
    timeEValue: {
      default: '',
    },
    errorAddListGate: {
      default: '',
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isUserPage: {
      type: Boolean,
      default: false,
    },
    isExportCSVWorkTimeType: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dateS: '',
    dateE: '',
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: ['00', '15', '30', '45'],
    timeS: '',
    dateStart: false,
    dateEnd: false,
    hourStart: '00',
    minuteStart: '00',
    timeE: '',
    timeEnd: false,
    hourEnd: '00',
    minuteEnd: '00',
    maxDateEnd: '',
    minDateEnd: '',
    menuTime: false,
    menuTimeE: false,
  }),
  mounted() {
    this.minutes = this.isUserPage ? ['00', '15', '30', '45'] : min;
    this.timeS = '';
    this.timeE =  '';
    this.dateS =  this.dateStartValue;
    this.dateE =  this.dateEndValue;
    this.timeS =  this.timeSValue;
    this.timeE =  this.timeEValue;
  },
  watch: {
    menuTime(val: any) {
      if (val === false) {
        this.timeS = this.formatTime(this.hourStart, this.minuteStart);
        this.$emit('getNewTimeStart', this.timeS);
      }
    },
    menuTimeE(val: any) {
      if (val === false) {
        this.timeE = this.formatTime(this.hourEnd, this.minuteEnd);
        this.$emit('getNewTimeEnd', this.timeE);
      }
    },
    dateStartValue(newValue: string) {
      this.dateS = newValue;
    },
    dateEndValue(newValue: string) {
      this.dateE = newValue;
    },
    timeSValue(newValue: string) {
      this.timeS = newValue;
    },
    timeEValue(newValue: string) {
      this.timeE = newValue;
    },
    dateS(newValue: string) {
      this.$emit('getNewDateStart', newValue);
      this.updateDateStartDateEnd();
    },
    minuteStart(newValue: string) {
      this.$emit('getNewTimeStart', this.formatTime(this.hourStart, newValue));
      this.timeS = this.formatTime(this.hourStart, newValue);
    },
    hourStart(newValue: string) {
      this.timeS = this.formatTime(newValue, this.minuteStart);
      this.$emit('getNewTimeStart', this.timeS);
    },
    dateE(newValue: string) {
      this.$emit('getNewDateEnd', newValue);
      this.updateDateStartDateEnd();
    },
    hourEnd(newValue: string) {
      this.timeE = this.formatTime(newValue, this.minuteEnd);
      this.$emit('getNewTimeEnd', this.timeE);
    },
    minuteEnd(newValue: string) {
      this.$emit('getNewTimeEnd', this.formatTime(this.hourEnd, newValue));
      this.timeE = this.formatTime(this.hourEnd, newValue);
    },
    isExportCSVWorkTimeType() {
      this.updateDateStartDateEnd();
    },
  },
  methods: {
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    updateDateStartDateEnd() {
      if (this.isExportCSVWorkTimeType) {
        const start = this.dateS.split('-');
        const dateStart = new Date(Number(start[0]), Number(start[1]) - 1, Number(start[2]));
        const end = this.dateE.split('-');
        const dateEnd = new Date(Number(end[0]), Number(end[1]) - 1, Number(end[2]));
        if (dateEnd < dateStart) {
          this.dateE = this.dateS;
        }
        this.minDateEnd = this.dateS;
        const maxDateEnd = this.addDays(dateStart, 31 - 1);
        this.maxDateEnd = this.formatDateYYYYMMDD(maxDateEnd);
        if (dateEnd > maxDateEnd) {
          this.dateE = this.maxDateEnd;
        }
      } else {
        this.minDateEnd = '';
        this.maxDateEnd = '';
      }
    },
    formatDateYYYYMMDD(date: any) {
      return date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2);
    },
    addDays(date: any, days: any) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
});
