var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { justify: "end" } },
            [
              _c(
                "v-col",
                { staticClass: "btn-add-group" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("clickBackGroup")
                        }
                      }
                    },
                    [_vm._v("\n        戻る\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.errorMessageLogs !== ""
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("group-detail-form", {
            attrs: {
              usersGroup: _vm.userList,
              gatesGroup: _vm.gateList,
              groupDetail: _vm.groupDetail,
              loading: _vm.loading
            },
            on: {
              clickEditGroup: function($event) {
                return _vm.$emit("clickEditGroup", $event)
              },
              "click-user": function($event) {
                return _vm.$emit("click-user", $event)
              },
              "click-gate": function($event) {
                return _vm.$emit("click-gate", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }