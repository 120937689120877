var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-key-token-setting" },
    [
      _c(
        "v-container",
        [
          _vm.successMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-success" },
                [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-form",
            { ref: "KeyTokenSettingForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "span",
                            { staticClass: "col-3 pl-0 pr-0 mt-1" },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "justify-start title-sub",
                                  staticStyle: { "padding-left": "12px" }
                                },
                                [
                                  _vm._v(
                                    "\n              スマートフォン自動承認機能\n                "
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-help-circle")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2569386827
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "自動承認が無効の場合、スマホアプリのログイン操作後、「承認依頼」という画面に移り"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                        Webの管理画面から「承認（ステータスの有効）」する必要があります。"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                        「承認」するまで鍵が表示されず、通行ができません。"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "col-6" },
                        [
                          _c("input-form", {
                            staticClass: "v-input--reverse swicth-all",
                            attrs: { inputType: "switch" },
                            model: {
                              value: _vm.settingInfor.phonesBeValid,
                              callback: function($$v) {
                                _vm.$set(_vm.settingInfor, "phonesBeValid", $$v)
                              },
                              expression: "settingInfor.phonesBeValid"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "messageRow",
                      attrs: { cols: "10", align: "right" }
                    },
                    [
                      _vm.successMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "mr-5 mb-4 font-weight-bold mt-3 bg-success bg-no messageRow",
                              attrs: { cols: "9", align: "right" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.successMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "mr-5 mb-4 font-weight-bold mt-3 bg-error bg-no messageRow",
                              attrs: { cols: "9", align: "right" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errorMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("savePhoneStatus")
                            }
                          }
                        },
                        [_vm._v("\n            設定を保存\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }