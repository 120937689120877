var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "zenescan-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _vm._l(_vm.errorMessages, function(message) {
        return _c("alert", {
          key: message,
          attrs: { value: message !== "", type: "error", text: message }
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content white rounded-lg pa-4" },
        [
          _c("tabs", {
            ref: "zenescan-tabs",
            attrs: { items: _vm.tabItems },
            on: {
              "click-tab": function($event) {
                return _vm.$emit("click-tab", $event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "zenescan-face-db",
                fn: function() {
                  return [
                    _c("zenescan-face-db-list", {
                      attrs: {
                        isLoadingZenescanFaceDb: _vm.zenescanFaceDbLoading,
                        faceDbs: _vm.faceDbs,
                        zenescanFaceDbs: _vm.zenescanFaceDbs,
                        showSelect: _vm.showSelectZenescanFaceDb,
                        errorMessages: _vm.errorMessages
                      },
                      on: {
                        "click-zenescan-face-db-access-and-save": function(
                          $event
                        ) {
                          return _vm.$emit(
                            "click-zenescan-face-db-access-and-save",
                            $event
                          )
                        },
                        "click-open-add-zenescan-face-db": function($event) {
                          return _vm.$emit(
                            "click-open-add-zenescan-face-db",
                            $event
                          )
                        },
                        "click-edit-zenescan-face-db": function($event) {
                          return _vm.$emit(
                            "click-edit-zenescan-face-db",
                            $event
                          )
                        },
                        "click-delete-zenescan-face-db": function($event) {
                          return _vm.$emit(
                            "click-delete-zenescan-face-db",
                            $event
                          )
                        },
                        "click-sync-zenescan-face-db": function($event) {
                          return _vm.$emit(
                            "click-sync-zenescan-face-db",
                            $event
                          )
                        }
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "zenescan-face-image",
                fn: function() {
                  return [
                    _c("zenescan-face-image-list", {
                      attrs: {
                        isLoadingZenescanFaceImage:
                          _vm.zenescanFaceImageLoading,
                        faceImages: _vm.faceImages,
                        showSelect: _vm.showSelectZenescanFaceImage,
                        typeItems: _vm.typeItems,
                        pCodeItems: _vm.pCodeItems,
                        userItems: _vm.userItems,
                        pCodes: _vm.pCodes,
                        users: _vm.users,
                        progressModalInfo: _vm.faceImageProgressModalInfo,
                        searchValue: _vm.innerFaceImageSearchValue,
                        isSelectedClear: _vm.isSelectedClearFaceImage,
                        faceImageTotalCount: _vm.faceImageTotalCount,
                        page: _vm.faceImagePage,
                        perPage: _vm.faceImagePerPage,
                        isDisabledPagination: _vm.isDisabledPaginationFaceImage
                      },
                      on: {
                        "reset-msg": function($event) {
                          return _vm.$emit("reset-msg", $event)
                        },
                        search: function($event) {
                          return _vm.$emit("search", $event)
                        },
                        "input-search-value": function($event) {
                          _vm.innerFaceImageSearchValue = $event
                        },
                        "select-pcode": function($event) {
                          return _vm.$emit("select-pcode", $event)
                        },
                        "select-face-image": function($event) {
                          return _vm.$emit("select-face-image", $event)
                        },
                        "show-dialog-confirm": function($event) {
                          return _vm.$emit("show-dialog-confirm", $event)
                        },
                        "confirm-action": function($event) {
                          return _vm.$emit("confirm-action", $event)
                        },
                        "click-delete-face-image": function($event) {
                          return _vm.$emit("click-delete-face-image", $event)
                        },
                        "reload-face-images": function($event) {
                          return _vm.$emit("reload-face-images", $event)
                        },
                        "click-download-template-excel": function($event) {
                          return _vm.$emit(
                            "click-download-template-excel",
                            $event
                          )
                        },
                        "start-add-face-image": function($event) {
                          return _vm.$emit("start-add-face-image", $event)
                        },
                        "add-face-image": function($event) {
                          return _vm.$emit("add-face-image", $event)
                        },
                        "start-edit-face-image": function($event) {
                          return _vm.$emit("start-edit-face-image", $event)
                        },
                        "end-no-match-face-image": function($event) {
                          return _vm.$emit("end-no-match-face-image", $event)
                        },
                        "edit-face-image": function($event) {
                          return _vm.$emit("edit-face-image", $event)
                        },
                        "progress-modal-close": function($event) {
                          return _vm.$emit("progress-modal-close", $event)
                        },
                        "update-items-per-page": function($event) {
                          return _vm.$emit("update-items-per-page", $event)
                        },
                        "selected-clear": function($event) {
                          return _vm.$emit("selected-clear-face-image", $event)
                        },
                        "update-page": function($event) {
                          return _vm.$emit("update-page", $event)
                        },
                        "click-open-edit-face-image": function($event) {
                          return _vm.$emit("click-open-edit-face-image", $event)
                        },
                        "change-page": function($event) {
                          return _vm.$emit("change-page-face-image", $event)
                        },
                        "change-per-page": function($event) {
                          return _vm.$emit("change-per-page-face-image", $event)
                        },
                        "click-open-face-image": function($event) {
                          return _vm.$emit("click-open-face-image", $event)
                        }
                      }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.innerTab,
              callback: function($$v) {
                _vm.innerTab = $$v
              },
              expression: "innerTab"
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }