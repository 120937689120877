












import Vue from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';

export default Vue.extend({
  name: 'NoticeList',
  components: {
    InputForm,
    TextLink,
    SuccessButton,
    CancelButton,
  },
  props: {
    linkList: {
      type: Array,
      default: () => [],
    },
  },
});
