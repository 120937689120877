var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-detail" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold"
                },
                [_vm._v("\n        グループ情報\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "pa-6 pr-10 mb-5" },
            [
              _c("div", { staticClass: "info-item" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col col-2" },
                    [_c("v-subheader", [_vm._v("グループ名")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-body-2 col align-self-center" },
                    [_vm._v(" " + _vm._s(_vm.groupDetail.name) + " ")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [_c("v-subheader", [_vm._v("ゲート")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.gatesGroup, function(gate) {
                          return _c(
                            "v-col",
                            {
                              key: gate.value,
                              attrs: { md: "3", sm: "6", cols: "12" }
                            },
                            [
                              _c("text-link", {
                                attrs: { text: gate.text },
                                on: {
                                  click: function($event) {
                                    return _vm.clickGate(
                                      gate.value,
                                      _vm.groupDetail.groupId
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [_c("v-subheader", [_vm._v("ユーザー")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.usersGroup, function(user) {
                          return _c(
                            "v-col",
                            {
                              key: user.value,
                              attrs: { md: "3", sm: "6", cols: "12" }
                            },
                            [
                              _c("text-link", {
                                attrs: { text: user.text },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("click-user", {
                                      userId: user.value,
                                      groupId: _vm.groupDetail.groupId
                                    })
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold"
                },
                [_vm._v("\n        通行可能時間設定\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-row", { staticClass: "ml-1 pt-0 mt-0" }, [
            _c(
              "div",
              { staticClass: "col col-12 pl-0 pt-0" },
              [
                _c("v-data-table", {
                  staticClass: "border-table shadow-table ",
                  attrs: {
                    "must-sort": "",
                    "items-per-page": -1,
                    headers: _vm.headerTimes,
                    items:
                      _vm.groupDetail.passableTimeSetting !== undefined &&
                      _vm.groupDetail.passableTimeSetting !== null
                        ? _vm.groupDetail.passableTimeSetting.timeSettings
                        : [],
                    "item-key": "dayTypeId",
                    "no-data-text": "該当するデータがありません",
                    "hide-default-footer": "",
                    "custom-sort": _vm.tableSort
                  },
                  on: {
                    "update:options": function($event) {
                      return _vm.$emit("change-sort", $event)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.name",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.groupDetail.passableTimeSetting.name
                                  ) +
                                  "\n          "
                              )
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.dayTypeName",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.dayTypeName) +
                                  "\n          "
                              )
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.expand-time",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.startTime) +
                                  " ~ " +
                                  _vm._s(item.endTime) +
                                  "\n          "
                              )
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-row", {
            staticStyle: { height: "20px" },
            attrs: { "no-gutters": "" }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mb-10", attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-primary",
                  attrs: {
                    outlined: "",
                    disabled:
                      _vm.groupDetail.groupId === null ||
                      _vm.groupDetail.groupId === undefined ||
                      _vm.groupDetail.groupId === ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "clickEditGroup",
                        _vm.groupDetail.groupId
                      )
                    }
                  }
                },
                [_vm._v("編集")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }