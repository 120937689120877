var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-table" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _vm.successMessageLogs !== ""
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-success"
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.successMessageLogs) + "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.errorMessageLogs !== ""
                ? _c(
                    "v-col",
                    { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.errorMessageLogs) + "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          attrs: {
            "align-content": "center",
            "no-gutters": "",
            justify: "space-between"
          }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c("show-per-page", {
            staticClass: "ml-4 mb-2 mr-2 mt-5",
            attrs: {
              "per-page": _vm.perPage,
              page: _vm.page,
              "items-length": _vm.groupTotalCount
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "border-table",
        attrs: {
          "must-sort": "",
          "custom-sort": _vm.customSort,
          headers: _vm.headers,
          items: _vm.getDataGroups,
          "items-per-page": _vm.perPage,
          "item-key": "groupId",
          "no-data-text": "データーはありません",
          "hide-default-footer": "",
          "show-select": true
        },
        on: {
          "update:options": function($event) {
            return _vm.$emit("change-sort", $event)
          },
          "item-expanded": function($event) {
            return _vm.$emit("change-expanded", $event)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.data-table-select",
              fn: function(ref) {
                var item = ref.item
                var isSelected = ref.isSelected
                var select = ref.select
                return [
                  item.disable
                    ? _c("v-simple-checkbox", {
                        attrs: {
                          value: true,
                          readonly: item.disable,
                          disabled: item.disable,
                          ripple: false
                        },
                        on: {
                          input: function($event) {
                            return select($event)
                          }
                        }
                      })
                    : _c("v-simple-checkbox", {
                        attrs: {
                          value: isSelected,
                          readonly: item.disabled,
                          disabled: item.disabled,
                          ripple: false
                        },
                        on: {
                          input: function($event) {
                            return select($event)
                          }
                        }
                      })
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedItem,
          callback: function($$v) {
            _vm.selectedItem = $$v
          },
          expression: "selectedItem"
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-3",
          attrs: { "no-gutters": "", justify: "space-between" }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mr-5 mb-2" },
            [
              _c("select-per-page", {
                attrs: {
                  page: _vm.page,
                  "per-page-items": [25, 50, 100, 1000],
                  "items-length": _vm.groupTotalCount,
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.innerPerPage,
                  callback: function($$v) {
                    _vm.innerPerPage = $$v
                  },
                  expression: "innerPerPage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            staticClass: "mb-1",
            attrs: {
              "page-length": Math.ceil(_vm.groupTotalCount / _vm.perPage),
              disabled: _vm.loading
            },
            model: {
              value: _vm.innerPage,
              callback: function($$v) {
                _vm.innerPage = $$v
              },
              expression: "innerPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }