




































































import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';

export interface SearchTargetItem {
  label: string;
  value: string;
}

export interface SearchValue {
  text: string;
  targets: string[];
}

export interface DataType {
  rand: string;
  showCustomSearch: boolean;
}

export default Vue.extend({
  name: 'Search',
  components: {
    SuccessButton,
  },
  props: {
    value: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: [],
      }),
    },
    searchTargetItems: {
      type: Array as PropType<SearchTargetItem[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '検索',
    },
    isVisibleButton: {
      type: Boolean,
      default: true,
    },
  },
  data: (): DataType => ({
    rand: Math.random().toString(36).slice(-8),
    showCustomSearch: false,
  }),
  computed: {
    innerValueText: {
      get(): string {
        return this.value.text;
      },
      set(val: string): void {
        this.$emit('input', {
          text: val,
          targets: this.value.targets,
        });
        return;
      },
    },
    innerValueTargets: {
      get(): string[] {
        return this.value.targets;
      },
      set(val: string[]): void {
        this.$emit('input', {
          text: this.value.text,
          targets: val,
        });
        return;
      },
    },
  },
  methods: {
    onClickOutsideCustomSearch() {
      if (this.showCustomSearch) {
        this.showCustomSearch = false;
      }
    },
    included() {
      return [document.querySelector(`.included-${this.rand}`)];
    },
    search(event: any) {
      if (!this.disabled && event.keyCode === 13) {
        this.$emit('search', this.value);
      }
    },
    clickSearch() {
      this.$emit('search', this.value);
      this.showCustomSearch = false;
    },
  },
});
