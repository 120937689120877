






























































import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import UserAddForm from '@/components/organisms/UserAddForm/UserAddForm.vue';
import * as Config from '@/config';
export interface DataType {
  Message: any;
}

export default Vue.extend({
  name: 'UserAddTemplate',
  components: {
    Breadcrumbs,
    UserAddForm,
    SuccessButton,
  },
  props: {
    departments: {
      type: Array,
      default: () => [],
    },
    groupsAddFail: {
      type: Array,
      default: () => [],
    },
    getDepartments: {
      type: Array,
      default: () => [],
    },
    gateList: {
      type: Array,
      default: () => [],
    },
    isModal: {
      type: Boolean,
      default: true,
    },
    isDisabledGateOption: {
      type: Boolean,
      default: true,
    },
    isGetDepartment: {
      type: Boolean,
      default: true,
    },
    isGetDepartmentAll: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    isDisabledPCodeOptions: {
      type: Boolean,
      default: true,
    },
    isDisabledGroupOptions: {
      type: Boolean,
      default: true,
    },
    gateGroupOptions: {
      type: Array,
      default: () => [],
    },
    phoneItems: {
      type: Array,
      default: () => [],
    },
    pCodeAddFail: {
      type: Array,
      default: () => [],
    },
    gateAddFail: {
      type: Array,
      default: () => [],
    },
    pCodeOptions: {
      type: Array,
      default: () => [],
    },
    listGatePassages: {
      default: () => [],
    },
    userData: {
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    authority: {
      type: Object,
      default: ({}),
    },
    dataFailed: {
      default: () => ({}),
    },
  },
  data: (): DataType => ({
    Message : Config,
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ユーザー', disabled: true },
          { text: this.isEdit ? 'ユーザー編集' : '新規ユーザー登録', disabled: true },
        ];
      },
    },
  },
  methods: {
  },
});
