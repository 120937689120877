
















import {Vue } from 'vue-property-decorator';
import CardAddTemplate from '@/components/templates/CardAddTemplate/CardAddTemplate.vue';
import * as Config from '@/config';
import { CardDetail } from '@/types/alligate';

export interface DataType {
  typeItems: any;
  userItems: any;
  loading: boolean;
  successMessageLogs: string;
  errorMessageLogs: string;
  cardDetail: CardDetail;
  isEdit: boolean;
  isResetData: boolean;
}
export default Vue.extend({
  name: 'CardAddPage',
  components: {
    CardAddTemplate,
  },
  data: (): DataType => ({
    typeItems: [
      { text: 'NFCカード', value: 0 },
      { text: 'テンキー', value: 1},
    ],
    userItems: [],
    loading: false,
    successMessageLogs: '',
    errorMessageLogs: '',
    isEdit : false,
    cardDetail: {
      pCodeId: '',
      userId: {},
      type: 0,
      name: '',
      isValid: true,
    },
    isResetData: false,
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    clickBackCard() {
      this.$router.push({ path: Config.CARD_LIST});
    },
    clickSaveCard(eventArgs: any) {
      this.registerData(eventArgs);
    },
    clickUpdateCard(eventArgs: any) {
      this.updateData(eventArgs);
    },

    async loadData() {
      const dataQuery = this.$route.query;
      if (dataQuery.pCodeId !== undefined) {
        this.isEdit = true;
        this.getCardData(dataQuery.pCodeId.toString());
      }
      this.getUsers(1);
    },

    getUsers(page: any) {
      this.$http.get(Config.USERS_LIST_API + '?limit=1000&page=' + page + '&sortKey=asc:userId&isValid=true&isExceptOptionalUser=true&exclude=thumbnail')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.users.forEach((element: any) => {
              this.userItems.push({text: element.name, value: element.userId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getUsers(page + 1);
            }
          } else {
            this.userItems = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        }); // catch
    },
    getCardData(pCodeId: string) {
      this.loading = true;
      this.$http.get(Config.CARD_LIST_API + '?pCodeId=' + encodeURIComponent(pCodeId))
        .then((response: any) => {
          this.loading = false;
          console.log('response: ', response);
          if (response.body.maxRecords > 0 && response.body.pCodes.length > 0) {
            this.cardDetail = response.body.pCodes[0];
            this.cardDetail.userId = {
              text : response.body.pCodes[0].userId,
              value : response.body.pCodes[0].userId,
            };
          } else {
            this.errorMessageLogs = Config.ERROR_DEFAULT;
            this.cardDetail = {
              pCodeId: '',
              userId: {},
              type: -1,
              name: '',
              isValid: false,
            };
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          console.log('err: ', err);
          this.cardDetail = {
              pCodeId: '',
              userId: {},
              type: -1,
              name: '',
              isValid: false,
            };
          switch (err.status) {
          case 400:
            this.errorMessageLogs = Config.ERROR_400;
            break;
          case 403:
            if (err.message === 'Unauthorized') {
              this.errorMessageLogs = Config.ERROR_403;
            } else {
              this.errorMessageLogs = Config.ERROR_403_DENIED;
            }
            break;
          case 404:
            this.errorMessageLogs = Config.ERROR_404;
            break;
          case 500:
            this.errorMessageLogs = Config.ERROR_500;
            break;
          case 503:
            this.errorMessageLogs = Config.ERROR_503;
            break;
          default:
            this.errorMessageLogs = Config.ERROR_DEFAULT;
            break;
        }
        }); // catch
    },
    registerData(eventArgs: any) {
        const dataInfor = eventArgs.data;
        if (dataInfor.userId !== undefined && dataInfor.userId !== null && dataInfor.userId !== '') {
          dataInfor.userId = dataInfor.userId.value;
        } else {
          dataInfor.userId = '';
        }
        console.log(dataInfor);
        this.resetData();
        this.loading = true;
        this.isResetData = false;
        this.$http.post(Config.CARD_LIST_API, dataInfor)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.loading = false;
              this.successMessageLogs = Config.ADD_PCODE_API_SUCCESS;
              this.cardDetail = {
                pCodeId: '',
                userId: {},
                type: 0,
                name: '',
                isValid: true,
              };
              this.isResetData = true;
            } else {
              this.loading = false;
              this.errorMessageLogs = Config.ERROR_DEFAULT;
            }
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = this.cardDetail.userId + '>>' + Config.ERROR_404;
                break;
              case 409:
                this.errorMessageLogs = '入力されたカード番号は既に使用されています。他のカード番号を入力してください。';
                break;
              case 420:
                this.errorMessageLogs = 'ゲートに紐づく個人カード番号が上限を超えるため、追加できない。';
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            this.loading = false;
            },
          ); // catch
    },
    updateData(eventArgs: any) {
      console.log(eventArgs);
      const data = eventArgs.data;
      let dataInfor = {};
      if (data.userId !== undefined && data.userId !== null && data.userId !== '') {
        dataInfor = {
          userId: data.userId.value,
          type: data.type,
          isValid: data.isValid,
          name: data.name,
        };
      } else {
        dataInfor = {
          userId: '',
          type: data.type,
          isValid: data.isValid,
          name: data.name,
        };
      }
      this.resetData();
      this.loading = true;
      this.$http.put(Config.CARD_LIST_API + '/' + encodeURIComponent(data.pCodeId), dataInfor)
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.loading = false;
            this.successMessageLogs = Config.EDIT_PCODE_API_SUCCESS;
          } else {
            this.loading = false;
            this.errorMessageLogs = Config.ERROR_DEFAULT;
          }
        })
        .then( null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
            case 409:
              this.errorMessageLogs = 'カード番号がすでに存在している。';
              break;
            case 420:
              this.errorMessageLogs = 'ゲートに紐づく個人カード番号が上限を超えるため、追加できない。';
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        }); // catch
    },
    resetData() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
    },
  },
});
