







































































































import Vue from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';

export interface DataType {
  selectedItem: any;
}

export default Vue.extend({
  name: 'GroupUserTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
  },
  props: {
    groupExpanedUser: {
      type: Map,
      default: new Map(),
    },
    groupId: {
      default: '',
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
  },
  data: (): DataType => ({
    selectedItem: [],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ユーザーID',
            align: 'start',
            sortable: true,
            value: 'user.userId',
          },
          {
            text: 'ユーザー名',
            align: 'start',
            sortable: true,
            value: 'user.name',
          },
          {
            text: '備考1',
            align: 'start',
            sortable: true,
            value: 'user.option1',
          },
          {
            text: '備考2',
            align: 'start',
            sortable: true,
            value: 'user.option2',
          },
          {
            text: 'ステータス',
            align: 'start',
            sortable: true,
            value: 'user.isValid',
          },
        ];
        return headers;
      },
    },
  },
  watch: {
    isReRenderUser(value) {
      this.$forceUpdate();
    },
    isRemoveUserSelected(value) {
      this.selectedItem = [];
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
        return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'user.name') {
          if (!isDescending[0]) {
              return (a.user.name < b.user.name) ? -1 : 1;
          } else {
              return (b.user.name < a.user.name) ? -1 : 1;
          }
        } else if (index[0] === 'user.userId') {
          if (!isDescending[0]) {
              return (a.user.userId < b.user.userId) ? -1 : 1;
          } else {
              return (b.user.userId < a.user.userId) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    changePage(val: any) {
      this.$emit('change-page-user', {page: val, groupId: this.groupId});
    },
    clickJoinUser(ID: any) {
      this.$router.push({ path: Config.GROUP_ADD_USER, query: {groupId: ID}});
    },
    clickDetail(ID: string) {
      this.$router.push({ path: Config.USER_DETAIL, query: {userId: ID, goBack: 'groups'}});
    },
  },
});
