var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "px-0",
      attrs: { fluid: "" },
      on: {
        change: function($event) {
          return _vm.$emit("change")
        }
      }
    },
    [
      _c(
        "v-radio-group",
        {
          model: {
            value: _vm.radioGroup,
            callback: function($$v) {
              _vm.radioGroup = $$v
            },
            expression: "radioGroup"
          }
        },
        _vm._l(_vm.arrayItem, function(n) {
          return _c("v-radio", {
            key: n.value,
            attrs: {
              color: "green",
              checked: n.checked,
              label: n.name,
              value: n.value
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }