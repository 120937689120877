













































































































































































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import DateTimePicker from '@/components/molecules/DateTimePicker/DateTimePicker.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import { DataTableHeader } from 'vuetify';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import ModalPageGroupGate from '@/components/pages/common-modal/ModalPage.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import ErrorButton from '@/components/molecules/ErrorButton/ErrorButton.vue';

import moment from 'moment';
import * as Config from '@/config';

export interface DataType {
  Message: any;
  isNoPasswordUser: boolean;
  pCodeIdValue: string;
  model: any;
  detailValue: string;
  typeValue: string;
  userIdValue: string;
  dataTime: any;
  // userInfo: User;
  realtionsGateList: any;
  timeStartGate: string;
  timeEndGate: string;
  dateStartGate: string;
  dateEndGate: string;
  itemGate: any;
  isClickExpandArea: boolean;
  checkSMS: boolean;
  showModal: boolean;
  showConfirm: boolean;
  autoselectMenu: boolean;
  rulesValidate: any;
  authorityItems: any;
  type: any;
  addItem: any;
  sameUser: boolean;
  isMfa: boolean;
  searchInput: string;
  searchInputTmp: string;
  errorAddListGate: string;
  errorGate: string;
  errorTime: string;
  update: boolean;
  isHasLowerCaseCardNumber: boolean;
  lastSearchCard: any;
  listAff: any;
  listGroup: any;
  selectedIds: any;
  deleteData: any;
  dataAllow: any;
  addIds: any;
  erroMessage: any;
  userDepartmentId: any;
  Error: any;
  isAddGroup: any;
}

interface User {
  userId: string;
  password: string;
  name: string;
  furigana: string;
  email: string;
  phoneNumber: string;
  thumbnail: string;
  passCode: string;
  isMfaSms: boolean;
  isAdmin: boolean;
  isPhoneAdmin: boolean;
  shareKeyBeValid: boolean;
  isValid: boolean;
  option1: string;
  option2: string;
  isSendEmail: boolean;
  pCodeIds: any;
  isPasswordLoginValid: boolean;
}

export default Vue.extend({
  name: 'UserAddForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    DateTimePicker,
    TextLink,
    ModalPageGroupGate,
    ModalTitle,
    Modal,
    ErrorButton,
  },
  props: {
    isGetDepartmentAll: {
      type: Boolean,
      default: false,
    },
    isGetDepartment: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    gates: {
      type: Array,
      default: () => [],
    },
    listGatePassages: {
      default: () => [],
    },
    gateList: {
      type: Array,
      default: () => [],
    },
    isDisabledGateOption: {
      type: Boolean,
      default: true,
    },
    isDisabledPCodeOptions: {
      type: Boolean,
      default: true,
    },
    isDisabledGroupOptions: {
      type: Boolean,
      default: true,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    gateGroupOptions: {
      type: Array,
      default: () => [],
    },
    pCodeOptions: {
      type: Array,
      default: () => [],
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    phoneItems: {
      type: Array,
      default: () => [],
    },
    groupsAddFail: {
      type: Array,
      default: () => [],
    },
    pCodeAddFail: {
      type: Array,
      default: () => [],
    },
    gateAddFail: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
    getDepartments: {
      type: Array,
      default: () => [],
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    authority: {
      type: Object,
      default: ({}),
    },
    dataFailed: {
      type: Object,
      default: ({}),
    },
  },
  created() {
    this.$emit('getListGate');
  },
  mounted() {
    this.isNoPasswordUser = (this.isEdit === true && this.userInfo.isPasswordLoginValid === true) ? false : true;
    if (this.authority.isCustomUserAuthorityService) {
      this.authorityItems = [
        { text: '一般ユーザー', value: false },
        { text: '管理者', value: true },
        { text: 'カスタマイズ権限', value: null },
      ];
    } else {
      this.authorityItems = [
        { text: '一般ユーザー', value: false },
        { text: '管理者', value: true },
      ];
    }
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [];
        headers.push({
          text: 'ゲート名',
          align: 'start',
          sortable: true,
          width: '300px',
          value: 'gateName',
        });
        headers.push({
          text: '時間',
          align: 'start',
          sortable: true,
          value: 'time',
        });
        headers.push({
          text: '',
          align: 'start',
          sortable: false,
          value: 'edit',
          width: '100px',
        });
        return headers;
      },
      set(): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    isAddGroup: false,
    Error: '',
    userDepartmentId: '',
    selectedIds: [],
    listAff: [{
      departmentId: '',
      isAdminToView: true,
      isAdminToAddUser: false,
      isAdminToEditUser: false,
      isAdminToSetPcodesToUser: false,
      disabled: false,
      relationId: '',
      searchInput: '',
    }],
    listGroup: [{
      groupId: '',
      isAdminToView: true,
      isAdminOfGroupPassages: false,
      isAdminOfGatePassages: false,
      isAdminOfRemoteUnlock: false,
      disabled: false,
      groupAdministratorId: '',
      searchInput: '',
    }],
    addItem: {
      index: 0,
      item: '',
    },
    showModal: false,
    showConfirm: false,
    autoselectMenu: false,
    isNoPasswordUser: true,
    type: '0',
    model: [],
    errorAddListGate: '',
    errorGate: '',
    errorTime: '',
    pCodeIdValue: '',
    detailValue: '',
    typeValue: '',
    userIdValue: '',
    searchInput: '',
    searchInputTmp: '',
    realtionsGateList: [],
    timeStartGate: '',
    timeEndGate: '',
    dateStartGate: '',
    dateEndGate: '',
    itemGate: [],
    isClickExpandArea: false,
    checkSMS: false,
    update: true,
    sameUser: false,
    isHasLowerCaseCardNumber: false,
    lastSearchCard: '',
    erroMessage: {
      isAff : false,
      messageAff: [],
      messageGroup: [],
      isGroup : false,
    },
    rulesValidate: {
      requiredUserId: (value: string) => !!value || 'ユーザーIDは必須です。',
      validateUserId: (userId: string) => {
        const pattern = /^[a-zA-Z0-9@.!#$&*+/=?^_`{|}~-]{4,96}$/;
        return pattern.test(userId) || 'ユーザーIDは半角英数字で4文字以上96文字以内で設定してください。';
      },
      validateFurigana: (furigana: string) => {
      if (furigana !== null && furigana !== undefined && furigana !== '') {
          return furigana.length <= 64 || 'ふりがなは64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateOption1: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateOption2: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateEmail: (email: string) => {
        if (email !== null && email !== undefined && email !== '') {
          const pattern = email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/);
          if (pattern) {
            return true;
          } else {
            return '正しいメールアドレスを入力してください。';
          }
        } else {
          return true;
        }
      },
      // requiredPassword: (pass: string) => !!pass || 'パスワードは必須です。',
      validatePassword: (pass: string, isPasswordLoginValid: any) => {
        if (isPasswordLoginValid) {
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
          return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
        } else {
          return true;
        }
      },
      validateNewPassword: (pass: string) => {
        if (pass !== null && pass !== undefined && pass !== '') {
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
          return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
        } else {
          return true;
        }
      },
      requiredName: (name: string) => !!name || 'ユーザー名は必須です。',
      validateName: (name: string) => {
        if (name !== null && name !== undefined && name !== '') {
          return name.length <= 64 || 'ユーザー名は64文字以内で入力してください。';
        } else {
          return true;
        }

      },
      validatePhone: (phone: string, checkSMS: boolean) => {
        if (checkSMS) {
          const pattern = /^[0][0-9]{10}$/;
          return pattern.test(phone) || '0から始まる-(ハイフン)無しの携帯電話番号を半角数字で11桁入力してください。';
        } else {
          return true;
        }
      },
      validateConfirmPass(confirm: any, pass: any) {
        if (confirm !== pass) {
          return '「新しいパスワード」と一致しません。';
        } else {
          return true;
        }
      },
      validatePassCode(val: any) {
        if (val !== '' && val !== null) {
          const pattern = val.match(/^\d{4}$/);
          if (pattern) {
            return true;
          } else {
            return '解錠時の二段階認証パスワードは半角数字で4文字入力してください。';
          }
        } else {
          return true;
        }
      },
      // requiredDepartment: (departmentId: any) => (!!departmentId) || '所属選択は必須です。',
      requiredDepartment(departmentId: any, authority: any, userAdmin: any, listAff: any) {
        console.log('authority', authority);
        if (authority !== undefined && authority.isAdmin === true) {
          return true;
        } else {
          const checkAff = listAff.filter((element: any, index: any) => {
            if (element.departmentId !== '') {
              return element;
            }
          });
          if (authority !== undefined && (authority.isAdmin === false && authority.isAdminToViewUser === true)) {
            return (!!departmentId) || '所属選択は必須です。';
          } else {
            if (userAdmin !== null || (userAdmin === null && checkAff.length === 0)) {
              return true;
            } else {
              return (!!departmentId) || '所属選択は必須です。';
            }
          }
        }
      },
    },
    authorityItems: [
      { text: '一般ユーザー', value: false },
      { text: '管理者', value: true },
    ],
    dataTime: '',
    isMfa: localStorage.isMfa === 'true' ? false : true,
    Message : Config,
    deleteData: {
      relationIds: [],
      groupAdministratorIds: [],
    },
    dataAllow: {
      group: [],
      groupOri: [],
      department: [],
    },
    addIds: [],
  }),
  watch: {
    isGetDepartmentAll(val: any) {
      if (val === false) {
        this.userDepartmentId = this.userInfo.departmentId ? this.userInfo.departmentId : '';
      }
    },
    userDepartmentId(val: any) {
      this.$emit('change-user-departmentId', val);
    },
    isGetDepartment(val: any) {
      if (val === false) {
        const departments  = Array.of(...this.departments);
        this.dataAllow.department = departments;
      }
      if (val === false && this.userInfo.departmentAdministrators.length > 0 && this.isEdit === true) {
        this.deleteData.relationIds = [];
        this.listAff = [];
        this.userInfo.departmentAdministrators.forEach((element: any) => {
          const item: any = this.dataAllow.department.filter((el: any) => {
            if (el.value === element.departmentId) {
              return el;
            }
          });
          this.listAff.push({
            departmentId: {value: element.departmentId, text: item.length > 0 ? item[0].text : '' },
            isAdminToView: true,
            isAdminToEditUser: element.isAdminToEditUser,
            isAdminToAddUser: element.isAdminToAddUser,
            isAdminToSetPcodesToUser: element.isAdminToSetPcodesToUser,
            disabled: true,
            relationId: element.departmentAdministratorId,
            searchInput: '',
          });
        });
        this.changeDepartment();
      }
    },
    isDisabledGroupOptions(val: any) {
      if (val === false) {
        const group  = Array.of(...this.gateGroupOptions);
        this.dataAllow.group = group;
        this.dataAllow.groupOri = group;
      }
      if (val === false && this.userInfo.groupAdministrators.length > 0 && this.isEdit === true) {
        this.listGroup = [];
        this.deleteData.groupAdministratorIds = [];
        this.userInfo.groupAdministrators.forEach((element: any) => {
          const item: any = this.dataAllow.group.filter((el: any) => {
            if (el.value === element.groupId) {
              return el;
            }
          });
          this.listGroup.push({
            groupId: {value: element.groupId, text: item.length > 0 ? item[0].text :
              element.group.name !== '' ? element.group.name : '' },
            isAdminToView: true,
            isAdminOfGroupPassages: element.isAdminOfGroupPassages,
            isAdminOfGatePassages: element.isAdminOfGatePassages,
            isAdminOfRemoteUnlock: element.isAdminOfRemoteUnlock,
            disabled: true,
            groupAdministratorId: element.groupAdministratorId,
            searchInput: '',
          });
        });
        this.changeGroup();
      }
    },
    loading(val) {
      if (val === false) {
        this.deleteData.groupAdministratorIds = [];
        this.deleteData.relationIds = [];
        this.isNoPasswordUser = (this.isEdit === true && this.userInfo.isPasswordLoginValid === true) ? false : true;
        const group  = Array.of(...this.gateGroupOptions);
        const departments  = Array.of(...this.departments);
        this.dataAllow.department = departments;
        this.dataAllow.group = group;
        if (this.successMessageLogs !== '' && this.isEdit === true) {
          if (this.isEdit) {
          // add checked Group
          this.listGroup = [];
          this.userInfo.groupAdministrators.forEach((element: any) => {
            const item: any = this.dataAllow.group.filter((el: any) => {
            if (el.value === element.groupId) {
              return el;
            }
          });
            this.listGroup.push({
              groupId: {value: element.groupId, text: item.length > 0 ? item[0].text :
                element.group.name !== '' ? element.group.name : ''},
              isAdminToView: true,
              isAdminOfGroupPassages: element.isAdminOfGroupPassages,
              isAdminOfGatePassages: element.isAdminOfGatePassages,
              isAdminOfRemoteUnlock: element.isAdminOfRemoteUnlock,
              disabled: true,
              groupAdministratorId: element.groupAdministratorId,
              searchInput: '',
            });
          });
          if (this.dataFailed.groupAdministrators.add.length > 0) {
            this.dataFailed.groupAdministrators.add.forEach((element: any) => {
              this.listGroup.push({
                groupId: element.groupId,
                isAdminToView: element.isAdminToView,
                isAdminOfGroupPassages: element.isAdminOfGroupPassages,
                isAdminOfGatePassages: element.isAdminOfGatePassages,
                isAdminOfRemoteUnlock: element.isAdminOfRemoteUnlock,
                disabled: false,
                groupAdministratorId: element.groupAdministratorId,
                searchInput: '',
              });
            });
          }
          this.changeGroup();
          // add checked Aff
          this.listAff = [];
          this.userInfo.departmentAdministrators.forEach((element: any) => {
            const item: any = this.dataAllow.department.filter((el: any) => {
              if (el.value === element.departmentId) {
                return el;
              }
            });
            this.listAff.push({
              departmentId: {value: element.departmentId, text: item.length > 0 ? item[0].text : ''},
              isAdminToView: true,
              isAdminToEditUser: element.isAdminToEditUser,
              isAdminToAddUser: element.isAdminToAddUser,
              isAdminToSetPcodesToUser: element.isAdminToSetPcodesToUser,
              disabled: true,
              relationId: element.departmentAdministratorId,
              searchInput: '',
            });
          });
          if (this.dataFailed.departmentAdministrators.add.length > 0) {
            this.dataFailed.departmentAdministrators.add.forEach((element: any) => {
              this.listAff.push({
                departmentId: element.departmentId,
                isAdminToView: element.isAdminToView,
                isAdminToEditUser: element.isAdminToEditUser,
                isAdminToAddUser: element.isAdminToAddUser,
                isAdminToSetPcodesToUser: element.isAdminToSetPcodesToUser,
                disabled: false,
                relationId: element.departmentAdministratorId,
                searchInput: '',
              });
            });
          }
          this.changeDepartment();
          // get items add unsuccess
          if (this.dataFailed.departmentAdministrators.add.length > 0) {
            this.listAff.filter((element: any, index: any) => {
              const i = this.dataFailed.departmentAdministrators.add.findIndex((x: any) =>
              x.departmentId !== '' && x.departmentId.value === element.departmentId.value);
              if (i >= 0) {
                this.erroMessage.messageAff.push({i: index, type : 'add'});
              }
            });
          }
          // get items edit unsuccess
          if (this.dataFailed.departmentAdministrators.edit.length > 0) {
            this.listAff.filter((element: any, index: any) => {
              const i = this.dataFailed.departmentAdministrators.edit.findIndex((x: any) =>
              x.departmentId !== '' && x.departmentId.value === element.departmentId.value);
              if (i >= 0) {
                this.erroMessage.messageAff.push({i: index, type : 'edit'});
              }
            });
          }
          // get items del unsuccess
          if (this.dataFailed.departmentAdministrators.del.length > 0) {
            this.listAff.filter((element: any, index: any) => {
              const i = this.dataFailed.departmentAdministrators.del.findIndex((x: any) =>
              x.departmentId !== '' && x.departmentId.value === element.departmentId.value);
              if (i >= 0) {
                this.erroMessage.messageAff.push({i: index, type : 'del'});
              }
            });
          }
          // groupAdministrators
          // get items add unsuccess
          if (this.dataFailed.groupAdministrators.add.length > 0) {
            this.listGroup.filter((element: any, index: any) => {
              const i = this.dataFailed.groupAdministrators.add.findIndex((x: any) =>
              x.groupId !== '' && x.groupId.value === element.groupId.value);
              if (i >= 0) {
                this.erroMessage.messageGroup.push({i: index, type : 'add'});
              }
            });
          }
          // get items edit unsuccess
          if (this.dataFailed.groupAdministrators.edit.length > 0) {
            this.listGroup.filter((element: any, index: any) => {
              const i = this.dataFailed.groupAdministrators.edit.findIndex((x: any) =>
              x.groupId !== '' && x.groupId.value === element.groupId.value);
              if (i >= 0) {
                this.erroMessage.messageGroup.push({i: index, type : 'edit'});
              }
            });
          }
          // get items delete unsuccess
          if (this.dataFailed.groupAdministrators.del.length > 0) {
            this.listGroup.filter((element: any, index: any) => {
              const i = this.dataFailed.groupAdministrators.del.findIndex((x: any) =>
              x.groupId !== '' && x.groupId.value === element.groupId.value);
              if (i >= 0) {
                this.erroMessage.messageGroup.push({i: index, type : 'del'});
              }
            });
          }
        }
        } else {
          // departmentAdministrators
          // get items add unsuccess
          if (this.dataFailed.departmentAdministrators.add.length > 0) {
            this.listAff.filter((element: any, index: any) => {
              const i = this.dataFailed.departmentAdministrators.add.findIndex((x: any) =>
              x.departmentId !== '' && x.departmentId.value === element.departmentId.value);
              if (i >= 0) {
                this.erroMessage.messageAff.push({i: index, type : 'add'});
              }
            });
          }
          // groupAdministrators
          // get items add unsuccess
          if (this.dataFailed.groupAdministrators.add.length > 0) {
            this.listGroup.filter((element: any, index: any) => {
              const i = this.dataFailed.groupAdministrators.add.findIndex((x: any) =>
              x.groupId !== '' && x.groupId.value === element.groupId.value);
              if (i >= 0) {
                this.erroMessage.messageGroup.push({i: index, type : 'add'});
              }
            });
          }
          if (this.successMessageLogs !== '' && this.isEdit === false &&
          this.dataFailed.groupAdministrators.add.length === 0
          && this.dataFailed.departmentAdministrators.add.length === 0) {
            this.$emit('init-data');
            this.dateStartGate = '';
            this.dateEndGate = '';
            this.timeStartGate = '';
            this.timeEndGate = '';
            this.itemGate = [];
            (this.$refs.UserAddForm as Vue & { resetValidation: () => boolean }).resetValidation();
          }
        }
      }
    },
    listGatePassages(val) {
      this.realtionsGateList = val;
    },
    userInfo(data: any) {
      console.log('data', data);
      this.checkSMS = this.userInfo.isMfaSms;
      this.sameUser = this.userInfo.userId === localStorage.userProfileId ? true : false;
    },
    successMessageLogs(val: any) {
      if (val !== '' && this.isEdit === false && this.dataFailed.groupAdministrators.add.length === 0
      && this.dataFailed.departmentAdministrators.add.length === 0) {
        // this.$emit('init-data');
        // this.dateStartGate = '';
        // this.dateEndGate = '';
        // this.timeStartGate = '';
        // this.timeEndGate = '';
        // this.itemGate = [];
        // (this.$refs.UserAddForm as Vue & { resetValidation: () => boolean }).resetValidation();
      }
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      console.log(items);
      items.sort((a: any, b: any) => {
        if (index[0] === 'gateName') {
          if (!isDescending[0]) {
              return (a.gate.text < b.gate.text) ? -1 : 1;
          } else {
              return (b.gate.text < a.gate.text) ? -1 : 1;
          }
        } else if (index[0] === 'time') {
          if (!isDescending[0]) {
              return (a.notAfter < b.notAfter) ? -1 : 1;
          } else {
              return (b.notAfter < a.notAfter) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    yesChoice() {
      this.userInfo.isSendEmail = true;
      this.isNoPasswordUser = this.userInfo.isPasswordLoginValid === true ? false : true;
      if (this.isEdit) {
        const modifiedPhonesList: any[] = [];
        this.phoneItems.forEach((item: any) => {
          if (item.text.startsWith('申請中 : ')) {
            const addItem = this.userInfo.phoneIds.find((a: any) => a.text === item.text && a.value === item.value);
            if (addItem) {
              modifiedPhonesList.push(addItem);
            }
          } else {
            const delItem = this.userInfo.phoneIds.find((a: any) => a.text === item.text && a.value === item.value);
            if (!delItem) {
              modifiedPhonesList.push(item);
            }
          }
        });
        this.$emit('clickUpdateUser', {
          userInfo: this.userInfo,
          gate: this.realtionsGateList,
          updatedPhonesList: modifiedPhonesList,
          listAff: this.listAff,
          listGroup: this.listGroup,
          deleteData: this.deleteData,
        });
      } else {
        this.$emit('clickSaveUser', {
          userInfo: this.userInfo,
          gate: this.realtionsGateList,
          listAff: this.listAff,
          listGroup: this.listGroup,
          addIds: this.addIds,
        });
      }
      this.showConfirm = false;
    },
    noChoice() {
      this.userInfo.isSendEmail = false;
      this.isNoPasswordUser = this.userInfo.isPasswordLoginValid === true ? false : true;
      if (this.isEdit) {
        const modifiedPhonesList: any[] = [];
        this.phoneItems.forEach((item: any) => {
          if (item.text.startsWith('申請中 : ')) {
            const addItem = this.userInfo.phoneIds.find((a: any) => a.text === item.text && a.value === item.value);
            if (addItem) {
              modifiedPhonesList.push(addItem);
            }
          } else {
            const delItem = this.userInfo.phoneIds.find((a: any) => a.text === item.text && a.value === item.value);
            if (!delItem) {
              modifiedPhonesList.push(item);
            }
          }
        });
        this.$emit('clickUpdateUser', {
          userInfo: this.userInfo,
          gate: this.realtionsGateList,
          updatedPhonesList: modifiedPhonesList,
          listAff: this.listAff,
          listGroup: this.listGroup,
          deleteData: this.deleteData,
        });
      } else {
        this.$emit('clickSaveUser', {
          userInfo: this.userInfo,
          gate: this.realtionsGateList,
          listAff: this.listAff,
          listGroup: this.listGroup,
          addIds: this.addIds,
        });
      }
      this.showConfirm = false;
    },
    clickSaveUser() {
      const result = (this.$refs.UserAddForm as Vue & { validate: () => boolean }).validate();
      this.erroMessage = {
        isAff : false,
        messageAff: [],
        messageGroup: [],
        isGroup : false,
      };
      if (this.userInfo.isAdmin === null) {
          // this.listAff.filter((element: any, index: any) => {
          //   if (this.isValidateItem(element, 'department')) {
          //     this.erroMessage.messageAff.push({i: index, type : ''});
          //   } else {
          //     return element;
          //   }
          // });
          // this.listGroup.filter((element: any, index: any) => {
          //   if (this.isValidateItem(element, 'group')) {
          //     this.erroMessage.messageGroup.push({i: index, type : ''});
          //   } else {
          //     return element;
          //   }
          // });
          const checkAff = this.listAff.filter((element: any, index: any) => {
            if (element.departmentId === '') {
              return element;
            }
          });
          const checkGroup = this.listGroup.filter((element: any, index: any) => {
            if (element.groupId === '') {
              return element;
            }
          });
          if (checkAff.length === this.listAff.length && checkGroup.length === this.listGroup.length
          && (this.listGroup.length > 0 || this.listAff.length > 0) ||
          (this.listGroup.length === 0 && this.listAff.length === 0) ) {
            this.erroMessage.isAff = true;
          }
        }
      if (result && this.erroMessage.messageGroup.length === 0  && this.erroMessage.messageAff.length === 0
      && this.erroMessage.isAff === false) {
        if (this.userInfo.email !== undefined && this.userInfo.email !== null && this.userInfo.email !== '' &&
            this.userInfo.isPasswordLoginValid !== undefined && this.userInfo.isPasswordLoginValid !== null &&
            this.userInfo.isPasswordLoginValid === true) {
          this.showConfirm = true;
        } else {
          this.isNoPasswordUser = this.userInfo.isPasswordLoginValid === true ? false : true;
          this.$emit('clickSaveUser', {
            userInfo: this.userInfo,
            gate: this.realtionsGateList,
            listAff: this.listAff,
            listGroup: this.listGroup,
            addIds: this.addIds,
          });
        }
      }
    },
    clickUpdateUser() {
      const result = (this.$refs.UserAddForm as Vue & { validate: () => boolean }).validate();
      this.erroMessage = {
        isAff : false,
        messageAff: [],
        messageGroup: [],
        isGroup : false,
      };
      if (this.userInfo.isAdmin === null) {
        const checkAff = this.listAff.filter((element: any, index: any) => {
          if (element.departmentId === '') {
            return element;
          }
        });
        const checkGroup = this.listGroup.filter((element: any, index: any) => {
          if (element.groupId === '') {
            return element;
          }
        });
        if (checkAff.length === this.listAff.length && checkGroup.length === this.listGroup.length
        && (this.listGroup.length > 0 || this.listAff.length > 0) ||
        (this.listGroup.length === 0 && this.listAff.length === 0) ) {
          this.erroMessage.isAff = true;
        }
      }
      if (result && this.erroMessage.messageGroup.length === 0  && this.erroMessage.messageAff.length === 0 &&
      this.erroMessage.isAff === false) {
        if (this.userInfo.email !== undefined && this.userInfo.email !== null && this.userInfo.email !== '') {
          this.showConfirm = true;
        } else {
          this.erroMessage = {
            isAff : false,
            isGroup : false,
            messageAff: [],
            messageGroup: [],
          };
          this.isNoPasswordUser = this.userInfo.isPasswordLoginValid === true ? false : true;
          const modifiedPhonesList: any[] = [];
          this.phoneItems.forEach((item: any) => {
            if (item.text.startsWith('申請中 : ')) {
              const addItem = this.userInfo.phoneIds.find((a: any) => a.text === item.text && a.value === item.value);
              if (addItem) {
                modifiedPhonesList.push(addItem);
              }
            } else {
              const delItem = this.userInfo.phoneIds.find((a: any) => a.text === item.text && a.value === item.value);
              if (!delItem) {
                modifiedPhonesList.push(item);
              }
            }
          });
          this.$emit('clickUpdateUser', {
            userInfo: this.userInfo,
            gate: this.realtionsGateList,
            updatedPhonesList: modifiedPhonesList,
            listAff: this.listAff,
            listGroup: this.listGroup,
            deleteData: this.deleteData,
          });
        }
      }
    },
    isObject(val: any) {
      return val instanceof Object;
    },
    closeModal() {
      const pCodeIds = this.userInfo.pCodeIds;
      pCodeIds.splice(parseInt(this.addItem.index), 1);
      this.showModal = false;
    },
    addPcode() {
      const pCodeIds = this.userInfo.pCodeIds;
      pCodeIds.splice(parseInt(this.addItem.index), 1);
      pCodeIds.push({
        text: this.addItem.value + '＜' + this.addItem.value + '＞',
        value: this.addItem.value,
        type: parseInt(this.type),
      });
      this.showModal = false;
      this.addItem = {
        index: 0,
        value: '',
      };
      this.type = '0';
    },
    toggle(value: any) {
      this.autoselectMenu = value;
    },
    updateGroupTags() {
      const groupIds = this.userInfo.groupIds;
      const key = Object.keys(groupIds).length - 1;
      const last = groupIds[Object.keys(groupIds)[key]];
      if (last !== undefined && this.isObject(last) === false) {
        groupIds.splice(key, 1);
        groupIds.push({
          text: last,
          value: last,
          type: 1,
        });
      }
      this.searchInput = '';
    },
    updatePhoneTags() {
      const phoneIds = this.userInfo.phoneIds;
      const key = Object.keys(phoneIds).length - 1;
      const last = phoneIds[Object.keys(phoneIds)[key]];
      if (last !== undefined && this.isObject(last) === false) {
        this.addItem = {
          index: Object.keys(phoneIds).length - 1,
          value: last,
        };
        phoneIds.splice(parseInt(this.addItem.index), 1);
      }
      this.searchInput = '';
    },
    updateTags(data: any) {
      this.searchInputTmp = this.searchInput;
      const pCodeIds = this.userInfo.pCodeIds;
      const key = Object.keys(pCodeIds).length - 1;
      this.lastSearchCard = pCodeIds[Object.keys(pCodeIds)[key]];
      if (this.lastSearchCard !== undefined && this.isObject(this.lastSearchCard) === false) {
        this.addItem = {
            index: Object.keys(pCodeIds).length - 1,
            value: this.lastSearchCard,
        };
        if (this.lastSearchCard.toUpperCase() !== this.lastSearchCard) {
          this.isHasLowerCaseCardNumber = true;
        } else {
          this.showModal = true;
        }
      }
      this.searchInput = '';
    },
    ChangeValue(value: any) {
      this.checkSMS = value;
    },
    ClickForm() {
      (this.$refs.form123 as Vue & { validate: () => boolean }).validate();
    },
    getNewTimeStart(time: string) {
      this.timeStartGate = time;
    },
    getNewTimeEnd(time: string) {
      this.timeEndGate = time;
    },
    getNewDateStart(date: string) {
      this.dateStartGate = date;
    },
    getNewDateEnd(date: string) {
      this.dateEndGate = date;
    },
    formatDateTime(date: Date): string {
      // return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}
      // ${date.getHours()}:${date.getMinutes()}`;
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const min = date.getMinutes().toString().padStart(2, '0');
      return year + '/' + month + '/' + day + ' ' + hour + ':' + min;

    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
    deleteGate(index: any) {
      this.realtionsGateList.splice(parseInt(index), 1);
    },
    addGate() {
      let count = 0;
      this.errorAddListGate = '';
      this.errorGate = '';
      this.errorTime = '';
      const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
      const hoursStartError =  pattern.test(this.timeStartGate);
      const hoursEndError =  pattern.test(this.timeEndGate);
      const timeStart = this.dateStartGate + ' ' + this.timeStartGate;
      const timeEnd = this.dateEndGate + ' ' + this.timeEndGate;
      for (const item of this.realtionsGateList) {
        const oldGate = item.gate.value + '-' + item.notBefore + '-' + item.notAfter;
        const newGate = this.itemGate.value + '-' + this.formatTimestamp(this.dateStartGate + ' ' + this.timeStartGate)
        + '-' + this.formatTimestamp(this.dateEndGate + ' ' + this.timeEndGate);
        if (oldGate === newGate) {
          this.errorAddListGate = 'すでに追加されている時間帯になります。追加する場合は、一度削除してから実施してください。';
          this.errorGate = '';
          this.errorTime = '';
          break ;
        } else {
          count++;
        }
      }
      if (count === this.realtionsGateList.length && this.dateStartGate !== ''
      && this.timeStartGate !== '' && this.dateEndGate !== ' ' && this.timeEndGate !== '' &&
      hoursStartError === true && hoursEndError === true && timeEnd > timeStart) {
        this.realtionsGateList.push({
          gate: this.itemGate,
          notBefore: this.formatTimestamp(this.dateStartGate + ' ' + this.timeStartGate),
          notAfter: this.formatTimestamp(this.dateEndGate + ' ' + this.timeEndGate),
        });
      } else {
        if (this.itemGate.length === 0 || this.itemGate.value === '') {
          this.errorGate = 'ゲートを指定してください。';
          this.errorAddListGate = '';
        }
        if (timeEnd <= timeStart && this.errorAddListGate === '') {
          this.errorTime = Config.ERROR_END_TIME;
        }
        if ((hoursStartError === false || hoursEndError === false) && this.errorAddListGate === '') {
          this.errorTime = '時間を指定してください。';
        }
      }
    },
    closeDialogConfirm() {
      this.isHasLowerCaseCardNumber = false;
      const pCodeIds = this.userInfo.pCodeIds;
      pCodeIds.splice(parseInt(this.addItem.index), 1);
      setTimeout(() => {
        this.searchInput = this.searchInputTmp;
        const d = document.getElementById('id-list-card');
        if (d) {
          d.focus();
        }
      }, 300);
    },
    yesChoiceLowerCase() {
      this.isHasLowerCaseCardNumber = false;
      const pCodeIds = this.userInfo.pCodeIds;
      this.showModal = true;
      this.addItem = {
        index: Object.keys(pCodeIds).length - 1,
        value: this.lastSearchCard,
      };
    },
    //
    addAffiliation() {
      //
      this.listAff.push({
        departmentId: '',
        isAdminToView: true,
        isAdminToAddUser: false,
        isAdminToEditUser: false,
        isAdminToSetPcodesToUser: false,
        disabled: false,
        relationId: '',
        searchInput: '',
      });
    },
    addGroup() {
      //
      this.listGroup.push({
        groupId: '',
        isAdminToView: true,
        isAdminOfGroupPassages: false,
        isAdminOfGatePassages: false,
        isAdminOfRemoteUnlock: false,
        disabled: false,
        groupAdministratorId: '',
        searchInput: '',
      });
      this.isAddGroup = true;
    },
    deleteAff(index: any) {
      const i = this.dataAllow.department.map((e: { value: any; }) =>
      e.value).indexOf(this.listAff[index].departmentId.value);
      if (i >= 0) {
        this.dataAllow.department[i].disabled = false;
      }
      if (this.listAff[index].disabled) {
        this.deleteData.relationIds.push(this.listAff[index]);
      }
      this.listAff.splice(parseInt(index), 1);
    },
    deleteGroup(index: any) {
      const i = this.dataAllow.groupOri.map((e: { value: any; }) =>
      e.value).indexOf(this.listGroup[index].groupId.value);
      if (i >= 0) {
        this.dataAllow.groupOri[i].disabled = false;
      }
      if (this.listGroup[index].disabled) {
        this.deleteData.groupAdministratorIds.push(this.listGroup[index]);
      }
      this.listGroup.splice(parseInt(index), 1);
      this.changeGroup();
    },
    changeGroup() {
      this.dataAllow.groupOri.forEach((element: any, key: any) => {
        const check = this.listGroup.some((item: any) => item.groupId.value === element.value);
        if (check) {
          this.dataAllow.groupOri[key].disabled = true;
        }
      });
      this.dataAllow.group = this.dataAllow.groupOri.filter((element: any) => element.disabled === false);
    },
    changeDepartment() {
      this.dataAllow.department.forEach((element: any, key: any) => {
        const check = this.listAff.some((item: any) => item.departmentId.value === element.value);
        if (check) {
          this.dataAllow.department[key].disabled = true;
        }
      });
    },
    changeisAdmin(val: any) {
      this.$emit('change-id');
    },
    isDisable() {
      if (this.loading === false) {
        const requiredDepartment = this.rulesValidate.requiredDepartment(this.userInfo.departmentId,
        this.authority, this.userInfo.isAdmin, this.listAff);
        const validateEmail = this.rulesValidate.validateEmail(this.userInfo.email);
        const requiredUserId = this.rulesValidate.requiredUserId(this.userInfo.userId);
        const validateUserId = this.rulesValidate.validateUserId(this.userInfo.userId);
        const validateFurigana = this.rulesValidate.validateFurigana(this.userInfo.furigana);
        const validateOption1 = this.rulesValidate.validateOption1(this.userInfo.option1);
        const validateOption2 = this.rulesValidate.validateOption2(this.userInfo.option2);
        const requiredName = this.rulesValidate.requiredName(this.userInfo.name);
        const validateName = this.rulesValidate.validateName(this.userInfo.name);
        const validatePhone = this.rulesValidate.validatePhone(this.userInfo.phoneNumber, this.checkSMS);
        const validateConfirmPass = this.rulesValidate.validateConfirmPass(this.userInfo.confirmPassword,
        this.userInfo.newPassword);
        const validatePassword = this.isNoPasswordUser === true ?
        this.rulesValidate.validatePassword(this.userInfo.password, this.userInfo.isPasswordLoginValid) : true;
        const validatePassCode = this.rulesValidate.validatePassCode(this.userInfo.passCode, this.userInfo.newPassword);
        if (requiredDepartment === true && requiredUserId === true && validateUserId === true &&
        validateFurigana === true && validateEmail === true &&
        validateOption1 === true && validateOption2 === true && requiredName === true && validateName === true &&
        validatePhone === true && validateConfirmPass === true && validatePassCode === true &&
        validatePassword === true) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    isDisableRegister() {
      const requiredDepartment = this.rulesValidate.requiredDepartment(this.userInfo.departmentId,
      this.authority, this.userInfo.isAdmin, this.listAff);
      const validateEmail = this.rulesValidate.validateEmail(this.userInfo.email);
      const requiredUserId = this.rulesValidate.requiredUserId(this.userInfo.userId);
      const validateUserId = this.rulesValidate.validateUserId(this.userInfo.userId);
      const validateFurigana = this.rulesValidate.validateFurigana(this.userInfo.furigana);
      const validateOption1 = this.rulesValidate.validateOption1(this.userInfo.option1);
      const validateOption2 = this.rulesValidate.validateOption2(this.userInfo.option2);
      const requiredName = this.rulesValidate.requiredName(this.userInfo.name);
      const validateName = this.rulesValidate.validateName(this.userInfo.name);
      const validatePhone = this.rulesValidate.validatePhone(this.userInfo.phoneNumber, this.checkSMS);
      const validatePassword = this.rulesValidate.validatePassword(this.userInfo.password,
      this.userInfo.isPasswordLoginValid);
      const validateNewPassword = this.rulesValidate.validateNewPassword(this.userInfo.newPassword,
      this.userInfo.newPassword);
      const validateConfirmPass = this.rulesValidate.validateConfirmPass(this.userInfo.confirmPassword,
      this.userInfo.newPassword);
      const validatePassCode = this.rulesValidate.validatePassCode(this.userInfo.passCode, this.userInfo.newPassword);
      if (requiredDepartment === true && requiredUserId === true && validateUserId === true &&
      validateFurigana === true && validateEmail === true &&
      validatePassword === true &&
      validateNewPassword === true &&
      validateOption1 === true && validateOption2 === true && requiredName === true && validateName === true &&
      validatePhone === true && validateConfirmPass === true && validatePassCode === true) {
        return false;
      } else {
        return true;
      }
    },
    isShowMsg(item: any, val: any) {
      const result  = item.find((element: any) => element.i === val);
      if (result) {
        return true;
      } else {
        return false;
      }
    },
    isMsgDepartment(item: any, val: any) {
      const result  = item.find((element: any) => element.i === val);
      if (result) {
        if (result.type === 'add') {
          return '所属権限追加に失敗しました。';
        } else if (result.type === 'edit') {
          return '所属権限編集に失敗しました。';
        } else if (result.type === 'del') {
          return '所属権限削除に失敗しました。';
        } else {
          return 'カスタマイズ権限の時は一つ以上入力してください。';
        }
      } else {
        return 'カスタマイズ権限の時は一つ以上入力してください。';
      }
    },
    isMsgGroup(item: any, val: any) {
      const result  = item.find((element: any) => element.i === val);
      if (result) {
        if (result.type === 'add') {
          return '通行権限グループ追加に失敗しました。';
        } else if (result.type === 'edit') {
          return '通行権限グループ編集に失敗しました。';
        } else if (result.type === 'del') {
          return '通行権限グループ削除に失敗しました。';
        } else {
          return 'カスタマイズ権限の時は一つ以上入力してください。';
        }
      } else {
        return 'カスタマイズ権限の時は一つ以上入力してください。';
      }
    },
    isValidateItem(item: any, type: any) {
      if (type === 'department') {
        if (item.departmentId === '') {
          return true;
        } else {
          return false;
        }
      } else if (type === 'group') {
        if (item.groupId === '') {
          return true;
        } else {
          return false;
        }
      }
    },
    changeDepartmentInput() {
      this.listAff.forEach((element: any, key: any) => {
        if ((element.departmentId !== '' && element.departmentId.value === undefined) || element.departmentId === '') {
          console.log('key', key);
          this.listAff[key].searchInput = '';
          this.listAff[key].departmentId = '';
        }
      });
    },
    changeGroupInput() {
      this.listGroup.forEach((element: any, key: any) => {
        if ((element.groupId !== '' && element.groupId.value === undefined) || element.groupId === '') {
          console.log('key', key);
          this.listGroup[key].searchInput = '';
          this.listGroup[key].groupId = '';
        }
      });
    },
  },
});
