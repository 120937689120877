











































import {Vue } from 'vue-property-decorator';
import GroupListTemplate from '@/components/templates/GroupListTemplate/GroupListTemplate.vue';
import * as Config from '@/config';
export interface DataType {
  isGetDataList: boolean;
  dataGroups: any;
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  groupTotalCount: number;
  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;
  showProgress: boolean;
  rowinErrDialog: number;
  errorMessageProcess: any;
  successItemProcess: number;
  totalDataRowProcess: number;
  progress: number;
  isFinishProcess: boolean;
  typeAction: number;
  dataAction: any;
  groupIdAction: string;
  showConfirm: boolean;

  groupExpanedGate: Map<string, GroupGate>; // data Gate of Group
  isReRenderGate: number;
  isRemoveGateSelected: number;

  groupExpanedUser: Map<string, GroupUser>; // data User of Group
  isReRenderUser: number;
  isRemoveUserSelected: number;

  isRemoveGroupSelected: number;
}
export interface GroupGate {
  currPageGate?: number;
  totalGateCount?: number;
  isGetDataGate?: boolean;
  data?: any;
}

export interface GroupUser {
  currPageUser?: number;
  totalUserCount?: number;
  isGetDataUser?: boolean;
  data?: any;
}

export default Vue.extend({
  name: 'GateListPage',
  components: {
    GroupListTemplate,
  },
  data: (): DataType => ({
    isGetDataList: false,
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['groupName'],
    keyword: '',
    groupTotalCount: 0,
    dataGroups: [],
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    showProgress: false,
    rowinErrDialog: 0,
    errorMessageProcess: [],
    successItemProcess: 0,
    totalDataRowProcess: 0,
    progress: 0,
    isFinishProcess: false,
    typeAction: 0,
    dataAction: [],
    groupIdAction: '',
    showConfirm: false,

    groupExpanedGate: new Map(),
    isReRenderGate: 0,
    isRemoveGateSelected: 0,

    groupExpanedUser: new Map(),
    isReRenderUser: 0,
    isRemoveUserSelected : 0,

    isRemoveGroupSelected: 0,
  }),
  created() {
    if (typeof localStorage.isVaildDataGroup === 'undefined') {
      localStorage.setItem('isVaildDataGroup', 'true');
    }
    if (typeof localStorage.groupSortKey === 'undefined') {
      localStorage.setItem('groupSortKey', 'registered');
    }
    if (typeof localStorage.groupSortName === 'undefined') {
      localStorage.setItem('groupSortName', 'desc');
    }
    if (typeof localStorage.pageSizeGroup === 'undefined') {
      localStorage.setItem('pageSizeGroup', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeGroup);
    }
    this.getListGroups();
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.$emit('activeMenu', 'groups');
  },
  methods: {
    clickAddGroup() {
      this.$router.push({ path: Config.GROUP_ADD});
    },
    clickEditGroup(value: any) {
      this.$router.push({ path: Config.GROUP_EDIT, query: {groupId: value}});
    },
    clickGroupDetail(value: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: value}});
    },
    closeModalProgress() {
      this.showProgress = false;
      if (this.successItemProcess > 0) {
        if (this.typeAction === 0 || this.typeAction === 1 || this.typeAction === 2) {
          this.getListGroups();
        } else if (this.typeAction === 3) {
          // gate
          this.removegateSelected();
          const gate: GroupGate = {
            isGetDataGate: false,
            currPageGate: 1,
            totalGateCount: 0,
            data: [],
          };
          this.groupExpanedGate.set(this.groupIdAction, gate);
          this.getGateOfGroup(this.groupIdAction, 1);
          this.getListGroups();
        } else if (this.typeAction === 4) {
          // user
          this.removeUserSelected();
          this.groupExpanedUser = new Map();
          const user: GroupUser = {
            isGetDataUser: false,
            currPageUser: 1,
            totalUserCount: 0,
            data: [],
          };
          this.groupExpanedUser.set(this.groupIdAction, user);
          this.getUserOfGroup(this.groupIdAction, 1);
          this.getListGroups();
        }
      }
    },

    showDialogConfirm(value: any) {
      this.typeAction = value.type;
      this.dataAction = value.data;
      if (value.groupId !== undefined) {
        this.groupIdAction = value.groupId;
      }
      this.showConfirm = true;
    },

    closeDialogConfirm() {
      this.showConfirm = false;
    },

    async clickGroupsAction() {
      this.showConfirm = false;
      this.showProgress = true;
      this.rowinErrDialog = 0;
      this.rowinErrDialog = 0;
      this.errorMessageProcess = [];
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcess = false;

      this.totalDataRowProcess = this.dataAction.length;
      const percent = (100 / this.totalDataRowProcess);

      for (const item of this.dataAction) {
        let result;
        if (this.typeAction === 0) {
          //  enable
          result = await this.enableOrDisableGroup(item, true);
        } else if (this.typeAction === 1) {
          //  disable
          result = await this.enableOrDisableGroup(item, false);
        } else if (this.typeAction === 2) {
          //  delete
          let resultDel;
          let resultdeleteRe;
          resultDel = await this.deleteGroup(item);
          if (resultDel) {
            resultdeleteRe = await this.deleteRelationGateGroup(item);
            if (resultdeleteRe) {
              result = await this.putGroup(item);
            }
          }
        } else if (this.typeAction === 3) {
          // remove from to group
          result = await this.removeGateFromGroup(item.relationId, item.gate.gateId);
        } else if (this.typeAction === 4) {
          // remove user from group
          result = await this.removeUserFromGroup(item.groupPassageId, item.user.userId);
        }
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
      this.isFinishProcess = true;
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeGroup', value);
      this.getListGroups();
    },
    changePage(value: any): any {
      this.currPage = value;
      this.getListGroups();
    },
    searchGroup(value: any): any {
      this.keyword = value.text;
      this.scorp = value.targets;
      this.getListGroups();
    },
    enableOrDisableGroup(groupId: string, isEnable: boolean) {
      return new Promise((resolve) => {
        const data = {
          isValid: isEnable,
        };
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
        .then((response: any) => {
          console.log(response);
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = 'groupIdが存在しない。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = groupId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        });
      });
    },
    putGroup(groupId: any) {
      return new Promise((resolve) => {
        let data;
        data = {
          isValid: false,
        };
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              // this.successMessageLogs = Config.DELETE_API_SUCCESS;
              resolve(true);
            } else {
              const rowstatus = groupId + '>><span>' + Config.ERROR_DEFAULT + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorMessageLogs = '';
            switch (err.status) {
              case 400:
                errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                errorMessageLogs = Config.ERROR_403;
                break;
              case 409:
                errorMessageLogs = '入力されたグループ名は既に使用されています。他のグループ名を入力してください。';
                break;
              case 500:
                errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                errorMessageLogs = Config.ERROR_503;
                break;
              default:
                errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupId + '>><span>' + errorMessageLogs + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          });
      });
    },
    deleteRelationGateGroupItem(relationId: any) {
      return new Promise((resolve) => {
        this.$http.delete(Config.RELATION_GATE_GROUP + '/' + encodeURIComponent(relationId))
          .then((res: any) => {
            if (res.body.message === 'Success') {
              resolve(true);
            } else {
              const rowstatus = relationId + '>><span>' + Config.ERROR_DEFAULT + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorMessageLogs = '';
            switch (err.status) {
              case 400:
                errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorMessageLogs = Config.ERROR_403;
                } else {
                  errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                errorMessageLogs = Config.ERROR_503;
                break;
              default:
                errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = relationId + '>><span>' + errorMessageLogs + '</span>';
            if (err.status === 404) {
              resolve(true);
            } else {
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          });
      });
    },
    deleteRelationGateGroup(groupId: any) {
      return new Promise((resolve) => {
        this.$http.get(Config.RELATION_GATE_GROUP + '?groupId=' + encodeURIComponent(groupId) + '&isExceptOptionalGate=true')
          .then(async (response: any) => {
            if (response.body.maxRecords > 0) {
              // const relationId = response.body.relationsGateGroup[0].relationId;
              let result;
              for (const item of response.body.relationsGateGroup) {
                result = await this.deleteRelationGateGroupItem(item.relationId);
                if (!result) {
                  resolve(false);
                }
              }
              resolve(true);
            } else {
              const rowstatus = groupId + '>><span>' + Config.ERROR_404 + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorMessageLogs = '';
            switch (err.status) {
              case 400:
                errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorMessageLogs = Config.ERROR_403;
                } else {
                  errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                errorMessageLogs = Config.ERROR_503;
                break;
              default:
                errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupId + '>><span>' + errorMessageLogs + '</span>';
            if (err.status === 404) {
              resolve(true);
            } else {
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          }); // catch
      });
    },
    deleteGroupItem(groupPassageId: any) {
      return new Promise((resolve) => {
        this.$http.delete(Config.GROUP_PASSAGE + '/' + encodeURIComponent(groupPassageId))
          .then((res: any) => {
            if (res.body.message === 'Success') {
              resolve(true);
            } else {
              const rowstatus = groupPassageId + '>><span>' + Config.ERROR_DEFAULT + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupPassageId + '>><span>' + errorName + '</span>';
            if (err.status === 404) {
              resolve(true);
            } else {
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          }); // catch
      });
    },
    deleteGroup(groupId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.GROUP_PASSAGE + '?groupId=' + encodeURIComponent(groupId))
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            // const groupPassageId = response.body.groupPassages[0].groupPassageId;
            let result;
            for (const item of response.body.groupPassages) {
              result = await this.deleteGroupItem(item.groupPassageId);
              if (!result) {
                resolve(false);
              }
            }
            resolve(true);
          } else {
            const rowstatus = groupId + '>><span>' + Config.ERROR_404 + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = groupId + '>><span>' + errorName + '</span>';
          if (err.status === 404) {
            resolve(true);
          } else {
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }
          }); // catch
      });
    },

    removeGateFromGroup(relationId: string, gateId: string) {
      return new Promise((resolve) => {
        this.$http.delete(Config.RELATION_GATE_GROUP +
          '/' + encodeURIComponent(relationId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = gateId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        }); // catch
      });
    },

    removeUserFromGroup(groupPassageId: string, userId: string) {
      return new Promise((resolve) => {
        this.$http.delete(Config.GROUP_PASSAGE +
          '/' + encodeURIComponent(groupPassageId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = '該当するグループがありません。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = userId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        }); // catch
      });
    },
    ShowOnlyIsVaild(data: any) {
      localStorage.setItem('isVaildDataGroup', data.toString());
      this.getListGroups();
    },
    getListGroups(): any {
      this.isGetDataList = true;
      this.removeGroupSelected();
      let apiLink = Config.GROUP_LIST_API + '?limit=' + localStorage.pageSizeGroup +
      '&page=' + this.currPage + '&sortKey=' +
      localStorage.groupSortName + ':' + localStorage.groupSortKey;
      if (this.keyword !== null && this.keyword !== undefined && this.keyword !== '') {
        apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
      }
      if (localStorage.isVaildDataGroup === 'true') {
        apiLink += '&isValid=true';
      }
      apiLink += '&embed=passableTimeSetting&isExceptOptionalGate=true';
      this.$http.get(apiLink)
        .then((response: any) => {
          this.resetData();
          if (!this.isEmpty(response.body.groups)) {
            this.dataGroups = response.body.groups;
            this.groupTotalCount = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
          } else {
            this.groupTotalCount = 0;
            this.currPage = 1;
          }
          this.isGetDataList = false;
        })
        .then( null, (err: any) => {
          this.isGetDataList = false;
          this.resetData();
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataGroups = [];
      this.errorMessageLogs = '';
    },

    changeExpandedGroup(event: any) {
      if  (event.value) {
        const data = event.item;

        this.groupExpanedGate = new Map();
        const gate: GroupGate = {
          isGetDataGate: false,
          currPageGate: 1,
          totalGateCount: 0,
          data: [],
        };
        this.groupExpanedGate.set(data.groupId, gate);
        this.getGateOfGroup(data.groupId, 1);

        this.groupExpanedUser = new Map();
        const user: GroupUser = {
          isGetDataUser: false,
          currPageUser: 1,
          totalUserCount: 0,
          data: [],
        };
        this.groupExpanedUser.set(data.groupId, user);
        this.getUserOfGroup(data.groupId, 1);
      }
    },

    getGateOfGroup(groupId: string, page: number) {
      const data = this.groupExpanedGate.get(groupId);
      if (data !== undefined) {
        data.isGetDataGate = true;
        data.currPageGate = page;
        this.reRenderGate();
        const apiLink = Config.RELATION_GATE_GROUP +
          '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=' + page + '&sortKey=desc:gateId&embed=gate&isExceptOptionalGate=true';
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.relationsGateGroup)) {
              data.totalGateCount = response.body.maxRecords;
              data.currPageGate = Math.ceil(response.body.startRecord / 25);
              data.data = response.body.relationsGateGroup;
            } else {
              data.totalGateCount = 0;
              data.currPageGate = 1;
            }
            data.isGetDataGate = false;
            this.reRenderGate();
            this.removegateSelected();
          })
          .then( null, (err: any) => {
            data.isGetDataGate = false;
            this.reRenderGate();
            this.removegateSelected();
          }); // catch
      }
    },
    changePageGate(event: any) {
      this.getGateOfGroup(event.groupId, event.page);
    },

    getUserOfGroup(groupId: string, page: number) {
      const data = this.groupExpanedUser.get(groupId);
      if (data !== undefined) {
        data.isGetDataUser = true;
        data.currPageUser = page;
        this.reRenderUser();
        const apiLink = Config.GROUP_PASSAGE +
          '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=' + page + '&sortKey=desc:userId&embed=user';
        this.$http.get(apiLink)
          .then((response: any) => {
            // response.body = {
            //   message: 'Success',
            //   statementId: 'DEFA7140-1DEC-4642-BAA2-BB0B5B74D212',
            //   startRecord: 1,
            //   endRecord: 1,
            //   maxRecords: 100,
            //   groupPassages: [
            //     {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E42',
            //       user: {
            //         userId: 'zpsHmwSwxfSw1',
            //         name: 'User 1',
            //       },
            //     },
            //   {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E43',
            //       user: {
            //         userId: 'zpsHmwSwxfSw2',
            //         name: 'User 2',
            //       },
            //     },
            //   {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E44',
            //       user: {
            //         userId: 'zpsHmwSwxfSw3',
            //         name: 'User 3',
            //       },
            //     },
            //   {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E45',
            //       user: {
            //         userId: 'zpsHmwSwxfSw4',
            //         name: 'User 4',
            //       },
            //     },
            //   {
            //       groupPassageId: 'EA2CE971-F55F-45A6-835B-DE8C5D526E46',
            //       user: {
            //         userId: 'zpsHmwSwxfSw5',
            //         name: 'User 5',
            //       },
            //     },
            //   ],
            // };
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.groupPassages)) {
              data.totalUserCount = response.body.maxRecords;
              data.currPageUser = Math.ceil(response.body.startRecord / 25);
              data.data = response.body.groupPassages;
            } else {
              data.totalUserCount = 0;
              data.currPageUser = 1;
            }
            data.isGetDataUser = false;
            this.reRenderUser();
            this.removeUserSelected();
          })
          .then( null, (err: any) => {
            data.isGetDataUser = false;
            this.reRenderUser();
            this.removeUserSelected();
          }); // catch
      }
    },
    changePageUser(event: any) {
      this.getUserOfGroup(event.groupId, event.page);
    },

    reRenderGate() {
      if (this.isReRenderGate < 100) {
          this.isReRenderGate++;
        } else {
          this.isReRenderGate = 0;
        }
    },

    reRenderUser() {
      if (this.isReRenderUser < 100) {
          this.isReRenderUser++;
        } else {
          this.isReRenderUser = 0;
        }
    },

    removegateSelected() {
      if (this.isRemoveGateSelected < 100) {
        this.isRemoveGateSelected++;
      } else {
        this.isRemoveGateSelected = 0;
      }
    },
    removeUserSelected() {
      if (this.isRemoveUserSelected < 100) {
        this.isRemoveUserSelected++;
      } else {
        this.isRemoveUserSelected = 0;
      }
    },
    removeGroupSelected() {
      if (this.isRemoveGroupSelected < 100) {
        this.isRemoveGroupSelected++;
      } else {
        this.isRemoveGroupSelected = 0;
      }
    },
  },
});
