var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-card-add-form" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-form",
            { ref: "CardAddForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  _c("input-form", {
                    attrs: {
                      title: "カード番号 <span class='required'>※必須</span>",
                      inputType: "text",
                      disabled: _vm.isEdit,
                      rules: [
                        _vm.rulesValidate.requiredPcodeId,
                        _vm.rulesValidate.validatePcodeId(
                          _vm.cardDetail.pCodeId,
                          _vm.cardDetail.type
                        )
                      ]
                    },
                    model: {
                      value: _vm.cardDetail.pCodeId,
                      callback: function($$v) {
                        _vm.$set(_vm.cardDetail, "pCodeId", $$v)
                      },
                      expression: "cardDetail.pCodeId"
                    }
                  }),
                  _vm._v(" "),
                  _c("input-form", {
                    attrs: { title: "名称", inputType: "text" },
                    model: {
                      value: _vm.cardDetail.name,
                      callback: function($$v) {
                        _vm.$set(_vm.cardDetail, "name", $$v)
                      },
                      expression: "cardDetail.name"
                    }
                  }),
                  _vm._v(" "),
                  _c("input-form", {
                    attrs: {
                      title: "カード種別",
                      inputType: "select",
                      "select-items": _vm.typeItems,
                      disabled: _vm.isEdit
                    },
                    model: {
                      value: _vm.cardDetail.type,
                      callback: function($$v) {
                        _vm.$set(_vm.cardDetail, "type", $$v)
                      },
                      expression: "cardDetail.type"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-subheader", [
                                _vm._v("\n            ユーザー\n          ")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.userItems,
                              color: "green",
                              "return-object": "",
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                              "deletable-chips": "",
                              "small-chips": "",
                              "no-data-text": "",
                              disabled: _vm.userItems.length === 0,
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.cardDetail.userId,
                              callback: function($$v) {
                                _vm.$set(_vm.cardDetail, "userId", $$v)
                              },
                              expression: "cardDetail.userId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c("v-col", { attrs: { cols: "2" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "v-subheader justify-start theme--light"
                              },
                              [_vm._v("ステータス")]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-switch", {
                            staticClass: "v-input--reverse swicth-all",
                            attrs: {
                              color: "green",
                              "hide-details": "auto",
                              inset: "",
                              label: _vm.$vuetify.breakpoint.mdAndUp
                                ? _vm.cardDetail.isValid
                                  ? "ON"
                                  : "OFF"
                                : _vm.cardDetail.isValid
                                ? "ステータス ON"
                                : "ステータス OFF"
                            },
                            model: {
                              value: _vm.cardDetail.isValid,
                              callback: function($$v) {
                                _vm.$set(_vm.cardDetail, "isValid", $$v)
                              },
                              expression: "cardDetail.isValid"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.isEdit === true
                ? _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "messageRow",
                          attrs: { cols: "11", align: "right" }
                        },
                        [
                          _vm.successMessageLogs !== "" && _vm.loading === false
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-2 bg-success bg-no"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.successMessageLogs) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageLogs !== "" && _vm.loading === false
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-2 bg-error bg-no"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.errorMessageLogs) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "messageRow",
                          attrs: { cols: "1", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                disabled:
                                  _vm.cardDetail.pCodeId === null ||
                                  _vm.cardDetail.pCodeId === undefined ||
                                  _vm.cardDetail.pCodeId === "",
                                outlined: ""
                              },
                              on: { click: _vm.updateCard }
                            },
                            [_vm._v("\n                登録\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "messageRow",
                          attrs: { cols: "11", align: "right" }
                        },
                        [
                          _vm.successMessageLogs !== "" && _vm.loading === false
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-2 bg-success bg-no"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.successMessageLogs) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageLogs !== "" && _vm.loading === false
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-2 bg-error bg-no"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.errorMessageLogs) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "messageRow",
                          attrs: { cols: "1", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: { click: _vm.checkCard }
                            },
                            [_vm._v("\n            登録\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "600", persistent: "" },
              model: {
                value: _vm.isHasLowerCaseCardNumber,
                callback: function($$v) {
                  _vm.isHasLowerCaseCardNumber = $$v
                },
                expression: "isHasLowerCaseCardNumber"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "modal-export" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [_vm._v("カード登録")]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "pa-5" }, [
                    _c("div", { staticClass: "bock-info" }, [
                      _c("div", { staticClass: "alert alert-info col" }, [
                        _vm._v(
                          "\n              カード番号に半角英字の小文字が含まれております。"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n              小文字が含まれているとカードが正常に読み込まれません。"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n              小文字が含まれているかを確認してください。\n            "
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: { click: _vm.closeDialogConfirm }
                            },
                            [_vm._v("見直す\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary btn-red",
                              attrs: { outlined: "" },
                              on: { click: _vm.saveCard }
                            },
                            [
                              _vm._v(
                                "小文字が含まれていても登録する\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }