












































































import Vue, { PropType } from 'vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import { PassableTime } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';

export interface ActionItem {
  title: string;
}
export interface DataType {
  tab: null;
  selectedItem: PassableTime[];
  searchTargetItems: SearchTargetItem[];
}

export default Vue.extend({
  name: 'VerifyLogList',
  components: {
    ShowPerPage,
    Pagination,
    Searchbox,
  },
  props: {
    data: {
      type: Array as PropType<PassableTime[]>,
      default: () => [],
    },
    gatePassageTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    tab: null,
    selectedItem: [],
    searchTargetItems: [
      { label: 'ユーザ名', value: 'name' },
      { label: 'メールアドレス', value: 'email' },
    ],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'timeSetting',
          },
          {
            text: '詳細',
            align: 'start',
            sortable: true,
            value: 'isValid',
          },
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'day',
          },
          {
            text: '詳細',
            align: 'start',
            sortable: true,
            value: 'item4',
          },
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'item5',
          },
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'item5',
          },
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'item6',
          },
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'item7',
          },
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'item8',
          },
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'item9',
          },
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'item10',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
  },
});
