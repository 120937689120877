import Vue from 'vue';
import * as Config from '@/config';
import { User } from '@/types/alligate';

/**
 * 全ユーザを取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<User[]>} 取得した全ユーザ
 */
export async function getAllUsers(vue: Vue): Promise<User[]> {
  try {
    const users = await getAllUsersIter(vue, 1);
    return users;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('ユーザ情報の取得に失敗しました。ユーザ情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全ユーザを取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<User[]>} 取得した全ユーザ
 */
async function getAllUsersIter(vue: Vue, page: number): Promise<User[]> {
  const query = `?limit=1000&exclude=thumbnail&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.USERS_LIST_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.users;
    } else {
      const nextUsers = await getAllUsersIter(vue, page + 1);
      return response.data.users.concat(nextUsers);
    }
  } catch (err) {
    throw err;
  }
}

/**
 * ユーザIDを指定して取得する
 * @param {Vue} vue Vueインスタンス
 * @param {string} userId ユーザID
 * @return {Promise<User>} 取得したユーザ
 */
export async function getUser(vue: Vue, userId: string): Promise<User> {
  const query = `?userId=${encodeURIComponent(userId)}`;
  try {
    const response = await vue.$axios.get(Config.USERS_LIST_API + query);
    return response.data.users[0];
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('ユーザ情報の取得に失敗しました。ユーザ情報を取得する権限がありません。'));
      case 404:
        throw(new Error('指定されたユーザは存在しません。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}
