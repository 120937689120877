var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-users-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c("v-row", { staticClass: "ml-0", attrs: { justify: "start" } }, [
            _c(
              "div",
              { staticClass: "mt-4 mb-3 mr-9 ml-5" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-secondary",
                        attrs: { outlined: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("backUserList", $event)
                          }
                        }
                      },
                      [_vm._v("\n          戻る\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.loading === false
            ? _c(
                "v-row",
                { staticClass: "mt-5", attrs: { justify: "start" } },
                [
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length > 0 &&
                  _vm.pCodeAddFail.length > 0 &&
                  _vm.gateAddFail.length > 0
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          this.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_1.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    )
                                      .replace(
                                        "GATE_NAME",
                                        _vm.gateAddFail.toString()
                                      )
                                      .replace(
                                        "CARD_NAME",
                                        _vm.pCodeAddFail.toString()
                                      )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_1.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    )
                                      .replace(
                                        "GATE_NAME",
                                        _vm.gateAddFail.toString()
                                      )
                                      .replace(
                                        "CARD_NAME",
                                        _vm.pCodeAddFail.toString()
                                      )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length > 0 &&
                  _vm.pCodeAddFail.length === 0 &&
                  _vm.gateAddFail.length === 0
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          this.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_2.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_2.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length === 0 &&
                  _vm.pCodeAddFail.length === 0 &&
                  _vm.gateAddFail.length > 0
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          this.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_3.replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_3.replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length === 0 &&
                  _vm.pCodeAddFail.length > 0 &&
                  _vm.gateAddFail.length === 0
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          this.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_4.replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_4.replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length === 0 &&
                  _vm.pCodeAddFail.length > 0 &&
                  _vm.gateAddFail.length > 0
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          this.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_5.replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    ).replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_5.replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    ).replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length > 0 &&
                  _vm.pCodeAddFail.length > 0 &&
                  _vm.gateAddFail.length === 0
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          this.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_6.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    ).replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_6.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    ).replace(
                                      "CARD_NAME",
                                      _vm.pCodeAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length > 0 &&
                  _vm.pCodeAddFail.length === 0 &&
                  _vm.gateAddFail.length > 0
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          this.isEdit
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_EDIT_USER_7.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    ).replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    )
                                  )
                                }
                              })
                            : _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Message.ERROR_ADD_USER_7.replace(
                                      "GROUP_NAME",
                                      _vm.groupsAddFail.toString()
                                    ).replace(
                                      "GATE_NAME",
                                      _vm.gateAddFail.toString()
                                    )
                                  )
                                }
                              })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successMessageLogs !== "" &&
                  _vm.groupsAddFail.length === 0 &&
                  _vm.pCodeAddFail.length === 0 &&
                  _vm.gateAddFail.length === 0
                    ? _c(
                        "v-col",
                        {
                          staticClass: "mb-4 font-weight-bold mt-1 bg-success"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.successMessageLogs) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errorMessageLogs !== ""
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.errorMessageLogs) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer", { staticClass: "mt-10" }),
          _vm._v(" "),
          _c("UserAddForm", {
            attrs: {
              loading: _vm.loading,
              isEdit: _vm.isEdit,
              isDisabledGateOption: _vm.isDisabledGateOption,
              gateList: _vm.gateList,
              isDisabledGroupOptions: _vm.isDisabledGroupOptions,
              isDisabledPCodeOptions: _vm.isDisabledPCodeOptions,
              gateGroupOptions: _vm.gateGroupOptions,
              phoneItems: _vm.phoneItems,
              pCodeOptions: _vm.pCodeOptions,
              userInfo: _vm.userData,
              listGatePassages: _vm.listGatePassages,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              groupsAddFail: _vm.groupsAddFail,
              pCodeAddFail: _vm.pCodeAddFail,
              gateAddFail: _vm.gateAddFail
            },
            on: {
              getListGate: function($event) {
                return _vm.$emit("getListGate")
              },
              clickSaveUser: function($event) {
                return _vm.$emit("clickSaveUser", $event)
              },
              clickUpdateUser: function($event) {
                return _vm.$emit("clickUpdateUser", $event)
              },
              "init-data": function($event) {
                return _vm.$emit("init-data", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }