



























import Vue, { PropType } from 'vue';
import ControlSettingForm from '@/components/organisms/ControlSettingForm/ControlSettingForm.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
export interface DataType {
  breadcrumbs: BreadcrumbItem[];
}
export default Vue.extend({
  name: 'ControlSettingTemplate',
  components: {
    ControlSettingForm,
    Breadcrumbs,
    IconButton,
  },
  props: {

  },
  data: (): DataType => ({
    breadcrumbs: [
      { text: 'Home', to: '/home' },
      { text: 'ゲート', to: '/gates' },
      { text: '制御盤設定', disabled: true },
    ],
  }),
});
