























import Vue from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import * as Config from '@/config';
interface TypeSelect {
  text: string;
  value: string;
}
export default Vue.extend({
  name: 'PersonalSettingForm',
  components: {
    SuccessButton,
    InfoItem,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    userData: {
      default: () => [],
    },
  },
  computed: {
  },
  methods: {
    editUser(value: any) {
      this.$router.push({ path: Config.USER_EDIT, query: {userId: value, goBack: 'personal'}});
    },
  },
});
