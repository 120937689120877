var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card-detail-template", {
    attrs: {
      loading: _vm.loading,
      errorMessageLogs: _vm.errorMessageLogs,
      cardDetail: _vm.cardDetail
    },
    on: {
      clickBackCard: function($event) {
        return _vm.clickBackCard($event)
      },
      clickEditCard: function($event) {
        return _vm.clickEditCard($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }