



























































import Vue, { PropType } from 'vue';

export interface TimelineItem {
  id: string;
  title: string;
  timelines: Array<{
    start?: string;
    end?: string;
  }>;
}
export interface Tooltip {
  [name: string]: {
    title: string;
    text: string;
  };
}

interface TableLineItem {
  id: string;
  title: string;
  colors: string[]; // 下記のNONE, TIME. BETWEENのいずれか
  tooltipText: string[];
}

const NONE = '#FFF';
const TIME = '#2E7D32';
const BETWEEN = '#66BB6A';

export interface DataType {
  NONE: string;
  tableHeader: string[];
}

export default Vue.extend({
  name: 'TimelineTable',
  props: {
    // 前提：ソートされている
    value: {
      type: Array as PropType<TimelineItem[]>,
      default: () => [],
    },
    noDataMessage: {
      type: String,
      default: 'データがありません',
    },
    endHour: {
      type: Number,
      default: 24,
    },
    startTooltipMessage: {
      type: String,
      default: '入室',
    },
    endTooltipMessage: {
      type: String,
      default: '退室',
    },
    betweenTooltipMessage: {
      type: String,
      default: '滞在中',
    },
  },
  data: (): DataType => ({
    NONE,
    tableHeader: ['0時', '', '', '', '', '', '6時', '', '', '', '', '', '12時', '', '', '', '', '', '18時', '', '', '', '', '', '24時'],
  }),
  computed: {
    tableLineItems: {
      get(): TableLineItem[] {
        const ret: TableLineItem[] = this.value.map((item) => {
          const data: TableLineItem = {
            id: item.id,
            title: item.title,
            colors: new Array<string>(24).fill(NONE),
            tooltipText: new Array<string>(24).fill(''),
          };
          const times = item.timelines.map((timeline) => {
            return {
              start: timeline.start ? Number(timeline.start.split(':')[0]) : null,
              startTime: timeline.start,
              end: timeline.end ? Number(timeline.end.split(':')[0]) : null,
              endTime: timeline.end,
            };
          })
          .filter((timeline) => timeline.start !== undefined || timeline.end !== undefined);

          // dataのcolorsを埋めていく
          times.forEach((time, index) => {
            if (time.start !== null && time.start >= 0 && time.start < 24) {
              data.colors[time.start] = TIME;
              data.tooltipText[time.start] += (data.tooltipText[time.start] !== '' ? '\n' : '');
              data.tooltipText[time.start] += `${time.startTime} ${this.startTooltipMessage}`;
            }
            if (time.end !== null && time.end >= 0 && time.end < 24) {
              data.colors[time.end] = TIME;
              data.tooltipText[time.end] += (data.tooltipText[time.end] !== '' ? '\n' : '');
              data.tooltipText[time.end] += `${time.endTime} ${this.endTooltipMessage}`;
            }
            let start = time.start;
            if (start === null) {
              // 一番最初のstartならば最初から埋める
              if (index === 0) {
                start = -1;
              }
            }
            let end = time.end;
            if (end === null) {
              // 一番最後のendならば最後まで埋める
              if (index === times.length - 1) {
                end = this.endHour;
              }
            }
            if (start !== null && end !== null) {
              for (let i = start + 1; i < Math.min(end, this.endHour); i++) {
                data.colors[i] = BETWEEN;
                data.tooltipText[i] = this.betweenTooltipMessage;
              }
            }
          });
          return data;
        });
        return ret;
      },
    },
  },
});
