import Vue from 'vue';
import * as Config from '@/config';
import { NoticeSettingType } from '@/types/alligate';

/**
 * 通知設定分類を取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<User[]>} 取得した全通知設定タイプ
 */
export async function getNoticeSettingType(vue: Vue): Promise<NoticeSettingType[]> {
  try {
    const noticeSettingTypes = await getAllNoticeSettingTypesIter(vue, 1);
    return noticeSettingTypes;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('情報の取得に失敗しました。通知設定情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全通知設定分類を取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<NoticeSettingType[]>} 取得した全通知設定
 */
async function getAllNoticeSettingTypesIter(vue: Vue, page: number): Promise<NoticeSettingType[]> {
  const query = `?`;
  try {
    const response = await vue.$axios.get(Config.NOTICE_SETTING_TYPE_LIST_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.noticeSettingTypes;
    } else {
      const nextNoticeSettingTypes = await getAllNoticeSettingTypesIter(vue, page + 1);
      return response.data.noticeSettingTypes.concat(nextNoticeSettingTypes);
    }
  } catch (err) {
    throw err;
  }
}
