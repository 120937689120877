















































































































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import moment from 'moment';
export interface DataType {
  selectedItem: any;
}

export default Vue.extend({
  name: 'UserGateTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
  },
  props: {
    isUserOfGate: {
      default: false,
    },
    isShowConfirm: {
      default: false,
    },
    userOfGate: {
      type: Array,
      default: () => [],
    },
    gateId: {
      default: '',
    },
    groupId: {
      default: '',
    },
    pageUser: {
      type: Number,
      default: 1,
    },
    userTotalCount: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    userTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    errorMessageProcess: {
      type: Array,
      default: () => [],
    },
  },
  data: (): DataType => ({
    selectedItem: [],
  }),
  computed: {
    innerPageUser: {
      get(): number {
        return this.pageUser;
      },
      set(val: number): void {
        this.$emit('change-page-user', {page: val});
        return;
      },
    },
    headersUser: {
      get(): DataTableHeader[] {
        const headersUser: DataTableHeader[] = [
          {
            text: 'ユーザーID		',
            align: 'start',
            sortable: false,
            value: 'user.userId',
          },
          {
            text: 'ユーザー名		',
            align: 'start',
            sortable: false,
            value: 'user.name',
          },
          {
            text: 'ふりがな',
            align: 'start',
            sortable: false,
            value: 'user.furigana',
          },
          {
            text: 'ステータス',
            align: 'start',
            sortable: false,
            value: 'isValid',
          },
          {
            text: '開始日時	',
            align: 'start',
            sortable: false,
            value: 'notBefore',
          },
          {
            text: '終了日時',
            align: 'start',
            sortable: false,
            value: 'notAfter',
          },
        ];
        return headersUser;
      },
      set(): void {
        return;
      },
    },
  },
  watch: {
    isReRenderUser(value) {
      console.log(value);
      this.$forceUpdate();
    },
    isRemoveUserSelected(value) {
      this.selectedItem = [];
    },
    selectedItem(value: any) {
      this.$emit('select-user', value.map((item: { passageId: any; }) => item.passageId));
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
        return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'user.name') {
          if (!isDescending[0]) {
              return (a.user.name < b.user.name) ? -1 : 1;
          } else {
              return (b.user.name < a.user.name) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    converDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm:ss');
    },
    changePage(val: any) {
      this.$emit('change-page-user', {page: val, groupId: this.groupId});
    },
    clickJoinUser(ID: any) {
      this.$router.push({ path: Config.GATE_ADD_USER_GROUP, query: {groupId: ID, goBack: 'gates'}});
    },
    clickDetail(ID: string) {
      this.$router.push({ path: Config.USER_DETAIL, query: {userId: ID, goBack: 'gates'}});
    },
  },
});
