var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-display-option" },
    [
      _vm.isShowOption
        ? _c(
            "v-card",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: {
                    handler: _vm.onClickOutsideOption,
                    include: _vm.included
                  },
                  expression:
                    "{ handler: onClickOutsideOption, include: included}"
                }
              ],
              staticClass: "display-option-contents mr-0 ml-5 mt-1",
              attrs: { color: "white" }
            },
            [
              _c("v-card-text", { staticClass: "pb-0" }, [
                _c("div", { staticClass: "font-weight-black" }, [
                  _vm._v("\n        表示項目: \n      ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap" },
                  _vm._l(_vm.displayOptionItems, function(item) {
                    return _c("v-checkbox", {
                      key: item.value,
                      staticClass: "mt-1 mr-10 allig-custom-search-checkbox",
                      attrs: {
                        label: item.label,
                        value: item.value,
                        color: "grey",
                        dense: "",
                        "hide-details": "",
                        disabled: item.isDisabled
                      },
                      model: {
                        value: _vm.innerValue,
                        callback: function($$v) {
                          _vm.innerValue = $$v
                        },
                        expression: "innerValue"
                      }
                    })
                  }),
                  1
                )
              ]),
              _vm._v(" "),
              _vm.isVaildOnlyShow
                ? _c("v-card-text", { staticClass: "pt-0" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap" },
                      [
                        _c("v-checkbox", {
                          staticClass:
                            "mt-1 mr-10 allig-custom-search-checkbox",
                          attrs: {
                            label: "有効なデーターのみ表示",
                            color: "grey",
                            dense: "",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.isValid,
                            callback: function($$v) {
                              _vm.isValid = $$v
                            },
                            expression: "isValid"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isLog !== "" &&
              (_vm.isLog === "gate" || _vm.isLog === "operation")
                ? _c("v-card-text", { staticClass: "pt-0" }, [
                    _c("div", { staticClass: "d-flex flex-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "allig-per-page-select-page" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("div", { staticClass: "mr-4 lable-page" }, [
                                _vm._v(
                                  "\n        ページことに表示する項目: \n      "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-select", {
                                staticClass: "input-search",
                                attrs: {
                                  color: "gray",
                                  "item-color": "gray",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  items: _vm.items,
                                  "item-text": "text",
                                  "item-value": "value"
                                },
                                model: {
                                  value: _vm.perPageValue,
                                  callback: function($$v) {
                                    _vm.perPageValue = $$v
                                  },
                                  expression: "perPageValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }