var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-user-table" },
    [
      _c(
        "v-container",
        { staticClass: "mt-1" },
        [
          _c(
            "v-row",
            [
              _vm.successMessageLogs !== "" && _vm.loading === false
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-success"
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.successMessageLogs) + "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.errorMessageLogs !== "" && _vm.loading === false
                ? _c(
                    "v-col",
                    { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.errorMessageLogs) + "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "pa-6 pr-10 mb-5" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-subtitle-2 border-left-blue font-weight-bold"
                    },
                    [_vm._v("\n        ユーザー情報\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "ユーザーID", text: _vm.user.userId }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "ユーザー名", text: _vm.user.name }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "ふりがな", text: _vm.user.furigana }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "メールアドレス", text: _vm.user.email }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-item ml-5" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [_c("v-subheader", [_vm._v("グループ")])],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: { "align-self": "center" }
                        },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.tempGroupPassages, function(item) {
                              return _c(
                                "v-col",
                                {
                                  key: item.groupPassageId,
                                  attrs: { md: "2" }
                                },
                                [
                                  item.group !== undefined
                                    ? _c("text-link", {
                                        attrs: { text: item.group.groupName },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickGroup(
                                              _vm.user.userId,
                                              item.group.groupId
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-item ml-5" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [_c("v-subheader", [_vm._v("カード")])],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: { "align-self": "center" }
                        },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.cardItems, function(item) {
                              return _c(
                                "v-col",
                                {
                                  key: item.value,
                                  attrs: { md: "2", "align-self": "center" }
                                },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-item ml-5" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [_c("v-subheader", [_vm._v("スマートフォン")])],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: { "align-self": "center" }
                        },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.phoneItems, function(phone) {
                              return _c(
                                "v-col",
                                {
                                  key: phone.value,
                                  attrs: { md: "2", "align-self": "center" }
                                },
                                [
                                  phone.text.startsWith("申請中 : ")
                                    ? _c(
                                        "span",
                                        { staticClass: "applying-color" },
                                        [_vm._v(_vm._s(phone.text))]
                                      )
                                    : _c("span", [_vm._v(_vm._s(phone.text))])
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "権限",
                  text: _vm.user.isAdmin ? "管理者" : "一般ユーザ"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "ゲート設定権限",
                  text: _vm.user.isPhoneAdmin ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "アプリへのログイン",
                  text: _vm.user.isPasswordLoginValid ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "Web管理画面ログイン時のSMS認証",
                  text: _vm.user.isMfaSms ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "電話番号", text: _vm.user.phoneNumber }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-item ml-5" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "v-subheader",
                                [
                                  _vm._v(
                                    "解錠時の二段階認証パスワード\n        "
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-help-circle")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2569386827
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          " 二段階認証パスワードは、カード＋テンキーによる二段階の解錠用パスワードです。"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "Lock Proでご利用いただくには、リーダーの設定変更が必要です。"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: { "align-self": "center" }
                        },
                        [
                          _vm._t("text", [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.user.passCode) +
                                " \n      "
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "鍵共有権限",
                  text: _vm.user.shareKeyBeValid ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "備考.1", text: _vm.user.option1 }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "備考.2", text: _vm.user.option2 }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-subtitle-2 border-left-blue font-weight-bold"
                    },
                    [_vm._v("期限付き解錠可能ゲート")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "mt-5 border-table",
                attrs: {
                  "must-sort": "",
                  "items-per-page": -1,
                  headers: _vm.headerGateTable,
                  items: _vm.listGatePassages,
                  "item-key": "gateId",
                  "no-data-text": "該当するゲートがありません",
                  "loading-text": "データを読み込中です",
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.gateId",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v("\n      " + _vm._s(item.gateName) + "\n    ")
                        ]
                      }
                    },
                    {
                      key: "item.time",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            "\n      " +
                              _vm._s(
                                _vm.formatDateTime(
                                  new Date(item.notBefore * 1000)
                                )
                              ) +
                              " ~ " +
                              _vm._s(
                                _vm.formatDateTime(
                                  new Date(item.notAfter * 1000)
                                )
                              ) +
                              "\n    "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }