


































































import {Vue } from 'vue-property-decorator';
import GateLockAddTemplate from '@/components/templates/GateLockAddTemplate/GateLockAddTemplate.vue';
import * as Config from '@/config';
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface DataType {
  loading: boolean;
  successMessageLogs: string;
  errorMessageLogs: string;
  gateDetail: any;
  listGateModel: any;
  dataUnlockingTime: any;
  groupId: any;
  dayTypes: any;
  relationsGateGroup: any;
  groupItemsOrigin: any;
  groupsAddFail: any;
  userOfGate: any;
  listAntiPassGates: any;
  isFinishDetailGate: boolean;
  isEdit: boolean;
  goBack: string;
  keyword: string;
  successMessageModal: string;
  errorMessageModal: string;
  showModal: boolean;
  showConfirm: boolean;
  isUserOfGate: boolean;
  isGroupOfGate: boolean;
  currPage: number;
  gateListPages: number;
  totalGate: number;
  currPageGroup: number;
  currPageUser: number;
  groupTotalCount: number;
  userTotalCount: number;
  isShowConfirmUser: boolean;
  showProgressUser: boolean;
  userTotalCountDel: number;
  totalDataRowProcessUser: number;
  progressUser: number;
  successItemProcessUser: number;
  errorMessageProcessUser: any;
  isFinishProcessUser: boolean;
  rowinErrDialog: number;
  isShowConfirmGroup: boolean;
  showProgressGroup: boolean;
  groupTotalCountDel: number;
  totalDataRowProcessGroup: number;
  progressGroup: number;
  successItemProcessGroup: number;
  errorMessageProcessGroup: any;
  isFinishProcessGroup: boolean;
}
export default Vue.extend({
  name: 'GateLockAddPage',
  components: {
    GateLockAddTemplate,
  },
  data: (): DataType => ({
    loading: false,
    isGroupOfGate: false,
    showConfirm: false,
    successMessageLogs: '',
    errorMessageLogs: '',
    gateDetail: {
      gateId: '',
      gateModelCode: '',
      gateModelName: '',
      gateModelId: '',
      gateModel: {},
      iconType: 0,
      makerCode: '',
      customizable: false,
      target: 0, // 0: Alligate Logger, 1: Alligate I/O
      controlTime: 0,
      timeUnit: 0,
      gateName: '',
      isVisitor: false,
      isNFCValid: true,
      isTenKeyValid: true,
      isGatePasswordValid: false,
      gatePasswordLength: 5,
      gatePasswordSeed: '',
      isTwoStepAuthValid: false,
      bleIntensity: '', // デフォルトの値は空欄です
      buzzerPattern: 1,
      option1: '',
      option2: '',
      gateSNMain: '',
      gateSNBLE: '',
      gateFWMain: '',
      gateFWBLE: '',
      registered: '',
      updated: '',
      reported: '',
      revisionDesired: '',
      revisionReported: '',
      voltage: '',
      scanDate: '',
      isValid: true,
      needLocation: false,
      isAntiPassback: false,
      antiPassback: {
        gateId: '', // デフォルトの値は空欄です
        gateName: '',
        mode: 0,
        relationId: 0,
      },
      antiPassbackPrev: {
        relationId: 0,
        gateId: '', // デフォルトの値は空欄です
        gateName: '',
        mode: '',
      },
    },
    listGateModel: [],
    groupsAddFail: [],
    dataUnlockingTime: [],
    groupItemsOrigin: [],
    dayTypes: [],
    isFinishDetailGate: false,
    groupId: '',
    goBack: 'gates',
    showModal: false,
    successMessageModal: '',
    errorMessageModal: '',
    listAntiPassGates: [],
    relationsGateGroup: [],
    userOfGate: [],
    currPage: 1,
    currPageGroup: 1,
    currPageUser: 1,
    keyword: '',
    gateListPages: 0,
    totalGate: 0,
    groupTotalCount: 0,
    userTotalCount: 0,
    isEdit: false,
    isUserOfGate: false,
    isShowConfirmUser: false,
    showProgressUser: false,
    userTotalCountDel: 0,
    totalDataRowProcessUser: 0,
    progressUser: 0,
    successItemProcessUser: 0,
    errorMessageProcessUser: [],
    isFinishProcessUser: false,
    rowinErrDialog: 0,
    isShowConfirmGroup: false,
    showProgressGroup: false,
    groupTotalCountDel: 0,
    totalDataRowProcessGroup: 0,
    progressGroup: 0,
    successItemProcessGroup: 0,
    errorMessageProcessGroup: [],
    isFinishProcessGroup: false,

  }),
  mounted() {
    const dataQuery = this.$route.query;
    if (dataQuery.gateId !== undefined) {
      this.isEdit = true;
      this.loading = true;
      this.listGateModel = [];
      this.getGateData(dataQuery.gateId.toString())
        .then(() => {
          this.getGroupOfGate(1, dataQuery.gateId.toString());
          this.getUserOfGate(1, dataQuery.gateId.toString());
          this.loading = false;
        });
    }
    if (this.$route.query.goBack !== undefined) {
      this.goBack = this.$route.query.goBack.toString();
    }
  },
  created() {
    this.getListGateModel();
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  methods: {
    resetAntiPassGate() {
      this.gateDetail.isAntiPassback = false;
      this.gateDetail.antiPassback = {
        relationId: 0,
        gateId: '', // デフォルトの値は空欄です
        gateName: '',
        mode: 0,
      };
    },
    disableAntiModes() {
      // this method uses to check and enable/disable anti modes (level, gate name)
      if (!this.gateDetail.isAntiPassback) {
        // if anti-pass not enable then reset anti modes
        this.gateDetail.antiPassback.gateId = ''; // デフォルトの値は空欄です
        this.gateDetail.antiPassback.gateName = ''; // デフォルトの値は空欄です
      }
    },
    changePage(value: any): any {
      this.currPage = value.page;
      this.getGates();
    },
    changePageUser(item: any): any {
      this.userOfGate = [];
      this.currPageUser = item.page;
      this.getUserOfGate(item.page, this.$route.query.gateId.toString());
    },
    changePageGroup(item: any): any {
      this.relationsGateGroup = [];
      this.currPageGroup = item.page;
      this.getGroupOfGate(item.page, this.$route.query.gateId.toString());
    },
    searchPage(value: any): any {
      const keyword = value.replace(/\s/g, ',');
      this.keyword = keyword;
      this.getGates();
    },
    setGate(item: any): any {
      this.gateDetail.antiPassback.gateId = item[0].gateId;
      this.gateDetail.antiPassback.gateName = item[0].gateName;
    },
    registerGates(): any {
      this.loading = true;
      let data: any = {};
      data = {
        gateName: this.gateDetail.gateName,
        gateModelId: this.gateDetail.gateModelId,
        gateModelName: this.gateDetail.gateModelName,
        gateModelCode: this.gateDetail.gateModelCode,
        iconType: this.gateDetail.iconType,
        makerCode: this.gateDetail.makerCode,
        customizable: this.gateDetail.customizable,
        target: this.gateDetail.target,
        timeUnit: this.gateDetail.timeUnit,
        controlTime: parseInt(this.gateDetail.controlTime),
        isVisitor: this.gateDetail.isVisitor,
        isNFCValid: this.gateDetail.isNFCValid,
        isTenKeyValid: this.gateDetail.isTenKeyValid,
        isGatePasswordValid: this.gateDetail.isGatePasswordValid,
        isTwoStepAuthValid: this.gateDetail.isTwoStepAuthValid,
        bleIntensity: this.gateDetail.bleIntensity,
        buzzerPattern: this.gateDetail.buzzerPattern,
        option1: this.gateDetail.option1,
        option2: this.gateDetail.option2,
        isValid: this.gateDetail.isValid,
        needLocation: this.gateDetail.needLocation,
      };
      if (this.gateDetail.isGatePasswordValid) {
        data.gatePasswordLength = this.gateDetail.gatePasswordLength;
        if (this.gateDetail.gatePasswordSeed.length > 0) {
          data.gatePasswordSeed = this.gateDetail.gatePasswordSeed;
        }
      }
      console.log(data);
      this.$http.post(Config.GATES_LIST_API, data)
      .then((response: any) => {
          if (this.gateDetail.isAntiPassback) {
            // anti-pass is actived then create anti-pass for this gate
            this.createAntiPassGate(response.body.gateId);
          } else {
            this.loading = false;
            this.successMessageLogs = Config.ADD_GATE_API_SUCCESS;
            this.errorMessageLogs = '';
          }

        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 409:
              this.errorMessageLogs = Config.ERROR_409_GATE;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    editGates(): any {
      this.loading = true;
      let data: any = {};
      data = {
        gateName: this.gateDetail.gateName,
        gateModelId: this.gateDetail.gateModelId,
        gateModelName: this.gateDetail.gateModelName,
        gateModelCode: this.gateDetail.gateModelCode,
        iconType: this.gateDetail.iconType,
        makerCode: this.gateDetail.makerCode,
        customizable: this.gateDetail.customizable,
        target: this.gateDetail.target,
        timeUnit: this.gateDetail.timeUnit,
        controlTime: parseInt(this.gateDetail.controlTime),
        isVisitor: this.gateDetail.isVisitor,
        isNFCValid: this.gateDetail.isNFCValid,
        isTenKeyValid: this.gateDetail.isTenKeyValid,
        isGatePasswordValid: this.gateDetail.isGatePasswordValid,
        isTwoStepAuthValid: this.gateDetail.isTwoStepAuthValid,
        bleIntensity: this.gateDetail.bleIntensity,
        buzzerPattern: this.gateDetail.buzzerPattern,
        option1: this.gateDetail.option1,
        option2: this.gateDetail.option2,
        isValid: this.gateDetail.isValid,
        needLocation: this.gateDetail.needLocation,
      };
      if (this.gateDetail.isGatePasswordValid) {
        data.gatePasswordLength = this.gateDetail.gatePasswordLength;
        if (this.gateDetail.gatePasswordSeed.length > 0) {
          data.gatePasswordSeed = this.gateDetail.gatePasswordSeed;
        }
      }
      console.log(data);
      this.$http.put(Config.GATES_LIST_API + '/' + encodeURIComponent(this.gateDetail.gateId), data)
      .then((response: any) => {
          this.updateAntiPassGate();
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 409:
              this.errorMessageLogs = Config.ERROR_409;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    updateAntiPassGate() {
      this.errorMessageLogs = '';
      if (!this.gateDetail.isAntiPassbackPrev) {
        // if the prev anti-pass infor is in-actived
        if (this.gateDetail.isAntiPassback === true) {
          // the new anti-pass infor is actived, then call to anti-pass insert API
          this.createAntiPassGate(this.gateDetail.gateId);
        } else {
          this.loading = false;
          this.successMessageLogs = Config.EDIT_GATE_API_SUCCESS;
          this.errorMessageLogs = '';
        }
      } else {
        // if the prev anti-pass infor is active, then calling anti-pass delete API
        this.deleteAntiPassGate(this.gateDetail.antiPassbackPrev.relationId);
      }
    },
    deleteAntiPassGate(relationId: any) {
      this.$http.delete(Config.RELATION_ANTIPASS_GATE_API + '/' + encodeURIComponent(relationId))
        .then(async (response: any) => {
          // deleted anti-pass successfully, then call to anti-pass insert API
          if (this.gateDetail.isAntiPassback === true) {
            // check isAntiPassback = true here because if prev is true but current is false
            this.createAntiPassGate(this.gateDetail.gateId);
          } else {
            await this.getGateData(this.gateDetail.gateId);
            this.loading = false;
            this.successMessageLogs = Config.EDIT_GATE_API_SUCCESS;
            this.errorMessageLogs = '';
          }
        })
        .then( null, async (err: any) => {
          await this.getGateData(this.gateDetail.gateId);
          this.successMessageLogs = '';
          this.loading = false;
          this.errorMessageLogs = 'アンチパスバックの更新に失敗しました。<br/>再度アンチパスバックの変更をお願いします。';
        });
    },
    setAntiPassData(antiPassback: any) {
      this.gateDetail.antiPassback.relationId = antiPassback.relationId ? antiPassback.relationId : '';
      this.gateDetail.antiPassback.gateId = antiPassback.gateId;
      this.gateDetail.antiPassback.gateName = antiPassback.gateName;
      this.gateDetail.antiPassback.mode = antiPassback.mode;
      // antiPassbackPrev is the backuped object;;
      this.gateDetail.antiPassbackPrev.relationId = antiPassback.relationId ? antiPassback.relationId : '';
      this.gateDetail.antiPassbackPrev.gateId = antiPassback.gateId;
      this.gateDetail.antiPassbackPrev.gateName = antiPassback.gateName;
      this.gateDetail.antiPassbackPrev.mode = antiPassback.mode;
    },
    createAntiPassGate(gateId: any) {
      const dataQuery = this.$route.query;
      let success = Config.ADD_GATE_API_SUCCESS;
      let error = 'ゲートの新規登録は完了しました。アンチパスバックの登録に失敗しました。<br>ゲート編集より、再度アンチパスバックの変更をお願いします。';
      if (dataQuery.gateId !== undefined) {
        success = Config.EDIT_GATE_API_SUCCESS;
        error = 'アンチパスバックの更新に失敗しました。<br/>再度アンチパスバックの変更をお願いします。';
      }
      const Infor = {
        ownerId: localStorage.ownerId,
        gateIdA: gateId,
        gateIdB: this.gateDetail.antiPassback.gateId,
        mode: this.gateDetail.antiPassback.mode,
      };
      this.$http.post(Config.RELATION_ANTIPASS_GATE_API, Infor)
        .then(async (response: any) => {
          this.successMessageLogs = success;
          this.errorMessageLogs = '';
          await this.getGateData(gateId);
          this.loading = false;
        })
        .then( null, async (err: any) => {
          this.resetAntiPassGate();
          await this.getGateData(gateId);
          this.successMessageLogs = '';
          this.loading = false;
          this.errorMessageLogs = error;
        });
    },
    getGates() {
      this.loading = true;
      this.listAntiPassGates = [];
      this.$http.get(Config.GATES_LIST_API + '?limit=25&page=' +
      this.currPage + '&keyword=' + this.keyword + '&scorp=gateId,gateName' + '&sortKey=desc:registered&isValid=true')
      .then((response: any) => {
          this.totalGate = response.body.maxRecords;
          if (response.body.maxRecords > 0 && response.body.gates.length > 0) {
            this.gateListPages = Math.ceil((response.body.maxRecords) / 25);
            this.listAntiPassGates = response.body.gates;
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          this.loading = true;
        });
    },
    changeInfoGate(agr: any) {
      this.gateDetail.gateModelCode = agr.gateModelCode;
      this.gateDetail.gateModelName = agr.gateModelName;
      this.gateDetail.gateModelId = agr.gateModelId;
      this.gateDetail.controlTime = agr.controlTime;
      this.gateDetail.makerCode = agr.makerCode;
      this.gateDetail.customizable = agr.customizable;
      this.gateDetail.iconType = agr.iconType;
      this.gateDetail.target = agr.target;
      this.gateDetail.timeUnit = agr.timeUnit;
      if (agr.makerCode === 2 || agr.makerCode === 4) {
        this.gateDetail.bleIntensity = 6;
      } else {
        this.gateDetail.bleIntensity = 1;
      }
      console.log( this.gateDetail);
    },
    clickConfirm(data: any) {
      this.successMessageModal  =  '';
      this.errorMessageModal  = '';
      this.showConfirm = true;
    },
    closeModalConfirm(data: any) {
      this.showConfirm = false;
    },
    clickBackGate() {
      this.$router.push({ path: Config.GATE, query: {goBack: 'gate-lock'}});
    },
    getGateData(gateId: string) {
      return new Promise((resolve, reject) => {
        this.errorMessageLogs = '';
        this.$http.get(Config.GATES_LIST_API + '?gateId=' + encodeURIComponent(gateId))
          .then((response: any) => {
            if (response.body.maxRecords > 0 && response.body.gates.length > 0) {
              this.gateDetail = response.body.gates[0];
              this.gateDetail.isAntiPassbackPrev = response.body.gates[0].isAntiPassback;
              this.gateDetail.gateModel = {
                gateModelCode: response.body.gates[0].gateModelCode,
                gateModelId: response.body.gates[0].gateModelId,
                gateModelName: response.body.gates[0].gateModelName,
              };
              this.gateDetail.antiPassbackPrev = {
                relationId: 0,
                gateId: '', // デフォルトの値は空欄です
                gateName: '',
                mode: '',
              };
              if (this.gateDetail.isAntiPassback === true) {
                this.setAntiPassData(response.body.gates[0].antiPassback);
              }
              resolve(null);
            }
          })
          .then( null, (err: any) => {
          console.log('err: ', err);
          this.errorMessageLogs = '';
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          resolve(null);
        }); // catch
      });
    },
    getGroupOfGate(page: any , gateId: any) {
      this.isGroupOfGate = true;
      this.$http.get(Config.RELATION_GATE_GROUP + '?gateId=' +
      encodeURIComponent(gateId) + '&limit=25&page=' + page + '&embed=group&isExceptOptionalGate=true')
        .then((response: any) => {
          this.groupTotalCount = response.body.maxRecords;
          this.relationsGateGroup = response.body.relationsGateGroup;
          this.currPageGroup = Math.ceil(response.body.startRecord / 25);
          this.isGroupOfGate = false;
        })
        .then( null, (err: any) => {
          this.isGroupOfGate = false;
          //
        }); // catch
    },
    getUserOfGate(page: any , gateId: any) {
      this.isUserOfGate = true;
      this.$http.get(Config.GATES_PASSAGE_API + '?gateId=' +
      encodeURIComponent(gateId) + '&limit=25&page=' + page + '&isExceptOptionalGate=true')
        .then(async (response: any) => {
          const data = response.body.passages;
          this.currPageUser = Math.ceil(response.body.startRecord / 25);
          this.userTotalCount = response.body.maxRecords;
          for (let i = 0; i < data.length; i++) {
            this.userOfGate.push(data[i]);
            await this.getUserItem(data[i].userId, i);
          }
          this.isUserOfGate = false;
        })
        .then( null, (err: any) => {
            this.isUserOfGate = false;
          }); // catch
    },
    getUserItem(userId: any, i: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
          .then((response: any) => {
            this.userOfGate[i].user = response.body.users[0];
            console.log('response: ', response);
            resolve(true);
          })
          .then(null, (error: any) => {
            console.log('error: ', error);
            resolve(false);
          });
      });
    },
    getListGateModel() {
      // this.listGateModel = [];
      this.$http.get(Config.GATE_MODEL_LIST_API)
        .then((response: any) => {
          this.listGateModel = response.body.gateModels;
          this.gateDetail.listModel = response.body.gateModels;
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        });
    },
    deleteGatePassage(passageId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GATES_PASSAGE_API + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = passageId + '>>' + Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = passageId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcessUser, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    closeModalProgressUser() {
      this.showProgressUser = false;
      this.rowinErrDialog = 0;
      this.errorMessageProcessUser = [];
      this.successItemProcessUser = 0;
      this.progressUser = 0;
      this.isFinishProcessUser = false;
      this.userOfGate = [];
      this.getUserOfGate(1, this.$route.query.gateId);
    },
    closeModalProgressGroup() {
      this.showProgressGroup = false;
      this.rowinErrDialog = 0;
      this.errorMessageProcessGroup = [];
      this.successItemProcessGroup = 0;
      this.progressGroup = 0;
      this.isFinishProcessGroup = false;
      this.relationsGateGroup = [];
      this.getGroupOfGate(1, this.$route.query.gateId);
    },
    async clickRemoveUser(data: any) {
      console.log(data);
      this.showProgressUser = true;
      this.rowinErrDialog = 0;
      this.errorMessageProcessUser = [];
      this.successItemProcessUser = 0;
      this.progressUser = 0;
      this.isFinishProcessUser = false;

      this.totalDataRowProcessUser = data.length;
      const percent = (100 / this.totalDataRowProcessUser);
      for (const item of data) {
        let result;
        result = await this.deleteGatePassage(item.passageId);
        if (result) {
          this.successItemProcessUser++;
        }
        this.progressUser += percent;
      }
      this.isFinishProcessUser = true;
    },
    async clickRemoveGroup(data: any) {
      console.log(data);
      this.showProgressGroup = true;
      this.rowinErrDialog = 0;
      this.errorMessageProcessGroup = [];
      this.successItemProcessGroup = 0;
      this.progressGroup = 0;
      this.isFinishProcessGroup = false;

      this.totalDataRowProcessGroup = data.length;
      const percent = (100 / this.totalDataRowProcessGroup);
      for (const item of data) {
        let result;
        result = await this.deleteRelationGateGroup(item.relationId);
        if (result) {
          this.successItemProcessGroup++;
        }
        this.progressGroup += percent;
      }
      this.isFinishProcessGroup = true;
    },
    deleteRelationGateGroup(relationId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_GATE_GROUP_API + '/' + encodeURIComponent(relationId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = relationId + '>>' + Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = relationId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcessGroup, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    validateBeforeSubmit(data: any) {
      this.errorMessageLogs = '';
    },
    resetData() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
