































































import Vue, { PropType } from 'vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
import RelationTbEyeTarget from '@/components/organisms/RelationTbEyeTarget/RelationTbEyeTarget.vue';
import Alert, { AlertType } from '@/components/molecules/Alert/Alert.vue';
import { TbEyeRelationGateDevice } from '@/types/alligate';
import { TbeyeLoginInfo } from '@/types/tbeye';

interface DataType {
  newRecordKey: string[];
  isDisplayMessage: boolean[];
}

export interface Resulted {
  type: AlertType;
  message: string;
}

export default Vue.extend({
  name: 'RelationsTbEyeList',
  components: {
    Modal,
    Loading,
    InputForm,
    SuccessButton,
    CancelButton,
    IconButton,
    Alert,
    RelationTbEyeTarget,
  },
  data: (): DataType => {
    return {
      newRecordKey: [],
      isDisplayMessage: [],
    };
  },
  props: {
    tbeyeRelationGateDevices: {
      type: Array as PropType<TbEyeRelationGateDevice[]>,
      default: () => [],
    },
    addNewRelationGateDevices: {
      type: Array as PropType<TbEyeRelationGateDevice[]>,
      default: () => [],
    },
    configurableGates: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    loginInfos: {
      type: Array as PropType<TbeyeLoginInfo[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    putDelLoading: {
      type: Array as PropType<boolean[]>,
      default: () => [],
    },
    postLoading: {
      type: Array as PropType<boolean[]>,
      default: () => [],
    },
    results: {
      type: Array as PropType<Resulted[]>,
      default: () => [],
    },
  },
  methods: {
    add(): string {
      const keyString = randamString(16);
      this.newRecordKey.push(keyString);
      return keyString;
    },
    del(index: number): void {
      this.newRecordKey.splice(index, 1);
    },
  },
  watch: {
    results(val: any): void {
      console.log(val);
      this.isDisplayMessage.push(true);
      // this.$set(this.isDisplayMessage )
    },
  },
});

function randamString(stringLength: number) {
    const str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < stringLength; i++) {
        result += str.charAt(Math.floor(Math.random() * str.length));
    }
    return result;
}
