var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    _vm._l(_vm.linkList, function(item, index) {
      return _c(
        "v-row",
        { key: index, staticClass: "ma-1", attrs: { justify: "center" } },
        [
          _c("text-link", {
            attrs: { text: item.link },
            on: { click: _vm.addItem }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }