import { render, staticRenderFns } from "./UserGateAddGroupPage.vue?vue&type=template&id=94ca1448&"
import script from "./UserGateAddGroupPage.vue?vue&type=script&lang=ts&"
export * from "./UserGateAddGroupPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/uchida/alliboard-pro/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('94ca1448')) {
      api.createRecord('94ca1448', component.options)
    } else {
      api.reload('94ca1448', component.options)
    }
    module.hot.accept("./UserGateAddGroupPage.vue?vue&type=template&id=94ca1448&", function () {
      api.rerender('94ca1448', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/user/UserGateAddGroupPage.vue"
export default component.exports