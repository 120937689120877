var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        { staticClass: "pa-6 pr-10 mb-5", attrs: { elevation: "3" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "ml-4 mb-4 text-subtitle-2 font-weight-bold" },
                [_vm._v("\n        外部連携設定一覧\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            _vm._l(_vm.externalList, function(external) {
              return _c("card-link", {
                key: JSON.stringify(external),
                staticClass: "border col-2",
                class: { commingsoon: external.isComingSoon },
                attrs: { width: 400 },
                on: {
                  click: function($event) {
                    return _vm.$emit("card-click", external)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cardbody",
                      fn: function() {
                        return [
                          _c(
                            "v-row",
                            {
                              staticClass: "full-height",
                              attrs: { align: "center" }
                            },
                            [
                              external.imgSrc
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        cols: "auto",
                                        "align-self": "center"
                                      }
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: external.imgSrc,
                                          "max-width": "380",
                                          "max-height": "360"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              external.imgSrc2
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        cols: "auto",
                                        "align-self": "center"
                                      }
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: external.imgSrc2,
                                          "max-width": "380",
                                          "max-height": "360"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: { cols: "12", "align-self": "center" }
                                },
                                [
                                  _c("div", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(external.name))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }