








































































































































































































































































import Vue, { PropType } from 'vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import { PassableTime } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import DateTimePicker from '@/components/molecules/DateTimePicker/DateTimePicker.vue';
import { Group } from '@/types/alligate';
import moment from 'moment';

export interface ActionItem {
  title: string;
}
const today = new Date();
const next30days = new Date(new Date().setDate(today.getDate() + 30));
const pre30days = new Date(new Date().setDate(today.getDate() - 30));
export interface DataType {
  tab: null;
  selectedItem: PassableTime[];
  searchTargetItems: SearchTargetItem[];
  showModal: boolean;
  messageErrorDialog: string;
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  queryOptions: any;
  searchKey: string;
  page: number;
  perPage: number;
  dateStart: string;
  dateEnd: string;
  isDateStart: boolean;
  isDateEnd: boolean;
  showOption: boolean;
  timeStartGate: string;
  timeEndGate: string;
  dateStartGate: string;
  dateEndGate: string;
  perPageValue: number;
  timeStartCSV: string;
  timeEndCSV: string;
  dateStartCSV: string;
  dateEndCSV: string;
  timeStart: string;
  timeEnd: string;
}

export default Vue.extend({
  name: 'OperationLogList',
  components: {
    ShowPerPage,
    Pagination,
    Searchbox,
    SuccessButton,
    CancelButton,
    DatePicker,
    DisplayOption,
    DateTimePicker,
  },
  created() {
    this.queryOptions.page = 1;
    this.queryOptions.perPage = this.perPageValue;
    this.queryOptions.text = this.searchKey;
    this.queryOptions.dateStart = '';
    this.queryOptions.dateEnd = '';
    this.queryOptions.targets = ['name', 'logLabel'];
    this.$emit('getOperationLogList', this.queryOptions);
    if (typeof localStorage.checkedLogOperationList !== 'undefined') {
      this.selectedDisplayOption = JSON.parse(localStorage.checkedLogOperationList);
    }
  },
  props: {
    gatePassageTotalCount: {
      type: Number,
      default: 0,
    },
    disableNextOwner: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    logOwner: {
      type: Array,
      default: () => [],
    },
    optionOwner: {
      default: () => [],
    },
    maxRecord: {
      type: Number,
      default: 0,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    tab: null,
    selectedItem: [],
    showModal: false,
    messageErrorDialog: '',
    displayOptionItems: [
      { label: '時間', value: 'timestamp', isDisabled: true },
      { label: 'ユーザーID', value: 'userId' },
      { label: 'ユーザー名', value: 'name' },
      { label: 'ふりがな', value: 'furigana' },
      { label: '操作ログID', value: 'configUserOperationLogId' },
      { label: 'メッセージ', value: 'logLabel' },
      { label: '操作対象', value: 'targetId' },
      { label: '操作内容', value: 'details' },
    ],
    searchTargetItems: [
      { label: 'ユーザー名', value: 'name' },
      { label: 'メッセージ', value: 'logLabel' },
    ],
    selectedDisplayOption: [
      'timestamp', 'userId', 'logLabel',
    ],
    queryOptions: {},
    searchKey: '',
    page: 1,
    perPage: 100,
    dateEnd: new Date().toISOString().substr(0, 10),
    dateStart: pre30days.toISOString().substr(0, 10),
    isDateEnd: false,
    isDateStart: false,
    timeStartGate: '00:00',
    timeEndGate: '23:59',
    dateStartGate: '',
    dateEndGate: '',
    showOption: false,
    perPageValue: 100,
    timeStartCSV: '00:00',
    timeEndCSV: '23:59',
    dateStartCSV: '',
    dateEndCSV: '',
    timeStart: '00:00',
    timeEnd: '23:59',
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [];
        if (this.selectedDisplayOption.includes('timestamp')) {
          headers.push({
            text: '時間',
            align: 'start',
            sortable: true,
            value: 'timestamp',
          });
        }
        if (this.selectedDisplayOption.includes('userId')) {
          headers.push({
            text: 'ユーザーID',
            align: 'start',
            sortable: true,
            value: 'userId',
          });
        }
        if (this.selectedDisplayOption.includes('name')) {
          headers.push({
            text: 'ユーザー名',
            align: 'start',
            sortable: true,
            value: 'name',
          });
        }
        if (this.selectedDisplayOption.includes('furigana')) {
          headers.push({
            text: 'ふりがな',
            align: 'start',
            sortable: true,
            value: 'furigana',
          });
        }
        if (this.selectedDisplayOption.includes('configUserOperationLogId')) {
          headers.push({
            text: '操作ログID',
            align: 'start',
            sortable: true,
            value: 'configUserOperationLogId',
          });
        }
        if (this.selectedDisplayOption.includes('logLabel')) {
          headers.push({
            text: 'メッセージ',
            align: 'start',
            sortable: true,
            width: '20%',
            value: 'logLabel',
          });
        }
        if (this.selectedDisplayOption.includes('targetId')) {
          headers.push({
            text: '操作対象',
            align: 'start',
            sortable: true,
            value: 'targetId',
          });
        }
        if (this.selectedDisplayOption.includes('details')) {
          headers.push({
            text: '操作内容',
            align: 'start',
            sortable: true,
            value: 'details',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    groupNames: {
      get(): Array<{ text: string; value: string }> {
        return this.groups.map((group) => ({
          text: group.groupName,
          value: group.groupId,
        }));
      },
      set(): void {
        return;
      },
    },
  },
  watch: {
    page(newPage: number) {
      if (newPage >  1) {
        this.perPage = newPage * this.perPageValue ;
        this.queryOptions = this.optionOwner;
        this.queryOptions.page = newPage;
        this.queryOptions.perPage = this.perPageValue;
        this.$emit('getOperationLogList', this.queryOptions);
      }
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    changePerPageLog(value: any) {
      this.page = 1;
      this.perPageValue = value;
      this.perPage = 1 * this.perPageValue ;
      this.queryOptions = this.optionOwner;
      this.queryOptions.page = 1;
      this.queryOptions.perPage = this.perPageValue;
      this.$emit('searchOperationLog', this.queryOptions);
    },
    getNewTimeStart(time: string) {
      this.timeStartGate = time;
    },
    getNewTimeEnd(time: string) {
      this.timeEndGate = time;
    },
    getNewDateStart(date: string) {
      this.dateStartGate = date;
    },
    getNewDateEnd(date: string) {
      this.dateEndGate = date;
    },
    formatDateTime(date: Date): string {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
    getDateStart(data: any) {
      console.log(data);
    },
    getDateEnd(data: any) {
      console.log(data);
    },
    searchOperationLog(data: any) {
      data.dateStart = this.dateStartGate + ' ' + this.timeStartGate;
      data.dateEnd = this.dateEndGate + ' ' + this.timeEndGate;
      data.page = 1;
      data.perPage = this.perPageValue;
      this.$emit('searchOperationLog', data);
    },
    CheckColumn(data: any) {
      localStorage.setItem('checkedLogOperationList', JSON.stringify(data));
    },
    clickModal() {
      this.dateStart = this.dateStartGate;
      this.dateEnd = this.dateEndGate;
      this.timeStart = this.timeStartGate;
      this.timeEnd = this.timeEndGate;
      this.timeStartCSV = this.timeStartGate;
      this.timeEndCSV = this.timeEndGate;
      this.showModal = true;
    },
    getNewTimeStartCSV(time: string) {
      this.timeStartCSV = time;
    },
    getNewTimeEndCSV(time: string) {
      this.timeEndCSV = time;
    },
    getNewDateStartCSV(date: string) {
      this.dateStartCSV = date;
    },
    getNewDateEndCSV(date: string) {
      this.dateEndCSV = date;
    },
  },
});
