var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "notice-setting-type-list" },
    [
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoadBlockingNoticeSettingType,
            expression: "isLoadBlockingNoticeSettingType"
          }
        ]
      }),
      _vm._v(" "),
      _vm._l(_vm.noticeSettingTypes, function(noticeSettingType) {
        return [
          _c(
            "v-row",
            { key: noticeSettingType.noticeSettingTypeId },
            [
              _c(
                "v-col",
                { staticClass: "col-md-4 text-md-body-1 font-weight-black" },
                [_vm._v(_vm._s(noticeSettingType.name))]
              ),
              _vm._v(" "),
              _c("v-col", { staticClass: "col-md-4 text-md-body-2" }, [
                _vm._v(_vm._s(noticeSettingType.description))
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "col-md-1" },
                [
                  _c("success-button", {
                    attrs: { text: "新規設定" },
                    on: {
                      click: function($event) {
                        return _vm.clickOpenNewAddNoticeSeting(
                          noticeSettingType
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "col-md-1" },
                [
                  _c("cancel-button", {
                    attrs: { text: "設定済み一覧" },
                    on: {
                      click: function($event) {
                        return _vm.displayNoticeSettingList(noticeSettingType)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      }),
      _vm._v(" "),
      _c("notice-setting-list", {
        attrs: {
          isVisible: _vm.isVisibleNoticeSettingList,
          isLoadingNoticeSetting: _vm.isLoadingNoticeSetting,
          noticeSettings: _vm.noticeSettings,
          noticeSettingType: _vm.selectedNoticeSettingType,
          errorMessageLogsModal: _vm.errorMessageLogsModal,
          successMessageLogsModal: _vm.successMessageLogsModal
        },
        on: {
          close: function($event) {
            _vm.isVisibleNoticeSettingList = false
          },
          "click-delete-notice-setting": function($event) {
            return _vm.$emit("click-delete-notice-setting", $event)
          },
          "reset-msg": function($event) {
            return _vm.$emit("reset-msg")
          },
          "reload-notice-settings": function($event) {
            return _vm.$emit("reload-notice-settings")
          },
          "click-open-edit-notice-setting": function($event) {
            return _vm.clickOpenEditNoticeSeting($event)
          },
          "click-open-add-notice-setting": function($event) {
            return _vm.clickOpenNewAddNoticeSeting($event)
          },
          "change-notice-setting-valid": function($event) {
            return _vm.$emit("click-edit-notice-setting", $event)
          }
        }
      }),
      _vm._v(" "),
      _vm.isVisibleNoticeSettingTarget
        ? _c("notice-setting-target", {
            attrs: {
              isVisible: _vm.isVisibleNoticeSettingTarget,
              noticeSettingType: _vm.selectedNoticeSettingType,
              mode: _vm.editMode,
              configurableUsers: _vm.configurableUsers,
              configurableGates: _vm.configurableGates,
              configurablePcodes: _vm.configurablePcodes,
              configurableLogs: _vm.configurableLogs,
              configurableConnectStatus: _vm.configurableConnectStatus,
              isLoadBlockingNoticeSetting: _vm.isLoadBlockingNoticeSetting,
              loadBlockingTextNoticeSetting: _vm.loadBlockingTextNoticeSetting,
              successMessageLogsModal: _vm.successMessageLogsModal,
              errorMessageLogsModal: _vm.errorMessageLogsModal
            },
            on: {
              close: function($event) {
                _vm.isVisibleNoticeSettingTarget = false
              },
              "click-add-notice-setting": function($event) {
                return _vm.$emit("click-add-notice-setting", $event)
              },
              "click-edit-notice-setting": function($event) {
                return _vm.$emit("click-edit-notice-setting", $event)
              }
            },
            model: {
              value: _vm.editNoticeSetting,
              callback: function($$v) {
                _vm.editNoticeSetting = $$v
              },
              expression: "editNoticeSetting"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }