var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-personal-setting-form" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            { staticClass: "mb-1 ml-1", attrs: { justify: "start" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-primary",
                  attrs: {
                    outlined: "",
                    disabled:
                      !_vm.userData.userId || _vm.userData.isAdmin === false
                  },
                  on: {
                    click: function($event) {
                      return _vm.editUser(_vm.userData.userId)
                    }
                  }
                },
                [_vm._v("\n          アカウントを編集\n          ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-row", {
            staticStyle: { height: "20px" },
            attrs: { "no-gutters": "" }
          }),
          _vm._v(" "),
          _c("info-item", {
            attrs: {
              title: "事業者ID",
              text:
                _vm.userData.owner !== undefined
                  ? _vm.userData.owner.ownerId
                  : ""
            }
          }),
          _vm._v(" "),
          _c("info-item", {
            attrs: { title: "ユーザーID", text: _vm.userData.userId }
          }),
          _vm._v(" "),
          _c("info-item", {
            attrs: { title: "メールアドレス", text: _vm.userData.email }
          }),
          _vm._v(" "),
          _c("info-item", {
            attrs: { title: "ユーザー名", text: _vm.userData.name }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }