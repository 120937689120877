var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-error-button" },
    [
      _c(
        "v-btn",
        {
          staticClass: "text-color-white text-bold",
          attrs: {
            color: "error",
            elevation: "0",
            disabled: _vm.disabled,
            outlined: _vm.outlined
          },
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            }
          }
        },
        [_vm._v("\n  " + _vm._s(_vm.text) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }