











































































































































































































import { Gate, GatePassage } from '@/types/alligate';
import Vue, { PropType } from 'vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import { DataTableHeader } from 'vuetify';

interface SelectItem { text: string; value: string; }
interface Item {
  gateId: string;
  notBeforeDate: string;
  notBeforeFormattedDate: string;
  notBeforeTime: string;
  notBeforeTimeHour: string;
  notBeforeTimeMinute: string;
  notAfterDate: string;
  notAfterFormattedDate: string;
  notAfterTime: string;
  notAfterTimeHour: string;
  notAfterTimeMinute: string;
  menuNotBeforeDate: boolean;
  menuNotAfterDate: boolean;
  index: number;
}

export interface DataType {
  headers: DataTableHeader[];
  hours: string[];
  minutes: string[];
}

const today = new Date();

export default Vue.extend({
  name: 'GatePassageEdit',
  components: {
    TextLink,
    CancelButton,
    SuccessButton,
  },
  props: {
    value: {
      type: Array as PropType<GatePassage[]>,
      default: () => [],
    },
    userId: {
      type: String,
      default: '',
    },
    allGates: {
      type: Array as PropType<Gate[]>,
      default: () => [],
    },
  },
  data: (): DataType => ({
    headers: [
      {
        text: 'ゲート名',
        align: 'start',
        sortable: false,
        value: 'gateName',
      },
      {
        text: '解錠可能期限',
        align: 'start',
        sortable: false,
        value: 'time',
      },
    ],
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: ['00', '15', '30', '45'],
  }),
  computed: {
    innerValue: {
      get(): Item[] {
        return this.value.map((gatePassage, index) => {
          const notBefore = new Date(gatePassage.notBefore * 1000);
          const notAfter = new Date(gatePassage.notAfter * 1000);
          const ret = {
            gateId: gatePassage.gateId,
            notBeforeDate: `${notBefore.getFullYear()}-${notBefore.getMonth() + 1}-${notBefore.getDate()}`,
            notBeforeFormattedDate: `${notBefore.getFullYear()}/${notBefore.getMonth() + 1}/${notBefore.getDate()}`,
            notBeforeTime: this.formatTime(notBefore.getHours(), notBefore.getMinutes()),
            notBeforeTimeHour: ('00' + notBefore.getHours()).slice(-2),
            notBeforeTimeMinute: ('00' + notBefore.getMinutes()).slice(-2),
            notAfterDate: `${notAfter.getFullYear()}-${notAfter.getMonth() + 1}-${notAfter.getDate()}`,
            notAfterFormattedDate: `${notAfter.getFullYear()}/${notAfter.getMonth() + 1}/${notAfter.getDate()}`,
            notAfterTime: this.formatTime(notAfter.getHours(), notAfter.getMinutes()),
            notAfterTimeHour: ('00' + notAfter.getHours()).slice(-2),
            notAfterTimeMinute: ('00' + notAfter.getMinutes()).slice(-2),
            menuNotBeforeDate: false,
            menuNotAfterDate: false,
            index,
          };
          return ret;
        });
      },
      set(val: Item[]): void {
        this.change(val);
      },
    },
    gateItems: {
      get(): SelectItem[] {
        return this.allGates.map((gate) => ({
          text: gate.gateName,
          value: gate.gateId,
        }));
      },
    },
  },
  methods: {
    formatDate(date: string): string {
      const [year, month, day] = date.split('-');
      return `${year}/${month}/${day}`;
    },
    formatTime(hours: number | string, minute: number | string): string {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    addItem() {
      const index = this.innerValue.length;
      this.innerValue.push({
        gateId: '',
        notBeforeDate: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
        notBeforeFormattedDate: `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`,
        notBeforeTime: '00:00',
        notBeforeTimeHour: '00',
        notBeforeTimeMinute: '00',
        notAfterDate: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
        notAfterFormattedDate: `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`,
        notAfterTime: '23:45',
        notAfterTimeHour: '23',
        notAfterTimeMinute: '45',
        menuNotBeforeDate: false,
        menuNotAfterDate: false,
        index,
      });
      this.change(this.innerValue);
    },
    deleteItem(index: any) {
      this.change(this.innerValue.filter((item) => item.index !== index));
    },
    change(items: Item[]): void {
      // const gatePassages: GatePassage[] = items.map((item) => {
      //   const [beforeYear, beforeMonth, beforeDay] = item.notBeforeDate.split('-');
      //   const [afterYear, afterMonth, afterDay] = item.notAfterDate.split('-');
      //   return {
      //     passageId: '',
      //     notBefore: Math.ceil(new Date(
      //       Number(beforeYear),
      //       Number(beforeMonth) - 1,
      //       Number(beforeDay),
      //       Number(item.notBeforeTimeHour),
      //       Number(item.notBeforeTimeMinute),
      //     ).getTime() / 1000),
      //     notAfter: Math.ceil(new Date(
      //       Number(afterYear),
      //       Number(afterMonth) - 1,
      //       Number(afterDay),
      //       Number(item.notAfterTimeHour),
      //       Number(item.notAfterTimeMinute),
      //     ).getTime() / 1000),
      //     gateId: item.gateId,
      //     userId: this.userId,
      //     gate: null,
      //   };
      // });
      // this.$emit('input', gatePassages);
      return;
    },
  },
});
