var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gate-add-user-template", {
    attrs: {
      dataUsers: _vm.dataUsers,
      userTotalCount: _vm.userTotalCount,
      page: _vm.currPage,
      perPage: _vm.perPage,
      loading: _vm.loading,
      showConfirm: _vm.showConfirm,
      userOfGate: _vm.userOfGate,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      errorMessageProcess: _vm.errorMessageProcess,
      successItemProcess: _vm.successItemProcess,
      totalDataRowProcess: _vm.totalDataRowProcess,
      showProgress: _vm.showProgress,
      isFinishProcess: _vm.isFinishProcess,
      progress: _vm.progress
    },
    on: {
      changePerPage: function($event) {
        return _vm.changePerPage($event)
      },
      clickAddUser: function($event) {
        return _vm.clickAddUser($event)
      },
      clickConfirm: function($event) {
        return _vm.clickConfirm($event)
      },
      clickDelete: function($event) {
        return _vm.clickDelete($event)
      },
      changePage: function($event) {
        return _vm.changePage($event)
      },
      closeModalProgress: function($event) {
        return _vm.closeModalProgress()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }