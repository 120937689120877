










































































































































































































































































































import Vue, { PropType } from 'vue';
import { PassableTime } from '@/types/alligate';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import GateListTable from '@/components/organisms/GateListTable/GateListTable.vue';
import GateListLockTable from '@/components/organisms/GateListLockTable/GateListLockTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';

export interface DataType {
  searchTargetItems: SearchTargetItem[];
  searchTargetItemsLock: SearchTargetItem[];
  selectedItem: PassableTime[];
  tab: number;
  actionItems: any;
}

export default Vue.extend({
  name: 'GateListTemplate',
  components: {
    Searchbox,
    ShowPerPage,
    IconButton,
    Pagination,
    GateListTable,
    Breadcrumbs,
    GateListLockTable,
  },
  props: {
    lastLog: {
      type: Array,
      default: () => [],
    },
    btnUnlock: {
      type: Array,
      default: () => [],
    },
    goBack: {
      type: String,
      default: '',
    },
    cardTotalCount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    isFinishHandleCommand: {
      type: Boolean,
      default: false,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    perPageLock: {
      type: Number,
      default: 25,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLock: {
      type: Number,
      default: 1,
    },
    gateTotalCount: {
      type: Number,
      default: 0,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    dataGates: {
      type: Array,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    gateName: {
      type: String,
      default: '',
    },
    errorMessageModal: {
      type: String,
      default: '',
    },
    successMessageModal: {
      type: String,
      default: '',
    },
    errorMessageProcess: {
      default: [],
    },

    gateExpanedGroup: {
      type: Map,
      default: new Map(),
    },
    isReRenderGroup: {
      default: 0,
    },
    isRemoveGroupSelected: {
      default: 0,
    },
    gateExpanedUser: {
      type: Map,
      default: new Map(),
    },
    gateLockExpanedGroup: {
      type: Map,
      default: new Map(),
    },
    gateLockExpanedUser: {
      type: Map,
      default: new Map(),
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
    typeAction: {
      default: 0,
    },
    safieRelationsGateDevice: {
      type: Object,
      default: () => ({}),
    },
    gateTotalCountLock: {
      type: Number,
      default: 0,
    },
    isGetDataListLock: {
      type: Boolean,
      default: false,
    },
    dataGatesLock: {
      type: Array,
      default: () => [],
    },
    // CSV
    showProgressExportCSV: {
      type: Boolean,
      default: false,
    },

    // Excel
    progressCsvXlsx: {
      type: Number,
      default: 0,
    },
    showModalImport: {
      type: Boolean,
      default: false,
    },
    showProgressImport: {
      type: Boolean,
      default: false,
    },
    successItemImport: {
      type: Number,
      default: 0,
    },
    totalDataRowImport: {
      type: Number,
      default: 0,
    },
    errorMessageImport: {
      default: [],
    },
    sheetUserName: {
      type: String,
      default: '',
    },
    isShowViewCSV: {
      type: Boolean,
      default: false,
    },
    errorMessageCsvXlsx: {
      type: String,
      default: '',
    },
    showProgressUser: {
      type: Boolean,
      default: false,
    },
    progressUser: {
      type: Number,
      default: 0,
    },
    isFinishProcessUser: {
      type: Boolean,
      default: false,
    },
    userTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcessUser: {
      type: Number,
      default: 0,
    },
    successItemProcessUser: {
      type: Number,
      default: 0,
    },
    errorMessageProcessUser: {
      type: Array,
      default: () => [],
    },
    showProgressGroup: {
      type: Boolean,
      default: false,
    },
    progressGroup: {
      type: Number,
      default: 0,
    },
    isFinishProcessGroup: {
      type: Boolean,
      default: false,
    },
    gatePassword: {
      type: String,
      default: '',
    },
    groupTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcessGroup: {
      type: Number,
      default: 0,
    },
    successItemProcessGroup: {
      type: Number,
      default: 0,
    },
    errorMessageProcessGroup: {
      type: Array,
      default: () => [],
    },
    errorMessageLogsPhone: {
      type: String,
      default: '',
    },
    successMessageLogsPhone: {
      type: String,
      default: '',
    },
    actionUpdateValidLock: {
      type: Number,
      default: 0,
    },
    showConfirmUpdateValidLock: {
      type: Boolean,
      default: false,
    },
    showProgressUpdateValidLock: {
      type: Boolean,
      default: false,
    },
    totalDataRowProcessUpdateValidLock: {
      type: Number,
      default: 0,
    },
    successItemProcessUpdateValidLock: {
      type: Number,
      default: 0,
    },
    progressUpdateValidLock: {
      type: Number,
      default: 0,
    },
    errorMessageProcessUpdateValidLock: {
      default: [],
    },
    isFinishProcessUpdateValidLock: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: 'ゲートID', value: 'gate.gateId' },
      { label: 'ゲート名', value: 'gate.name' },
      { label: '備考1', value: 'gate.option1' },
      { label: '備考2', value: 'gate.option2' },
    ],
    selectedItem: [],
    searchTargetItemsLock: [
      { label: 'ゲートID', value: 'gateId' },
      { label: '製品名', value: 'gateModelName' },
      { label: 'ゲート名', value: 'gateName' },
      { label: '備考.1', value: 'option1' },
      { label: '備考.2', value: 'option2' },
    ],
    tab: 0,
    actionItems:  [
      { title: 'Alligate Lock Pro', tabname: 'tab1' },
      { title: 'Alligate Lock', tabname: 'tab2' },
    ],
  }),
  methods: {
    clickGoController() {
      this.$router.push({ path: 'control-panel/add', query: {goBack: 'gates'}});
    },
  },
  mounted() {
    if (this.$route.query.goBack !== undefined && this.$route.query.goBack === 'gate-lock') {
      this.tab = 1;
    }
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ゲート', disabled: true },
        ];
      },
    },
  },
});
