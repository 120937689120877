























































































































































import Vue, { PropType } from 'vue';
import Modal from '../Modal/Modal.vue';
import InputForm from '../InputForm/InputForm.vue';
import SuccessButton from '../SuccessButton/SuccessButton.vue';
import CancelButton from '../CancelButton/CancelButton.vue';

export interface DataType {
  url: string;
  player: UmpPlayer | null;
  playingTime: Date;
  isPlay: boolean;
  isUnauthorized: boolean;
  isOtherError: boolean;
  errorCode: number;
  errorMessage: string;
  login: any;
  innerHeight: number;
  innerWidth: number;
}

/** UmpPlayerの拡張されているプロパティ群 */
interface UmpPlayer extends HTMLElement {
  sessionKey?: string;
  playType?: number;
  startTime?: string;
  endTime?: string;
  sunapiClient?: any;
  seekingTime?: string;
  overlappedId?: string;
  secure?: boolean;
  // playSpeed?: UmpPlaySpeed;
  framedrop?: boolean;
  bestshot?: boolean;
  /** readonly */
  isplay?: boolean;
  /** function */
  play?: any;
  /** function */
  stop?: any;
  /** function */
  pause?: any;
  /** function */
  resume?: any;
  /** function */
  forward?: any;
  /** function */
  backward?: any;
  /** function */
  mute?: any;
  /** function */
  unmute?: any;
  /** function */
  isMute?: any;
  /** function */
  getAudioVolume?: any;
  /** function(vol): 0~5 */
  setAudioVolume?: any;
}

export default Vue.extend({
  name: 'TbEyeVideo',
  components: {
    InputForm,
    Modal,
    SuccessButton,
    CancelButton,
  },
  props: {
    videoId: {
      type: String,
      default: () => {
        return Math.random().toString(32).substring(2);
      },
    },
    isMinimum: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    /** 現在時間より60秒前以前のDateが指定可能 */
    replayStartDate: {
      type: Number,
      default: () => new Date().getTime(),
    },
    username: {
      type: String,
      default: () => '',
    },
    password: {
      type: String,
      default: () => '',
    },
    hostname: {
      type: String,
      default: () => '',
    },
    channel: {
      type: Number,
      default: () => 1,
    },
    port: {
      type: Number,
      default: () => 80,
    },
    device: {
      type: String,
      default: () => '',
    },
    height: {
      type: Number,
      default: () => Math.floor(window.innerHeight),
    },
    width: {
      type: Number,
      default: () => Math.floor(window.innerWidth),
    },
  },
  data: (): DataType => ({
    url: 'url',
    player: null,
    playingTime: new Date(),
    isPlay: true,
    isUnauthorized: false,
    isOtherError: false,
    errorCode: 0,
    errorMessage: '',
    innerHeight: 0,
    innerWidth: 0,
    login: {
      username: '',
      password: '',
    },
  }),
  mounted(): void {
    this.player = document.getElementById(this.videoId);
    this.playingTime = new Date(this.replayStartDate);
    if (this.player !== null) {
      this.player.playType = 1;
      this.player.startTime = new Date(this.replayStartDate).toJSON();
      // this.player.overlappedId = '100';
      this.player.secure = true;
      this.player.addEventListener('error', this.onError, false);
      this.player.addEventListener('statechange', this.onStatechange, false);
      this.player.addEventListener('timestamp', this.onTimestamp, false);
      this.player.play();
      // this.player.mute();
    }
    this.innerHeight = this.height;
    this.innerWidth = this.width;
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy(): void {
    window.removeEventListener('resize', this.onResize);
    if (this.player !== null) {
      this.player.stop();
      this.player.removeEventListener('timestamp', this.onTimestamp, false);
      this.player.removeEventListener('error', this.onError, false);
      this.player.removeEventListener('statechange', this.onStatechange, false);
    }
  },
  watch: {
    isOtherError(newVal: boolean): void {
      if (newVal === false) {
        this.$emit('close');
      }
    },
    // エラーを閉じたらmountedから再開したいので上位に再描画させるため投げて任せる
    isUnauthorized(newVal: boolean): void {
      if (newVal === false) {
        this.$emit('rerender');
      }
    },
  },
  methods: {
    onResize(): void {
      // Modal内のスクロールバーが出ない上下余白が350
      // TODO: もし他環境でスクロールバーなど観測されるなら値の変更を推奨
      this.innerHeight = Math.floor(window.innerHeight - 350);
      this.innerWidth = Math.floor(window.innerWidth);
    },
    onTimestamp(event: any): void {
      this.playingTime = new Date(event.detail.timestamp);
    },
    onStatechange(event: any): void {
        // event.detail:{
        //   channelId: number;
        //   elementId: string;
        //   error: number; 0=success, anynumber = errorcode
        //   message: string;
        //   readyState: number; 1:play, 2:pause, 3:backward or forward
        // }
      if (event.detail.readyState === 1) {
        this.isPlay = true;
      } else if (event.detail.readyState === 2) {
        this.isPlay = false;
      }
    },
    onError(event: any): void {
      this.errorCode = event.detail.error;
      this.errorMessage = event.detail.message;
      // ログインできない場合
      if (event.detail.error === 518 || event.detail.error === 1027) {
        this.isUnauthorized = true;
        // 初期化
        this.login.username = this.username;
        this.login.password = this.password;
        this.$emit('unauthorized');
        this.stop();
      } else if ([// 無視するエラーコード。エラーは出てるけどChromeでは再生できてるので。
        768, // Unknown codec type:MP4V-ES, Control URL:track2
      ].includes(event.detail.error)) {
        // catch and release...
      } else {
        this.isOtherError = true;
        this.stop();
      }
    },
    play(): void {
      if (this.player !== null && this.player.isplay === false) {
        this.player.play();
      }
    },
    stop(): void {
      if (this.player !== null && this.player.isplay === true) {
        this.player.stop();
      }
    },
    pause(): void {
      if (this.player !== null && this.player.isplay === true) {
        this.player.pause();
      }
    },
    resume(): void {
      if (this.player !== null && this.player.isplay === false) {
        this.player.resume();
      }
    },
    /** 1秒進める */
    forward(): void {
      if (this.player !== null && this.player.isplay === false) {
        this.player.forward();
      }
    },
    /** 1秒戻す */
    backward(): void {
      if (this.player !== null && this.player.isplay === false) {
        this.player.backward();
      }
    },
    seekingTime(sec: number): void {
      if (this.player !== null) {
        const seekingTime = new Date(this.playingTime.getTime() + (sec * 1000)).toJSON();
        this.player.seekingTime = seekingTime;
        this.playingTime = new Date(seekingTime);
      }
    },
    updateLoginInfo(): void {
      this.$emit('update-login-info', this.login);
      this.isUnauthorized = false;
    },
    cancelUpdateLoginInfo(): void {
      this.$emit('close');
      this.isUnauthorized = false;
    },
    openToLink(): void {
      window.open(`https://${this.hostname}:${this.port}`, '_blank');
    },
  },
});
