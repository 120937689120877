




import {Vue } from 'vue-property-decorator';
import NoticeListTemplate from '@/components/templates/NoticeListTemplate/NoticeListTemplate.vue';

export default Vue.extend({
  name: 'NoticeListPage',
  components: {
    NoticeListTemplate,
  },
  data() {
    return {
        listLink: [],
    };
  },
  methods: {
    makeListLink(count: number) {
        const listLink = [];
        for (let i = 0; i < count; i++) {
            listLink.push({
              link: `getLink ${i} getLink ${i} getLink ${i} getLink ${i} getLink ${i} getLink ${i} getLink ${i} getLink ${i}`,
            });
        }
        return listLink;
    },
  },
});
