var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "btn-round mb-5", attrs: { justify: "end" } },
            [
              _c(
                "v-col",
                { staticClass: "btn-add pt-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary float-left",
                      attrs: { outlined: "", disabled: !_vm.userData.userId },
                      on: {
                        click: function($event) {
                          return _vm.goHome(_vm.userData.userId)
                        }
                      }
                    },
                    [_vm._v("\n        戻る\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "pa-6 pr-10 mb-5", attrs: { elevation: "3" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-subtitle-2 font-weight-bold" },
                    [_vm._v("\n          ユーザー情報\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "start" } },
                [
                  _vm.errorMessageLogs !== ""
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.errorMessageLogs) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("personal-setting-form", {
                attrs: { userData: _vm.userData, loading: _vm.loading }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }