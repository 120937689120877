var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-gate-table" },
    [
      _c(
        "v-row",
        {
          staticClass: "mt-2 mb-1 ml-5 mr-5",
          attrs: { justify: "space-between" }
        },
        [
          _c("div", { staticClass: "allig-title mt-1" }, [
            _vm._v(
              "\n      " +
                _vm._s("所属ゲート一覧 (全" + _vm.gates.length + "件)") +
                " \n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.clickJoinGroup(_vm.groupId)
                        }
                      }
                    },
                    [_vm._v("\n      関連付け追加\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary",
                      attrs: { disabled: _vm.selectedItem.length === 0 },
                      on: {
                        click: function($event) {
                          return _vm.$emit("confirm-action", {
                            action: 5,
                            groudId: _vm.groupId,
                            gateIds: _vm.selectedItem
                          })
                        }
                      }
                    },
                    [_vm._v("\n      関連付け解除\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-between" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("show-per-page", {
                staticClass: "ml-4 mb-2",
                attrs: {
                  "per-page": 25,
                  page: _vm.page,
                  "items-length": _vm.gateTotalCount
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "border-table",
            attrs: {
              "must-sort": "",
              "custom-sort": _vm.customSort,
              loading: _vm.loading,
              headers: _vm.headers,
              items: _vm.gates,
              "items-per-page": 25,
              "no-data-text": "該当するゲートがありません",
              "loading-text": "データを読み込中です",
              "hide-default-footer": "",
              dense: "",
              "show-select": _vm.gates.length > 0 ? true : false,
              "item-key": "relationId"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.gateName",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "item-user-name",
                          attrs: { text: item.name }
                        },
                        [_vm._v(_vm._s(item.gateName) + "\n    ")]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItem,
              callback: function($$v) {
                _vm.selectedItem = $$v
              },
              expression: "selectedItem"
            }
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-3",
              attrs: { "no-gutters": "", justify: "space-between" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("pagination", {
                staticClass: "mb-1",
                attrs: {
                  "page-length": Math.ceil(_vm.gateTotalCount / 25),
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.innerPage,
                  callback: function($$v) {
                    _vm.innerPage = $$v
                  },
                  expression: "innerPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }