var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tb-eye-template", {
    attrs: {
      loading: _vm.loading,
      putDelLoading: _vm.putDelLoading,
      postLoading: _vm.postLoading,
      tbeyeRelationGateDevices: _vm.tbeyeRelationGateDevices,
      tbeyeLoginInfos: _vm.tbeyeLoginInfos,
      addNewRelationGateDevices: _vm.newTbeyeRelationGateDevices,
      gateFullList: _vm.gateFullList,
      results: _vm.results
    },
    on: {
      "add-new-record": _vm.addNewRecord,
      "del-new-record": _vm.delNewRecord,
      "form-update": _vm.formUpdate,
      "new-form-update": _vm.formUpdateNew,
      "put-record": _vm.putTbeyeRelationGateDevice,
      "post-record": _vm.postTbeyeRelationGateDevice,
      "delete-record": _vm.deleteTbeyeRelationGateDevice,
      "update-login-info": _vm.updateLoginInfo
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }