export const STORAGE_KEY = 'alliboard';

let syncedData = {
  auth: {
    isLoggedIn: false,
    isLoginChallenge: false,
    accessToken: null,
    refreshToken: null,
  },
  user: {
    name: null,
  },
};

// Sync with local storage.
if (localStorage.getItem(STORAGE_KEY)) {
  syncedData = JSON.parse(localStorage.getItem(STORAGE_KEY) || '');
}

// Merge data and export it.
export const state = Object.assign(syncedData);
