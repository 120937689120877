











































































































import Vue, { PropType } from 'vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
export default Vue.extend({
  name: 'GroupDetailForm',
  components: {
    InfoItem,
    TextLink,
  },
  props: {
    gatesGroup: {
      default: [],
    },
    usersGroup: {
      default: [],
    },
    groupDetail: {
      default: {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
      headerTimes: {
        get(): DataTableHeader[] {
          const headers: DataTableHeader[] = [
            {
              text: '時間帯設定名',
              align: 'start',
              sortable: false,
              width: '20%',
              value: 'name',
            },
            {
              text: '曜日',
              align: 'start',
              sortable: true,
              value: 'dayTypeName',
              // sort: (a, b) => a.dayTypeId - b.dayTypeId,
            },
            {
              text: '時間',
              align: 'start',
              sortable: true,
              value: 'expand-time',
            },
          ];
          return headers;
        },
      },
  },
  methods: {
    tableSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'dayTypeName') {
          if (!isDescending[0]) {
          return a.dayTypeId > b.dayTypeId ? 1 : -1;
          } else {
          return a.dayTypeId < b.dayTypeId ? 1 : -1;
          }
        } else if (index[0] === 'expand-time') {
          if (!isDescending[0]) {
            if (a.startTime > b.startTime) {
              return 1;
            } else if (a.startTime === b.startTime && a.endTime > b.endTime) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.startTime < b.startTime) {
              return 1;
            } else if (a.startTime === b.startTime && a.endTime < b.endTime) {
              return 1;
            } else {
              return -1;
            }
          }
        }
      });
      return items;
    },
    clickGate(ID: any, groupId: any) {
      this.$router.push({ path: Config.GATE_DETAIL, query: {gateId: ID, goBack: 'group/detail?groupId=' + groupId}});
    },
  },
});
