























import { Vue } from 'vue-property-decorator';
import GroupAddTemplate from '@/components/templates/GroupAddTemplate/GroupAddTemplate.vue';
import * as Config from '@/config';
export interface DataType {
  gateItems: any;
  userItems: any;
  loading: boolean;
  successMessageLogs: string;
  errorMessageLogs: string;
  groupDetail: any;
  isEdit: boolean;
  dataTimeByGroup: any;
  dataAllTimes: any;
  dataAllUses: any;
  dataAllGates: any;
  groupId: string;
  UserSubmittedFail: any;
  GateSubmittedFail: any;
  expand: number;
  gateItemsOrigin: any;
  userItemsOrigin: any;
  timeDefault: string;
  isFinishGetData: number;
  loadingCircle: number;
  textCircle: string;
}
export default Vue.extend({
  name: 'GroupAddPage',
  components: {
    GroupAddTemplate,
  },
  data: (): DataType => ({
    loadingCircle: 0,
    textCircle: '',
    gateItems: [],
    userItems: [],
    dataTimeByGroup: [],
    loading: false,
    successMessageLogs: '',
    errorMessageLogs: '',
    isEdit : false,
    groupDetail:
      {
        groupId: '',
        groupName: '',
        isValid: true,
        passableTimeSettingId: '',
        passableTimeSetting: {
          passableTimeSettingId: '',
          name: '',
          isDefault: true,
          timeSettings: [],
        },
      },
    dataAllUses: [],
    dataAllGates: [],
    groupId: '',
    dataAllTimes: [],
    expand: 0,
    gateItemsOrigin: [],
    userItemsOrigin: [],
    isFinishGetData: 0,
    timeDefault: '',
    UserSubmittedFail: [],
    GateSubmittedFail: [],
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    loadingCircle(value) {
      this.$emit('loadingCircle', value);
    },
    textCircle(value) {
      this.$emit('textCircle', value);
    },
    isFinishGetData(value) {
      if (value === 5) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getAllUsers(1);
    this.getAllGates(1);
    this.getAllPassable(1); // Phase 2
    const dataQuery = this.$route.query;
    if (dataQuery.groupId !== undefined) {
      this.getUserByGroupId(1, dataQuery.groupId);
      this.getGateByGroupId(1, dataQuery.groupId);
      this.getGroupDetail(dataQuery.groupId);
      this.isEdit = true;
    } else {
      this.isEdit = false;
      this.isFinishGetData += 3;
    }
  },
  methods: {
    clickBackGroup() {
      this.$router.push({ path: Config.GROUP});
    },
    changeExpanded(data: any): any {
      this.loading = true;
      if (data.item.passableTimeSettingId !== undefined && data.item.passableTimeSettingId !== '') {
        // this.loading = true;
        this.getAllPassableById(data.item.passableTimeSettingId);
        this.loading = false;
      } else {
        this.loading = false;
        this.expand++;
      }
    },
    getGroupDetail(groupId: any) {
      this.loading = true;
      const apiLink = Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(groupId);
      this.$http.get(apiLink)
        .then((response: any) => {
          this.loading = false;
          this.resetData();
          if (!this.isEmpty(response.body.groups) && response.body.groups.length > 0) {
            this.groupDetail = response.body.groups[0];
          } else {
            this.errorMessageLogs = Config.ERROR_DEFAULT;
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.resetData();
          console.log('err: ', err);
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    getAllUsers(page: any) {
      this.$http.get(Config.USERS_LIST_API + '?limit=1000&page=' + page + '&sortKey=desc:userId&isExceptOptionalUser=true&isValid=true&exclude=thumbnail')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.users.forEach((element: any) => {
              this.dataAllUses.push({text: element.name, value: element.userId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getAllUsers(page + 1);
            } else {
              ++this.isFinishGetData;
            }
          } else {
            this.dataAllUses = [];
            ++this.isFinishGetData;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          ++this.isFinishGetData;
        }); // catch
    },
    getAllGates(page: any) {
      this.$http.get(Config.GATES_LIST_API + '?limit=1000&page=' + page + '&sortKey=desc:registered&isExceptOptionalGate=true&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.gates.forEach((element: any) => {
              this.dataAllGates.push({text: element.gateName, value: element.gateId,
              gateModelCode: element.gateModelCode});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getAllGates(page + 1);
            } else {
              ++this.isFinishGetData;
            }
          } else {
            this.dataAllGates = [];
            ++this.isFinishGetData;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          ++this.isFinishGetData;
        }); // catch
    },
    getUserByGroupId(page: any, groupId: any) {
      this.$http.get(Config.GROUP_PASSAGE + '?groupId=' + encodeURIComponent(groupId) + '&limit=1000&page=' + page + '&sortKey=desc:userId&embed=user')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groupPassages.forEach((element: any) => {
              if (element.user !== null && element.user !== undefined) {
                this.userItems.push({text: element.user.name, value: element.user.userId});
                this.userItemsOrigin.push({text: element.user.name, value: element.user.userId,
                groupPassageId: element.groupPassageId});
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getUserByGroupId(page + 1, groupId);
            } else {
              ++this.isFinishGetData;
            }
          } else {
            this.userItems = [];
            this.userItemsOrigin = [];
            ++this.isFinishGetData;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          ++this.isFinishGetData;
        }); // catch
    },
    getGateByGroupId(page: any, groupId: any) {
      this.$http.get(Config.RELATION_GATE_GROUP +
      '?groupId=' + encodeURIComponent(groupId) + '&limit=1000&page=' + page + '&sortKey=desc:gateId&embed=gate&isExceptOptionalGate=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.relationsGateGroup.forEach((element: any) => {
              if (element.gate !== null && element.gate !== undefined) {
                this.gateItems.push({text: element.gate.name, value: element.gate.gateId,
                  gateModelCode: element.gate.gateModelCode,
                });
                this.gateItemsOrigin.push({text: element.gate.name, value: element.gate.gateId,
                relationId: element.relationId});
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGateByGroupId(page + 1, groupId);
            } else {
              ++this.isFinishGetData;
            }
          } else {
            this.gateItems = [];
            this.gateItemsOrigin = [];
            ++this.isFinishGetData;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          ++this.isFinishGetData;
        }); // catch
    },
    getAllPassableById(passableTimeSettingId: any) {
      const apiLink = Config.PASSABLE_TIME_SETTING + '?passableTimeSettingId=' +
      encodeURIComponent(passableTimeSettingId);
      this.$http.get(apiLink)
        .then((response: any) => {
          this.groupDetail.passableTimeSetting = response.body.passableTimeSettings[0];
          this.expand++;
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        }); // catch
    },
    getAllPassable(page: any) {
      const apiLink = Config.PASSABLE_TIME_SETTING + '?limit=1000&page=' + page;
      this.dataAllTimes = [];
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.passableTimeSettings.forEach((element: any) => {
              this.dataAllTimes.push({text: element.name, value: element.passableTimeSettingId});
              if (element.isDefault === true) {
                this.timeDefault = element.passableTimeSettingId;
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getAllPassable(page + 1);
            } else {
              ++this.isFinishGetData;
            }
          } else {
            this.dataAllTimes = [];
            ++this.isFinishGetData;
          }
        })
        .then( null, (err: any) => {
          ++this.isFinishGetData;
          console.log('err: ', err);
          }); // catch
    },
    async clickSaveGroup(eventArgs: any) {
      console.log('eventArgs', eventArgs);
      this.loading = true;
      this.UserSubmittedFail = [];
      this.GateSubmittedFail = [];
      // const groupName = eventArgs.groupName;
      // this.postUserAndGate(eventArgs);
      this.resetData();
      let result;
      this.loadingCircle = 0;
      this.textCircle = 'グループ登録';
      result = await this.postGroup(eventArgs);
      if (result) {
      const stepLoading = Math.round(100 / (eventArgs.dataUserId.length));
      const stepLoadingGate = Math.round(100 / (eventArgs.dataGateId.length));
      this.waitforme(100)
      .then(() => {
        this.loadingCircle = 100;
      })
      .then(() => this.waitforme(1000))
      .then(() => {
        this.loadingCircle = 0;
        this.textCircle = 'ゲート登録';
      })
      .then(() => this.postGatesGroup(eventArgs.dataGateId, this.groupId, stepLoadingGate))
      .then(() => {
        if (eventArgs.dataGateId.length === 0) {
          this.loadingCircle = 100;
        }
      })
      .then(() => this.waitforme(1000))
      .then(() => {
        this.loadingCircle = 0;
        this.textCircle = 'ユーザー登録';
      })
      .then(() => this.postUsersGroup(eventArgs.dataUserId, this.groupId, stepLoading))
      .then(() => {
        if (eventArgs.dataUserId.length === 0) {
          this.loadingCircle = 100;
        }
      })
      .then(() => this.waitforme(500))
      .then(() => {
        this.loading = false;
      });
      } else {
        this.waitforme(100)
        .then(() => {
          this.loadingCircle = 100;
          this.loading = false;
        });
      }
    },
    postGroup(data: any) {
      return new Promise((resolve, reject) => {
        let dataInfo;
        const times =  data.dataTime;
        let passableId = '';
        if (times !== null && times.value !== undefined && times.value !== null && times.value !== '') {
          passableId = times.value;
        } else {
          passableId = this.timeDefault;
        }
        if (passableId === '') {
          dataInfo = {
            name: data.groupName,
            isValid: true,
          };
        } else {
          dataInfo = {
            name: data.groupName,
            isValid: true,
            passableTimeSettingId: passableId,
          };
        }
        this.$http.post(Config.GROUP_LIST_API, dataInfo)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.successMessageLogs = Config.ADD_GROUP_API_SUCCESS;
              this.groupId = response.body.groupId;
              resolve(true);
            } else {
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageLogs = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                this.errorMessageLogs = Config.ERROR_403;
                break;
              case 409:
                this.errorMessageLogs = '入力されたグループ名は既に使用されています。他のグループ名を入力してください。';
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
          }); // catch
      });
    },
    async postUsersGroup(data: any, ID: any, stepLoading: any) {
      if (data.length > 0) {
          for (const user of data) {
              const dataItem = {
                userId: user.value,
                groupId: ID,
              };
              await this.postUsersGroupItem(dataItem, user.text);
              this.loadingCircle = this.loadingCircle + stepLoading;
          }
        }
    },
    postUsersGroupItem(data: any, userName: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.GROUP_PASSAGE, data)
        .then((response: any) => {
          if (response.body.message === 'Success') {
            console.log('Success');
          } else {
            this.UserSubmittedFail.push(userName);
          }
          resolve(true);
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.UserSubmittedFail.push(userName);
          resolve(true);
        }); // catch
      });
    },
    async postGatesGroup(data: any, ID: any, stepLoadingGate: any) {
      if (data.length > 0) {
        for (const gate of data) {
            const dataItem = {
              gateId: gate.value,
              groupId: ID,
            };
            await this.postGatesGroupItem(dataItem, gate.text);
            this.loadingCircle = this.loadingCircle + stepLoadingGate;
        }
      }
    },
    postGatesGroupItem(data: any, gateName: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.RELATION_GATE_GROUP, data)
        .then((response: any) => {
          if (response.body.message === 'Success') {
            console.log('Success');
          } else {
            this.GateSubmittedFail.push(gateName);
          }
          resolve(true);
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.GateSubmittedFail.push(gateName);
          resolve(true);
        }); // catch
      });
    },
    waitforme(milisec: any) {
      return new Promise<void>((resolve, reject) => {
        setTimeout(() => { resolve(); }, milisec);
      });
    },
    clickUpdateGroup(eventArgs: any) {
      this.loading = true;
      this.resetData();
      this.GateSubmittedFail = [];
      this.UserSubmittedFail = [];
      console.log('eventArgs', eventArgs);
      const gateListForm = eventArgs.dataGateId;
      const gateList = this.gateItemsOrigin;
      const userListForm = eventArgs.dataUserId;
      const userList = this.userItemsOrigin;
      // gate delete
      const deGateData = gateList.filter((o1: { value: any; }) =>
      !gateListForm.some((o2: { value: any; }) => o1.value === o2.value));
      // gate add
      const postGateData = gateListForm.filter((o1: { value: any; }) =>
      !gateList.some((o2: { value: any; }) => o1.value === o2.value));
      // user delete
      const deUserData = userList.filter((o1: { value: any; }) =>
      !userListForm.some((o2: { value: any; }) => o1.value === o2.value));
      // user add
      const postUserData = userListForm.filter((o1: { value: any; }) =>
      !userList.some((o2: { value: any; }) => o1.value === o2.value));
      console.log(deUserData);
      console.log('deGateData', deGateData);
      const stepLoading = Math.round(100 / (deUserData.length + postUserData.length));
      const stepLoadingGate = Math.round(100 / (deGateData.length + postGateData.length));
      this.loadingCircle = 0;
      this.textCircle = 'グループ登録';
      this.putGroup(eventArgs)
      .then(() => this.waitforme(100))
      .then(() => {
        this.loadingCircle = 100;
      })
      .then(() => this.waitforme(1000))
      .then(() => {
        this.loadingCircle = 0;
        this.textCircle = 'ゲート登録';
      })
      .then(() => this.delGateGroup(deGateData, stepLoadingGate))
      .then(() => this.postGatesGroup(postGateData, eventArgs.groupId, stepLoadingGate))
      .then(() => {
        if (deGateData.length + postGateData.length === 0) {
          this.loadingCircle = 100;
        }
      })
      .then(() => this.waitforme(1000))
      .then(() => {
        this.loadingCircle = 0;
        this.textCircle = 'ユーザー登録';
      })
      .then(() => this.delUserGroup(deUserData, stepLoading))
      .then(() => this.postUsersGroup(postUserData, eventArgs.groupId, stepLoading))
      .then(() => {
        if (deUserData.length + postUserData.length === 0) {
          this.loadingCircle = 100;
        }
      })
      .then(() => this.waitforme(500))
      .then(() => {
        this.loading = false;
      })
      .then(() => {
        this.gateItemsOrigin = [];
        this.userItemsOrigin = [];
        this.userItems = [];
        this.gateItems = [];
        this.getUserByGroupId(1, eventArgs.groupId);
        this.getGateByGroupId(1, eventArgs.groupId);
      });
    },
    async delUserGroup(data: any, stepLoading: any) {
      if (data.length > 0) {
        for (const item of data) {
          await this.delUserGroupItem(item.groupPassageId, item.text);
          this.loadingCircle = this.loadingCircle + stepLoading;
        }
      }
    },
    delUserGroupItem(groupPassageId: any, userName: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GROUP_PASSAGE +
        '/' + encodeURIComponent(groupPassageId))
          .then((response: any) => {
            if (response.body.message === 'Success') {
              console.log('Success');
            } else {
              this.UserSubmittedFail.push(userName);
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            this.UserSubmittedFail.push(userName);
            resolve(true);
          }); // catch
      });
    },
    async delGateGroup(data: any, stepLoadingGate: any) {
      if (data.length > 0) {
        for (const item of data) {
          await this.delGateGroupItem(item.relationId, item.text);
          this.loadingCircle = this.loadingCircle + stepLoadingGate;
        }
      }
    },
    delGateGroupItem(relationId: any, gateName: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_GATE_GROUP +
        '/' + encodeURIComponent(relationId))
          .then((response: any) => {
            if (response.body.message === 'Success') {
              console.log('Success');
            } else {
              this.GateSubmittedFail.push(gateName);
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            this.GateSubmittedFail.push(gateName);
            resolve(true);
          }); // catch
      });
    },
    putGroup(eventArgs: any) {
      return new Promise<void>((resolve, reject) => {
        const times =  eventArgs.dataTime;
        const groupId =  eventArgs.groupId;
        let  passableId = '';
        let data;
        if (times !== null && times.value !== undefined &&
        times.value !== null && times.value !== '') {
          passableId = times.value;
        } else {
          passableId = this.timeDefault;
        }
        if (passableId === '') {
          data = {
            name: eventArgs.groupName,
          };
        } else {
          data = {
            name: eventArgs.groupName,
            passableTimeSettingId: passableId,
          };
        }
        this.resetData();
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.successMessageLogs = Config.EDIT_GROUP_API_SUCCESS;
              this.groupId = response.body.groupId;
              resolve();
            } else {
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              resolve();
            }
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                this.errorMessageLogs = Config.ERROR_403;
                break;
              case 409:
                this.errorMessageLogs = '入力されたグループ名は既に使用されています。他のグループ名を入力してください。';
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            resolve();
          }); // catch
      });
    },
    // Phase 2
    // postUserAndGate(data: any) {
    //   return new Promise<void>((resolve, reject) => {
    //     const arrayUserIds = [];
    //     const arrayGateIds = [];
    //     if (!this.isEmpty(data.dataUserId)) {
    //       for (const user of data.dataUserId) {
    //           arrayUserIds.push(user.value);
    //       }
    //     }
    //     if (!this.isEmpty(data.dataGateId)) {
    //       for (const gate of data.dataGateId) {
    //           arrayGateIds.push(gate.value);
    //       }
    //     }
    //     console.log('arrayUserIds', arrayUserIds);
    //     console.log('arrayGateIds', arrayGateIds);

    //     let dataInfo;
    //     const times =  data.dataTime;
    //     if (times.length > 0) {
    //       dataInfo = {
    //         groups: [
    //           {
    //             name: data.groupName,
    //             isValid: true,
    //             userIds: arrayUserIds,
    //             gateIds: arrayGateIds,
    //             passableTimeSettingId: times[0].passableTimeSettingId,
    //           },
    //         ],
    //       };
    //     } else {
    //       dataInfo = {
    //         groups: [
    //           {
    //             name: data.groupName,
    //             isValid: true,
    //             userIds: arrayUserIds,
    //             gateIds: arrayGateIds,
    //           },
    //         ],
    //       };
    //     }

    //     this.$http.post(Config.GROUPS_LIST_API, dataInfo)
    //       .then((response: any) => {
    //         if (response.body.message === 'Success') {
    //           this.loading = false;
    //           this.successMessageLogs = Config.ADD_GROUP_API_SUCCESS;
    //           this.groupId = response.body.groupId;
    //           resolve();
    //         } else {
    //           this.loading = false;
    //           this.errorMessageLogs = Config.ERROR_DEFAULT;
    //           resolve();
    //         }
    //       })
    //       .then( null, (err: any) => {
    //         switch (err.status) {
    //           case 400:
    //             this.errorMessageLogs = '入力したパラメーターが誤っています。入力内容をご確認ください。';
    //             break;
    //           case 403:
    //             this.errorMessageLogs = Config.ERROR_403;
    //             break;
    //           case 409:
    //             this.errorMessageLogs = Config.ERROR_409;
    //             break;
    //           case 500:
    //             this.errorMessageLogs = Config.ERROR_500;
    //             break;
    //           case 503:
    //             this.errorMessageLogs = Config.ERROR_503;
    //             break;
    //           default:
    //             this.errorMessageLogs = Config.ERROR_DEFAULT;
    //             break;
    //         }
    //         this.loading = false;
    //         resolve();
    //       }); // catch
    //   });
    // },
    resetData() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
