var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("control-panel-detail-template", {
    attrs: {
      loading: _vm.loading,
      errorMessageModal: _vm.errorMessageModal,
      successMessageModal: _vm.successMessageModal,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      controlPanelDetail: _vm.controlPanelDetail,
      gateList: _vm.gateList,
      showModal: _vm.showModal,
      showModalClear: _vm.showModalClear,
      dataAllUses: _vm.dataAllUses,
      errorMessageProcess: _vm.errorMessageProcess,
      successItemProcess: _vm.successItemProcess,
      totalDataRowProcess: _vm.totalDataRowProcess,
      showProgress: _vm.showProgress,
      countUsers: _vm.countUsers,
      isFinishProcess: _vm.isFinishProcess,
      progress: _vm.progress
    },
    on: {
      clickReconfigure: function($event) {
        return _vm.clickReconfigure($event)
      },
      closeModalProgress: function($event) {
        return _vm.closeModalProgress($event)
      },
      closeModalClear: function($event) {
        return _vm.closeModalClear($event)
      },
      clickConfirmClear: function($event) {
        return _vm.clickConfirmClear($event)
      },
      clickAntiPassBackClear: function($event) {
        return _vm.clickAntiPassBackClear($event)
      },
      clickPostReconfigure: function($event) {
        return _vm.postReconfigure($event)
      },
      closeModal: function($event) {
        return _vm.closeModal($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }