var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-key-add-form" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-form",
            { ref: "CardAddForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _vm._v("利用開始日時")
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "1" } }, [
                        _vm._v("いまから")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.listTimes,
                              "item-text": "text",
                              "item-value": "value",
                              "single-line": "",
                              color: "green",
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                              clearable: "",
                              "no-data-text": ""
                            },
                            model: {
                              value: _vm.passagesData.terms,
                              callback: function($$v) {
                                _vm.$set(_vm.passagesData, "terms", $$v)
                              },
                              expression: "passagesData.terms"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _vm._v("後まで通門可能")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _vm._v("許可ゲート")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.existGateId,
                              "item-text": "gateName",
                              "item-value": "gateId",
                              "single-line": "",
                              color: "green",
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                              clearable: "",
                              "no-data-text": "",
                              disabled: _vm.passagesData.terms === "0"
                            },
                            model: {
                              value: _vm.passagesData.gateId,
                              callback: function($$v) {
                                _vm.$set(_vm.passagesData, "gateId", $$v)
                              },
                              expression: "passagesData.gateId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "grey",
                              dense: "",
                              "hide-details": "",
                              label: "1回限り有効"
                            },
                            model: {
                              value: _vm.passagesData.isOnce,
                              callback: function($$v) {
                                _vm.$set(_vm.passagesData, "isOnce", $$v)
                              },
                              expression: "passagesData.isOnce"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                outlined: "",
                                disabled:
                                  _vm.passagesData.gateId === "0" ||
                                  _vm.passagesData.terms === "0"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.geneKey()
                                }
                              }
                            },
                            [_vm._v("鍵共有キー発行")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("div", { staticStyle: { padding: "10px 0px" } }, [
                          _vm._v(
                            "下記の発行されたURLをコピーして利用者に共有してください。"
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              color: "green",
                              "hide-details": "auto",
                              type: "text",
                              outlined: "",
                              dense: "",
                              rows: "2",
                              autocomplete: "off",
                              readonly: "",
                              disabled: "",
                              id: "geneLink"
                            },
                            model: {
                              value: _vm.passagesData.result,
                              callback: function($$v) {
                                _vm.$set(_vm.passagesData, "result", $$v)
                              },
                              expression: "passagesData.result"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.coppyClipboard()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              クリップボードへコピー\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }