var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        { staticClass: "pa-6 pr-10 mb-5", attrs: { elevation: "3" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "ml-4 mb-4 text-subtitle-2 font-weight-bold" },
                [
                  _vm._v("\n        TBEYE連携サービス\n        "),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href:
                          "https://support.alligate.me/hc/ja/articles/25190258064409"
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-information")])],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("relations-tb-eye-list", {
            attrs: {
              configurableGates: _vm.configurableGates,
              tbeyeRelationGateDevices: _vm.tbeyeRelationGateDevices,
              addNewRelationGateDevices: _vm.addNewRelationGateDevices,
              loading: _vm.loading,
              loginInfos: _vm.tbeyeLoginInfos,
              putDelLoading: _vm.putDelLoading,
              postLoading: _vm.postLoading,
              results: _vm.results
            },
            on: {
              "add-new-record": function($event) {
                return _vm.$emit("add-new-record", $event)
              },
              "del-new-record": function($event) {
                return _vm.$emit("del-new-record", $event)
              },
              "form-update": function($event) {
                return _vm.$emit("form-update", $event)
              },
              "new-form-update": function($event) {
                return _vm.$emit("new-form-update", $event)
              },
              "put-record": function($event) {
                return _vm.$emit("put-record", $event)
              },
              "post-record": function($event) {
                return _vm.$emit("post-record", $event)
              },
              "delete-record": function($event) {
                return _vm.$emit("delete-record", $event)
              },
              "update-login-info": function($event) {
                return _vm.$emit("update-login-info", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }