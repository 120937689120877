var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "allig-nav",
      attrs: {
        app: "",
        clipped: "",
        "expand-on-hover": "",
        "mini-variant": _vm.mini
      },
      on: {
        "update:miniVariant": function($event) {
          _vm.mini = $event
        },
        "update:mini-variant": function($event) {
          _vm.mini = $event
        }
      },
      model: {
        value: _vm.innerShow,
        callback: function($$v) {
          _vm.innerShow = $$v
        },
        expression: "innerShow"
      }
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { "active-class": "lighten-2" },
              model: {
                value: _vm.selectedItem,
                callback: function($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem"
              }
            },
            [
              _c(
                "v-list-item",
                {
                  class: { "v-list-item--active": _vm.selectedItemNav === 0 },
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("menuClick", "dashboard")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-home")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-title", [_vm._v("ホーム")])
                ],
                1
              ),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    {
                      class: {
                        "v-list-item--active": _vm.selectedItemNav === 1
                      },
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("menuClick", "user")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-account")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [_vm._v("ユーザー")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    {
                      class: {
                        "v-list-item--active": _vm.selectedItemNav === 2
                      },
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("menuClick", "groups")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-account-multiple")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [_vm._v("グループ")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    {
                      class: {
                        "v-list-item--active": _vm.selectedItemNav === 3
                      },
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("menuClick", "cards")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", [
                            _vm._v("mdi-badge-account-horizontal-outline")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [_vm._v("カード")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    {
                      class: {
                        "v-list-item--active": _vm.selectedItemNav === 4
                      },
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("menuClick", "gates")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-door-closed")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [_vm._v("ゲート")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  class: { "v-list-item--active": _vm.selectedItemNav === 5 },
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("menuClick", "log")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-format-list-bulleted")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-title", [_vm._v("ログ情報")])
                ],
                1
              ),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    {
                      class: {
                        "v-list-item--active": _vm.selectedItemNav === 6
                      },
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("menuClick", "in-room-management")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-account-clock")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [_vm._v("在室状況")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    {
                      class: {
                        "v-list-item--active": _vm.selectedItemNav === 7
                      },
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("menuClick", "setting")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-cog")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [_vm._v("システム設定")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    {
                      class: {
                        "v-list-item--active": _vm.selectedItemNav === 10
                      },
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("menuClick", "key-add")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-checkbox-marked-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [_vm._v("鍵共有")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    {
                      class: {
                        "v-list-item--active": _vm.selectedItemNav === 9
                      },
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("menuClick", "external")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-puzzle")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [_vm._v("外部システム連携")])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }