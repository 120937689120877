

















































































































































import Vue, { PropType } from 'vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import { NoticeSettingType, NoticeSetting, NoticeSettingTarget } from '@/types/alligate';

interface DataType {
  isDisplaySuccessMessage: boolean;
  isDisplayErrorMessage: boolean;
}

export default Vue.extend({
  name: 'NoticeSettingTarget',
  components: {
    Modal,
    Loading,
    InputForm,
    SuccessButton,
    CancelButton,
    IconButton,
    Alert,
  },
  data: (): DataType => {
    return {
      isDisplaySuccessMessage: false,
      isDisplayErrorMessage: false,
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    /** 新規追加モード(new)か編集モード(edit)のどちらかを選ぶ */
    mode: {
      type: String,
      default: () => 'new',
    },
    isLoadBlockingNoticeSetting: {
      type: Boolean,
      default: () => true,
    },
    loadBlockingTextNoticeSetting: {
      type: String,
      default: () => '',
    },
    successMessageLogsModal: {
      type: String,
      default: () => '',
    },
    errorMessageLogsModal: {
      type: String,
      default: () => '',
    },
    value: {
      type: Object as PropType<NoticeSetting>,
      default: () => {
        return {
          name: '',
          noticeSettingId: '',
          noticeSettingTypeId: '',
          isValid: true,
          option: '',
          emails: [],
          targets: [],
        };
      },
    },
    noticeSettingType: {
      type: Object as PropType<NoticeSettingType>,
      default: () => {
        return {
          noticeSettingTypeId: 'miss',
          description: '未設定',
          name: '未設定',
          targets: [],
        };
      },
    },
    configurableUsers: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    configurableGates: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    configurablePcodes: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    configurableLogs: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    configurableConnectStatus: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
  },
  watch: {
    successMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplaySuccessMessage = true;
        if (this.mode === 'new') {
          // 新規追加時の成功時にのみクリア
          this.value.name = '';
          this.value.noticeSettingId = '';
          this.value.option = '';
          this.value.emails = [''];
          this.value.targets = [];
        }
      } else {
        this.isDisplaySuccessMessage = false;
      }
    },
    errorMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplayErrorMessage = true;
      } else {
        this.isDisplayErrorMessage = false;
      }
    },
  },
  computed: {
    innerEditNoticeSettingName: {
        get(): string {
          return this.value.name;
        },
        set(val) {
          this.$emit('input', { ...this.value, name: val });
        },
    },
    innerEditNoticeSettingId: {
        get(): string {
          return this.value.noticeSettingId;
        },
        set(val) {
          this.$emit('input', { ...this.value, noticeSettingId: val });
        },
    },
    innerEditNoticeSettingTypeId: {
        get(): string {
          return this.value.noticeSettingTypeId;
        },
        set(val) {
          this.$emit('input', { ...this.value, noticeSettingTypeId: val });
        },
    },
    innerEditNoticeSettingIsValid: {
        get(): boolean {
          return this.value.isValid;
        },
        set(val) {
          this.$emit('input', { ...this.value, isValid: val });
        },
    },
    innerEditNoticeSettingOption: {
        get(): string {
          return this.value.option;
        },
        set(val) {
          this.$emit('input', { ...this.value, option: val });
        },
    },
    innerEditNoticeSettingEmails: {
        get(): string[] {
          if (this.value.emails.length === 0) {
              return [''];
          } else {
              return this.value.emails;
          }
        },
        set(val) {
          this.$emit('input', { ...this.value, emails: val });
        },
    },
    innerEditNoticeSettingTargets: {
        get(): NoticeSettingTarget[] {
          return this.value.targets;
        },
        set(val) {
          this.$emit('input', { ...this.value, targets: val });
        },
    },
    userIdTargetIndex: {
      get(): number {
        const typeTarget: any | undefined = this.noticeSettingType.targets
          .find((target) => target.targetColumn === 'userId');
        if (typeTarget === undefined) { return -1; }
        const targetId: string = typeTarget.noticeSettingTypeTargetId;
        let retIndex = -1;
        for (let index: number = 0; index < this.value.targets.length; index++) {
          if (targetId === this.value.targets[index].noticeSettingTypeTargetId) {
            retIndex = index;
            break;
          }
        }
        return retIndex;
      },
    },
    userIds: {
      get(): string[] {
        if (this.userIdTargetIndex === -1) {
          return [];
        } else {
          return this.value.targets[this.userIdTargetIndex].targetValues;
        }
      },
      set(userIds: string[]): void {
        if (this.userIdTargetIndex === -1) {
          const typeTarget: any | undefined = this.noticeSettingType.targets
            .find((target) => target.targetColumn === 'userId');
          if (typeTarget === undefined) {
            return ;
          }
          const targetId: string = typeTarget.noticeSettingTypeTargetId;
          const targets: NoticeSettingTarget[] = JSON.parse(JSON.stringify(this.value.targets));
          targets.push({
            noticeSettingTypeTargetId: targetId,
            targetValues: userIds,
          });
          this.$emit('input', { ...this.value, targets });
        } else {
          const targets = JSON.parse(JSON.stringify(this.value.targets));
          targets[this.userIdTargetIndex].targetValues = userIds;
          this.$emit('input', { ...this.value, targets });
        }
      },
    },
    pCodeIdTargetIndex: {
      get(): number {
        const typeTarget: any | undefined = this.noticeSettingType.targets
          .find((target) => target.targetColumn === 'deviceId');
        if (typeTarget === undefined) { return -1; }
        const targetId: string = typeTarget.noticeSettingTypeTargetId;
        let retIndex = -1;
        for (let index: number = 0; index < this.value.targets.length; index++) {
          if (targetId === this.value.targets[index].noticeSettingTypeTargetId) {
            retIndex = index;
            break;
          }
        }
        return retIndex;
      },
    },
    pCodeIds: {
      get(): string[] {
        if (this.pCodeIdTargetIndex === -1) {
          return [];
        } else {
          return this.value.targets[this.pCodeIdTargetIndex].targetValues;
        }
      },
      set(pCodeIds: string[]): void {
        if (this.pCodeIdTargetIndex === -1) {
          const typeTarget: any | undefined = this.noticeSettingType.targets
            .find((target) => target.targetColumn === 'deviceId');
          if (typeTarget === undefined) {
            return ;
          }
          const targetId: string = typeTarget.noticeSettingTypeTargetId;
          const targets: NoticeSettingTarget[] = JSON.parse(JSON.stringify(this.value.targets));
          targets.push({
            noticeSettingTypeTargetId: targetId,
            targetValues: pCodeIds,
          });
          this.$emit('input', { ...this.value, targets });
        } else {
          const targets = JSON.parse(JSON.stringify(this.value.targets));
          targets[this.pCodeIdTargetIndex].targetValues = pCodeIds;
          this.$emit('input', { ...this.value, targets });
        }
      },
    },
    logIdTargetIndex: {
      get(): number {
        const typeTarget: any | undefined = this.noticeSettingType.targets
          .find((target) => target.targetColumn === 'logId');
        if (typeTarget === undefined) { return -1; }
        const targetId: string = typeTarget.noticeSettingTypeTargetId;
        let retIndex = -1;
        for (let index: number = 0; index < this.value.targets.length; index++) {
          if (targetId === this.value.targets[index].noticeSettingTypeTargetId) {
            retIndex = index;
            break;
          }
        }
        return retIndex;
      },
    },
    logIds: {
      get(): string[] {
        if (this.logIdTargetIndex === -1) {
          return [];
        } else {
          return this.value.targets[this.logIdTargetIndex].targetValues;
        }
      },
      set(logIds: string[]): void {
        if (this.logIdTargetIndex === -1) {
          const typeTarget: any | undefined = this.noticeSettingType.targets
            .find((target) => target.targetColumn === 'logId');
          if (typeTarget === undefined) {
            return ;
          }
          const targetId: string = typeTarget.noticeSettingTypeTargetId;
          const targets: NoticeSettingTarget[] = JSON.parse(JSON.stringify(this.value.targets));
          targets.push({
            noticeSettingTypeTargetId: targetId,
            targetValues: logIds,
          });
          this.$emit('input', { ...this.value, targets });
        } else {
          const targets = JSON.parse(JSON.stringify(this.value.targets));
          targets[this.logIdTargetIndex].targetValues = logIds;
          this.$emit('input', { ...this.value, targets });
        }
      },
    },
    gateIdTargetIndex: {
      get(): number {
        const typeTarget: any | undefined = this.noticeSettingType.targets
          .find((target) => target.targetColumn === 'gateId');
        if (typeTarget === undefined) { return -1; }
        const targetId: string = typeTarget.noticeSettingTypeTargetId;
        let retIndex = -1;
        for (let index: number = 0; index < this.value.targets.length; index++) {
          if (targetId === this.value.targets[index].noticeSettingTypeTargetId) {
            retIndex = index;
            break;
          }
        }
        return retIndex;
      },
    },
    gateIds: {
      get(): string[] {
        if (this.gateIdTargetIndex === -1) {
          return [];
        } else {
          return this.value.targets[this.gateIdTargetIndex].targetValues;
        }
      },
      set(gateIds: string[]): void {
        if (this.gateIdTargetIndex === -1) {
          const typeTarget: any | undefined = this.noticeSettingType.targets.find((target) => target.targetColumn === 'gateId');
          if (typeTarget === undefined) {
            return ;
          }
          const targetId: string = typeTarget.noticeSettingTypeTargetId;
          const targets: NoticeSettingTarget[] = JSON.parse(JSON.stringify(this.value.targets));
          targets.push({
            noticeSettingTypeTargetId: targetId,
            targetValues: gateIds,
          });
          this.$emit('input', { ...this.value, targets });
        } else {
          const targets = JSON.parse(JSON.stringify(this.value.targets));
          targets[this.gateIdTargetIndex].targetValues = gateIds;
          this.$emit('input', { ...this.value, targets });
        }
      },
    },
    connectStatusTargetIndex: {
      get(): number {
        const typeTarget: any | undefined = this.noticeSettingType.targets.find((target) => target.targetColumn === 'connectStatus');
        if (typeTarget === undefined) { return -1; }
        const targetId: string = typeTarget.noticeSettingTypeTargetId;
        let retIndex = -1;
        for (let index: number = 0; index < this.value.targets.length; index++) {
          if (targetId === this.value.targets[index].noticeSettingTypeTargetId) {
            retIndex = index;
            break;
          }
        }
        return retIndex;
      },
    },
    connectStatuses: {
      get(): string[] {
        if (this.connectStatusTargetIndex === -1) {
          return [];
        } else {
          return this.value.targets[this.connectStatusTargetIndex].targetValues;
        }
      },
      set(connectStatuses: string[]): void {
        if (this.connectStatusTargetIndex === -1) {
          const typeTarget: any | undefined = this.noticeSettingType.targets.find((target) => target.targetColumn === 'connectStatus');
          if (typeTarget === undefined) {
            return ;
          }
          const targetId: string = typeTarget.noticeSettingTypeTargetId;
          const targets: NoticeSettingTarget[] = JSON.parse(JSON.stringify(this.value.targets));
          targets.push({
            noticeSettingTypeTargetId: targetId,
            targetValues: connectStatuses,
          });
          this.$emit('input', { ...this.value, targets });
        } else {
          const targets = JSON.parse(JSON.stringify(this.value.targets));
          targets[this.connectStatusTargetIndex].targetValues = connectStatuses;
          this.$emit('input', { ...this.value, targets });
        }
      },
    },
  },
  methods: {
    close(): void {
      this.$emit('reset-msg');
      this.$emit('close');
      return ;
    },
    /** 渡された要素が一個以上かチェックする */
    isValidateLengthOneMore(values: string[]): string | boolean {
      if (values && values.length > 0) {
        return true;
      } else {
        return '必須項目のため、一つ以上の指定が必須です。';
      }
    },
    isValidateEmail(input: string): string | boolean {
      interface ValidRegex {
        regexp: RegExp;
        errorMessage: string;
      }
      const validRegex: ValidRegex[] = [];
      // validRegecにpushした順でvalidationし先に引っかかったエラーメッセージを出力する
      // TODO: validationを増やす場合は
      validRegex.push({
        regexp: /^.+@.+$/,
        errorMessage: 'メールアドレスの形式が異なります。',
      });
      validRegex.push({
        regexp: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        errorMessage: 'メールアドレスに使用できない文字列が含まれています。使用可能な文字列は英字、数字、記号(.!#$%&\'*+/=?^_\`{|}~-)が使用可能です',
      });

      let ret: string|boolean = true;
      for (let index = 0; validRegex.length > index; index++) {
        if (!input.match(validRegex[index].regexp)) {
          ret = validRegex[index].errorMessage;
          break;
        }
      }
      return ret;
    },
    addNewEmail(): void {
      const emails: string[] = JSON.parse(JSON.stringify(this.value.emails));
      emails.push('');
      this.$emit('input', { ...this.value, emails});
    },
    deleteEmail(index: number): void {
      const emails: string[] = JSON.parse(JSON.stringify(this.value.emails));
      emails.splice(index, 1);
      this.$emit('input', { ...this.value, emails});
    },
    splitEmail(val: string, emailsIndex: number): void {
      const splitEmails: string[] = val.split(',');
      const before = this.value.emails.slice(0, emailsIndex);
      const after = this.value.emails.slice(emailsIndex + 1, this.value.emails.length);

      const emails = before.concat(splitEmails).concat(after);
      this.$emit('input', { ...this.value, emails});
    },
    clickAddPostNoticeSetting(): void {
      const validate = (this.$refs.formNoticeSetting as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('click-add-notice-setting', this.value);
      }
    },
    clickEditPutNoticeSetting(): void {
      const validate = (this.$refs.formNoticeSetting as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('click-edit-notice-setting', this.value);
      }
    },
  },
});
