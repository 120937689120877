






































import Vue, { PropType } from 'vue';
import PersonalSettingForm from '@/components/organisms/PersonalSettingForm/PersonalSettingForm.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import * as Config from '@/config';
interface TypeSelect {
  text: string;
  value: string;
}
export default Vue.extend({
  name: 'PersonalSettingTemplate',
  components: {
    PersonalSettingForm,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    userData: {
      default: () => [],
    },
    errorMessageLogs: {
      default: '',
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ホーム', disabled: true },
          { text: '個人設定', disabled: true },
        ];
      },
    },
  },
  methods: {
    goHome(value: any) {
      const dataQuery = this.$route.query;
      if (dataQuery.goBack !== undefined && dataQuery.goBack === 'true' && localStorage.goBack !== '') {
        this.$router.push({ path: localStorage.goBack});
      } else {
        this.$router.go(-1);
      }
      // this.$router.push({ path: Config.USER_DETAIL, query: {userId: value}});
    },
  },
});
