var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg mt-4 ml-4 pa-2",
      attrs: { width: _vm.width },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [_vm._t("cardbody", [_vm._v("\n    " + _vm._s(_vm.text) + "\n    ")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }