var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-log-label-setting-edit" },
    [
      _c(
        "v-container",
        [
          _vm.successMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-success" },
                [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold"
                },
                [_vm._v("\n        照合ログ編集\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "LogLabelSettingEditForm",
              attrs: { "lazy-validation": "" }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  _c("input-form", {
                    attrs: {
                      title: "照合ログID",
                      inputType: "text",
                      disabled: true
                    },
                    model: {
                      value: _vm.dataEditLogLabel.accessLogId,
                      callback: function($$v) {
                        _vm.$set(_vm.dataEditLogLabel, "accessLogId", $$v)
                      },
                      expression: "dataEditLogLabel.accessLogId"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("span", { staticClass: "error_m" }, [
                          _vm._v(_vm._s(_vm.logLabelErr))
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("input-form", {
                    attrs: { title: "ログ名称", inputType: "text" },
                    on: {
                      input: function($event) {
                        return _vm.inputLogLabel()
                      }
                    },
                    model: {
                      value: _vm.dataEditLogLabel.logLabel,
                      callback: function($$v) {
                        _vm.$set(_vm.dataEditLogLabel, "logLabel", $$v)
                      },
                      expression: "dataEditLogLabel.logLabel"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "messageRow",
                      attrs: { cols: "10", align: "right" }
                    },
                    [
                      _vm.successMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "mr-5 mb-4 font-weight-bold mt-3 bg-success bg-no messageRow",
                              attrs: { cols: "9", align: "right" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.successMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "mr-5 mb-4 font-weight-bold mt-3 bg-error bg-no messageRow",
                              attrs: { cols: "9", align: "right" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errorMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary background-cancel",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.clickBackLogLabel()
                            }
                          }
                        },
                        [_vm._v("\n            キャンセル\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: {
                            disabled:
                              (_vm.loading || _vm.dataEditLogLabel.isDefault) &&
                              _vm.isDisableDefaultTrue,
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickSaveLogLabel", {
                                accessLogId: _vm.dataEditLogLabel.accessLogId,
                                logLabel: _vm.dataEditLogLabel.logLabel
                              })
                            }
                          }
                        },
                        [_vm._v("\n            編集を保存\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary background-delete",
                          attrs: {
                            disabled:
                              _vm.loading || _vm.dataEditLogLabel.isDefault,
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickDeleteLogLabel", {
                                accessLogId: _vm.dataEditLogLabel.accessLogId
                              })
                            }
                          }
                        },
                        [_vm._v("\n            初期化する\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }