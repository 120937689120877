var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("group-detail-template", {
    attrs: {
      gateList: _vm.gateList,
      userList: _vm.userList,
      loading: _vm.loading,
      errorMessageLogs: _vm.errorMessageLogs,
      groupDetail: _vm.groupDetail
    },
    on: {
      clickBackGroup: function($event) {
        return _vm.clickBackGroup($event)
      },
      clickEditGroup: function($event) {
        return _vm.clickEditGroup($event)
      },
      "click-user": function($event) {
        return _vm.clickUser($event)
      },
      "click-gate": function($event) {
        return _vm.clickGate($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }