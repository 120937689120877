





















































import Vue, { PropType } from 'vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import DragAndDropFileInputForm from '@/components/molecules/DragAndDropInputForm/DragAndDropFileInputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';

export default Vue.extend({
  name: 'ZenescanFaceImageBulkAdd',
  components: {
    Modal,
    Loading,
    InputForm,
    DragAndDropFileInputForm,
    SuccessButton,
    CancelButton,
    IconButton,
    Alert,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Object as PropType<any>,
      default: () => {
        return {
          imageFiles: [],
          importFile: null,
        };
      },
    },
  },
  methods: {
    close(): void {
      this.$emit('reset-msg');
      this.$emit('close');
      return ;
    },
    isValidInputFile(values: any): string | boolean {
      let result = false;
      if (this.isValidImageFiles(values.imageFiles)) {
        result = true;
      }
      if (result) {
        return true;
      } else {
        return '必須項目のため、一つ以上の指定が必須です。';
      }
    },
    isValidImageFiles(values: File[]): boolean {
      if (values && values.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isValidImportFile(values: File): boolean {
      if (values) {
        return true;
      } else {
        return false;
      }
    },
    clickAddZenescanFaceImage(): void {
      const validate = (this.$refs.formZenescanFaceImage as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('add-face-image', this.value);
      }
    },
  },
});
