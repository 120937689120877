



















import {Vue } from 'vue-property-decorator';
import SafieTemplate from '@/components/templates/SafieTemplate/SafieTemplate.vue';
import * as Config from '@/config';
import { SafieRelationGateDevice, Gate } from '@/types/alligate.js';
import { getAllGates } from '@/utils/alligate/gate';
import { Resulted } from '@/components/organisms/RelationsSafieList/RelationsSafieList.vue';
export interface DataType {
  loading: boolean;
  putDelLoading: boolean[];
  postLoading: boolean[];
  results: Resulted[];
  safieRelationGateDevices: SafieRelationGateDevice[];
  newSafieRelationGateDevices: SafieRelationGateDevice[];
  gateFullList: Gate[];
  successMessageLogs: string;
  errorMessageLogs: string;
}
export default Vue.extend({
  name: 'SafiePage',
  components: {
    SafieTemplate,
  },
  data: (): DataType => ({
    loading: false,
    putDelLoading: [],
    postLoading: [],
    results: [],
    safieRelationGateDevices: [],
    newSafieRelationGateDevices: [],
    gateFullList: [],
    successMessageLogs: '',
    errorMessageLogs: '',
  }),
  watch: {
  },
  created() {
    this.loading = true;
    Promise.all([
      this.getSafieRelationGateDevice(1),
      getAllGates(this).then((gates) => this.gateFullList = gates),
    ])
    .then(() => {
      this.loading = false;
    })
    .catch((err) => {
      this.loading = false;
    });
  },
  mounted() {
    this.$emit('activeMenu', 'safie');
  },
  methods: {
    formUpdate(event: any) {
      this.$set(this.safieRelationGateDevices, event.index, event.value);
    },
    formUpdateNew(event: any) {
      this.$set(this.newSafieRelationGateDevices, event.index, event.value);
    },
    addNewRecord() {
      this.newSafieRelationGateDevices.push({
        relationId: '',
        deviceId: '',
        gateId: '',
        notes: '',
      });
    },
    delNewRecord(index: number) {
      this.newSafieRelationGateDevices.splice(index, 1);
    },
    deleteSafieRelationGateDevice(index: number) {
      this.$set(this.putDelLoading, index, true);
      const data: SafieRelationGateDevice = this.safieRelationGateDevices[index];
      this.$http.delete(`${Config.SAFIE_RELATIONS_GATE_API}/${encodeURIComponent(data.relationId)}`)
          .then((response: any) => {
            const resultAlert: Resulted = {
              type: 'error',
              message: Config.ERROR_DEFAULT,
            };
            if (response.body.message === 'Success') {
              this.safieRelationGateDevices.splice(index, 1);
              resultAlert.type = 'success';
              resultAlert.message = '削除しました。';
            } else {
              resultAlert.type = 'error';
              resultAlert.message = Config.ERROR_DEFAULT;
            }
            this.results.push(resultAlert);
            this.$set(this.putDelLoading, index, false);
          })
          .then( null, (err: any) => {
            let message = '';
            console.log(err);
            switch (err.status) {
              case 400:
                message = '形式が誤っている。';
                break;
              case 403:
                message = '削除する権限がありません。';
                break;
              case 404:
                message = '既に削除されているか存在しません。';
                break;
              case 409:
                message = '同じタイミングで操作されたため削除できませんでした。';
                break;
              case 500:
                message = 'サーバー側で意図しないエラーが発生しました。時間を置いて再度お試し下さい。';
                break;
              case 503:
                message = 'システムメンテナンス中のため只今アクセスできません。システムメンテナンス終了後再度お試し下さい';
                break;
              default:
                message = Config.ERROR_DEFAULT;
                break;
            }
            this.$set(this.putDelLoading, index, false);
            this.results.push({type: 'error', message});
          }); // catch
    },
    putSafieRelationGateDevice(index: number) {
      this.$set(this.putDelLoading, index, true);
      const data: SafieRelationGateDevice = this.safieRelationGateDevices[index];
      this.$http.put(`${Config.SAFIE_RELATIONS_GATE_API}/${encodeURIComponent(data.relationId)}`, data)
          .then((response: any) => {
            const resultAlert: Resulted = {
              type: 'error',
              message: Config.ERROR_DEFAULT,
            };
            if (response.body.message === 'Success') {
              resultAlert.type = 'success';
              resultAlert.message = '保存しました。';
            }
            this.results.push(resultAlert);
            this.$set(this.putDelLoading, index, false);
          })
          .then( null, (err: any) => {
            let message = '';
            switch (err.status) {
              case 400:
                message = '形式が誤っている。';
                break;
              case 403:
                message = '変更・保存する権限がありません。';
                break;
              case 404:
                message = '既に削除されているか存在しません。';
                break;
              case 409:
                message = 'すでに同じデバイスIDとゲートの組み合わせがあります。';
                break;
              case 500:
                message = 'サーバー側で意図しないエラーが発生しました。時間を置いて再度お試し下さい。';
                break;
              case 503:
                message = 'システムメンテナンス中のため只今アクセスできません。システムメンテナンス終了後再度お試し下さい';
                break;
              default:
                message = Config.ERROR_DEFAULT;
                break;
            }
            this.$set(this.putDelLoading, index, false);
            this.results.push({type: 'error', message});
          }); // catch
    },
    postSafieRelationGateDevice(index: number) {
      this.$set(this.postLoading, index, true);
      const data: SafieRelationGateDevice = this.newSafieRelationGateDevices[index];
      this.$http.post(Config.SAFIE_RELATIONS_GATE_API, data)
          .then((response: any) => {
            const resultAlert: Resulted = {
              type: 'error',
              message: Config.ERROR_DEFAULT,
            };
            if (response.body.message === 'Success') {
              resultAlert.type = 'success';
              resultAlert.message = '保存しました。';
              data.relationId = response.body.relationId;
              this.newSafieRelationGateDevices.splice(index, 1);
              this.safieRelationGateDevices.push(data);
            }
            this.results.push(resultAlert);
            this.$set(this.postLoading, index, false);
          })
          .then( null, (err: any) => {
            let message = '';
            switch (err.status) {
              case 400:
                message = '形式が誤っている。';
                break;
              case 403:
                message = '登録する権限がありません。';
                break;
              case 404:
                message = '既に削除されているか存在しません。';
                break;
              case 409:
                message = 'すでに同じデバイスIDとゲートの組み合わせがあります。';
                break;
              case 500:
                message = 'サーバー側で意図しないエラーが発生しました。時間を置いて再度お試し下さい。';
                break;
              case 503:
                message = 'システムメンテナンス中のため只今アクセスできません。システムメンテナンス終了後再度お試し下さい';
                break;
              default:
                message = Config.ERROR_DEFAULT;
                break;
            }
            this.$set(this.postLoading, index, false);
            this.results.push({type: 'error', message});
          }); // catch
    },
    getSafieRelationGateDevice(page: number): Promise<void> {
      return new Promise((resolve, reject) => {
        const limit = 25;
        const apiLink = `${Config.SAFIE_RELATIONS_GATE_API}?limit=${limit}`
          + `&page=${page}&sortKey=ASC:gateId&isExceptOptionalGate=true`;
        this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.relationsGateDevice)) {
            const relationsGateDevice = response.body.relationsGateDevice;
            for (let i = 0; i < relationsGateDevice.length; i++) {
              this.$set(this.safieRelationGateDevices, (response.body.startRecord - 1) + i, relationsGateDevice[i]);
            }
            if (response.body.endRecord !== response.body.maxRecords) {
              this.getSafieRelationGateDevice(page + 1).then(resolve).catch(resolve);
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        })
        .then( null, (err: any) => {
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
          }
          reject();
        }); // catch
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
