






























































































































import Vue, { PropType } from 'vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import IconButton from '@/components/molecules/IconButton/IconButton.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import { FaceImage } from '@/types/alligate-zenescan';
import Crypto from 'crypto';
import InfoImage from '@/components/molecules/InfoImage/InfoImage.vue';
import { User, PCode } from '@/types/alligate-zenescan';

export interface DisplayFaceImage {
  faceImageId: string; // 顔写真ID
  pCodeId: string; // 個人コード
  pCodeName: string; // 個人コード名
  pCodeType: number; // 個人コードの種別
  pCodeTypeName: string; // 個人コードの種別名
  userId: string; // ユーザーID
  userName: string; // ユーザー名
  isValid: boolean; // 有効無効
  image: File;
  imageBase64: '';
}

interface InitValue {
  faceImageId: string;
  pCodeType: number;
  pCodeId: string;
  userId: string;
}

interface DataType {
  isDisplaySuccessMessage: boolean;
  isDisplayErrorMessage: boolean;
  isPcode: boolean;
  initInputValue: InitValue;
  isEditSave: boolean;
}

interface TypeSelect {
  text: string;
  value: string;
}

export default Vue.extend({
  name: 'ZenescanFaceImageTarget',
  components: {
    Modal,
    Loading,
    InputForm,
    SuccessButton,
    CancelButton,
    IconButton,
    Alert,
    InfoImage,
  },
  data: (): DataType => {
    return {
      isDisplaySuccessMessage: false,
      isDisplayErrorMessage: false,
      isPcode: false,
      initInputValue: {
        faceImageId: '',
        pCodeType: 2,
        pCodeId: '',
        userId: '',
      },
      isEditSave: true,
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    /** 新規追加モード(new)か編集モード(edit)のどちらかを選ぶ */
    mode: {
      type: String,
      default: () => 'new',
    },
    isLoadBlockingZenescanFaceImage: {
      type: Boolean,
      default: () => true,
    },
    loadBlockingTextZenescanFaceImage: {
      type: String,
      default: () => '',
    },
    successMessageLogsModal: {
      type: String,
      default: () => '',
    },
    errorMessageLogsModal: {
      type: String,
      default: () => '',
    },
    value: {
      type: Object as PropType<DisplayFaceImage>,
      default: () => {
        return {
          faceImageId: '',
          // pCodeId: 'Z' + Crypto.randomBytes(9).toString('hex').toUpperCase(),
          pCodeId: Crypto.randomBytes(10).toString('hex').slice(0, 19).toUpperCase(),
          pCodeName: '',
          pCodeType: 2,
          pCodeTypeName: '顔',
          userId: '',
          userName: '',
          image: null,
          imageBase64: '',
        };
      },
    },
    typeItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    zenescanFaceImage: {
      type: Object as PropType<FaceImage>,
      default: () => {
        return {
          zenescanFaceImageId: 'miss',
          description: '未設定',
          name: '未設定',
          targets: [],
        };
      },
    },
    userItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    pCodeItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    pCodes: {
      type: Array as PropType<PCode[]>,
      default: () => [],
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
  },
  mounted() {
    this.initInputValue.faceImageId = this.value.faceImageId;
    this.initInputValue.pCodeType = this.value.pCodeType;
    this.initInputValue.pCodeId = this.value.pCodeId;
    this.initInputValue.userId = this.value.userId;
    this.isPcode = this.value.pCodeType === 2 ? false : true;
  },
  watch: {
    successMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplaySuccessMessage = true;
        if (this.mode === 'new') {
          // 新規追加時の成功時にのみクリア
          this.value.faceImageId = '';
          this.value.pCodeId = '';
          this.value.pCodeName = '';
          this.value.pCodeType = 2;
          this.value.pCodeTypeName = '顔';
          this.value.userId = '';
          this.value.userName = '';
        }
      } else {
        this.isDisplaySuccessMessage = false;
      }
    },
    errorMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplayErrorMessage = true;
      } else {
        this.isDisplayErrorMessage = false;
      }
    },
  },
  computed: {
  },
  methods: {
    close(): void {
      this.$emit('reset-msg');
      this.$emit('close');
      return ;
    },
    /** 渡された要素が一個以上かチェックする */
    isValidateLengthOneMore(values: string[]): string | boolean {
      if (values && values.length > 0) {
        return true;
      } else {
        return '必須項目のため、一つ以上の指定が必須です。';
      }
    },
    clickAddPostZenescanFaceImage(): void {
      const validate = (this.$refs.formZenescanFaceImage as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('click-add-zenescan-face-image', this.value);
      }
    },
    clickEditPutZenescanFaceImage(): void {
      const validate = (this.$refs.formZenescanFaceImage as Vue & { validate: () => boolean }).validate();
      if (validate) {
        const editInfo = {
          faceImageId: this.value.faceImageId,
          pCodeId: this.value.pCodeId,
          pCodeType: this.value.pCodeType,
          userId: this.value.userId,
        };
        this.$emit('click-edit-zenescan-face-image', editInfo);
      }
    },
    switchPcode(): void {
      if (this.isPcode) {
        this.isPcode = false;
        if (this.mode === 'new') {
          // this.value.pCodeId = 'Z' + Crypto.randomBytes(9).toString('hex').toUpperCase();
          this.value.pCodeId = Crypto.randomBytes(10).toString('hex').slice(0, 19).toUpperCase();
        } else if (this.mode === 'edit') {
          // 編集時に照合タイプが切り替わった時は、初期値に戻す
          if (this.initInputValue.pCodeType === 2) {
            this.value.pCodeId = this.initInputValue.pCodeId;
            this.value.userId = this.initInputValue.userId;
            this.isEditSave = true;
          } else if (this.initInputValue.pCodeType === 0) {
            this.value.pCodeId = Crypto.randomBytes(10).toString('hex').slice(0, 19).toUpperCase();
            this.value.userId = '';
            this.isEditSave = true;
          }
        }
      } else {
        this.isPcode = true;
        if (this.mode === 'new') {
          this.value.pCodeId = '';
        } else if (this.mode === 'edit') {
          // 編集時に照合タイプが切り替わった時は、初期値に戻す
          if (this.initInputValue.pCodeType === 0) {
            this.value.pCodeId = this.initInputValue.pCodeId;
            this.value.userId = this.initInputValue.userId;
            this.isEditSave = true;
          } else if (this.initInputValue.pCodeType === 2) {
            this.value.pCodeId = '';
            this.value.userId = '';
            this.isEditSave = true;
          }
        }
      }
    },
    getBase64Src(value: string): string {
      let result = value;
      if (value && value.search(/^data:image\/[a-z,*]+;base64,/) === -1) {
        result = 'data:image/*;base64,' + value;
      }
      return result;
    },
    changeTargetPCode(pCodeId: string) {
      const targetPcode = this.pCodes.find((u) => u.pCodeId === pCodeId);
      if (targetPcode && targetPcode.user) {
        this.value.userId = targetPcode.user.userId;
      } else if (targetPcode) {
        const targetUser = this.users.find((u) => u.userId === targetPcode.userId);
        if (targetUser) {
          this.value.userId = targetUser.userId;
        }
      }
      if (this.mode === 'edit') {
        if (this.initInputValue.pCodeType === 0 && pCodeId !== this.initInputValue.pCodeId) {
          this.isEditSave = false;
        } else if (this.initInputValue.pCodeType === 2 && pCodeId !== '') {
          this.isEditSave = false;
        } else {
          this.isEditSave = true;
        }
      }
    },
    changeTargetUser(userId: string) {
      if (this.mode === 'edit') {
        if (this.initInputValue.pCodeType === 2 && userId !== this.initInputValue.userId) {
          this.isEditSave = false;
        } else if (this.initInputValue.pCodeType === 0 && userId !== '') {
          this.isEditSave = false;
        } else {
          this.isEditSave = true;
        }
      }
    },
  },
});
