var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "align-center" },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            attrs: { color: "green", indeterminate: "" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: 2 } },
        [
          _c("input-form", {
            attrs: { inputType: "text", rules: [] },
            model: {
              value: _vm.notes,
              callback: function($$v) {
                _vm.notes = $$v
              },
              expression: "notes"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: 3 } },
        [
          _c("input-form", {
            attrs: {
              inputType: "text",
              rules: [_vm.isValidateLengthOneMore],
              "append-icon": _vm.appendIcon
            },
            on: {
              "click-append-icon": function($event) {
                _vm.showPreviewTbEye = !_vm.showPreviewTbEye
              }
            },
            model: {
              value: _vm.hostName,
              callback: function($$v) {
                _vm.hostName = $$v
              },
              expression: "hostName"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: 1 } },
        [
          _c("input-form", {
            attrs: { inputType: "number", rules: [_vm.isValidateOneMore] },
            model: {
              value: _vm.port,
              callback: function($$v) {
                _vm.port = $$v
              },
              expression: "port"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: 2 } },
        [
          _c("input-form", {
            attrs: { inputType: "number", rules: [_vm.isValidateOneMore] },
            model: {
              value: _vm.channelNo,
              callback: function($$v) {
                _vm.channelNo = $$v
              },
              expression: "channelNo"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: 2 } },
        [
          _c("input-form", {
            attrs: {
              inputType: "select",
              rules: [_vm.isValidateLengthOneMore],
              selectItems: _vm.configurableGates
            },
            model: {
              value: _vm.gateId,
              callback: function($$v) {
                _vm.gateId = $$v
              },
              expression: "gateId"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isUUID(_vm.value.relationId)
        ? _c("success-button", {
            staticClass: "m-sm",
            attrs: {
              cols: 1,
              disabled:
                _vm.isModified === false ||
                _vm.isValidate() === false ||
                _vm.loading === true,
              text: "保存"
            },
            on: {
              click: function($event) {
                return _vm.putRecord()
              }
            }
          })
        : _c("success-button", {
            staticClass: "m-sm",
            attrs: {
              cols: 1,
              disabled:
                _vm.isModified === false ||
                _vm.isValidate() === false ||
                _vm.loading === true,
              text: "登録"
            },
            on: {
              click: function($event) {
                return _vm.postRecord()
              }
            }
          }),
      _vm._v(" "),
      _c("error-button", {
        staticClass: "m-sm",
        attrs: { cols: 1, disabled: _vm.loading === true, text: "削除" },
        on: {
          click: function($event) {
            return _vm.delRecord(_vm.value)
          }
        }
      }),
      _vm._v(" "),
      _c("modal", {
        staticClass: "center justify-center",
        attrs: { noTitle: true },
        scopedSlots: _vm._u([
          {
            key: "modal-body",
            fn: function() {
              return [
                _vm.isRerender
                  ? _c("tb-eye", {
                      staticClass: "center justify-center",
                      attrs: {
                        videoId: _vm.value.relationId,
                        hostname: _vm.value.hostName,
                        port: _vm.value.port,
                        channel: _vm.value.channelNo,
                        device: _vm.value.deviceType,
                        username: _vm.loginInfo.username,
                        password: _vm.loginInfo.password,
                        replayStartDate: new Date().getTime() - 1000 * 70
                      },
                      on: {
                        close: function($event) {
                          _vm.showPreviewTbEye = false
                        },
                        rerender: function($event) {
                          return _vm.rerender()
                        },
                        "update-login-info": function($event) {
                          return _vm.$emit("update-login-info", {
                            relationId: _vm.value.relationId,
                            username: $event.username,
                            password: $event.password
                          })
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("v-spacer"),
                _vm._v(" "),
                _c("success-button", {
                  attrs: { text: "TBEYE管理画面" },
                  on: {
                    click: function($event) {
                      return _vm.openToLink(
                        "https://" +
                          _vm.loginInfo.username +
                          ":" +
                          _vm.loginInfo.password +
                          "@" +
                          _vm.value.hostName +
                          ":" +
                          _vm.value.port
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("cancel-button", {
                  attrs: { text: "閉じる" },
                  on: {
                    click: function($event) {
                      _vm.showPreviewTbEye = false
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.showPreviewTbEye,
          callback: function($$v) {
            _vm.showPreviewTbEye = $$v
          },
          expression: "showPreviewTbEye"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }