var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-reflection-card-table" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _vm.successMessageLogs !== ""
            ? _c(
                "v-col",
                { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-success" },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.successMessageLogs) + "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== ""
            ? _c(
                "v-col",
                { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errorMessageLogs) + "\n      "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-3 border-bottom-bold",
          attrs: {
            "no-gutters": "",
            justify: "space-between",
            "align-content": "center"
          }
        },
        [
          _c("show-per-page", {
            staticClass: "ml-8 mb-3",
            attrs: {
              "per-page": _vm.perPage,
              page: _vm.page,
              "items-length": _vm.cardTotalCount
            }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-8",
              on: {
                click: function() {
                  _vm.showOption = !_vm.showOption
                }
              }
            },
            [
              _c("span", { staticClass: "display-option-title" }, [
                _vm._v("\n        表示オプション\n      ")
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mb-3" },
                [
                  _c("v-icon", { attrs: { dense: "" } }, [
                    _vm._v("mdi-chevron-down")
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pt-0 pb-0 mt-0" },
        [
          _c(
            "v-container",
            { staticClass: "pt-0" },
            [
              _c("display-option", {
                attrs: {
                  isShowOption: _vm.showOption,
                  "display-option-items": _vm.displayOptionItems,
                  disabled: _vm.isGetDataList,
                  isVaildOnly: _vm.isVaildOnly
                },
                on: {
                  "show-only-isVaild": function($event) {
                    return _vm.$emit("show-only-isVaild", $event)
                  },
                  input: function($event) {
                    return _vm.CheckColumn($event)
                  }
                },
                model: {
                  value: _vm.selectedDisplayOption,
                  callback: function($$v) {
                    _vm.selectedDisplayOption = $$v
                  },
                  expression: "selectedDisplayOption"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "border-table scroll-table",
        attrs: {
          "must-sort": "",
          headers: _vm.headers,
          items: _vm.dataCards,
          "items-per-page": _vm.perPage,
          "item-key": "pCodeId",
          "no-data-text": "対象カードがありません",
          "loading-text": "データを読み込中です",
          loading: _vm.isGetDataList,
          "hide-default-footer": "",
          "show-select": true,
          "custom-sort": _vm.customSort
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.pCodeId",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("text-link", {
                    staticClass: "item-user-name",
                    attrs: { text: item.pCodeId },
                    on: {
                      click: function($event) {
                        return _vm.$emit("clickCardDetail", item.pCodeId)
                      }
                    }
                  })
                ]
              }
            },
            {
              key: "item.userId",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", { staticClass: "item-user-id" }, [
                    _vm._v(_vm._s(item.userId))
                  ])
                ]
              }
            },
            {
              key: "item.userName",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", { staticClass: "item-user-id" }, [
                    _vm._v(_vm._s(item.userName))
                  ])
                ]
              }
            },
            {
              key: "item.type",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.type === 0
                    ? _c("span", [_vm._v(" NFCカード ")])
                    : item.type === 1
                    ? _c("span", [_vm._v("テンキー")])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.registered",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(_vm.convertDate(item.registered)))])
                ]
              }
            },
            {
              key: "item.updated",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(_vm.convertDate(item.updated)))])
                ]
              }
            },
            {
              key: "item.isValid",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(item.isValid ? "有効" : "無効") +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: "item.icons",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "showDialogConfirm",
                                              {
                                                data: [item.pCodeId],
                                                type:
                                                  item.isValid === true ? 1 : 0
                                              }
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    item.isValid
                                      ? _c(
                                          "v-icon",
                                          { staticClass: "green--text" },
                                          [
                                            _vm._v(
                                              "mdi-card-account-details-star-outline"
                                            )
                                          ]
                                        )
                                      : _c("v-icon", [
                                          _vm._v(
                                            "mdi-card-account-details-outline"
                                          )
                                        ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(item.isValid ? "有効" : "無効") +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "clickEditCard",
                                              item.pCodeId
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-square-edit-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_vm._v(" "), _c("span", [_vm._v("カード編集")])]
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.cardSelected,
          callback: function($$v) {
            _vm.cardSelected = $$v
          },
          expression: "cardSelected"
        }
      }),
      _vm._v(" "),
      _c("v-row", {
        staticStyle: { height: "20px" },
        attrs: { "no-gutters": "" }
      }),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-3",
          attrs: { "no-gutters": "", justify: "space-between" }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mr-5 mb-2" },
            [
              _c("select-per-page", {
                attrs: {
                  page: _vm.page,
                  "per-page-items": [25, 50, 100, 1000],
                  "items-length": _vm.cardTotalCount,
                  disabled: _vm.isGetDataList
                },
                model: {
                  value: _vm.innerPerPage,
                  callback: function($$v) {
                    _vm.innerPerPage = $$v
                  },
                  expression: "innerPerPage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            staticClass: "mb-1",
            attrs: {
              "page-length": Math.ceil(_vm.cardTotalCount / _vm.perPage),
              disabled: _vm.isGetDataList
            },
            model: {
              value: _vm.innerPage,
              callback: function($$v) {
                _vm.innerPage = $$v
              },
              expression: "innerPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }