









































































































import Vue, { PropType } from 'vue';
import { PassableTime } from '@/types/alligate';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import UserGroupListTable from '@/components/organisms/UserGroupListTable/UserGroupListTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import * as Config from '@/config';

export interface DataType {
  selectedGroupIds: string[];
}
interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
interface ArrayDefault {
  text: string;
  value: string;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GroupListTemplate',
  components: {
    Searchbox,
    SuccessButton,
    UserGroupListTable,
    Breadcrumbs,
  },
  props: {
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    errorMessageProcess: {
      default: [],
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataGroups: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupOfTime: {
      default: [],
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'グループ', disabled: true },
        ];
      },
    },
  },
  methods: {
    clickBack() {
      this.$router.push({ path: Config.SETTING});
    },
  },
  data: (): DataType => ({
    selectedGroupIds: [],
  }),
  watch: {
    page() {
      this.selectedGroupIds = [];
    },
  },
});
