var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-users-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "btn-round mb-5", attrs: { justify: "end" } },
            [
              _c(
                "v-col",
                { staticClass: "btn-add pt-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary float-left",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.goUserList()
                        }
                      }
                    },
                    [_vm._v("\n        戻る\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("user-detail-table", {
            attrs: {
              listphones: _vm.listphones,
              listGatePassages: _vm.listGatePassages,
              tempGroupPassages: _vm.tempGroupPassages,
              cardItems: _vm.cardItems,
              phoneItems: _vm.phoneItems,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              user: _vm.userData
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "btn-round mb-5", attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-primary",
                  attrs: { outlined: "", disabled: !_vm.userData.userId },
                  on: {
                    click: function($event) {
                      return _vm.goUserEdit(_vm.userData.userId)
                    }
                  }
                },
                [_vm._v("\n          編集\n        ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }