var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "allig-header",
      attrs: {
        color: "white",
        dense: _vm.$vuetify.breakpoint.smAndDown,
        elevation: "1",
        app: "",
        "clipped-left": ""
      }
    },
    [
      _vm.$vuetify.breakpoint.mobile
        ? _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                return _vm.$emit("click-nav")
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "logo-text" },
        [
          !_vm.$vuetify.breakpoint.xs
            ? _c("v-img", {
                staticClass: "ml-2",
                attrs: {
                  src: require("../../../assets/image/logo.png"),
                  "max-height": "100%",
                  "max-width": "200px"
                }
              })
            : _c("v-img", {
                attrs: {
                  src: require("../../../assets/image/logo.png"),
                  "max-height": "100%",
                  "max-width": "150px",
                  width: _vm.$vuetify.breakpoint.width - 150
                }
              })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "div",
            [
              _c("v-img", {
                staticClass: "icon-support",
                attrs: {
                  src: require("../../../assets/image/support-center.svg"),
                  "max-height": "100%",
                  "max-width": "220px"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("click-notice")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            { staticClass: "sub-menu" },
                            "div",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v("\n        " + _vm._s(_vm.userName) + "  "),
                          _c("v-icon", [_vm._v("mdi-chevron-down")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [
                      _c(
                        "v-avatar",
                        [_c("v-icon", [_vm._v("mdi-account-circle")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("click-notice")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c("v-icon", [_vm._v("mdi-help-circle")]),
                          _vm._v("\n          ヘルプ\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click-user-setting")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-title",
                    [
                      _c("v-icon", [_vm._v("mdi-account-cog")]),
                      _vm._v(" 個人設定")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click-user-change-password")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-title",
                    [
                      _c("v-icon", [_vm._v("mdi-lock-open-variant")]),
                      _vm._v("パスワード変更")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click-logout")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-title",
                    [
                      _c("v-icon", [_vm._v("mdi-logout")]),
                      _vm._v(" ログアウト")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }