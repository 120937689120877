






































































































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GateList from '@/components/organisms/GateList/GateList.vue';
import * as Config from '@/config';
import { Group, Gate, RelationGateGroup } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';

export interface DataType {
  selectedItem: Group[];
  loadGroups: any;
}

export default Vue.extend({
  name: 'GroupGateTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
    GateList,
  },
  props: {
    isChange: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    gateList: {
      default: () => [],
    },
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageGroup: {
      type: Number,
      default: 1,
    },
    userId: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    perPage: {
      type: Number,
      default: 25,
    },
    relationsGateGroupLoading: {
      type: Boolean,
      default: false,
    },
    loadingGate: {
      type: Boolean,
      default: false,
    },
    ExpandedGroup: {
      default: () => [],
    },
    groupDetail: {
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    pageGate: {
      type: Number,
      default: 0,
    },
  },
  data: (): DataType => ({
    selectedItem: [],
    loadGroups: [],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'グループ名',
            align: 'start',
            sortable: true,
            value: 'groupName',
          },
          {
            text: '所属ゲート数',
            align: 'start',
            sortable: false,
            width: '200px',
            value: 'data-table-expand',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.pageGroup;
      },
      set(val: number): void {
        this.$emit('change-page-group', {page: val, userId: this.userId, change: 'group' });
        return;
      },
    },
  },
  watch: {
    groups(val) {
      this.selectedItem = [];
    },
    selectedItem(val: Group[]) {
      this.$emit('select-groups', val.map((item) => item.groupPassageId));
    },
    loading(val: any) {
      if (val) {
        //
      } else {
        this.loadGroups = this.groups;
      }
    },
    isChange(val: any) {
      if (val) {
        this.loadGroups = [];
        const target = document.getElementById('expanded-data') as HTMLElement;
        target.scrollIntoView({block: 'center'});
      }
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    clickDetail(ID: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: ID, goBack: 'user'}});
    },
  },
});
