var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card-add-template", {
    attrs: {
      typeItems: _vm.typeItems,
      userItems: _vm.userItems,
      loading: _vm.loading,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      cardDetail: _vm.cardDetail,
      isEdit: _vm.isEdit,
      isResetData: _vm.isResetData
    },
    on: {
      clickBackCard: function($event) {
        return _vm.clickBackCard($event)
      },
      clickSaveCard: function($event) {
        return _vm.clickSaveCard($event)
      },
      clickUpdateCard: function($event) {
        return _vm.clickUpdateCard($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }