



























































































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import { PassableTime } from '@/types/alligate';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import UserGateTable from '@/components/organisms/UserGateTable/UserGateTable.vue';
import GateImport from '@/components/organisms/GateImport/GateImport.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GroupOfGateTable from '@/components/organisms/GroupOfGateTable/GroupOfGateTable.vue';
import GroupOfGateLockTable from '@/components/organisms/GroupOfGateLockTable/GroupOfGateLockTable.vue';
import UserOfGateLockTable from '@/components/organisms/UserOfGateLockTable/UserOfGateLockTable.vue';
import * as Config from '@/config';
import moment from 'moment';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
export interface DataType {
  selectedItem: PassableTime[];
  selectedDisplayOption: string[];
  displayOptionItems: DisplayOptionItem[];
  selectedUserIds: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  isShowConfirmUser: boolean;
  isShowConfirmGroup: boolean;
  isGatePassword: boolean;
  selectUser: any;
  selectGroup: any;
  listTime: any;
  dateGate: string;
  timeGate: number;
  gateid: string;
  isHandleCommand: boolean;
  isHandleCommandProcess: boolean;
  currentGateName: string;
}

export default Vue.extend({
  name: 'GateListTable',
  components: {
    ShowPerPage,
    SuccessButton,
    Pagination,
    SelectPerPage,
    DisplayOption,
    UserGateTable,
    TextLink,
    GroupOfGateTable,
    Searchbox,
    GateImport,
    GroupOfGateLockTable,
    UserOfGateLockTable,
    DatePicker,
  },
  props: {
    gateTotalCount: {
      type: Number,
      default: 0,
    },
    pageLock: {
      type: Number,
      default: 1,
    },
    perPageLock: {
      type: Number,
      default: 100,
    },
    searchTargetItems: {
      default: () => [],
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    isFinishHandleCommand: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    dataGates: {
      type: Array,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageModal: {
      type: String,
      default: '',
    },
    successMessageModal: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },

    gateExpanedGroup: {
      type: Map,
      default: new Map(),
    },
    gateLockExpanedGroup: {
      type: Map,
      default: new Map(),
    },
    gateLockExpanedUser: {
      type: Map,
      default: new Map(),
    },
    gateId: {
      default: '',
    },
    gateName: {
      default: '',
    },
    isReRenderGroup: {
      default: 0,
    },
    isRemoveGroupSelected: {
      default: 0,
    },

    gateExpanedUser: {
      type: Map,
      default: new Map(),
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
    // CSV
    showProgressExportCSV: {
      type: Boolean,
      default: false,
    },

    // Excel
    progressCsvXlsx: {
      type: Number,
      default: 0,
    },
    showModalImport: {
      type: Boolean,
      default: false,
    },
    showProgressImport: {
      type: Boolean,
      default: false,
    },
    successItemImport: {
      type: Number,
      default: 0,
    },
    totalDataRowImport: {
      type: Number,
      default: 0,
    },
    errorMessageImport: {
      default: [],
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    sheetUserName: {
      type: String,
      default: '',
    },
    isShowViewCSV: {
      type: Boolean,
      default: false,
    },
    errorMessageCsvXlsx: {
      type: String,
      default: '',
    },
    showProgressUser: {
      type: Boolean,
      default: false,
    },
    progressUser: {
      type: Number,
      default: 0,
    },
    isFinishProcessUser: {
      type: Boolean,
      default: false,
    },
    userTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcessUser: {
      type: Number,
      default: 0,
    },
    successItemProcessUser: {
      type: Number,
      default: 0,
    },
    errorMessageProcessUser: {
      type: Array,
      default: () => [],
    },
    lastLog: {
      type: Array,
      default: () => [],
    },
    showProgressGroup: {
      type: Boolean,
      default: false,
    },
    progressGroup: {
      type: Number,
      default: 0,
    },
    isFinishProcessGroup: {
      type: Boolean,
      default: false,
    },
    groupTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcessGroup: {
      type: Number,
      default: 0,
    },
    successItemProcessGroup: {
      type: Number,
      default: 0,
    },
    errorMessageProcessGroup: {
      type: Array,
      default: () => [],
    },
    gatePassword: {
      type: String,
      default: '',
    },
    errorMessageLogsPhone: {
      type: String,
      default: '',
    },
    successMessageLogsPhone: {
      type: String,
      default: '',
    },
    btnUnlock: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (typeof localStorage.displayOptionGate !== 'undefined') {
      this.selectedDisplayOption = JSON.parse(localStorage.displayOptionGate);
    }
  },
  watch: {
    selectedDisplayOption(value) {
      localStorage.setItem('displayOptionGate', JSON.stringify(value));
      if (value.length > 8) {
        for (const item of this.displayOptionItems) {
          if (value.find((x: any) => x === item.value) === undefined) {
            item.isDisabled = true;
          }
        }
      } else {
        for (const item of this.displayOptionItems) {
          if (item.value !== 'controllerName') {
            item.isDisabled = false;
          }
        }
      }
    },
  },
  data: (): DataType => ({
    selectedItem: [],
    selectedDisplayOption: [
      'controllerName',
      'gateName',
      'isValid',
    ],
    displayOptionItems: [
      { label: 'ゲートID', value: 'gateId', isDisabled: true},
      { label: '製品ID', value: 'gateModelId'},
      { label: '製品名', value: 'gateModelName'},
      { label: '型番', value: 'gateModelCode'},
      { label: 'アイコンタイプ', value: 'iconType'},
      { label: 'メーカー番号', value: 'makerCode'},
      { label: '変更有無', value: 'customizable'},
      { label: 'ターゲット動作設定', value: 'target'},
      { label: 'ターゲット動作設定Time', value: 'controlTime'},
      { label: 'ターゲット動作設定TimeUnit', value: 'timeUnit'},
      { label: 'ゲート名', value: 'gateName'},
      { label: 'ゲート動作モード', value: 'isVisitor'},
      { label: 'NFCの有効', value: 'isNFCValid'},
      { label: 'テンキーの有効・無効', value: 'isTenKeyValid'},
      { label: 'ゲートパスワードの有効・無効', value: 'isGatePasswordValid'},
      { label: 'ゲートパスワードの桁数', value: 'gatePasswordLength'},
      { label: 'ゲートパスワードのseed値', value: 'gatePasswordSeed'},
      { label: '2段階認証の有効・無効', value: 'isTwoStepAuthValid'},
      { label: 'BLE出力設定', value: 'bleIntensity'},
      { label: 'ブザー鳴動設定', value: 'buzzerPattern'},
      { label: '位置情報取得', value: 'needLocation'},
      { label: '備考1', value: 'option1'},
      { label: '備考2', value: 'option2'},
      { label: 'ゲート個体番号1', value: 'gateSNMain'},
      { label: 'ゲート個体番号2', value: 'gateSNBLE'},
      { label: '適用されているファームウェアバージョン（メインボード）', value: 'gateFWMain'},
      { label: '適用されているファームウェアバージョン（BLE）', value: 'gateFWBLE'},
      { label: '登録日', value: 'registered'},
      { label: '更新日', value: 'updated'},
      { label: 'ゲートへ設定情報を適用した日時', value: 'reported'},
      { label: '個人コード照合データー：最新リビジョン', value: 'revisionDesired'},
      { label: '個人コード照合データー：ゲートへ設定済みのリビジョン', value: 'revisionReported'},
      { label: '電池電圧', value: 'voltage'},
      { label: '電池電圧更新日', value: 'scanDate'},
      { label: 'ステータス', value: 'isValid'},
    ],
    selectedUserIds: [],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataGate === 'true' ? true : false,
    isShowConfirmUser: false,
    isShowConfirmGroup: false,
    isGatePassword: false,
    selectUser: [],
    selectGroup: [],
    listTime: [
      { text: '00:00〜23:59', value: 1 },
      { text: '00:00〜11:59', value: 2 },
      { text: '12:00〜23:59', value: 3 },
      { text: '09:00〜16:59', value: 4 },
    ],
    timeGate: 1,
    dateGate: moment(new Date()).format('YYYY-MM-DD'),
    gateid: '',
    isHandleCommand: false,
    isHandleCommandProcess: false,
    currentGateName: '',
  }),
  methods: {
    getLastLog() {
      this.$emit('getLog', this.gateid);
    },
    openHandleCommand(val: any, text: any) {
      this.isHandleCommand = true;
      this.gateid = val;
      this.currentGateName = text;
      this.$emit('openHandleCommand', this.gateid);
    },
    closeHandleCommand() {
      this.isHandleCommand = false;
      this.gateid = '';
      this.currentGateName = '';
    },
    handleCommand() {
      this.$emit('handleCommand', this.gateid);
    },
    converDateFull(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD H:mm:ss');
    },
    converStatus(s: any) {
      let response = '';
      if (s === 0 || s === 99) {
        response = '実行中';
      } else if (s === 1) {
        response = '解錠成功';
      } else if (s === 2) {
        response = '解錠失敗';
      }
      return response;
    },
    getTime(val: any) {
      console.log(val);
      this.dateGate = val;
    },
    getGatePassword(val: any) {
      this.dateGate = moment(new Date()).format('YYYY-MM-DD'),
      this.isGatePassword = true;
      this.gateid = val;
    },
    closePasswordGenerate(val: any) {
      this.isGatePassword = false;
      this.gateid = '';
      this.$emit('closePasswordGenerate');
    },
    clickRemoveUser(val: any) {
      this.isShowConfirmUser = false;
      this.$emit('clickRemoveUser', val);
    },
    clickRemoveGroup(val: any) {
      this.isShowConfirmGroup = false;
      this.$emit('clickRemoveGroup', val);
    },
    getSelectUser(val: any) {
      this.selectUser = val;
    },
    getSelectGroup(val: any) {
      this.selectGroup = val;
    },
    addGateLock(val: any) {
      console.log('AAA');
      this.$router.push({ path: Config.GATE_LOCK_ADD});
    },
    addUser(val: any) {
      this.$router.push({ path: Config.GATE_LOCK_ADD_USER, query: {gateId: val, goBack: 'gate-lock'}});
    },
    addGroup(val: any) {
      this.$router.push({ path: Config.GATE_LOCK_ADD_GROUP, query: {gateId: val, goBack: 'gate-lock'}});
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (index[0] === 'gateId') {
            if (!isDescending[0]) {
              return (a.gateId < b.gateId) ? -1 : 1;
            } else {
              return (b.gateId < a.gateId) ? -1 : 1;
            }
        } else if (index[0] === 'gateName') {
            if (!isDescending[0]) {
                return (a.gateName < b.gateName) ? -1 : 1;
            } else {
                return (b.gateName < a.gateName) ? -1 : 1;
            }
        } else if (index[0] === 'gateModelId') {
            if (!isDescending[0]) {
                return (a.gateModelId < b.gateModelId) ? -1 : 1;
            } else {
                return (b.gateModelId < a.gateModelId) ? -1 : 1;
            }
        } else if (index[0] === 'gateModelName') {
            if (!isDescending[0]) {
                return (a.gateModelName < b.gateModelName) ? -1 : 1;
            } else {
                return (b.gateModelName < a.gateModelName) ? -1 : 1;
            }
        } else if (index[0] === 'gateModelCode') {
            if (!isDescending[0]) {
                return (a.gateModelCode < b.gateModelCode) ? -1 : 1;
            } else {
                return (b.gateModelCode < a.gateModelCode) ? -1 : 1;
            }
        } else if (index[0] === 'iconType') {
            if (!isDescending[0]) {
                return (a.iconType < b.iconType) ? -1 : 1;
            } else {
                return (b.iconType < a.iconType) ? -1 : 1;
            }
        } else if (index[0] === 'makerCode') {
            if (!isDescending[0]) {
                return (a.makerCode < b.makerCode) ? -1 : 1;
            } else {
                return (b.makerCode < a.makerCode) ? -1 : 1;
            }
        } else if (index[0] === 'customizable') {
            if (!isDescending[0]) {
                return (a.customizable < b.customizable) ? -1 : 1;
            } else {
                return (b.customizable < a.customizable) ? -1 : 1;
            }
        } else if (index[0] === 'target') {
            if (!isDescending[0]) {
                return (a.target < b.target) ? -1 : 1;
            } else {
                return (b.target < a.target) ? -1 : 1;
            }
        } else if (index[0] === 'controlTime') {
            if (!isDescending[0]) {
                return (a.controlTime < b.controlTime) ? -1 : 1;
            } else {
                return (b.controlTime < a.controlTime) ? -1 : 1;
            }
        } else if (index[0] === 'timeUnit') {
            if (!isDescending[0]) {
                return (a.timeUnit < b.timeUnit) ? -1 : 1;
            } else {
                return (b.timeUnit < a.timeUnit) ? -1 : 1;
            }
        } else if (index[0] === 'gatePasswordLength') {
            if (!isDescending[0]) {
                return (a.gatePasswordLength < b.gatePasswordLength) ? -1 : 1;
            } else {
                return (b.gatePasswordLength < a.gatePasswordLength) ? -1 : 1;
            }
        } else if (index[0] === 'gatePasswordSeed') {
            if (!isDescending[0]) {
                return (a.gatePasswordSeed < b.gatePasswordSeed) ? -1 : 1;
            } else {
                return (b.gatePasswordSeed < a.gatePasswordSeed) ? -1 : 1;
            }
        } else if (index[0] === 'bleIntensity') {
            if (!isDescending[0]) {
                return (a.bleIntensity < b.bleIntensity) ? -1 : 1;
            } else {
                return (b.bleIntensity < a.bleIntensity) ? -1 : 1;
            }
        } else if (index[0] === 'buzzerPattern') {
            if (!isDescending[0]) {
                return (a.buzzerPattern < b.buzzerPattern) ? -1 : 1;
            } else {
                return (b.buzzerPattern < a.buzzerPattern) ? -1 : 1;
            }
        } else if (index[0] === 'option1') {
            if (!isDescending[0]) {
                return (a.option1 < b.option1) ? -1 : 1;
            } else {
                return (b.option1 < a.option1) ? -1 : 1;
            }
        } else if (index[0] === 'option2') {
            if (!isDescending[0]) {
                return (a.option2 < b.option2) ? -1 : 1;
            } else {
                return (b.option2 < a.option2) ? -1 : 1;
            }
        } else if (index[0] === 'gateSNMain') {
            if (!isDescending[0]) {
                return (a.gateSNMain < b.gateSNMain) ? -1 : 1;
            } else {
                return (b.gateSNMain < a.gateSNMain) ? -1 : 1;
            }
        } else if (index[0] === 'gateSNBLE') {
            if (!isDescending[0]) {
                return (a.gateSNBLE < b.gateSNBLE) ? -1 : 1;
            } else {
                return (b.gateSNBLE < a.gateSNBLE) ? -1 : 1;
            }
        } else if (index[0] === 'gateFWMain') {
            if (!isDescending[0]) {
                return (a.gateFWMain < b.gateFWMain) ? -1 : 1;
            } else {
                return (b.gateFWMain < a.gateFWMain) ? -1 : 1;
            }
        } else if (index[0] === 'gateFWBLE') {
            if (!isDescending[0]) {
                return (a.gateFWBLE < b.gateFWBLE) ? -1 : 1;
            } else {
                return (b.gateFWBLE < a.gateFWBLE) ? -1 : 1;
            }
        } else if (index[0] === 'reported') {
            if (!isDescending[0]) {
                return (a.reported < b.reported) ? -1 : 1;
            } else {
                return (b.reported < a.reported) ? -1 : 1;
            }
        } else if (index[0] === 'revisionDesired') {
            if (!isDescending[0]) {
                return (a.revisionDesired < b.revisionDesired) ? -1 : 1;
            } else {
                return (b.revisionDesired < a.revisionDesired) ? -1 : 1;
            }
        } else if (index[0] === 'revisionReported') {
            if (!isDescending[0]) {
                return (a.revisionReported < b.revisionReported) ? -1 : 1;
            } else {
                return (b.revisionReported < a.revisionReported) ? -1 : 1;
            }
        } else if (index[0] === 'voltage') {
            if (!isDescending[0]) {
                return (a.voltage < b.voltage) ? -1 : 1;
            } else {
                return (b.voltage < a.voltage) ? -1 : 1;
            }
        } else if (index[0] === 'isVisitor') {
            if (!isDescending[0]) {
                return (a.isVisitor < b.isVisitor) ? -1 : 1;
            } else {
                return (b.isVisitor < a.isVisitor) ? -1 : 1;
            }
        } else if (index[0] === 'isNFCValid') {
            if (!isDescending[0]) {
                return (a.isNFCValid < b.isNFCValid) ? -1 : 1;
            } else {
                return (b.isNFCValid < a.isNFCValid) ? -1 : 1;
            }
        } else if (index[0] === 'isTenKeyValid') {
            if (!isDescending[0]) {
                return (a.isTenKeyValid < b.isTenKeyValid) ? -1 : 1;
            } else {
                return (b.isTenKeyValid < a.isTenKeyValid) ? -1 : 1;
            }
        } else if (index[0] === 'isGatePasswordValid') {
            if (!isDescending[0]) {
                return (a.isGatePasswordValid < b.isGatePasswordValid) ? -1 : 1;
            } else {
                return (b.isGatePasswordValid < a.isGatePasswordValid) ? -1 : 1;
            }
        } else if (index[0] === 'isTwoStepAuthValid') {
            if (!isDescending[0]) {
                return (a.isTwoStepAuthValid < b.isTwoStepAuthValid) ? -1 : 1;
            } else {
                return (b.isTwoStepAuthValid < a.isTwoStepAuthValid) ? -1 : 1;
            }
        } else if (index[0] === 'needLocation') {
            if (!isDescending[0]) {
                return (a.needLocation < b.needLocation) ? -1 : 1;
            } else {
                return (b.needLocation < a.needLocation) ? -1 : 1;
            }
        } else if (index[0] === 'scanDate') {
            if (!isDescending[0]) {
                return (a.scanDate < b.scanDate) ? -1 : 1;
            } else {
                return (b.scanDate < a.scanDate) ? -1 : 1;
            }
        } else {
          if (!isDescending[0]) {
              console.log(a[index]);
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    CheckColumn(data: any) {
      localStorage.setItem('displayOptionGate', JSON.stringify(data));
    },
    clickEditController(ID: any) {
      this.$router.push({ path: Config.CONTROL_PANEL_EDIT,
      query: {controllerId: ID, goBack: 'gates'}});
    },
    clickJoinUser(ID: any) {
      this.$router.push({ path: Config.GATE_ADD_USER, query: {gateId: ID}});
    },
    converDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD');
    },
    converTextBLE(value: any) {
      let textBLE = '';
      switch (value) {
        case 0:
          textBLE = '非常に強い';
          break;
        case 1:
          textBLE = 'とても強い';
          break;
        case 2:
          textBLE = '強い';
          break;
        case 3:
          textBLE = 'やや強い';
          break;
        case 4:
          textBLE = '普通';
          break;
        case 5:
          textBLE = 'やや弱い';
          break;
        case 6:
          textBLE = '弱い';
          break;
        case 7:
          textBLE = 'とても弱い';
          break;
        default:
          textBLE = '普通';
          break;
      }
      return textBLE;
    },
    converDateTime(t: any) {
      if (t > 0) {
        return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm:ss');
      } else {
        return 'ー';
      }
    },
    goDetailGate(ID: any) {
      this.$router.push({ path: Config.GATE_LOCK_DETAIL, query: {gateId: ID}});
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
            {
              text: 'ゲートID',
              align: 'start',
              sortable: true,
              value: 'gateId',
            },
          ];
        if (this.selectedDisplayOption.includes('gateModelId')) {
          headers.push({
            text: '製品ID',
            align: 'start',
            sortable: true,
            value: 'gateModelId',
          });
        }
        if (this.selectedDisplayOption.includes('gateModelName')) {
          headers.push({
            text: '製品名',
            align: 'start',
            sortable: true,
            value: 'gateModelName',
          });
        }
        if (this.selectedDisplayOption.includes('gateModelCode')) {
          headers.push({
            text: '型番',
            align: 'start',
            sortable: true,
            value: 'gateModelCode',
          });
        }
        if (this.selectedDisplayOption.includes('iconType')) {
          headers.push({
            text: 'アイコンタイプ',
            align: 'start',
            sortable: true,
            value: 'iconType',
          });
        }
        if (this.selectedDisplayOption.includes('makerCode')) {
          headers.push({
            text: 'メーカー番号',
            align: 'start',
            sortable: true,
            value: 'makerCode',
          });
        }
        if (this.selectedDisplayOption.includes('customizable')) {
          headers.push({
            text: '変更有無',
            align: 'start',
            sortable: true,
            value: 'customizable',
          });
        }
        if (this.selectedDisplayOption.includes('target')) {
          headers.push({
            text: 'ターゲット動作設定',
            align: 'start',
            sortable: true,
            value: 'target',
          });
        }
        if (this.selectedDisplayOption.includes('controlTime')) {
          headers.push({
            text: 'ターゲット動作設定Time',
            align: 'start',
            sortable: true,
            value: 'controlTime',
          });
        }
        if (this.selectedDisplayOption.includes('timeUnit')) {
          headers.push({
            text: 'ターゲット動作設定TimeUnit',
            align: 'start',
            sortable: true,
            value: 'timeUnit',
          });
        }
        if (this.selectedDisplayOption.includes('gateName')) {
          headers.push({
            text: 'ゲート名',
            align: 'start',
            sortable: true,
            value: 'gateName',
          });
        }
        if (this.selectedDisplayOption.includes('isVisitor')) {
          headers.push({
            text: 'ゲート動作モード',
            align: 'start',
            sortable: true,
            value: 'isVisitor',
          });
        }
        if (this.selectedDisplayOption.includes('isNFCValid')) {
          headers.push({
            text: 'NFCの有効',
            align: 'start',
            sortable: true,
            value: 'isNFCValid',
          });
        }
        if (this.selectedDisplayOption.includes('isTenKeyValid')) {
          headers.push({
            text: 'テンキーの有効・無効',
            align: 'start',
            sortable: true,
            value: 'isTenKeyValid',
          });
        }
        if (this.selectedDisplayOption.includes('isGatePasswordValid')) {
          headers.push({
            text: 'ゲートパスワードの有効・無効',
            align: 'start',
            sortable: true,
            value: 'isGatePasswordValid',
          });
        }
        if (this.selectedDisplayOption.includes('gatePasswordLength')) {
          headers.push({
            text: 'ゲートパスワードの桁数',
            align: 'start',
            sortable: true,
            value: 'gatePasswordLength',
          });
        }
        if (this.selectedDisplayOption.includes('gatePasswordSeed')) {
          headers.push({
            text: 'ゲートパスワードのseed値',
            align: 'start',
            sortable: true,
            value: 'gatePasswordSeed',
          });
        }
        if (this.selectedDisplayOption.includes('isTwoStepAuthValid')) {
          headers.push({
            text: '2段階認証の有効・無効',
            align: 'start',
            sortable: true,
            value: 'isTwoStepAuthValid',
          });
        }
        if (this.selectedDisplayOption.includes('bleIntensity')) {
          headers.push({
            text: 'BLE出力設定',
            align: 'start',
            sortable: true,
            value: 'bleIntensity',
          });
        }
        if (this.selectedDisplayOption.includes('buzzerPattern')) {
          headers.push({
            text: 'ブザー鳴動設定',
            align: 'start',
            sortable: true,
            value: 'buzzerPattern',
          });
        }
        if (this.selectedDisplayOption.includes('needLocation')) {
          headers.push({
            text: '位置情報取得',
            align: 'start',
            sortable: true,
            value: 'needLocation',
          });
        }
        if (this.selectedDisplayOption.includes('option1')) {
          headers.push({
            text: '備考1',
            align: 'start',
            sortable: true,
            value: 'option1',
          });
        }
        if (this.selectedDisplayOption.includes('option2')) {
          headers.push({
            text: '備考2',
            align: 'start',
            sortable: true,
            value: 'option2',
          });
        }
        if (this.selectedDisplayOption.includes('gateSNMain')) {
          headers.push({
            text: 'ゲート個体番号1',
            align: 'start',
            sortable: true,
            value: 'gateSNMain',
          });
        }
        if (this.selectedDisplayOption.includes('gateSNBLE')) {
          headers.push({
            text: 'ゲート個体番号2',
            align: 'start',
            sortable: true,
            value: 'gateSNBLE',
          });
        }
        if (this.selectedDisplayOption.includes('gateFWMain')) {
          headers.push({
            text: '適用されているファームウェアバージョン（メインボード）',
            align: 'start',
            sortable: true,
            value: 'gateFWMain',
          });
        }
        if (this.selectedDisplayOption.includes('gateFWBLE')) {
          headers.push({
            text: '適用されているファームウェアバージョン（BLE）',
            align: 'start',
            sortable: true,
            value: 'gateFWBLE',
          });
        }
        if (this.selectedDisplayOption.includes('registered')) {
          headers.push({
            text: '登録日',
            align: 'start',
            sortable: true,
            value: 'registered',
          });
        }
        if (this.selectedDisplayOption.includes('updated')) {
          headers.push({
            text: '更新日',
            align: 'start',
            sortable: true,
            value: 'updated',
          });
        }
        if (this.selectedDisplayOption.includes('reported')) {
          headers.push({
            text: 'ゲートへ設定情報を適用した日時',
            align: 'start',
            sortable: true,
            value: 'reported',
          });
        }
        if (this.selectedDisplayOption.includes('revisionDesired')) {
          headers.push({
            text: '個人コード照合データー：最新リビジョン',
            align: 'start',
            sortable: true,
            value: 'revisionDesired',
          });
        }
        if (this.selectedDisplayOption.includes('revisionReported')) {
          headers.push({
            text: '個人コード照合データー：ゲートへ設定済みのリビジョン',
            align: 'start',
            sortable: true,
            value: 'revisionReported',
          });
        }
        if (this.selectedDisplayOption.includes('voltage')) {
          headers.push({
            text: '電池電圧',
            align: 'start',
            sortable: true,
            value: 'voltage',
          });
        }
        if (this.selectedDisplayOption.includes('scanDate')) {
          headers.push({
            text: '電池電圧更新日',
            align: 'start',
            sortable: true,
            value: 'scanDate',
          });
        }
        if (this.selectedDisplayOption.includes('isValid')) {
          headers.push({
            text: 'ステータス',
            align: 'start',
            sortable: true,
            value: 'isValid',
          });
        }
        headers.push({
          text: ' 所属グループ/ユーザー数',
          align: 'center',
          sortable: false,
          width: '220px',
          value: 'data-table-expand',
        });
        headers.push({
          text: '',
          align: 'start',
          sortable: false,
          value: 'action-expand',
        });
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.pageLock;
      },
      set(val: number): void {
        this.$emit('change-page-lock', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPageLock;
      },
      set(val: number): void {
        this.$emit('change-per-page-lock', val);
        return;
      },
    },
  },
});
