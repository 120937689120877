



































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { Gate, GatePassage } from '@/types/alligate';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import { User } from '@/types/alligate';
interface Type {
  userId: string;
  name: string;
  gateId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
}
interface ArrayDefault {
  text: string;
  value: string;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
const today = new Date();
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  searchTargetItems: SearchTargetItem[];
  selectedItem: Type[];
  dateS: string;
  dateE: string;
  hours: any;
  minutes: any;
  timeS: string;
  dateStart: boolean;
  dateEnd: boolean;
  hourStart: string;
  minuteStart: string;
  timeE: string;
  timeEnd: boolean;
  hourEnd: string;
  minuteEnd: string;
}

export default Vue.extend({
  name: 'GateUserListTable',
  components: {
    Searchbox,
    ShowPerPage,
    SuccessButton,
    Pagination,
    DisplayOption,
    SelectPerPage,
  },
  props: {
    userTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    dataUsers: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    gateList: {
      default: () => [],
    },
    dataTimes: {
      type: Array as PropType<ArrayTime[]>,
      default: () => [],
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: 'グループ名', value: 'name' },
    ],
    selectedItem: [],
    selectedDisplayOption: [
      'name',
      'registered',
    ],
    displayOptionItems: [
      { label: 'グループ名', value: 'name', isDisabled: true },
    ],
    dateS: '',
    dateE: '',
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: ['00', '15', '30', '45'],
    timeS: '',
    dateStart: false,
    dateEnd: false,
    hourStart: '',
    minuteStart: '',
    timeE: '',
    timeEnd: false,
    hourEnd: '',
    minuteEnd: '',
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ユーザー名',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'ユーザーID',
            align: 'start',
            sortable: true,
            value: 'userId',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
    innerValue: {
      get(): any {
        return this.dataUsers.map((item, index) => {
          const ret = {
            name: item.name,
            userId: item.userId,
            index,
          };
          return ret;
        });
      },
    },
  },
  watch: {
    selectedItem(val: User[]) {
      this.$emit('select-users', val.map((item) => item));
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    formatDate(date: string): string {
      const [year, month, day] = date.split('-');
      return `${year}/${month}/${day}`;
    },
  },
});
