var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("personal-setting-template", {
    attrs: {
      loading: _vm.loading,
      errorMessageLogs: _vm.errorMessageLogs,
      userData: _vm.userData
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }