var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-loading allig-loading-mask" },
    [
      _c(
        "v-row",
        {
          staticClass: "allig-loading-contents",
          attrs: { "no-gutters": "", justify: "center", align: "center" }
        },
        [
          _c("v-progress-circular", {
            staticClass: "col-auto",
            attrs: { color: "green", indeterminate: "" }
          }),
          _vm._v(" "),
          _c("v-col", { staticClass: "col-auto pl-5" }, [
            _vm._v("\n      " + _vm._s(_vm.text) + "\n    ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }