























































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import ErrorButton from '@/components/molecules/ErrorButton/ErrorButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
export interface DataType {
  files: any;
  fileName: string;
  isCheckData: boolean;
  actionItems: any;
  tab: any;
}
export default Vue.extend({
  name: 'UserImport',
  components: {
    Modal,
    SuccessButton,
    ErrorButton,
    CancelButton,
  },
  props: {
    totalDataRowCSV: {
      type: Number,
      default: 0,
    },
    typeConfirm: {
      type: Number,
      default: 0,
    },
    successItemCSV: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      default: [],
    },
    success: {
      type: Boolean,
      default: false,
    },
    showModalConfirmEmail: {
      type: Boolean,
      default: false,
    },
    showModalProcess: {
      type: Boolean,
      default: false,
    },
    showModalImport: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    exportType: {
      type: Number,
      default: 0, // 0: CSV (user), 1: CSV (gate), 2: Excel
    },
    showProcessImportExcel: {
      type: Boolean,
      default: false,
    },
    isExport: {
      type: Boolean,
      default: false,
    },
    successItem: {
      type: Number,
      default: 0,
    },
    totalDataRow: {
      type: Number,
      default: 0,
    },
    errorMessageExcel: {
      default: [],
    },
    isFinishImportExcel: {
      type: Boolean,
      default: false,
    },
    sheetUserName: {
      type: String,
      default: '',
    },
    isType: {
      type: String,
      default: '',
    },
    sheet1Name: {
      type: String,
      default: '',
    },
    successItemSheet1: {
      type: Number,
      default: 0,
    },
    progressSheet1: {
      type: Number,
      default: 0,
    },
    totalDataRowSheet1: {
      type: Number,
      default: 0,
    },
    sheet2Name: {
      type: String,
      default: '',
    },
    successItemSheet2: {
      type: Number,
      default: 0,
    },
    progressSheet2: {
      type: Number,
      default: 0,
    },
    totalDataRowSheet2: {
      type: Number,
      default: 0,
    },
    isHasLowerCaseCardNumberExcel: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    tab: 0,
    actionItems: [
      { title: '一斉登録', tabname: 'tab1' },
      { title: 'カードも併せて一斉登録', tabname: 'tab2' },
    ],
    files: [],
    fileName: '',
    isCheckData: false,
  }),
  methods: {
    previewFiles(event: any) {
      this.files = event.target.files[0];
      this.fileName = event.target.files[0] ? event.target.files[0].name : '';
    },
    openFile() {
      const element: HTMLElement = document.getElementsByClassName('custom-file-input')[0] as HTMLElement;
      element.click();
    },
    closeDialogImport() {
      const element: HTMLInputElement = document.getElementsByClassName('custom-file-input')[0] as HTMLInputElement;
      element.value = '';
      this.files = null;
      this.fileName = '';
      this.$emit('closeImportCSV');
    },
    clickExportExcel() {
      this.$emit('clickExportExcel', this.isCheckData);
    },
    yesChoice() {
      if (this.typeConfirm === 0) {
        this.$emit('clickImportCSV', {
          files: this.files,
          isSendEmail: true,
        });
      } else if (this.typeConfirm === 2) {
        this.$emit('clickImportExcel', {
          files: this.files,
          isSendEmail: true,
        });
      }
    },
    noChoice() {
      if (this.typeConfirm === 0) {
        this.$emit('clickImportCSV', {
          files: this.files,
          isSendEmail: false,
        });
      } else if (this.typeConfirm === 2) {
        this.$emit('clickImportExcel', {
          files: this.files,
          isSendEmail: false,
        });
      }
    },
    clickImportCSVTimeUnlockGate() {
      this.$emit('clickImportCSVTimeUnlockGate', {
          files: this.files,
        });
    },
    changeTab(event: any) {
      const element: HTMLInputElement = document.getElementsByClassName('custom-file-input')[0] as HTMLInputElement;
      element.value = '';
      this.files = null;
      this.fileName = '';
      this.$emit('changeTab', event);
    },
  },
});
