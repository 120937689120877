var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { justify: "end" } },
            [
              _c(
                "v-col",
                { staticClass: "btn-add-card" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("clickBackCard")
                        }
                      }
                    },
                    [_vm._v("\n        戻る\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.errorMessageLogs !== ""
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold"
                },
                [_vm._v("\n          カード情報\n        ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("card-detail-form", {
            attrs: { cardDetail: _vm.cardDetail },
            on: {
              clickEditCard: function($event) {
                return _vm.$emit("clickEditCard", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }