















import Vue from 'vue';
export default Vue.extend({
  name: 'FormInput',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
});
