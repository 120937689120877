var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-user-table" },
    [
      _vm.successMessageLogs !== "" && _vm.isGetDataList === false
        ? _c(
            "v-col",
            { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-success" },
            [_vm._v("\n    " + _vm._s(_vm.successMessageLogs) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.errorMessageLogs !== "" && _vm.isGetDataList === false
        ? _c(
            "v-col",
            { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
            [_vm._v("\n    " + _vm._s(_vm.errorMessageLogs) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-3 border-bottom-bold",
          attrs: {
            "no-gutters": "",
            justify: "space-between",
            "align-content": "center"
          }
        },
        [
          _c("show-per-page", {
            staticClass: "ml-8 mb-3",
            attrs: {
              "per-page": _vm.perPage,
              page: _vm.page,
              "items-length": _vm.maxRecord
            }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-8",
              on: {
                click: function() {
                  _vm.showOption = !_vm.showOption
                }
              }
            },
            [
              _c("span", { staticClass: "display-option-title" }, [
                _vm._v("\n      表示オプション\n    ")
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mb-3" },
                [
                  _c("v-icon", { attrs: { dense: "" } }, [
                    _vm._v("mdi-chevron-down")
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pt-0 pb-0 mt-0" },
        [
          _c(
            "v-container",
            { staticClass: "pt-0" },
            [
              _c("display-option", {
                attrs: {
                  isShowOption: _vm.showOption,
                  "display-option-items": _vm.displayOptionItems,
                  disabled: _vm.isGetDataList,
                  isVaildOnly: _vm.isVaildOnly
                },
                on: {
                  "show-only-isVaild": function($event) {
                    return _vm.$emit("show-only-isVaild", $event)
                  },
                  input: function($event) {
                    return _vm.CheckColumn($event)
                  }
                },
                model: {
                  value: _vm.selectedDisplayOption,
                  callback: function($$v) {
                    _vm.selectedDisplayOption = $$v
                  },
                  expression: "selectedDisplayOption"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "border-table scroll-table user-list",
        attrs: {
          "must-sort": "",
          loading: _vm.isGetDataList,
          headers: _vm.headers,
          items: _vm.users,
          "items-per-page": _vm.perPage,
          "item-key": "userId",
          "no-data-text": "該当するユーザーがいません",
          "loading-text": "データを読み込中です",
          "hide-default-footer": "",
          "show-select": _vm.showSelect,
          "show-expand": "",
          "single-expand": "",
          "custom-sort": _vm.customSort,
          expanded: _vm.expanded
        },
        on: {
          "item-expanded": _vm.changeEx,
          "update:expanded": function($event) {
            _vm.expanded = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.userId",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", { staticClass: "item-user-id" }, [
                    _vm._v(_vm._s(item.userId))
                  ])
                ]
              }
            },
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("text-link", {
                    staticClass: "item-user-name",
                    attrs: { text: item.name },
                    on: {
                      click: function($event) {
                        return _vm.clickDetail(item.userId)
                      }
                    }
                  })
                ]
              }
            },
            {
              key: "item.isAdmin",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n    " +
                      _vm._s(item.isAdmin ? "管理者" : "一般ユーザ") +
                      "\n  "
                  )
                ]
              }
            },
            {
              key: "item.isPhoneAdmin",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n    " +
                      _vm._s(item.isPhoneAdmin ? "有効" : "無効") +
                      "\n  "
                  )
                ]
              }
            },
            {
              key: "item.shareKeyBeValid",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n    " +
                      _vm._s(item.shareKeyBeValid ? "有効" : "無効") +
                      "\n  "
                  )
                ]
              }
            },
            {
              key: "item.isValid",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n    " + _vm._s(item.isValid ? "有効" : "無効") + "\n  "
                  )
                ]
              }
            },
            {
              key: "item.isMfaSms",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n    " + _vm._s(item.isMfaSms ? "有効" : "無効") + "\n  "
                  )
                ]
              }
            },
            {
              key: "item.registered",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n    " +
                      _vm._s(_vm.converDateShort(item.registered)) +
                      "\n  "
                  )
                ]
              }
            },
            {
              key: "item.updated",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    "\n    " +
                      _vm._s(_vm.converDateShort(item.updated)) +
                      "\n  "
                  )
                ]
              }
            },
            {
              key: "item.data-table-expand",
              fn: function(ref) {
                var expand = ref.expand
                var item = ref.item
                var isExpanded = ref.isExpanded
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", disabled: isExpanded },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "white",
                                          elevation: "0"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.selectedGroupIds = []
                                            expand(!isExpanded)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "item-count" },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-account-supervisor")
                                        ]),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.groupCount) +
                                            "\n              "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml-5 mr-5" }, [
                                      _vm._v("/")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "item-count" },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-door-closed")
                                        ]),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.gateCount) +
                                            "\n              "
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", { staticClass: "text-caption" }, [
                        _vm._v("グループ一覧を開く")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "transition",
                    { attrs: { name: "expand-icon" } },
                    [
                      isExpanded
                        ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.icons",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("confirm-action", {
                                              action: item.isValid ? 3 : 2,
                                              userId: item.userId,
                                              isValid: item.isValid
                                                ? false
                                                : true
                                            })
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    item.isValid
                                      ? _c(
                                          "v-icon",
                                          { staticClass: "green--text" },
                                          [_vm._v("mdi-account-check")]
                                        )
                                      : _c("v-icon", [
                                          _vm._v("mdi-account-off")
                                        ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(item.isValid ? "有効" : "無効") +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.goLogPage(item.name)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-file-move-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_vm._v(" "), _c("span", [_vm._v(" ゲートログ ")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "clickEditUser",
                                              item.userId
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-square-edit-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_vm._v(" "), _c("span", [_vm._v("ユーザー編集")])]
                  )
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-2 mb-1 ml-5 mr-5",
                              attrs: {
                                justify: "space-between",
                                id: "expanded-data"
                              }
                            },
                            [
                              _vm.ExpandedGroup[item.userId] &&
                              _vm.ExpandedGroup[item.userId].maxRecords !==
                                undefined
                                ? _c(
                                    "div",
                                    { staticClass: "allig-title mt-1" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            "所属グループ一覧 (全" +
                                              _vm.ExpandedGroup[item.userId]
                                                .maxRecords +
                                              "件)"
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "allig-title mt-1" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s("所属グループ一覧 (全0件)") +
                                          "\n              "
                                      )
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-0" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn-primary",
                                          on: {
                                            click: function($event) {
                                              return _vm.clickJoinGroup(
                                                item.userId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                関連付け追加\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn-secondary",
                                          attrs: {
                                            disabled:
                                              _vm.selectedGroupIds.length === 0
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "confirm-action",
                                                {
                                                  action: 4,
                                                  userId: item.userId,
                                                  groupIds: _vm.selectedGroupIds
                                                }
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                関連付け解除\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("group-gate-table", {
                            attrs: {
                              loading: _vm.loadingGroup,
                              isChange: _vm.isChange,
                              userId: item.userId,
                              groups: _vm.ExpandedGroup[item.userId]
                                ? _vm.ExpandedGroup[item.userId].groups
                                : [],
                              gateList: _vm.gateList,
                              pageGroup: _vm.pageGroup,
                              groupDetail: _vm.groupDetail,
                              pageGate: _vm.pageGate,
                              groupTotalCount:
                                _vm.ExpandedGroup[item.userId] &&
                                _vm.ExpandedGroup[item.userId].maxRecords
                                  ? _vm.ExpandedGroup[item.userId].maxRecords
                                  : 0,
                              groupId: _vm.groupId,
                              loadingGate: _vm.loadingGate
                            },
                            on: {
                              "select-groups": function(groupIds) {
                                _vm.selectedGroupIds = groupIds
                              },
                              "change-expanded-group": function($event) {
                                return _vm.$emit(
                                  "change-expanded-group",
                                  $event
                                )
                              },
                              "change-page-group": function($event) {
                                return _vm.$emit(
                                  "change-page-group-gate",
                                  $event
                                )
                              },
                              "change-page-gate": function($event) {
                                return _vm.$emit(
                                  "change-page-group-gate",
                                  $event
                                )
                              },
                              "confirm-action": function($event) {
                                return _vm.$emit("confirm-action", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedItem,
          callback: function($$v) {
            _vm.selectedItem = $$v
          },
          expression: "selectedItem"
        }
      }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-3",
          attrs: { "no-gutters": "", justify: "space-between" }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mr-5 mb-2" },
            [
              _c("select-per-page", {
                attrs: {
                  page: _vm.page,
                  "per-page-items": [25, 50, 100, 1000],
                  "items-length": _vm.maxRecord,
                  disabled: _vm.isGetDataList
                },
                model: {
                  value: _vm.innerPerPage,
                  callback: function($$v) {
                    _vm.innerPerPage = $$v
                  },
                  expression: "innerPerPage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            staticClass: "mb-1",
            attrs: {
              "page-length": Math.ceil(_vm.maxRecord / _vm.perPage),
              disabled: _vm.isGetDataList
            },
            model: {
              value: _vm.innerPage,
              callback: function($$v) {
                _vm.innerPage = $$v
              },
              expression: "innerPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }