














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import { DataTableHeader } from 'vuetify';
import { Group } from '@/types/alligate';
import * as Config from '@/config';
const min: string[] = [];
for (let i = 0; i <= 59 ; i++) {
  let value = '';
  if (i < 10) {
    value = '0' + i;
  } else {
    value = i.toString();
  }
  min.push(value);
}
const arrayDayName = [
  {dayTypeName: '月曜'},
  {dayTypeName: '火曜'},
  {dayTypeName: '水曜'},
  {dayTypeName: '木曜'},
  {dayTypeName: '金曜'},
  {dayTypeName: '土曜'},
  {dayTypeName: '日曜'},
];
export interface TypeItem {
  title: string;
}
export interface SearchTargetItem {
  label: string;
  value: string;
}
export interface DataType {
  hours: any[];
  timeZones: any[];
  minutes: any[];
  hourStart: string;
  minuteStart: string;
  hourEnd: string;
  minuteEnd: string;
  isAdvanced: boolean;
  doSet: boolean;
  notSet: boolean;
  dataTimes: any;
  dataTimesDefault: any;
  dataSpecialTimes: any;
  allDay: boolean;
  valid: boolean;
  itemSpecialday: any[];
  arrayAddSpecialTime: any;
  inputSpecialday: any;
  inputGroup: any;
  hoursEndSpecial: string;
  hoursStartSpecial: string;
  passableName: string;
  arrayAddGroup: any;
  rulesValidate: any;
  errorMessageTime: string;
  errorMessageGroup: string;
  errorListGate: string;
  errorHoursStartSpecial: string;
  errorHoursEndSpecial: string;
  errorAddListGate: string;
}
interface Type {
  name: string;
  isValid: boolean;
}
interface GroupItem {
  text: string;
  value: string;
}
interface DayType {
  value: any;
  text: string;
}
interface PassableType {
  passableTimeSettingId: string;
  name: string;
  isDefault: boolean;
  timeSettings: [
    {
      dayTypeId: any;
      dayTypeName: string;
      allDay: boolean;
      startTime?: string;
      endTime?: string;
      notSet?: boolean;
      doSet?: boolean;
    }
  ];
  updated: number;
}
interface TimeSettings {
  dayTypeId: any;
  dayTypeName: string;
  allDay: boolean;
  startTime?: string;
  endTime?: string;
  notSet?: boolean;
  doSet?: boolean;
}
export default Vue.extend({
  name: 'PassableTimeSettingEdit',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    TextLink,
  },
  props: {
    dataPassableDetail: {
      type: Array as PropType<PassableType[]>,
      default: () => [],
    },
    isEdit: {
      type: Number,
      default: 0,
    },
    groupListAdd: {
      type: Array,
      default: () => {
        return [];
      },
    },
    timeSettings: {
      type: Array as PropType<TimeSettings[]>,
      default: () => [],
    },
    groupListEdit: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dayTypes: {
      type: Array as PropType<DayType[]>,
      default: () => {
        return [
          {
            value: 0,
            text: '',
          },
        ];
      },
    },
    groupListByPassableTime: {
      type: Array as PropType<GroupItem[]>,
      default: () => {
        return [];
      },
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'その他時間帯',
            align: 'start',
            sortable: false,
            width: '20%',
            value: 'name',
          },
          {
            text: '期間指定',
            align: 'start',
            sortable: false,
            value: 'expand-time',
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'expand-action',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    headersGroup: {
      get(): DataTableHeader[] {
        const headersGroup: DataTableHeader[] = [
          {
            text: 'グループ名',
            align: 'start',
            sortable: false,
            width: '20%',
            value: 'text',
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'expand-action',
          },
        ];
        return headersGroup;
      },
      set(): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    valid: true,
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: ['00', '15', '30', '45'],
    hourStart: '00',
    minuteStart: '00',
    hourEnd: '00',
    minuteEnd: '00',
    timeZones: [
      {
        dayTypeId: 7,
        dayTypeName: '日曜',
        allDay: 1,
        startTime: '00:00',
        endTime: '00:00',
        notSet: false,
      },
      {
        dayTypeId: 1,
        dayTypeName: '月曜',
        allDay: 1,
        startTime: '00:00',
        endTime: '00:00',
        notSet: false,
      },
      {
        dayTypeId: 2,
        dayTypeName: '火曜',
        allDay: 1,
        startTime: '00:00',
        endTime: '00:00',
        notSet: false,
      },
      {
        dayTypeId: 3,
        dayTypeName: '水曜',
        allDay: 1,
        startTime: '00:00',
        endTime: '00:00',
        notSet: false,
      },
      {
        dayTypeId: 4,
        dayTypeName: '木曜',
        allDay: 1,
        startTime: '00:00',
        endTime: '00:00',
        notSet: false,
      },
      {
        dayTypeId: 5,
        dayTypeName: '金曜',
        allDay: 1,
        startTime: '00:00',
        endTime: '00:00',
        notSet: false,
      },
      {
        dayTypeId: 6,
        dayTypeName: '土曜',
        allDay: 1,
        startTime: '00:00',
        endTime: '00:00',
        notSet: false,
      },
    ],
    isAdvanced: false,
    doSet: false,
    notSet: false,
    dataTimes: [],
    dataSpecialTimes: [],
    allDay: false,
    itemSpecialday: [],
    arrayAddSpecialTime: [],
    inputSpecialday: [],
    hoursEndSpecial: '',
    hoursStartSpecial: '',
    inputGroup: '',
    arrayAddGroup: [],
    passableName: '',
    errorMessageTime: '',
    errorMessageGroup: '',
    errorListGate: '',
    errorHoursStartSpecial: '',
    errorHoursEndSpecial: '',
    errorAddListGate: '',
    dataTimesDefault: [],
    rulesValidate: {
      validateStartTime: (name: string, value: string, notSet: boolean, doSet: boolean, allDay: boolean) => {
        if (notSet === true || doSet === true || allDay === true) {
          return true;
        } else {
          const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
          return pattern.test(value) || name + '開始時間を設定してください。';
        }
      },
      validateEndTime:
      (name: string, value: string, notSet: boolean, doSet: boolean, allDay: boolean, startTime: any) => {
        if (notSet === true || doSet === true || allDay === true) {
          return true;
        } else {
          if (value <= startTime) {
            return name + Config.ERROR_END_TIME;
          } else {
          const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
          return pattern.test(value) || name + '終了時間を設定してください。';
          }
        }
      },
    },
  }),
  methods: {
    changeDoSetSpecial() {
      this.notSet = false;
    },
    changeNotSetSpecial() {
      this.doSet = false;
    },
    changeDoSet(divId: any, value: any, change: any) {
      if (change === true && value === true) {
        const div = document.querySelector('#' + divId);
        (div as HTMLElement).click();
      }
    },
    changeNotSet(divId: any, value: any, change: any) {
      if (change === true && value === true) {
        const div = document.querySelector('#' + divId);
        (div as HTMLElement).click();
      }
    },
    goGroup(ID: any) {
      this.$router.push({ path: Config.GROUP_DETAIL,
      query: {groupId: ID, goBack: 'setting'}});
    },
    init() {
      this.dataTimes = [];
      if (this.dataPassableDetail.length > 0) {
        if (this.groupListByPassableTime !== undefined && this.groupListByPassableTime[0] !== undefined) {
          this.inputGroup = this.groupListByPassableTime;
        }
        let timeSettings;
        if (this.isEdit === 1) {
          timeSettings = this.dataPassableDetail[0].timeSettings;
        } else {
          timeSettings = this.timeSettings;
        }
        this.passableName = this.dataPassableDetail[0].name;
        this.dataTimes = timeSettings.filter(({ dayTypeName: val1}) =>
        arrayDayName.some(({ dayTypeName: val2}) => val2 === val1));
        this.dataSpecialTimes = timeSettings.filter(
          ({ dayTypeName: id1}) => !arrayDayName.some(({ dayTypeName: id2}) => id2 === id1));
        this.dataTimes.map((o: { hourStart: string; }) => o.hourStart = '00');
        this.dataTimes.map((o: { hourEnd: string; }) => o.hourEnd = '00');
        this.dataTimes.map((o: { minuteEnd: string; }) => o.minuteEnd = '00');
        this.dataTimes.map((o: { minuteStart: string; }) => o.minuteStart = '00');
        this.dataTimes.forEach((element: any, key: any) => {
          const arrS = element.startTime.split(':');
          const arrE = element.endTime.split(':');
          this.dataTimes[key].hourStart = arrS[0] !== '99' ? arrS[0] : '00' ;
          this.dataTimes[key].minuteStart = arrS[1] !== '99' ? arrS[1] : '00';
          this.dataTimes[key].hourEnd = arrE[0] !== '99' ? arrE[0] : '00';
          this.dataTimes[key].minuteEnd = arrE[1] !== '99' ? arrE[1] : '00';
          if (element.allDay === 2 && element.startTime === '99:99' && element.endTime === '99:99') {
            this.dataTimes[key].notSet = true;
            this.dataTimes[key].doSet = false;
          } else if (element.allDay === 1) {
            this.dataTimes[key].notSet = false;
            this.dataTimes[key].doSet = true;
          } else {
            this.dataTimes[key].notSet = false;
            this.dataTimes[key].doSet = false;
          }
        });
      } else {
        this.dataTimes = this.dayTypes.filter(({ text: val1}) =>
        arrayDayName.some(({ dayTypeName: val2}) => val2 === val1));
        this.dataTimes.map((o: { hourStart: string; }) => o.hourStart = '00');
        this.dataTimes.map((o: { hourEnd: string; }) => o.hourEnd = '00');
        this.dataTimes.map((o: { minuteEnd: string; }) => o.minuteEnd = '00');
        this.dataTimes.map((o: { minuteStart: string; }) => o.minuteStart = '00');
      }
    },
    clickRegisterPassable() {
      this.$emit('resetMsg');
      this.errorListGate = '';
      this.errorHoursStartSpecial = '';
      this.errorHoursEndSpecial = '';
      const validate = (this.$refs.formPassable as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('clickRegisterPassable', {
          timeZones: this.dataTimes,
          allDay: this.allDay,
          // arrayAddGroup: this.arrayAddGroup,
          arrayAddSpecialTime: this.arrayAddSpecialTime,
          passableName: this.passableName,
          inputGroup: this.inputGroup,
        });
      }
    },
    clickSavePassable() {
      this.$emit('resetMsg');
      this.errorListGate = '';
      this.errorHoursStartSpecial = '';
      this.errorHoursEndSpecial = '';
      const validate = (this.$refs.formPassable as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('clickSavePassable', {
          timeZones: this.dataTimes,
          allDay: this.allDay,
          inputGroup: this.inputGroup,
          arrayAddSpecialTime: this.dataSpecialTimes,
          dataPassableDetail: this.dataPassableDetail,
          passableName: this.passableName,
        });
      }
    },
    resetInput() {
      this.hourStart = '00';
      this.hourEnd = '00';
      this.minuteEnd = '00';
      this.minuteStart = '00';
    },
    convertStatus(data: any) {
      let check = 0;
      for (const item of data) {
        if (item.allDay === 1) {
          check++;
        }
      }
      if (check === data.length) {
        return '有効';
      } else {
        return '無効';
      }
    },
    updateSelect(value: any) {
      console.log('value', value);
    },
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    advancedSetting(isAdvanced: boolean) {
      this.isAdvanced = isAdvanced ? false : true;
    },
    addSpecialTime() {
      let count = 0;
      this.errorMessageTime = '';
      this.errorListGate = '';
      this.errorHoursStartSpecial = '';
      this.errorHoursEndSpecial = '';
      this.errorAddListGate = '';
      for (const item of this.dataSpecialTimes) {
        if (item.dayTypeId === undefined || item.dayTypeId === '' ||
        item.dayTypeId === null || (item.dayTypeId.toString() === this.inputSpecialday.value)) {
          this.errorAddListGate = 'すでに追加されている時間帯になります。追加する場合は、一度削除してから実施してください。';
          this.errorHoursEndSpecial = '';
          this.errorHoursStartSpecial = '';
          break ;
        } else {
          count++;
        }
      }
      const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
      const hoursStartError =  pattern.test(this.hoursStartSpecial);
      const hoursEndError =  pattern.test(this.hoursEndSpecial);
      if (count === this.dataSpecialTimes.length &&
      this.inputSpecialday.value !== undefined && this.inputSpecialday.value !== null && this.inputSpecialday.value !== '' &&
      (hoursStartError === true && hoursEndError === true && this.hoursStartSpecial < this.hoursEndSpecial ||
      this.doSet === true || this.notSet === true)
      ) {
        let setAllDay = 0;
        if (this.doSet === true && this.notSet !== true) {
          setAllDay = 1;
        } else if (this.doSet !== true && this.notSet === true) {
          setAllDay = 2;
        }
        this.arrayAddSpecialTime.push({
            dayTypeId: this.inputSpecialday.value,
            dayTypeName: this.inputSpecialday.text,
            startTime: this.hoursStartSpecial,
            endTime: this.hoursEndSpecial,
            allDay: setAllDay,
          });
        this.dataSpecialTimes.push({
          dayTypeId: this.inputSpecialday.value,
          dayTypeName: this.inputSpecialday.text,
          startTime: this.hoursStartSpecial,
          endTime: this.hoursEndSpecial,
          allDay: setAllDay,
        });
      } else {
        if (this.inputSpecialday.length === 0 || this.inputSpecialday.value === '') {
          this.errorListGate = '時間を指定ください。';
          this.errorAddListGate = '';
        }
        if (hoursStartError === false && this.errorAddListGate === '' &&
        this.doSet !== true && this.notSet !== true) {
          this.errorHoursStartSpecial = '開始時間を設定してください。';
        }
        if (hoursEndError === true && hoursStartError === true &&
        this.hoursEndSpecial <= this.hoursStartSpecial && this.errorAddListGate === '' &&
        this.doSet !== true && this.notSet !== true) {
          this.errorHoursEndSpecial = Config.ERROR_END_TIME;
        }
        if (hoursEndError === false && this.errorAddListGate === '' && this.doSet !== true && this.notSet !== true) {
          this.errorHoursEndSpecial = '終了時間を設定してください。';
        }
      }
    },
    addGroup() {
      let count = 0;
      this.errorMessageGroup = '';
      for (const itemGroup of this.groupListByPassableTime) {
        if (itemGroup.value === this.inputGroup.value) {
          break;
        } else {
          count++;
        }
      }
      if (count === this.groupListByPassableTime.length && this.inputGroup !== '') {
          this.arrayAddGroup.push({
            groupId: this.inputGroup.value,
            groupName: this.inputGroup.text,
          });
          this.groupListByPassableTime.push({
            value: this.inputGroup.value,
            text: this.inputGroup.text,
          });
      } else {
        this.errorMessageGroup = '形式が誤っている。';
      }
    },
    deleteSpecialTime(ID: string) {
      for (const i of Object.keys(this.arrayAddSpecialTime)) {
        if (this.arrayAddSpecialTime[parseInt(i)].dayTypeId === ID) {
          this.arrayAddSpecialTime.splice(parseInt(i), 1);
        }
      }
      for (const i of Object.keys(this.dataSpecialTimes)) {
        if (this.dataSpecialTimes[parseInt(i)].dayTypeId === ID) {
          this.dataSpecialTimes.splice(parseInt(i), 1);
        }
      }
    },
    deleteGroup(ID: string): void {
      for (const i of Object.keys(this.groupListByPassableTime)) {
        if (this.groupListByPassableTime[parseInt(i)] !== undefined
        && this.groupListByPassableTime[parseInt(i)].value === ID) {
        this.groupListByPassableTime.splice(parseInt(i), 1);
        }
      }
      for (const i of Object.keys(this.arrayAddGroup)) {
        if (this.arrayAddGroup[parseInt(i)].groupId === ID) {
          this.arrayAddGroup.splice(parseInt(i), 1);
        }
      }
    },
    loadSpecialTimes() {
      const arrayDay = ['月曜', '火曜', '水曜', '木曜', '金曜', '土曜', '日曜'];
      const dataDayTypes =  this.dayTypes;
      const arr = Array.prototype.map.call(Object(dataDayTypes), (item) => {
        // process
        if (!arrayDay.includes(item.text)) {
          this.itemSpecialday.push({
            text: item.text,
            value: item.value.toString(),
          });
        }
      });
    },
    loadDefaultTimes() {
      return new Promise(async (resolve, reject) => {
        this.dataTimesDefault = [];
        const arrayDay = ['月曜', '火曜', '水曜', '木曜', '金曜', '土曜', '日曜'];
        const dataDayTypes =  this.dayTypes;
        for (const item of dataDayTypes) {
          if (arrayDay.includes(item.text)) {
            await this.dataTimesDefault.push({
              text: item.text,
              value: item.value.toString(),
            });
          }
        }
        resolve(null);
      });
    },
    loadTimes() {
      this.dataTimes = this.dayTypes.filter(({ text: val1}) =>
        arrayDayName.some(({ dayTypeName: val2}) => val2 === val1));
      this.dataTimes.map((o: { hourStart: string; }) => o.hourStart = '00');
      this.dataTimes.map((o: { hourEnd: string; }) => o.hourEnd = '00');
      this.dataTimes.map((o: { minuteEnd: string; }) => o.minuteEnd = '00');
      this.dataTimes.map((o: { minuteStart: string; }) => o.minuteStart = '00');
      this.dataTimes.forEach((element: any, key: any) => {
        this.dataTimes[key].dayTypeName =  element.text;
        this.dataTimes[key].name =  element.text;
        this.dataTimes[key].dayTypeId =  element.value;
      });
    },
    loadTimesMore() {
      let dataTimesTmp;
      dataTimesTmp = this.dayTypes.filter(({ text: val1}) =>
        arrayDayName.some(({ dayTypeName: val2}) => val2 === val1));
      const arr = Array.prototype.map.call(Object(dataTimesTmp), (item) => {
        const index  = this.dataTimes.map((e: { dayTypeId: any; }) => e.dayTypeId).indexOf(item.value);
        if (index < 0 && !arrayDayName.includes(item.text)) {
          item.dayTypeName =  item.text;
          item.name =  item.text;
          item.dayTypeId =  item.value;
          item.hourStart =  '00:00';
          item.hourEnd =  '00:00';
          item.minuteEnd =  '00:00';
          item.minuteStart =  '00:00';
          this.dataTimes.push(item);
        }
      });
    },
  },
  watch: {
    loadingDate(value: any) {
      if (value === false) {
        this.init();
      }
    },
    loading(value: any) {
      if (this.isEdit === 1) {
        this.init();
      }
    },
    // groupListAdd(value: any) {
    //   console.log('val', value);
    //   (this.$refs.formPassable as Vue & { reset: () => boolean }).reset();
    // },
    dayTypes(value: any) {
      this.loadDefaultTimes().then(() => {
        if (this.isEdit === 3) {
          this.loadTimes();
          this.allDay = false;
          this.loadSpecialTimes();
        }
        if (this.isEdit === 2) {
          this.loadSpecialTimes();
        }
        if (this.dataTimes.length === 0 && this.isEdit !== 1) {
          this.loadTimes();
        } else if (this.dataTimes.length < this.dataTimesDefault.length && this.isEdit !== 1) {
          this.loadTimesMore();
        }
      });
    },
    successMessageLogs(val: any) {
      if (val !== '' && this.isEdit === 3) {
        (this.$refs.formPassable as Vue & { reset: () => boolean }).reset();
      }
    },
    groupListByPassableTime(val: any) {
      this.inputGroup = this.groupListByPassableTime;
    },
  },
  mounted() {
    this.minutes = min;
    // if (this.isEdit === 3) {
    //   this.dataTimes = this.timeZones;
    // }
  },
});
