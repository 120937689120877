var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "zenescan-face-db-list" },
    [
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c("alert", {
              attrs: { text: _vm.errorMessageLogsModal, type: "error" },
              model: {
                value: _vm.isDisplayErrorMessage,
                callback: function($$v) {
                  _vm.isDisplayErrorMessage = $$v
                },
                expression: "isDisplayErrorMessage"
              }
            }),
            _vm._v(" "),
            _c("alert", {
              attrs: { text: _vm.successMessageLogsModal, type: "success" },
              model: {
                value: _vm.isDisplaySuccessMessage,
                callback: function($$v) {
                  _vm.isDisplaySuccessMessage = $$v
                },
                expression: "isDisplaySuccessMessage"
              }
            }),
            _vm._v(" "),
            _c(
              "v-row",
              { attrs: { justify: "end" } },
              [
                _c(
                  "v-col",
                  { staticClass: "btn-add" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-primary",
                        attrs: { outlined: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("click-open-add-zenescan-face-db")
                          }
                        }
                      },
                      [_vm._v("\n          追加\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("v-data-table", {
              staticClass: "border-table scroll-table",
              attrs: {
                "must-sort": "",
                headers: _vm.headers,
                loading: _vm.isLoadingZenescanFaceDb,
                items: _vm.displayZenescanFaceDbs,
                "items-per-page": _vm.perPageItemLength,
                "footer-props": {
                  "items-per-page-text": "表示件数:",
                  "items-per-page-options": [10, 50, 100]
                },
                "no-data-text": "データはありません",
                "loading-text": "データを読み込中です",
                "show-select": _vm.showSelect,
                dense: "",
                "item-key": "faceDbId",
                "item-class": "align-center"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.icons",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      item.status === "-"
                                        ? _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { color: "success" } },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                mdi-check-circle\n              "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { color: "error" } },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                mdi-alert-circle\n              "
                                              )
                                            ]
                                          )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            item.status === "-"
                              ? _c("span", [_vm._v(" 問題なし ")])
                              : _c("span", [
                                  _vm._v(" " + _vm._s(item.status) + " ")
                                ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openLoginInfoEdit(
                                                    item.faceDbId
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          item.isConnected
                                            ? _c("v-icon", [
                                                _vm._v("mdi-lan-connect")
                                              ])
                                            : _c(
                                                "v-icon",
                                                { staticClass: "red" },
                                                [_vm._v("mdi-lan-disconnect")]
                                              )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(" Zenescan接続状態 ")])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "click-edit-zenescan-face-db",
                                                    item.faceDbId
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-square-edit-outline")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(" Zenescan設定編集 ")])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.clickConfirmDeleteZenescanFaceDb(
                                                    item.faceDbId
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-trash-can-outline")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(" Zenescan設定削除 ")])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.clickConfirmSyncZenescanFaceDb(
                                                    item.faceDbId
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "mdi-arrow-up-bold-box-outline"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(" Zenescan設定再登録 ")])
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.selectedItem,
                callback: function($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem"
              }
            }),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "mt-3",
                attrs: { "no-gutters": "", justify: "space-between" }
              },
              [_c("v-spacer")],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "600", persistent: "" },
            model: {
              value: _vm.isDisplayDeleteConfirm,
              callback: function($$v) {
                _vm.isDisplayDeleteConfirm = $$v
              },
              expression: "isDisplayDeleteConfirm"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "background-color-green" }, [
                  _vm._v(" Zenescan設定削除 ")
                ]),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c("v-row", {
                  staticStyle: { height: "5px" },
                  attrs: { "no-gutters": "" }
                }),
                _vm._v(" "),
                _c("v-card-text", { staticClass: "pa-4" }, [
                  _c("p", [_vm._v("このZenescan設定を削除しますか？")])
                ]),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { justify: "end" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-secondary",
                            attrs: { outlined: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteComfirmZenescanFaceDb(false)
                              }
                            }
                          },
                          [_vm._v("\n              キャンセル\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-secondary",
                            attrs: { outlined: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteComfirmZenescanFaceDb(true)
                              }
                            }
                          },
                          [_vm._v("OK")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "600", persistent: "" },
            model: {
              value: _vm.isDisplaySyncConfirm,
              callback: function($$v) {
                _vm.isDisplaySyncConfirm = $$v
              },
              expression: "isDisplaySyncConfirm"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "background-color-green" }, [
                  _vm._v(" Zenescan設定再登録 ")
                ]),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c("v-row", {
                  staticStyle: { height: "5px" },
                  attrs: { "no-gutters": "" }
                }),
                _vm._v(" "),
                _c("v-card-text", { staticClass: "pa-4" }, [
                  _c("p", [_vm._v("このZenescan設定を再登録しますか？")])
                ]),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { justify: "end" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-secondary",
                            attrs: { outlined: "" },
                            on: {
                              click: function($event) {
                                return _vm.syncComfirmZenescanFaceDb(false)
                              }
                            }
                          },
                          [_vm._v("\n              キャンセル\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-secondary",
                            attrs: { outlined: "" },
                            on: {
                              click: function($event) {
                                return _vm.syncComfirmZenescanFaceDb(true)
                              }
                            }
                          },
                          [_vm._v("OK")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _vm._v(" "),
      _c("zenescan-login", {
        attrs: { isVisible: _vm.isDisplayLoginForm },
        on: {
          close: function($event) {
            _vm.isDisplayLoginForm = false
          },
          "click-save-zenescan-login": function($event) {
            return _vm.saveLoginInfo($event)
          }
        },
        model: {
          value: _vm.loginInfo,
          callback: function($$v) {
            _vm.loginInfo = $$v
          },
          expression: "loginInfo"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }