


















import {Vue } from 'vue-property-decorator';
import ControlPanelAddTemplate from '@/components/templates/ControlPanelAddTemplate/ControlPanelAddTemplate.vue';
import * as Config from '@/config';
import { ControlPanelDetail } from '@/types/alligate';
export interface DataType {
  dataControlPanels: ControlPanelDetail;
  keyword: string;
  controllerModel: any;
  scorp: any;
  loading: boolean;
  isEdit: boolean;
  typeItems: any;
  successMessageLogs: string;
  errorMessageLogs: string;
  goBack: string;
  controllerModelId: string;
  dataAllGates: any;
  controllerId: any;
  gateList: any;
  gateCount: number;
  isCountProcess: number;
  isCheck: boolean;
}
export default Vue.extend({
  name: 'ControllerListPage',
  components: {
    ControlPanelAddTemplate,
  },
  data: (): DataType => ({
    scorp: [],
    keyword: '',
    dataControlPanels: {
      controllerId: '',
      name: '',
      phoneId: '',
      model: '',
      antipassbackSetting: 0,
      antipassbackClearTime: '',
      userId: '',
      password: '',
      newPassword: '',
      gateCount: 0,
    },
    loading: false,
    isEdit: false,
    typeItems: [],
    controllerModel: [],
    successMessageLogs: '',
    errorMessageLogs: '',
    goBack: 'control-panel',
    dataAllGates: [],
    controllerId: '',
    controllerModelId: '',
    gateList: [],
    gateCount: 0,
    isCountProcess: 0,
    isCheck: false,
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    isCountProcess(value) {
      if (value > 0 && this.errorMessageLogs === '' && this.isCheck === true) {
        console.log('errorMessageLogs', this.errorMessageLogs);
        this.loading = true;
        this.clearItem(this.controllerId);
      }
    },
  },
  created() {
    if (typeof localStorage.controllerSortKey === 'undefined') {
      localStorage.setItem('controllerSortKey', 'registered');
    }
    if (typeof localStorage.controllerSortName === 'undefined') {
      localStorage.setItem('controllerSortName', 'desc');
    }
    this.getControllerModelAll(1);
  },
  mounted() {
    const dataQuery = this.$route.query;
    if (dataQuery.controllerId !== undefined) {
      this.isEdit = true;
      this.controllerId = dataQuery.controllerId;
      this.getControllerData(dataQuery.controllerId);
    } else {
      this.isEdit = false;
    }
    if (dataQuery.goBack !== undefined) {
      this.goBack = dataQuery.goBack.toString();
    }
  },
  methods: {
    getGateCount(model: any) {
      return new Promise((resolve, reject) => {
        this.typeItems = [];
        this.typeItems.push(
          {
            text : '',
            value: '0',
          },
        );
        this.$http.get(Config.CONTROLLER_MODEL_API + '?limit=100&page=1&keyword=' + model +
        '&scorp=controllerModelName')
          .then((response: any) => {
            const gateCount = response.body.controllerModels[0].gateCount;
            for (let i = 1 ; i <= gateCount - this.gateCount; i++) {
              this.typeItems.push(
                {
                  text : i,
                  value: i,
                },
              );
            }
            resolve(true);
          }).then( null, (err: any) => {
            console.log(err);
            resolve(false);
          });
      });
    },
    postGateCount(ID: any, data: any) {
      const dataInfo = {
        gateCount: data.gateCount,
      };
      this.$http.post(Config.CONTROLLER_API + '/' + encodeURIComponent(ID) + '/gate', dataInfo)
        .then((response: any) => {
          if (response.body.message === 'Success') {
            if (data.newPassword !== undefined && data.newPassword !== null && data.newPassword !== '') {
              this.changePassword(data);
            } else {
              this.loading = false;
              this.isCountProcess++;
            }
          } else {
            this.successMessageLogs = '';
            this.errorMessageLogs = Config.ERROR_DEFAULT;
            this.loading = false;
            this.isCountProcess++;
          }
        })
        .then(null, (error) => {
          this.successMessageLogs = '';
          this.loading = false;
          switch (error.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (error.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.isCountProcess++;
        });
    },
    changeModel(data: any) {
      this.typeItems = [];
      this.$http.get(Config.CONTROLLER_MODEL_API + '?limit=100&page=1&keyword=' + data.value + '&scorp=controllerModelId')
        .then((response: any) => {
          const gateCount = response.body.controllerModels[0].gateCount;
          for (let i = 1 ; i <= gateCount; i++) {
            this.typeItems.push(
              {
                text : i,
                value: i,
              },
            );
          }
          this.dataControlPanels.gateCount = 1;
        }).then( null, (err: any) => {
          console.log(err);
        });
    },
    clickBackControlPanel() {
      if (this.goBack !== 'control-panel') {
        this.$router.push({ path: '/' + this.goBack});
      } else {
        this.$router.go(-1);
      }
    },
    async clickSaveControlPanel(eventArgs: any) {
      console.log('eventArgs', eventArgs);
      this.loading = true;
      const controlData = eventArgs.data;
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      const resultID  = await this.postController(eventArgs);
      this.loading = false;
    },
    clickUpdateControlPanel(eventArgs: any) {
      this.isCheck = eventArgs.check;
      console.log('eventArgs', eventArgs);
      this.updateController(eventArgs.data, eventArgs.antipassbackSetting,
      eventArgs.antipassbackClearTime, eventArgs.isAntipass);
    },
    clearItem(controllerId: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.CONTROLLER_API + '/' + encodeURIComponent(controllerId) +
        '/antipassback-clear', {type : 0})
          .then((response: any) => {
            if (response.body.message === 'Success') {
              resolve(true);
            } else {
              resolve(false);
            }
            this.loading = false;
          })
          .then( null, (error: any) => {
            this.loading = false;
            switch (error.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
          }); // catch
      });
    },
    async getGate(controllerId: any, model: any) {
      await this.getGateCountNumber(controllerId);
      await this.getGateCount(model);
    },
    async getControllerData(controllerId: any) {
      this.loading = true;
      // const apiLink = Config.RELATION_CONTROLLER_GATE + '?controllerId=' +
      // encodeURIComponent(controllerId) + '&embed=controller';
      const apiLink = Config.CONTROLLER_MANAGEMENT_USER_API + '?controllerId=' + encodeURIComponent(controllerId) + '&embed=user,controller';
      this.$http.get(apiLink)
        .then((response: any) => {
          this.resetData();
          if (!this.isEmpty(response.body.controllerManagementUsers) &&
          response.body.controllerManagementUsers.length > 0) {
            this.dataControlPanels = response.body.controllerManagementUsers[0].controller;
            this.dataControlPanels.userId = response.body.controllerManagementUsers[0].user.userId;
            this.getListGateByControllerId(1, controllerId);
            this.getGate(controllerId, response.body.controllerManagementUsers[0].controller.model);
          } else {
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          this.resetData();
          this.loading = false;
          console.log('err: ', err);
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    getControllerModelAll(page: any) {
      this.$http.get(Config.CONTROLLER_MODEL_API + '?limit=1000&page=' + page)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.controllerModels.forEach((element: any) => {
              this.controllerModel.push({text: element.controllerModelName, value: element.controllerModelId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getControllerModelAll(page + 1);
            }
          } else {
            this.controllerModel = null;
          }
        })
        .then( null, (err: any) => {
          console.log(err);
        }); // catch
    },
    getListGateByControllerId(page: any, controllerId: string) {
      const apiLinkGetGate = Config.RELATION_CONTROLLER_GATE + '?controllerId=' + encodeURIComponent(controllerId) +
      '&limit=1000&page=' + page +
      '&sortKey=desc:gateId&embed=gate';
      this.$http.get(apiLinkGetGate)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.relationsControllerGate.forEach((element: any) => {
              this.gateList.push({text: element.gate.gateName, value: element.gateId,
              controllerGateNo: element.controllerGateNo});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getListGateByControllerId(page + 1, controllerId);
            } else {
              this.gateList.sort((a: any, b: any) => {
                return a.controllerGateNo - b.controllerGateNo;
              });
              this.loading = false;
            }
          } else {
            this.gateList = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          console.log('err: ', err);
        }); // catch
    },
    getGateCountNumber(controllerId: string) {
      return new Promise((resolve, reject) => {
        this.dataControlPanels.gateCount = 0;
        this.gateCount = 0;
        const apiLinkGetGate = Config.RELATION_CONTROLLER_GATE + '?controllerId=' + encodeURIComponent(controllerId) +
        '&limit=25&page=1' +
        '&sortKey=desc:gateId&embed=gate';
        this.$http.get(apiLinkGetGate)
          .then((response: any) => {
            if (response.body.maxRecords > 0) {
              this.gateCount = response.body.maxRecords;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            this.loading = false;
            console.log('err: ', err);
            resolve(false);
          }); // catch
      });
    },
    getControllerModel(data: ControlPanelDetail) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.CONTROLLER_MODEL_API + '?limit=100&page=1&keyword=' + data.model + '&scorp=controllerModelName')
        .then((response: any) => {
          this.controllerModelId = response.body.controllerModels[0].controllerModelId;
          resolve(true);
        }).then( null, (err: any) => {
          console.log(err);
          resolve(false);
        });
      });
    },
    postController(eventArgs: any) {
      let data: ControlPanelDetail ;
      data = eventArgs.data;
      return new Promise((resolve, reject) => {
        const dataInfor = {
          // model: data.model,
          name: data.name,
          gateCount: data.gateCount,
          antipassbackSetting: 0,
          antipassbackClearTime: '99:99',
          userId: data.userId,
          password: data.password,
          controllerModelId: data.model.value,
        };
        if (eventArgs.antipassbackSetting === true) {
          dataInfor.antipassbackSetting = 2;
        }
        if (eventArgs.antipassbackClearTime !== undefined && eventArgs.antipassbackClearTime !== null &&
        eventArgs.antipassbackClearTime !== '' && eventArgs.isAntipass === true) {
          dataInfor.antipassbackClearTime = eventArgs.antipassbackClearTime;
        }
        this.$http.post(Config.CONTROLLER_API, dataInfor)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.dataControlPanels = {
                controllerId: '',
                name: '',
                phoneId: '',
                model: '',
                antipassbackSetting: 0,
                antipassbackClearTime: '',
                userId: '',
                newPassword: '',
                password: '',
                gateCount: 0,
              };
              this.successMessageLogs = Config.ADD_CONTROLER_API_SUCCESS;
              this.controllerId = response.body.controllerId;
              resolve(true);
            } else {
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                this.errorMessageLogs = Config.ERROR_403;
                break;
              case 409:
                this.errorMessageLogs = Config.ERROR_409_CONTROLLER;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
          }); // catch
      });
    },
    changePassword(data: any) {
      const credentials = {
        userId: data.userId,
        newPassword: data.newPassword,
        isSendEmail: false,
      };
      this.$http.post(Config.USER_CHANGE_PASSWORD_API, JSON.stringify(credentials))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            console.log(response);
          } else {
            this.successMessageLogs = '';
            this.errorMessageLogs = Config.ERROR_DEFAULT;
          }
          this.loading = false;
          this.isCountProcess++;
        })
        .then(null, (error) => {
          this.loading = false;
          this.successMessageLogs = '';
          switch (error.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (error.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.isCountProcess++;
        });
    },
    updateController(data: ControlPanelDetail, antipassbackSetting: any, antipassbackClearTime: any, isAntipass: any) {
      this.isCountProcess = 0;
      console.log('data', data);
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      this.loading = true;
      const dataInfor = {
        name: data.name,
        antipassbackSetting: 0,
        antipassbackClearTime: '99:99',
      };
      if (antipassbackSetting === true) {
        dataInfor.antipassbackSetting = 2;
      }
      if (antipassbackClearTime !== undefined && antipassbackClearTime !== null
      && antipassbackClearTime !== '' && isAntipass === true) {
        dataInfor.antipassbackClearTime = antipassbackClearTime;
      }
      this.$http.put(Config.CONTROLLER_API + '/' + encodeURIComponent(this.controllerId), dataInfor)
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.successMessageLogs = Config.EDIT_CONTROLER_API_SUCCESS;
            this.dataControlPanels.antipassbackClearTime = dataInfor.antipassbackClearTime;
            if (data.gateCount.toString() !== '0') {
              this.postGateCount(this.controllerId, data);
            } else {
              if (data.newPassword !== undefined && data.newPassword !== null && data.newPassword !== '') {
                this.changePassword(data);
              } else {
                this.loading = false;
                this.isCountProcess++;
              }
            }
          } else {
            this.loading = false;
            this.errorMessageLogs = Config.ERROR_DEFAULT;
            this.isCountProcess++;
          }
        })
        .then( null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              this.errorMessageLogs = Config.ERROR_403;
              break;
            case 404:
              this.errorMessageLogs = '対象の制御盤、または、デバイスが存在しない。';
              break;
            case 409:
              this.errorMessageLogs = '指定したphoneIdのデバイスが既に他の制御盤の管理に使われている。';
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
          this.isCountProcess++;
        }); // catch
    },

    postAtto(data: ControlPanelDetail) {
      return new Promise((resolve, reject) => {
        const dataInfor = {
          // productId: data.productId,
          appId: '',
          controllerId: data.controllerId,
          password: data.password,
        };
        this.$http.post(Config.CONTROLLER_MANAGEMENT_DEVICE, dataInfor)
          .then((response: any) => {
            console.log(response);
            resolve(null);
          })
          .then( null, (err: any) => {
            console.log(err);
            resolve(null);
          }); // catch
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataControlPanels = {
        controllerId: '',
        name: '',
        phoneId: '',
        model: '',
        antipassbackSetting: 0,
        antipassbackClearTime: '',
        userId: '',
        newPassword: '',
        password: '',
        gateCount: 0,
      };
      this.gateList = [];
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
    },
  },
});
