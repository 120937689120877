



































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import GroupDetailForm from '@/components/organisms/GroupDetailForm/GroupDetailForm.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
export default Vue.extend({
  name: 'GroupDetailTemplate',
  components: {
    CancelButton,
    SuccessButton,
    GroupDetailForm,
    Breadcrumbs,
  },
  props: {
    gateList: {
      default: [],
    },
    userList: {
      default: [],
    },
    groupDetail: {
      default: {},
    },
    errorMessageLogs: {
      default: '',
    },
    loading: {
      default: false,
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'グループ', disabled: true },
          { text: 'グループ詳細', disabled: true },
        ];
      },
    },
  },
});
