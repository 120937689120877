


























































































































































































































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import {Group} from '@/types/alligate';
export interface TypeItem {
  title: string;
}
interface Type {
  pCodeId: string;
  type: number;
  userId: string;
  isValid: boolean;
  detail: string;
}
export interface DataType {
  userIds: any;
  searchInput: string;
}
export default Vue.extend({
  name: 'ControlPanelDetail',
  components: {
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    showModalClear: {
      type: Boolean,
      default: false,
    },
    controlPanelDetail: {
      default: {},
    },
    gateList: {
      default: () => [],
    },
    dataAllUses: {
      default: () => [],
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageModal: {
      type: String,
      default: '',
    },
    successMessageModal: {
      type: String,
      default: '',
    },
    errorMessageProcess: {
      default: [],
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    countUsers: {
      type: Number,
      default: 0,
    },
  },
  data: (): DataType => ({
    userIds: [],
    searchInput: '',
  }),
  computed: {
  },
  methods: {
    convertStatus(data: any) {
      let status = '登録済み';
      if (data.phoneId === '') {
        status = '未登録';
      }
      return status;
    },
    clickEditController(value: any) {
      this.$router.push({ path: Config.CONTROL_PANEL_EDIT, query: {controllerId: value}});
    },
    clickAddUser(value: any) {
      this.$router.push({ path: Config.GATE_ADD_USER, query: {gateId: value}});
    },
    updateUserTags() {
      this.searchInput = '';
    },
    isObject(val: any) {
      return val instanceof Object;
    },
  },
  watch: {
  },
});
