





















































import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
export interface DataType {
  showAntiPass: boolean;
}
export default Vue.extend({
  name: 'ControlSettingForm',
  components: {
    InputForm,
  },
    data: (): DataType => ({
    showAntiPass: false,
  }),
});
