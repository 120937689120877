var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UserAddTemplate", {
    attrs: {
      isEdit: _vm.isEdit,
      isDisabledGateOption: _vm.isDisabledGateOption,
      gateList: _vm.gateList,
      loading: _vm.loading,
      isDisabledGroupOptions: _vm.isDisabledGroupOptions,
      isDisabledPCodeOptions: _vm.isDisabledPCodeOptions,
      gateGroupOptions: _vm.gateGroupOptions,
      pCodeOptions: _vm.pCodeOptions,
      groupsAddFail: _vm.groupsAddFail,
      pCodeAddFail: _vm.pCodeAddFail,
      gateAddFail: _vm.gateAddFail,
      userData: _vm.userData,
      goBack: _vm.goBack,
      errorMessageLogs: _vm.errorMessageLogs,
      successMessageLogs: _vm.successMessageLogs,
      listGatePassages: _vm.listGatePassages,
      phoneItems: _vm.phoneItems
    },
    on: {
      clickSaveUser: _vm.clickSaveUser,
      clickUpdateUser: _vm.clickUpdateUser,
      backUserList: _vm.backUserList,
      "init-data": function($event) {
        return _vm.initData()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }