






























import {Vue } from 'vue-property-decorator';
import UserGateAddGroupTemplate from '@/components/templates/UserGateAddGroupTemplate/UserGateAddGroupTemplate.vue';
import { Gate } from '@/types/alligate';
import * as Config from '@/config';
import moment from 'moment';
export interface DataType {
  dataGates: any;
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  gateTotalCount: number;

  loading: boolean;
  showConfirm: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;

  isFinishGetGate: boolean;
  rowinErrDialog: number;
  errorMessageProcess: any;
  successItemProcess: number;
  totalDataRowProcess: number;
  showProgress: boolean;
  isFinishProcess: boolean;
  progress: number;
  goBack: string;
  gateOfGroup: any;
}
export default Vue.extend({
  name: 'GateAddGatePage',
  components: {
    UserGateAddGroupTemplate,
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['gateId'],
    keyword: '',
    gateTotalCount: 0,
    dataGates: [],
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    isFinishGetGate: false,
    rowinErrDialog: 0,
    errorMessageProcess: [],
    successItemProcess: 0,
    totalDataRowProcess: 0,
    showProgress: false,
    isFinishProcess: false,
    showConfirm: false,
    progress: 0,
    goBack: 'user',
    gateOfGroup: [],
  }),
  created() {
    if (typeof localStorage.pageSizeGate === 'undefined') {
      localStorage.setItem('pageSizeGate', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeGate);
    }
    const dataQuery = this.$route.query;
    this.loading = true;
    this.gateOfGroup = [];
    this.getList().then(() => {
      this.getGateOfGroup(1, dataQuery.groupId);
    });
  },
  mounted() {
    const dataQuery = this.$route.query;
    if (dataQuery.goBack !== undefined && dataQuery.goBack !== '') {
      this.goBack = dataQuery.goBack.toString();
    }
  },
  watch: {
    isFinishGetGate(value) {
      if (this.isFinishGetGate && value) {
        this.loading = false;
      }
    },
  },
  methods: {
    closeDialog() {
      this.showConfirm = false;
    },
    closeModalProgress() {
      this.showProgress = false;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.showConfirm = false;
      this.progress = 0;
      this.loading = true;
      const dataQuery = this.$route.query;
      this.getGateOfGroup(1, dataQuery.groupId);
    },
    clickConfirm() {
      this.showConfirm = true;
      this.errorMessageProcess = [];
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = false;
      this.totalDataRowProcess = 0;
    },
    async clickAddGate(data: any) {
      this.showConfirm = false;
      this.errorMessageProcess = [];
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = true;
      this.totalDataRowProcess = data.length;
      const percent = (100 / this.totalDataRowProcess);
      const dataQuery = this.$route.query;
      if (dataQuery.groupId !== undefined && dataQuery.groupId !== '' && dataQuery.groupId !== null) {
        for (const item of data) {
          let result;
          const notBeforeTime = this.formatTimestamp(item.notBefore + ' ' + item.startTime);
          const notAfterTime = this.formatTimestamp(item.notAfter + ' ' + item.endTime);
          const array = {
            gateId: item.gateId,
            groupId: dataQuery.groupId,
          };
          result  = await this.postGate(array, item.gateId);
          if (result) {
            this.successItemProcess++;
          }
          this.progress += percent;
        }
        this.isFinishProcess = true;
      }
    },
    postGate(data: any, gateId: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.RELATION_GATE_GROUP, data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then( null, (error: any) => {
            let errorName = '';
            switch (error.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 409:
                errorName = '選択されたゲートは既に使用されています。他のゲートを選択してください。';
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = gateId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeGate', value);
      this.loading = true;
      this.getList().then(() => {
        this.loading = false;
      });
    },
    changePage(value: any): any {
      this.currPage = value;
      this.loading = true;
      this.getList().then(() => {
        this.loading = false;
      });
    },
    searchGate(value: any): any {
      this.keyword = value.text;
      this.scorp = value.targets;
      this.getList();
    },
    getGateOfGroup(page: any , groupId: any) {
      this.$http.get(Config.RELATION_GATE_GROUP + '?groupId=' +
      encodeURIComponent(groupId) + '&limit=1000&page=' + page + '&embed=gate&isExceptOptionalGate=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.relationsGateGroup.forEach((element: any) => {
              this.gateOfGroup.push({text: element.gate.name, value: element.gateId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGateOfGroup(page + 1, groupId);
            } else {
              this.loading = false;
            }
          } else {
            this.gateOfGroup = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
          }); // catch
    },
    getList(): any {
      return new Promise((resolve, reject) => {
        const apiLink = Config.GATES_LIST_API + '?limit=' + localStorage.pageSizeGate +
        '&page=' + this.currPage + '&isValid=true&isExceptOptionalGate=true';
        this.$http.get(apiLink)
          .then((response: any) => {
            this.resetData();
            if (!this.isEmpty(response.body.gates)) {
              this.dataGates = response.body.gates;
              this.gateTotalCount = response.body.maxRecords;
              this.currPage = Math.ceil(response.body.startRecord / this.perPage);
            } else {
              this.gateTotalCount = 0;
              this.currPage = 1;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(true);
            switch (err.status) {
                case 400:
                  this.errorMessageLogs = Config.ERROR_400;
                  break;
                case 403:
                  if (err.message === 'Unauthorized') {
                    this.errorMessageLogs = Config.ERROR_403;
                  } else {
                    this.errorMessageLogs = Config.ERROR_403_DENIED;
                  }
                  break;
                case 404:
                  this.errorMessageLogs = Config.ERROR_404;
                  break;
                case 500:
                  this.errorMessageLogs = Config.ERROR_500;
                  break;
                case 503:
                  this.errorMessageLogs = Config.ERROR_503;
                  break;
                default:
                  this.errorMessageLogs = Config.ERROR_DEFAULT;
                  break;
              }
          }); // catch
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataGates = [];
      this.errorMessageLogs = '';
      // this.gateTotalCount = 0;
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
  },
});
