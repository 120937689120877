



















































import { Gate, RelationRoomGate, Room } from '@/types/alligate';
import Vue, { PropType } from 'vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import validator from '@/utils/validator';

export interface DataType {
  showSuccessMessages: boolean[];
  noValidationError: boolean;
  nameRules: Array<(val: string | undefined | null) => string | boolean>;
  relationsRoomGateRules: Array<(val: string[] | undefined | null) => string | boolean>;
}

export default Vue.extend({
  name: 'RoomForm',
  components: {
    Loading,
    Alert,
    InputForm,
    CancelButton,
    SuccessButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    successMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    value: {
      type: Object as PropType<Room>,
      default: () => ({
        roomId: '',
        name: '',
        relationsRoomGate: [],
      }),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    allGates: {
      type: Array as PropType<Gate[]>,
      default: () => [],
    },
  },
  computed: {
    innerValue: {
      get(): Room {
        return this.value;
      },
      set(val: Room): void {
        this.$emit('input', val);
        return;
      },
    },
    innerRoomName: {
      get(): string {
        return this.value.name;
      },
      set(val: string): void {
        this.$emit('input', {...this.value, name: val});
      },
    },
    innerSelectedGateIds: {
      get(): string[] {
        if (this.value.relationsRoomGate) {
          const relations: RelationRoomGate[] = this.value.relationsRoomGate;
          return relations.map((r: RelationRoomGate) => r.gateId);
        } else {
          return [];
        }
      },
      set(val: string[]): void {
        const relationsRoomGate: RelationRoomGate[] = val.map((gateId) => ({
          relationId: '',
          roomId: this.value.roomId,
          gateId,
          entryExitType: 0, // 現在は0固定
        }));
        this.$emit('input', {...this.value, relationsRoomGate});
      },
    },
    gateItems: {
      get(): SelectItem[] {
        return this.allGates.map((gate) => ({
          text: gate.gateName,
          value: gate.gateId,
        }));
      },
    },
  },
  watch: {
    successMessages(messages: string[]) {
      const shows: boolean[] = [];
      messages.forEach((message) => {
        shows.push(message !== '');
      });
      this.showSuccessMessages = shows;
    },
  },
  data: (): DataType => ({
    showSuccessMessages: [],
    noValidationError: true,
    // 入力ルール
    nameRules: validator.roomNameRules,
    relationsRoomGateRules: validator.roomRelationsRoomGateRules,
  }),
  methods: {
    clickSave() {
      this.validateField();
      if (this.noValidationError) {
        if (this.isEdit) {
          this.$emit('update-room', this.value);
        } else {
          this.$emit('create-room', this.value);
        }
      }
    },
    /**
     * フィールドのバリデーションを実施する
     */
    validateField() {
      (this.$refs.form as any).validate();
    },
  },
});
