import { render, staticRenderFns } from "./HelpIcon.vue?vue&type=template&id=3471bb2d&"
import script from "./HelpIcon.vue?vue&type=script&lang=ts&"
export * from "./HelpIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/uchida/alliboard-pro/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3471bb2d')) {
      api.createRecord('3471bb2d', component.options)
    } else {
      api.reload('3471bb2d', component.options)
    }
    module.hot.accept("./HelpIcon.vue?vue&type=template&id=3471bb2d&", function () {
      api.rerender('3471bb2d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/HelpIcon/HelpIcon.vue"
export default component.exports