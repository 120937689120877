var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-user-change-password-table" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c("user-change-password-form", {
            attrs: {
              userInfo: _vm.userInfo,
              loading: _vm.loading,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs
            },
            on: {
              clickChangePassword: function($event) {
                return _vm.$emit("clickChangePassword")
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }