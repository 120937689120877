var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showModalProcess,
            callback: function($$v) {
              _vm.showModalProcess = $$v
            },
            expression: "showModalProcess"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "modal-export" },
            [
              _vm.exportType === 0
                ? _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [_vm._v(" 一斉登録（ユーザーCSV）")]
                  )
                : _vm.exportType === 1
                ? _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [_vm._v(" 期限付き解錠可能ゲート登録")]
                  )
                : _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [_vm._v(" カードも併せて一斉登録")]
                  ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row-message" },
                [
                  _vm.errorMessageLogs !== ""
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.errorMessageLogs)
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.errorMessageLogs === ""
                ? _c(
                    "div",
                    { staticClass: "pa-5" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progress,
                            callback: function($$v) {
                              _vm.progress = $$v
                            },
                            expression: "progress"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.successItemCSV > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowCSV) +
                                "件中" +
                                _vm._s(_vm.successItemCSV) +
                                "件をインポートしました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessage.length > 0
                        ? _c("div", [
                            _c("span", { staticClass: "error-box-title" }, [
                              _vm._v("インポート失敗")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(_vm.errorMessage, function(item) {
                                  return [
                                    item !== ""
                                      ? _c("p", {
                                          key: item,
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.success
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary mr-0",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("closeModalProcessCSV")
                                }
                              }
                            },
                            [_vm._v("\r\n              OK\r\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card-actions",
                    [
                      _vm.isExport === false
                        ? _c(
                            "v-row",
                            { staticClass: "ma-2", attrs: { justify: "end" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-primary mr-0",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("CancelProcessCSV")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\r\n              キャンセル\r\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProcessImportExcel,
            callback: function($$v) {
              _vm.showProcessImportExcel = $$v
            },
            expression: "showProcessImportExcel"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("登録データのアップロード")
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row-message" },
                [
                  _vm.errorMessageLogs !== ""
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.errorMessageLogs)
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.totalDataRow > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c("div", { staticStyle: { "padding-top": "13px" } }, [
                        _vm.sheet1Name
                          ? _c("span", [_vm._v(_vm._s(_vm.sheet1Name) + "：")])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progressSheet1,
                            callback: function($$v) {
                              _vm.progressSheet1 = $$v
                            },
                            expression: "progressSheet1"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progressSheet1)) + "%")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.successItemSheet1 > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowSheet1) +
                                "件中" +
                                _vm._s(_vm.successItemSheet1) +
                                "件をアップロードしました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "padding-top": "13px" } }, [
                        _vm.sheet2Name
                          ? _c("span", [_vm._v(_vm._s(_vm.sheet2Name) + "：")])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progressSheet2,
                            callback: function($$v) {
                              _vm.progressSheet2 = $$v
                            },
                            expression: "progressSheet2"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progressSheet2)) + "%")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.successItemSheet2 > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowSheet2) +
                                "件中" +
                                _vm._s(_vm.successItemSheet2) +
                                "件をアップロードしました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageExcel.length > 0
                        ? _c("div", [
                            _c("span", { staticClass: "error-box-title" }, [
                              _vm._v("アップロード失敗")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(_vm.errorMessageExcel, function(item) {
                                  return [
                                    _c("p", {
                                      key: item,
                                      domProps: { innerHTML: _vm._s(item) }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFinishImportExcel
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "closeModalProcessImportExcel"
                                  )
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "1000", persistent: "" },
          model: {
            value: _vm.showModalImport,
            callback: function($$v) {
              _vm.showModalImport = $$v
            },
            expression: "showModalImport"
          }
        },
        [
          _vm.exportType === 0
            ? _c(
                "v-card",
                { staticClass: "modal-export" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [_vm._v("一斉登録（ユーザーCSV）")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "pa-5" }, [
                    _c(
                      "div",
                      { staticClass: "bock-info" },
                      [
                        _c("div", { staticClass: "alert alert-info col" }, [
                          _vm._v(
                            "\r\n            ユーザーの登録を一括で登録するテンプレートファイルをダウンロードできます。"
                          ),
                          _c("br"),
                          _vm._v(
                            "\r\n            ＜本ファイルで設定可能なデーター＞"
                          ),
                          _c("br"),
                          _vm._v("\r\n            ユーザーの登録"),
                          _c("br"),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\r\n            ＜SMS認証サービス加入者のみ設定可能なデーター＞"
                          ),
                          _c("br"),
                          _vm._v("\r\n            SMS認証の設定"),
                          _c("br"),
                          _vm._v("\r\n            携帯電話番号の登録"),
                          _c("br")
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          {
                            staticClass: "mb-4 mt-3",
                            attrs: { justify: "center" }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-secondary mt-5",
                                attrs: { outlined: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("clickExportCSV")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\r\n                テンプレートのダウンロード\r\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("v-checkbox", {
                              staticClass: "mt-5 isCheckData",
                              attrs: {
                                color: "grey",
                                dense: "",
                                "hide-details": "",
                                label: "ユーザー情報を入れてダウンロード"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$emit(
                                    "clickCheckBoxExportUserInfoCSV"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pa-5 pt-0" }, [
                    _c(
                      "div",
                      { staticClass: "bock-info" },
                      [
                        _c("div", { staticClass: "alert alert-info col" }, [
                          _vm._v(
                            "\r\n            ユーザーの登録をCSVファイルを使用して、一括で実施することができます。"
                          ),
                          _c("br"),
                          _vm._v(
                            "\r\n            登録するために必要なテンプレートCSVファイルは、”テンプレートのダウンロード”から取得して下さい。\r\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "mt-5" },
                          [
                            _c("v-col", { attrs: { cols: "3" } }, [
                              _vm._v("CSVファイルを選択")
                            ]),
                            _vm._v(" "),
                            _c("v-col", [
                              _c("div", { staticClass: "info-item " }, [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input-add add-file-name",
                                      attrs: {
                                        "hide-details": "auto",
                                        outlined: "",
                                        dense: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openFile()
                                        }
                                      },
                                      model: {
                                        value: _vm.fileName,
                                        callback: function($$v) {
                                          _vm.fileName = $$v
                                        },
                                        expression: "fileName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _c(
                                "label",
                                {
                                  staticClass: "btn-upload-second",
                                  attrs: { for: "file_csv" }
                                },
                                [
                                  _vm._v(
                                    "\r\n                ...\r\n                "
                                  ),
                                  _c("input", {
                                    ref: "files",
                                    staticClass: "custom-file-input",
                                    staticStyle: { display: "none" },
                                    attrs: {
                                      type: "file",
                                      id: "file_csv",
                                      multiple: "",
                                      accept: ".csv"
                                    },
                                    on: { change: _vm.previewFiles }
                                  })
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeDialogImport()
                                }
                              }
                            },
                            [_vm._v("\r\n          キャンセル\r\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary mr-0",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("clickConfirmEmail", {
                                    type: 0
                                  })
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\r\n              ファイルをインポート\r\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.exportType === 1
            ? _c(
                "v-card",
                { staticClass: "modal-export" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [_vm._v("期限付き解錠可能ゲート登録")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "pa-5" }, [
                    _c(
                      "div",
                      { staticClass: "bock-info" },
                      [
                        _c("div", { staticClass: "alert alert-info col" }, [
                          _vm._v(
                            "\r\n            期限付き解錠可能ゲートを登録するテンプレートファイルをダウンロードできます。"
                          ),
                          _c("br"),
                          _vm._v(
                            "\r\n            ＜本ファイルで設定可能なデーター＞"
                          ),
                          _c("br"),
                          _vm._v(
                            "\r\n            ユーザーに期限付き解錠可能ゲートを紐づける"
                          ),
                          _c("br")
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          {
                            staticClass: "mb-4 mt-3",
                            attrs: { justify: "center" }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-secondary mt-5",
                                attrs: { outlined: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "clickExportCSVTimeUnlockGate"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\r\n                テンプレートのダウンロード\r\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pa-5 pt-0" }, [
                    _c(
                      "div",
                      { staticClass: "bock-info" },
                      [
                        _c("div", { staticClass: "alert alert-info col" }, [
                          _vm._v(
                            "\r\n            期限付き解錠可能ゲートの登録をCSVファイルを使用して、一括で実施することができます。"
                          ),
                          _c("br"),
                          _vm._v(
                            "\r\n            登録するために必要なテンプレートCSVファイルは、”テンプレートのダウンロード”から取得して下さい。 "
                          ),
                          _c("br"),
                          _vm._v(
                            "\r\n            ※開始時間、終了時間は半角数字のみで入力して下さい。例：2023/01/01 01:00 ⇒ 202301010100\r\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "mt-5" },
                          [
                            _c("v-col", { attrs: { cols: "3" } }, [
                              _vm._v("CSVファイルを選択")
                            ]),
                            _vm._v(" "),
                            _c("v-col", [
                              _c("div", { staticClass: "info-item " }, [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input-add add-file-name",
                                      attrs: {
                                        "hide-details": "auto",
                                        outlined: "",
                                        dense: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openFile()
                                        }
                                      },
                                      model: {
                                        value: _vm.fileName,
                                        callback: function($$v) {
                                          _vm.fileName = $$v
                                        },
                                        expression: "fileName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _c(
                                "label",
                                {
                                  staticClass: "btn-upload-second",
                                  attrs: { for: "file_csv" }
                                },
                                [
                                  _vm._v(
                                    "\r\n                ...\r\n                "
                                  ),
                                  _c("input", {
                                    ref: "files",
                                    staticClass: "custom-file-input",
                                    staticStyle: { display: "none" },
                                    attrs: {
                                      type: "file",
                                      id: "file_csv",
                                      multiple: "",
                                      accept: ".csv"
                                    },
                                    on: { change: _vm.previewFiles }
                                  })
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeDialogImport()
                                }
                              }
                            },
                            [_vm._v("\r\n          キャンセル\r\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary mr-0",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.clickImportCSVTimeUnlockGate()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\r\n              ファイルをインポート\r\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-card",
                { staticClass: "modal-export" },
                [
                  _vm.isType === "all-user"
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "modal-title",
                              attrs: { color: "green" }
                            },
                            [_vm._v("ユーザー一括登録")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "pa-5" }, [
                            _c(
                              "div",
                              { staticClass: "bock-info" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "alert alert-info col" },
                                  [
                                    _vm._v(
                                      "\r\n            ユーザーの登録と個人コードとゲートグループの紐づけを一括で登録するテンプレートファイルをダウンロードできます。"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            ＜本ファイルで設定可能なデーター＞"
                                    ),
                                    _c("br"),
                                    _vm._v("\r\n            ユーザーの登録"),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            ユーザーとカード（個人コード）の紐づけ"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            ユーザーとゲートグループの紐づけ"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            ＜SMS認証サービス加入者のみ設定可能なデーター＞"
                                    ),
                                    _c("br"),
                                    _vm._v("\r\n            SMS認証の設定"),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            携帯電話番号の登録"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            ダウンロード前に以下の設定を行って下さい。"
                                    ),
                                    _c("br"),
                                    _vm._v("\r\n             ・ゲートの設定"),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n             ・ゲートグループの作成"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n             ・ゲートグループへの紐付け"
                                    ),
                                    _c("br")
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mb-4 mt-3",
                                    attrs: { justify: "center" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "btn-secondary mt-5",
                                        attrs: { outlined: "", disabled: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("clickExportExcel")
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\r\n                  テンプレートのダウンロード\r\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "pa-5 pt-0" }, [
                            _c(
                              "div",
                              { staticClass: "bock-info" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "alert alert-info col" },
                                  [
                                    _vm._v(
                                      "\r\n              ユーザーの登録と個人コードとゲートグループの紐づけをExcelファイルを使用して、一括で実施することができます。"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n              登録するために必要なテンプレートExcelファイルは、”テンプレートのダウンロード”から取得して下さい。\r\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-5" },
                                  [
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _vm._v("Excelファイルを選択")
                                    ]),
                                    _vm._v(" "),
                                    _c("v-col", [
                                      _c("div", { staticClass: "info-item " }, [
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "input-add add-file-name",
                                              attrs: {
                                                "hide-details": "auto",
                                                outlined: "",
                                                dense: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openFile()
                                                }
                                              },
                                              model: {
                                                value: _vm.fileName,
                                                callback: function($$v) {
                                                  _vm.fileName = $$v
                                                },
                                                expression: "fileName"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "btn-upload-second",
                                          attrs: { for: "file_excel" }
                                        },
                                        [
                                          _vm._v(
                                            "\r\n                  ...\r\n                  "
                                          ),
                                          _c("input", {
                                            ref: "files",
                                            staticClass: "custom-file-input",
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "file",
                                              id: "file_excel",
                                              multiple: "",
                                              accept: ".xlsx,xls"
                                            },
                                            on: { change: _vm.previewFiles }
                                          })
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "modal-title titleExcelImportUser",
                              attrs: { color: "green" }
                            },
                            [_vm._v("カードも併せて一斉登録")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "pa-5" }, [
                            _c(
                              "div",
                              { staticClass: "bock-info" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "alert alert-info col" },
                                  [
                                    _vm._v(
                                      "\r\n            ユーザーの登録とカードの紐づけを一括で登録するテンプレートファイルをダウンロードできます。"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            ＜本ファイルで設定可能なデーター＞"
                                    ),
                                    _c("br"),
                                    _vm._v("\r\n            ユーザーの登録"),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            ユーザーとカード（個人コード）の紐づけ"
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            ＜SMS認証サービス加入者のみ設定可能なデーター＞"
                                    ),
                                    _c("br"),
                                    _vm._v("\r\n            SMS認証の設定"),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n            携帯電話番号の登録"
                                    ),
                                    _c("br")
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mb-4 mt-3",
                                    attrs: { justify: "center" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "btn-secondary mt-5",
                                        attrs: { outlined: "" },
                                        on: { click: _vm.clickExportExcel }
                                      },
                                      [
                                        _vm._v(
                                          "\r\n                  テンプレートのダウンロード\r\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "mt-5 isCheckData" },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            color: "grey",
                                            dense: "",
                                            "hide-details": "",
                                            label:
                                              "ユーザー情報を入れてダウンロード"
                                          },
                                          model: {
                                            value: _vm.isCheckData,
                                            callback: function($$v) {
                                              _vm.isCheckData = $$v
                                            },
                                            expression: "isCheckData"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "noteExportExcel" },
                                          [
                                            _vm._v(
                                              "\r\n                  ※個人コードは最大３件まで表示されます\r\n                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "pa-5 pt-0" }, [
                            _c(
                              "div",
                              { staticClass: "bock-info" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "alert alert-info col" },
                                  [
                                    _vm._v(
                                      "\r\n              ユーザーの登録とカードの紐づけをExcelファイルを使用して、一括で実施することができます。"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\r\n              登録するために必要なテンプレートExcelファイルは、”テンプレートのダウンロード”から取得して下さい。\r\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-5" },
                                  [
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _vm._v("Excelファイルを選択")
                                    ]),
                                    _vm._v(" "),
                                    _c("v-col", [
                                      _c("div", { staticClass: "info-item " }, [
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "input-add add-file-name",
                                              attrs: {
                                                "hide-details": "auto",
                                                outlined: "",
                                                dense: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openFile()
                                                }
                                              },
                                              model: {
                                                value: _vm.fileName,
                                                callback: function($$v) {
                                                  _vm.fileName = $$v
                                                },
                                                expression: "fileName"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "btn-upload-second",
                                          attrs: { for: "file_excel" }
                                        },
                                        [
                                          _vm._v(
                                            "\r\n                  ...\r\n                  "
                                          ),
                                          _c("input", {
                                            ref: "files",
                                            staticClass: "custom-file-input",
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "file",
                                              id: "file_excel",
                                              multiple: "",
                                              accept: ".xlsx,xls"
                                            },
                                            on: { change: _vm.previewFiles }
                                          })
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeDialogImport()
                                }
                              }
                            },
                            [_vm._v("\r\n          キャンセル\r\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary mr-0",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("clickConfirmEmail", {
                                    type: 2
                                  })
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\r\n              ファイルをインポート\r\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showModalConfirmEmail,
            callback: function($$v) {
              _vm.showModalConfirmEmail = $$v
            },
            expression: "showModalConfirmEmail"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "background-color-green",
                  staticStyle: { "font-size": "15px" }
                },
                [
                  _vm._v(
                    "作成したユーザーのメールアドレスへ通知メールを送信しますか？"
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.yesChoice()
                            }
                          }
                        },
                        [_vm._v("\r\n            送信する\r\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.noChoice()
                            }
                          }
                        },
                        [_vm._v("送信しない")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.isHasLowerCaseCardNumberExcel,
            callback: function($$v) {
              _vm.isHasLowerCaseCardNumberExcel = $$v
            },
            expression: "isHasLowerCaseCardNumberExcel"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "modal-export" },
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "green" } },
                [_vm._v("カード登録")]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "pa-5" }, [
                _c("div", { staticClass: "bock-info" }, [
                  _c("div", { staticClass: "alert alert-info col" }, [
                    _vm._v(
                      "\r\n              カード番号に半角英字の小文字が含まれております。"
                    ),
                    _c("br"),
                    _vm._v(
                      "\r\n              小文字が含まれているとカードが正常に読み込まれません。"
                    ),
                    _c("br"),
                    _vm._v(
                      "\r\n              小文字が含まれているかを確認してください。\r\n            "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close-dialog-check-card-excel")
                            }
                          }
                        },
                        [_vm._v("\r\n              見直す\r\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary btn-red",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("accept-dialog-check-card-excel")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\r\n              小文字が含まれていても登録する\r\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }