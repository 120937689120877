


















































































































import Vue, { PropType } from 'vue';
const min: string[] = [];
for (let i = 0; i <= 59 ; i++) {
  let value = '';
  if (i < 10) {
    value = '0' + i;
  } else {
    value = i.toString();
  }
  min.push(value);
}
export default Vue.extend({
  name: 'DateTimePickerFull',
  props: {
    dateV: {
      type: String,
      default: '',
    },
    minuteV: {
      type: String,
      default: '',
    },
    hourV: {
      type: String,
      default: '',
    },
    secondV: {
      type: String,
      default: '',
    },
    errorAddListGate: {
      default: '',
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isUserPage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dateS: '',
    dateE: '',
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: ['00', '15', '30', '45'],
    seconds: ['00', '15', '30', '45'],
    time: '',
    dateStart: false,
    hour: '00',
    minute: '00',
    second: '00',
    dateTime: '',
  }),
  mounted() {
    this.minutes = min;
    this.dateS =  this.dateV;
    this.hour =  this.hourV;
    this.minute =  this.minuteV;
    this.second =  this.secondV;
    this.time =  this.formatTime(this.hour, this.minute, this.second);
    this.dateTime = '';
  },
  watch: {
    dateTime(newValue: string) {
      this.time = newValue;
      this.$emit('getDateTime', newValue);
    },
    time(newValue: string) {
      this.time = newValue;
      this.$emit('getDateTime', this.dateS + ' ' + newValue);
    },
    dateS(newValue: string) {
      this.$emit('getDateTime', newValue + ' ' + this.time);
    },
    minute(newValue: string) {
      this.time = this.formatTime(this.hour, newValue, this.second);
    },
    hour(newValue: string) {
      this.time = this.formatTime(newValue, this.minute, this.second);
    },
    second(newValue: string) {
      this.time = this.formatTime(this.hour, this.minute, newValue);
    },
  },
  methods: {
    formatTime(hours: string, minute: number | string, second: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}:${('00' + second).slice(-2)}`;
    },
  },
});
