var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { class: { minimum: _vm.isMinimum, normal: !_vm.isMinimum } },
    [
      _c("v-row", { staticClass: "mb-3", attrs: { justify: "center" } }, [
        _c("span", { staticClass: "mt-3" }, [_vm._v(_vm._s(_vm.playingTime))])
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("ump-player", {
            style: {
              height: _vm.innerHeight + "px",
              width: _vm.innerWidth + "px"
            },
            attrs: {
              id: _vm.videoId,
              hostname: _vm.hostname,
              channel: _vm.channel,
              port: _vm.port,
              device: _vm.device,
              width: _vm.width,
              height: _vm.height,
              username: _vm.username,
              password: _vm.password,
              secure: "true"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMinimum !== true
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _vm.isPlay === true
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 mt-2",
                      attrs: { color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.seekingTime(-60)
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("\n        mdi-rewind-60\n      ")]),
                      _vm._v("\n      60秒戻し\n    ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPlay === true
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 mt-2",
                      attrs: { color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.seekingTime(-10)
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("\n        mdi-rewind-10\n      ")]),
                      _vm._v("\n      10秒戻し\n    ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPlay === false
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 mt-2",
                      attrs: { color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.backward()
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v("\n        mdi-step-backward\n      ")
                      ]),
                      _vm._v("\n      1秒戻し\n    ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPlay === true
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 mt-2",
                      attrs: { color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.pause()
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v("\n        mdi-pause-circle-outline\n      ")
                      ]),
                      _vm._v("\n      一時停止\n    ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPlay === false
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 mt-2",
                      attrs: { color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.play()
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v("\n        mdi-play-circle-outline\n      ")
                      ]),
                      _vm._v("\n      再生\n    ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPlay === false
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 mt-2",
                      attrs: { color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.forward()
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v("\n        mdi-step-forward\n      ")
                      ]),
                      _vm._v("\n      1秒送り\n    ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPlay === true
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 mt-2",
                      attrs: { color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.seekingTime(10)
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v("\n        mdi-fast-forward-10\n      ")
                      ]),
                      _vm._v("\n      10秒送り\n    ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPlay === true
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 mt-2",
                      attrs: { color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.seekingTime(60)
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v("\n        mdi-fast-forward-60\n      ")
                      ]),
                      _vm._v("\n      60秒送り\n    ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("modal", {
        scopedSlots: _vm._u([
          {
            key: "modal-title",
            fn: function() {
              return [
                _vm._v(
                  "\n      ネットワークビデオレコーダーにログインできませんでした\n    "
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-body",
            fn: function() {
              return [
                _c("InputForm", {
                  attrs: { inputType: "text", title: "username" },
                  model: {
                    value: _vm.login.username,
                    callback: function($$v) {
                      _vm.$set(_vm.login, "username", $$v)
                    },
                    expression: "login.username"
                  }
                }),
                _vm._v(" "),
                _c("InputForm", {
                  attrs: { inputType: "password", title: "password" },
                  model: {
                    value: _vm.login.password,
                    callback: function($$v) {
                      _vm.$set(_vm.login, "password", $$v)
                    },
                    expression: "login.password"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c("SuccessButton", {
                  attrs: { text: "このブラウザに記憶する" },
                  on: {
                    click: function($event) {
                      return _vm.updateLoginInfo()
                    }
                  }
                }),
                _vm._v(" "),
                _c("CancelButton", {
                  attrs: { text: "今回は入力しない" },
                  on: {
                    click: function($event) {
                      return _vm.cancelUpdateLoginInfo()
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isUnauthorized,
          callback: function($$v) {
            _vm.isUnauthorized = $$v
          },
          expression: "isUnauthorized"
        }
      }),
      _vm._v(" "),
      _c("modal", {
        scopedSlots: _vm._u([
          {
            key: "modal-title",
            fn: function() {
              return [
                _vm._v(
                  "\n      ネットワークビデオレコーダーの映像が再生できませんでした（ERROR:" +
                    _vm._s(_vm.errorCode) +
                    "["
                ),
                _c("small", [_vm._v(_vm._s(_vm.errorMessage))]),
                _vm._v("]）\n    ")
              ]
            },
            proxy: true
          },
          {
            key: "modal-body",
            fn: function() {
              return [
                _c("div", { staticClass: "fw-bold fs-4 text-warning" }, [
                  _vm._v(
                    "以下の点を今一度ご確認・設定の上、再生画面を開きなおしてください"
                  )
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "fw-bold fs-4" }, [
                  _c("li", [
                    _vm._v("設定されている値が正しいか今一度ご確認ください。")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("設定したネットワークビデオレコーダー("),
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openToLink()
                          }
                        }
                      },
                      [
                        _c("b", [
                          _vm._v(
                            "https://" +
                              _vm._s(_vm.hostname) +
                              ":" +
                              _vm._s(_vm.port)
                          )
                        ])
                      ]
                    ),
                    _vm._v(")にアクセスしてカメラ映像を確認してください")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://support.alligate.me/hc/ja/articles/25190258064409#h_01HFAS5VN2NTYYHK595QJJY9PZ",
                          target: "_blank"
                        }
                      },
                      [_c("b", [_vm._v("こちらの記事")])]
                    ),
                    _vm._v("から連携時の注意事項をご確認ください")
                  ])
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isOtherError,
          callback: function($$v) {
            _vm.isOtherError = $$v
          },
          expression: "isOtherError"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }