var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "align-center" },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            attrs: { color: "green", indeterminate: "" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        [
          _c("input-form", {
            attrs: { inputType: "text", rules: [] },
            model: {
              value: _vm.notes,
              callback: function($$v) {
                _vm.notes = $$v
              },
              expression: "notes"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        [
          _c("input-form", {
            attrs: {
              inputType: "text",
              rules: [
                _vm.isValidateLengthOneMore,
                _vm.isValidateHalfAlphaNumeric
              ],
              appendIcon: _vm.isLengthOneMore(_vm.deviceId) ? "mdi-video" : ""
            },
            on: {
              "click-append-icon": function($event) {
                return _vm.linkTo(_vm.deviceId)
              }
            },
            model: {
              value: _vm.deviceId,
              callback: function($$v) {
                _vm.deviceId = $$v
              },
              expression: "deviceId"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        [
          _c("input-form", {
            attrs: {
              inputType: "select",
              rules: [_vm.isValidateLengthOneMore],
              selectItems: _vm.configurableGates
            },
            model: {
              value: _vm.gateId,
              callback: function($$v) {
                _vm.gateId = $$v
              },
              expression: "gateId"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLengthOneMore(_vm.value.relationId)
        ? _c("success-button", {
            staticClass: "m-sm",
            attrs: {
              disabled:
                _vm.isModified === false ||
                _vm.isValidate() === false ||
                _vm.loading === true,
              text: "保存"
            },
            on: {
              click: function($event) {
                return _vm.putRecord(_vm.value)
              }
            }
          })
        : _c("success-button", {
            staticClass: "m-sm",
            attrs: {
              disabled:
                _vm.isModified === false ||
                _vm.isValidate() === false ||
                _vm.loading === true,
              text: "登録"
            },
            on: {
              click: function($event) {
                return _vm.postRecord(_vm.value)
              }
            }
          }),
      _vm._v(" "),
      _c("error-button", {
        staticClass: "m-sm",
        attrs: { disabled: _vm.loading === true, text: "削除" },
        on: {
          click: function($event) {
            return _vm.delRecord(_vm.value)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }