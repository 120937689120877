




























































import Vue from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';

export default Vue.extend({
  name: 'UserChangePasswordForm',
  components: {
    Loading,
    InputForm,
  },

  props: {
    userInfo: {
      type: Object,
      default: {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    rulesValidate: {
      validatePassword: (pass: string) => {
        const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
        return (
          pattern.test(pass) ||
          'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。'
        );
      },
      validateMatchPassword(newPass: string, reNewPass: string) {
        return (newPass === reNewPass || '「新しいパスワード」と一致しません。');
      },
    },
  }),
  methods: {
    clickChangePassword() {
      const result = (this.$refs.UserChangePasswordForm as Vue & { validate: () => boolean }).validate();
      if (result) {
          this.$emit('clickChangePassword');
        }
    },
  },
  watch: {
    successMessageLogs(val: any) {
      if (val !== '') {
        (this.$refs.UserChangePasswordForm as Vue & { resetValidation: () => boolean }).resetValidation();
      }
    },
  },
});
