



























































































export type InputType = 'file' | 'account-image-file';

import Vue, { PropType } from 'vue';
import HelpIcon from '@/components/molecules/HelpIcon/HelpIcon.vue';
import AlertIcon from '@/components/molecules/AlertIcon/AlertIcon.vue';

export const imageExtensions: string[] = [
  '.jpeg',
  '.jpg',
  '.bmp',
  '.png',
  '.tiff',
];

export const isFileExtension = (fileName: string, extensions: string[]): boolean => {
  return extensions.some((extension: string) => {
    return fileName.toLowerCase().endsWith(`${extension}`);
  });
};

export default Vue.extend({
  name: 'DragAndDropFileInputForm',
  components: {
    HelpIcon,
    AlertIcon,
  },
  props: {
    value: {
      type: [String, Boolean, Array, Number, File] as PropType<string | boolean | string[] | number | File | File[]>,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inputType: {
      type: String as PropType<InputType>,
      default: 'file',
    },
    helpText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: '*.*',
    },
    appendIcon: {
      type: String,
      default: () => '',
    },
    imageAccept: {
      type: String,
      default: '.jpg,.jpeg,.png,.bmp,.tif',
    },
    alertText: {
      type: String,
      default: '',
    },
    maxSumFileNameSize: {
      type: Number,
      default: 525,
    },
  },
  data: () => ({
    files: [] as File[],
    isDragging: false,
    dragCount: 0,
    displayFileNameCount: 0,
  }),
  computed: {
    innerValue: {
      get(): string | boolean | string[] | number | File | File[] {
        return this.value;
      },
      set(val: string | boolean | string[] | number | File | File[]): void {
        this.$emit('input', val);
        return;
      },
    },
  },
  methods: {
    onDrop(e: any) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const inputFiles: FileList = e.dataTransfer.files;
      const regex = new RegExp(/^[0-9]+$/);
      const tmpFiles: File[] = [];
      let fileNameSizeSum = 0;
      this.displayFileNameCount = 0;
      for (const file in inputFiles) {
        if (regex.test(file)) {
          // filesはファイル以外のデータが入っており、ファイルの場合のみキー名が数字になるため
          if (this.inputType === 'account-image-file' && isFileExtension(inputFiles[file].name, imageExtensions)) {
            const fileNameSize = Buffer.byteLength(inputFiles[file].name, 'utf8');
            if (fileNameSizeSum + fileNameSize <= this.maxSumFileNameSize) {
              fileNameSizeSum += fileNameSize;
              this.displayFileNameCount += 1;
            }
            this.files.push(inputFiles[file]);
            tmpFiles.push(inputFiles[file]);
          } else if (this.inputType === 'file' && this.accept !== '*.*'
            && isFileExtension(inputFiles[file].name, this.accept.split(','))) {
            this.files.push(inputFiles[file]);
            tmpFiles.push(inputFiles[file]);
          } else if (this.inputType === 'file' && this.accept === '*.*') {
            this.files.push(inputFiles[file]);
            tmpFiles.push(inputFiles[file]);
          }
        }
      }
      if (tmpFiles.length > 0) {
        this.innerValue = tmpFiles;
      }
    },
    onDragEnter(e: any) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },
    onDragLeave(e: any) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    },
    onChange(e: any) {
      const inputFiles: FileList = e.target.files;
      const regex = new RegExp(/^[0-9]+$/);
      const tmpFiles: File[] = [];
      let fileNameSizeSum = 0;
      this.displayFileNameCount = 0;
      for (const file in inputFiles) {
        if (regex.test(file)) {
          // filesはファイル以外のデータが入っており、ファイルの場合のみキー名が数字になるため
          if (this.inputType === 'account-image-file' && isFileExtension(inputFiles[file].name, imageExtensions)) {
            // window.console.log(`size=${JSON.stringify(inputFiles[file].size)}`);
            const fileNameSize = Buffer.byteLength(inputFiles[file].name, 'utf8');
            if (fileNameSizeSum + fileNameSize <= this.maxSumFileNameSize) {
              fileNameSizeSum += fileNameSize;
              this.displayFileNameCount += 1;
            }
            tmpFiles.push(inputFiles[file]);
          } else if (this.inputType === 'file' && this.accept !== '*.*'
            && isFileExtension(inputFiles[file].name, this.accept.split(','))) {
            this.files.push(inputFiles[file]);
            tmpFiles.push(inputFiles[file]);
          } else if (this.inputType === 'file' && this.accept === '*.*') {
            this.files.push(inputFiles[file]);
            tmpFiles.push(inputFiles[file]);
          }
        }
      }
      if (tmpFiles.length > 0) {
        this.innerValue = tmpFiles;
      }
    },
  },
});
