var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-nfc-setting" },
    [
      _c(
        "v-container",
        [
          _vm.successMessageLogs !== ""
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-1 bg-success" },
                [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== ""
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "nfc-note" }, [
                _vm._v("※こちらの設定は、[ALLIGATE LOCK PRO]では使用できません")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "pa-6 pr-10 mb-5" },
            [
              _c(
                "v-form",
                { ref: "UserAddForm", attrs: { "lazy-validation": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  NFCカード動作設定\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.listNfcSettingSelect,
                                      "item-text": "name",
                                      "item-value": "type",
                                      "single-line": "",
                                      color: "green",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$emit("checkEditStatus")
                                      }
                                    },
                                    model: {
                                      value: _vm.settingInfor.pCodeConfigType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "pCodeConfigType",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.pCodeConfigType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  読み取り位置\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  個人ID 1 位置\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.editStatus,
                                      type: "number",
                                      min: "0",
                                      max: "47"
                                    },
                                    model: {
                                      value: _vm.settingInfor.readPosition1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "readPosition1",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.readPosition1"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3", align: "right" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "right-subheader theme--light no-padding"
                                    },
                                    [_vm._v("長さ")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.editStatus,
                                      type: "number",
                                      min: "0",
                                      max: "20"
                                    },
                                    model: {
                                      value: _vm.settingInfor.readLength1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "readLength1",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.readLength1"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  個人ID 2 位置\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.editStatus,
                                      type: "number",
                                      min: "0",
                                      max: "47"
                                    },
                                    model: {
                                      value: _vm.settingInfor.readPosition2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "readPosition2",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.readPosition2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3", align: "right" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "right-subheader theme--light no-padding"
                                    },
                                    [_vm._v("長さ")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.editStatus,
                                      type: "number",
                                      min: "0",
                                      max: "20"
                                    },
                                    model: {
                                      value: _vm.settingInfor.readLength2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "readLength2",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.readLength2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  個人ID 3 位置\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.editStatus,
                                      type: "number",
                                      min: "0",
                                      max: "47"
                                    },
                                    model: {
                                      value: _vm.settingInfor.readPosition3,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "readPosition3",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.readPosition3"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3", align: "right" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "right-subheader theme--light no-padding"
                                    },
                                    [_vm._v("長さ")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.editStatus,
                                      type: "number",
                                      min: "0",
                                      max: "20"
                                    },
                                    model: {
                                      value: _vm.settingInfor.readLength3,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "readLength3",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.readLength3"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  固定コード 1 位置\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.getEditStatus(),
                                      type: "number",
                                      min: "0",
                                      max: "47"
                                    },
                                    model: {
                                      value:
                                        _vm.settingInfor.ownerCodePosition1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "ownerCodePosition1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settingInfor.ownerCodePosition1"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3", align: "right" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "right-subheader theme--light no-padding"
                                    },
                                    [_vm._v("長さ")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.getEditStatus(),
                                      type: "number",
                                      min: "0",
                                      max: "20"
                                    },
                                    model: {
                                      value: _vm.settingInfor.ownerCodeLength1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "ownerCodeLength1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settingInfor.ownerCodeLength1"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  固定コード 2 位置\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.getEditStatus(),
                                      type: "number",
                                      min: "0",
                                      max: "47"
                                    },
                                    model: {
                                      value:
                                        _vm.settingInfor.ownerCodePosition2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "ownerCodePosition2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settingInfor.ownerCodePosition2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3", align: "right" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "right-subheader theme--light no-padding"
                                    },
                                    [_vm._v("長さ")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.getEditStatus(),
                                      type: "number",
                                      min: "0",
                                      max: "20"
                                    },
                                    model: {
                                      value: _vm.settingInfor.ownerCodeLength2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "ownerCodeLength2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settingInfor.ownerCodeLength2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  固定コード 3 位置\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.getEditStatus(),
                                      type: "number",
                                      min: "0",
                                      max: "47"
                                    },
                                    model: {
                                      value:
                                        _vm.settingInfor.ownerCodePosition3,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "ownerCodePosition3",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settingInfor.ownerCodePosition3"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3", align: "right" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "right-subheader theme--light no-padding"
                                    },
                                    [_vm._v("長さ")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.getEditStatus(),
                                      type: "number",
                                      min: "0",
                                      max: "20"
                                    },
                                    model: {
                                      value: _vm.settingInfor.ownerCodeLength3,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "ownerCodeLength3",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settingInfor.ownerCodeLength3"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  固定コード有効・無効\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("input-form", {
                                    staticClass: "v-input--reverse swicth-all",
                                    attrs: {
                                      inputType: "switch",
                                      disabled: _vm.editStatusException
                                    },
                                    model: {
                                      value: _vm.settingInfor.isOwnerCodeValid,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "isOwnerCodeValid",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settingInfor.isOwnerCodeValid"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", {
                                attrs: { cols: "3", align: "right" }
                              }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  固定コード\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.getEditStatus()
                                    },
                                    model: {
                                      value: _vm.settingInfor.ownerCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "ownerCode",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.ownerCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", {
                                attrs: { cols: "3", align: "right" }
                              }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Felica認証情報\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", {
                                attrs: { cols: "3", align: "right" }
                              }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  システムコード\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.checkIsMifare()
                                    },
                                    model: {
                                      value: _vm.settingInfor.systemCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "systemCode",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.systemCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3", align: "right" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "right-subheader theme--light no-padding"
                                    },
                                    [_vm._v("サービスコード")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.checkIsMifare()
                                    },
                                    model: {
                                      value: _vm.settingInfor.serviceCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "serviceCode",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.serviceCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  ブロックカウント\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.checkIsMifare(),
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: _vm.settingInfor.blockCount,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "blockCount",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.blockCount"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", {
                                attrs: { cols: "3", align: "right" }
                              }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  MIFARE認証情報\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", {
                                attrs: { cols: "3", align: "right" }
                              }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  キー種別\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { row: "" },
                                      model: {
                                        value: _vm.settingInfor.keyType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.settingInfor,
                                            "keyType",
                                            $$v
                                          )
                                        },
                                        expression: "settingInfor.keyType"
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        staticClass: "radio-item",
                                        attrs: {
                                          color: "green-primary",
                                          label: "Aキー",
                                          value: 1,
                                          disabled: _vm.checkIsFalica()
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-radio", {
                                        staticClass: "radio-item",
                                        attrs: {
                                          color: "green-primary",
                                          label: "Bキー",
                                          value: 0,
                                          disabled: _vm.checkIsFalica()
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", {
                                attrs: { cols: "3", align: "right" }
                              }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  キー情報\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.checkIsFalica()
                                    },
                                    model: {
                                      value: _vm.settingInfor.keyValue,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "keyValue",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.keyValue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", {
                                attrs: { cols: "3", align: "right" }
                              }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light no-padding"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  セクタ No\n                "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "green",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "auto",
                                      autocomplete: "off",
                                      disabled: _vm.checkIsFalica()
                                    },
                                    model: {
                                      value: _vm.settingInfor.sectorNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.settingInfor,
                                          "sectorNumber",
                                          $$v
                                        )
                                      },
                                      expression: "settingInfor.sectorNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", {
                                attrs: { cols: "3", align: "right" }
                              }),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "9" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-primary",
                  attrs: { outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("saveNfcSetting")
                    }
                  }
                },
                [_vm._v("設定を保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }