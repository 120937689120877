var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table setting" },
    [
      _c("v-container", [
        _vm.loading
          ? _c("div", { attrs: { id: "loading" } }, [
              _c("div", { staticClass: "loadingMsg" })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pa-6 pr-10 mb-5", attrs: { elevation: "3" } },
          [
            _c(
              "v-row",
              [
                _vm.successMessageLogs !== ""
                  ? _c(
                      "v-col",
                      { staticClass: "mb-4 font-weight-bold mt-1 bg-success" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.successMessageLogs) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.errorMessageLogs !== ""
                  ? _c(
                      "v-col",
                      { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.errorMessageLogs) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass:
                      "text-subtitle-2 border-left-blue font-weight-bold"
                  },
                  [_vm._v("\n            外部ログイン\n          ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("v-card", { staticClass: "pa-6 pr-10 mb-5" }, [
              _c(
                "div",
                { staticClass: "pl-10" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "align-center custom-label",
                          attrs: { md: "2" }
                        },
                        [
                          _vm._v(
                            "\n              SAML でログイン\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "1" } },
                        [
                          _c("v-switch", {
                            staticClass: "custom-switch",
                            attrs: {
                              color: "success",
                              inset: "",
                              disabled:
                                _vm.isSamlFederatedLoginService === "false"
                            },
                            on: {
                              change: function($event) {
                                return _vm.checkIsChangeConfig()
                              }
                            },
                            model: {
                              value: _vm.oneLoginLoginService.isValid,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.oneLoginLoginService,
                                  "isValid",
                                  $$v
                                )
                              },
                              expression: "oneLoginLoginService.isValid"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("cancel-button", {
                            attrs: {
                              text: "ログインユーザー登録",
                              disabled:
                                _vm.isSamlFederatedLoginService === "false"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "goRelation",
                                  _vm.oneLoginLoginService
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "align-center custom-label",
                          attrs: { md: "2" }
                        },
                        [_vm._v("\n              メタデータURL\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "input-url",
                            attrs: {
                              "hide-details": "auto",
                              outlined: "",
                              color: "green",
                              dense: "",
                              "full-width": "",
                              "single-line": "",
                              label: "メタデータURL",
                              disabled:
                                !_vm.oneLoginLoginService.isValid ||
                                _vm.isSamlFederatedLoginService === "false",
                              placeholder: "メタデータURL"
                            },
                            on: {
                              change: function($event) {
                                return _vm.checkIsChangeConfig()
                              }
                            },
                            model: {
                              value: _vm.oneLoginLoginService.metadataUrl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.oneLoginLoginService,
                                  "metadataUrl",
                                  $$v
                                )
                              },
                              expression: "oneLoginLoginService.metadataUrl"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-row", {
                    staticStyle: { height: "8px" },
                    attrs: { "no-gutters": "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "align-center custom-label",
                          attrs: { md: "2" }
                        },
                        [
                          _vm._v(
                            "\n              Google でログイン\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "1" } },
                        [
                          _c("v-switch", {
                            staticClass: "custom-switch",
                            attrs: {
                              color: "success",
                              inset: "",
                              disabled: _vm.isFederatedLoginService === "false"
                            },
                            on: {
                              change: function($event) {
                                return _vm.checkIsChangeConfig()
                              }
                            },
                            model: {
                              value: _vm.googleLoginService.isValid,
                              callback: function($$v) {
                                _vm.$set(_vm.googleLoginService, "isValid", $$v)
                              },
                              expression: "googleLoginService.isValid"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("cancel-button", {
                            attrs: {
                              text: "ログインユーザー登録",
                              disabled: _vm.isFederatedLoginService === "false"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "goRelation",
                                  _vm.googleLoginService
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "align-center custom-label",
                          attrs: { md: "2" }
                        },
                        [
                          _vm._v(
                            "\n              Apple でログイン\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "1" } },
                        [
                          _c("v-switch", {
                            staticClass: "custom-switch",
                            attrs: {
                              color: "success",
                              inset: "",
                              disabled: _vm.isFederatedLoginService === "false"
                            },
                            on: {
                              change: function($event) {
                                return _vm.checkIsChangeConfig()
                              }
                            },
                            model: {
                              value: _vm.appleIdLoginService.isValid,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appleIdLoginService,
                                  "isValid",
                                  $$v
                                )
                              },
                              expression: "appleIdLoginService.isValid"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c("cancel-button", {
                            attrs: {
                              text: "ログインユーザー登録",
                              disabled: _vm.isFederatedLoginService === "false"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "goRelation",
                                  _vm.appleIdLoginService
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mr-3 mb-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "", disabled: !_vm.isSubmit },
                          on: {
                            click: function($event) {
                              return _vm.$emit("saveConfig")
                            }
                          }
                        },
                        [_vm._v("\n            変更を登録\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "", disabled: !_vm.isSubmit },
                          on: {
                            click: function($event) {
                              return _vm.$emit("saveConfig")
                            }
                          }
                        },
                        [_vm._v("\n            変更を破棄\n            ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }