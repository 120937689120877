




























import {Vue } from 'vue-property-decorator';
import GroupAddUserTemplate from '@/components/templates/GroupAddUserTemplate/GroupAddUserTemplate.vue';
import { User } from '@/types/alligate';
import * as Config from '@/config';
import moment from 'moment';
export interface DataType {
  dataUsers: any;
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  userTotalCount: number;

  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;

  isFinishGetGate: boolean;
  rowinErrDialog: number;
  errorMessageProcess: any;
  successItemProcess: number;
  totalDataRowProcess: number;
  showProgress: boolean;
  isFinishProcess: boolean;
  showConfirm: boolean;
  progress: number;
  goBack: string;
  userOfGroup: any;
}
export default Vue.extend({
  name: 'GroupAddUserPage',
  components: {
    GroupAddUserTemplate,
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['userId'],
    keyword: '',
    userTotalCount: 0,
    dataUsers: [],
    userOfGroup: [],
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    isFinishGetGate: false,
    rowinErrDialog: 0,
    errorMessageProcess: [],
    successItemProcess: 0,
    totalDataRowProcess: 0,
    showProgress: false,
    isFinishProcess: false,
    showConfirm: false,
    progress: 0,
    goBack: 'groups',
  }),
  created() {
    if (typeof localStorage.pageSizeUser === 'undefined') {
      localStorage.setItem('pageSizeUser', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeUser);
    }
  },
  watch: {
    isFinishGetGate(value) {
      if (this.isFinishGetGate && value) {
        this.loading = false;
      }
    },
  },
  mounted() {
    const dataQuery = this.$route.query;
    if (dataQuery.goBack !== undefined && dataQuery.goBack !== '') {
      this.goBack = dataQuery.goBack.toString();
    }
    this.loading = true;
    this.userOfGroup = [];
    this.getListUsers().then(() => {
      this.getUserOfGroup(1, dataQuery.groupId);
    });
  },
  methods: {
    closeModalProgress() {
      this.showProgress = false;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showConfirm = false;
      this.loading = true;
      const dataQuery = this.$route.query;
      this.getUserOfGroup(1, dataQuery.groupId);
    },
    clickConfirm() {
      this.showConfirm = true;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = false;
      this.totalDataRowProcess = 0;
    },
    async clickAddUser(data: any) {
      this.showConfirm = false;
      console.log(data);
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = true;
      this.totalDataRowProcess = data.length;
      const percent = (100 / this.totalDataRowProcess);
      const dataQuery = this.$route.query;
      if (dataQuery.groupId !== undefined && dataQuery.groupId !== '' && dataQuery.groupId !== null) {
        for (const item of data) {
          let result;
          const array = {
            userId: item.userId,
            groupId: dataQuery.groupId,
          };
          result  = await this.postUser(array, item.userId);
          if (result) {
            this.successItemProcess++;
          }
          this.progress += percent;
        }
        this.isFinishProcess = true;
      }
    },
    postUser(data: any, userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.GROUPS_PASSAGE_API, data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then( null, (error: any) => {
            let errorName = '';
            switch (error.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 409:
                errorName = '他グループと紐づいてます。通行可能時間設定が適用されたユーザーのため追加できません。';
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = userId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeUser', value);
      this.loading = true;
      this.getListUsers().then(() => {
        this.loading = false;
      });
    },
    changePage(value: any): any {
      this.currPage = value;
      this.getListUsers().then(() => {
        this.loading = false;
      });
    },
    searchUser(value: any): any {
      this.keyword = value.text;
      this.scorp = value.targets;
      this.getListUsers();
    },
    getUserOfGroup(page: any , groupId: any) {
      this.$http.get(Config.GROUPS_PASSAGE_API + '?groupId=' +
      encodeURIComponent(groupId) + '&limit=1000&page=' + page + '&embed=user')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            const groupData =  response.body.groupPassages;
            groupData.forEach((element: any) => {
              this.userOfGroup.push({text: element.user.name, value: element.userId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getUserOfGroup(page + 1, groupId);
            } else {
              this.loading = false;
            }
          } else {
            this.userOfGroup = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
          }); // catch
    },
    getListUsers(): any {
      return new Promise((resolve, reject) => {
        this.loading = true;
        const apiLink = Config.USERS_LIST_API + '?limit=' + localStorage.pageSizeUser +
        '&page=' + this.currPage + '&isExceptOptionalUser=true&isValid=true&exclude=thumbnail';
        this.$http.get(apiLink)
          .then((response: any) => {
            this.resetData();
            if (!this.isEmpty(response.body.users)) {
              this.dataUsers = response.body.users;
              this.userTotalCount = response.body.maxRecords;
              this.currPage = Math.ceil(response.body.startRecord / this.perPage);
            } else {
              this.userTotalCount = 0;
              this.currPage = 1;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(true);
            switch (err.status) {
                case 400:
                  this.errorMessageLogs = Config.ERROR_400;
                  break;
                case 403:
                  if (err.message === 'Unauthorized') {
                    this.errorMessageLogs = Config.ERROR_403;
                  } else {
                    this.errorMessageLogs = Config.ERROR_403_DENIED;
                  }
                  break;
                case 404:
                  this.errorMessageLogs = Config.ERROR_404;
                  break;
                case 500:
                  this.errorMessageLogs = Config.ERROR_500;
                  break;
                case 503:
                  this.errorMessageLogs = Config.ERROR_503;
                  break;
                default:
                  this.errorMessageLogs = Config.ERROR_DEFAULT;
                  break;
              }
          }); // catch
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataUsers = [];
      this.errorMessageLogs = '';
      // this.userTotalCount = 0;
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
  },
});
