
































































































































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import { Gate, GatePassage } from '@/types/alligate';
import { PassableTime } from '@/types/alligate';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import { DataTableHeader } from 'vuetify';
import { Group } from '@/types/alligate';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import RadioButton from '@/components/molecules/RadioButton/RadioButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import DateTimePicker from '@/components/molecules/DateTimePicker/DateTimePicker.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import GatePassageEdit from '@/components/organisms/GatePassageEdit/GatePassageEdit.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import TbEye from '@/components/molecules/TbEyeVideo/TbEyeVideo.vue';
import moment from 'moment';
import * as Config from '@/config';

export interface ActionItem {
  title: string;
}
const today = new Date();
const next30days = new Date(new Date().setDate(today.getDate() + 30));
const pre30days = new Date(new Date().setDate(today.getDate() - 30));
const min: string[] = [];
for (let i = 0; i <= 59 ; i++) {
  let value = '';
  if (i < 10) {
    value = '0' + i;
  } else {
    value = i.toString();
  }
  min.push(value);
}
export interface DataType {
  showOption: boolean;
  tab: null;
  selectedItem: PassableTime[];
  searchTargetItems: SearchTargetItem[];
  showModal: boolean;
  messageErrorDialog: string;
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  queryOptions: any;
  searchKey: string;
  page: number;
  perPageValue: number;
  perPage: number;
  dateStart: string;
  dateEnd: string;
  isDateStart: boolean;
  isDateEnd: boolean;
  dateStartExportCSV: string;
  dateEndExportCSV: string;
  typeExportCSV: string;
  isDateStartCSV: boolean;
  isDateEndCSV: boolean;
  timeStartGate: string;
  timeEndGate: string;
  dateStartGate: string;
  dateEndGate: string;
  timeStartGateCSV: string;
  timeEndGateCSV: string;
  dateStartGateCSV: string;
  dateEndGateCSV: string;
  logType: string;
  timeStart: string;
  timeEnd: string;
  isDisableLog: boolean;
  timeWorktime: string;
  hours: any;
  hourWorktime: string;
  minutes: any;
  minuteWorktime: string;
  replayStartDate: number;
  showTbeyeModal: boolean;
  showTbeyeVideo: any;
  isRerender: boolean;
}

export default Vue.extend({
  name: 'GateLogList',
  components: {
    ShowPerPage,
    Pagination,
    Searchbox,
    SuccessButton,
    GatePassageEdit,
    DateTimePicker,
    CancelButton,
    RadioButton,
    DatePicker,
    DisplayOption,
    Modal,
    TbEye,
  },
  mounted() {
    this.minutes = min;
    console.log('goBack', this.goBack);
  },
  created() {
    if (typeof localStorage.checkedLogGateList !== 'undefined') {
      this.selectedDisplayOption = JSON.parse(localStorage.checkedLogGateList);
    }
  },
  props: {
    logGates: {
      type: Array,
      default: () => [],
    },
    dataDate: {
      type: Array as PropType<GatePassage[]>,
      default: () => [],
    },
    gatePassageTotalCount: {
      type: Number,
      default: 0,
    },
    disableNextGate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    optionGate: {
      default: () => [],
    },
    maxRecord: {
      type: Number,
      default: 0,
    },
    gateIdToSafieDeviceIds: {
      type: Object,
      default: () => ({}),
    },
    gateIdToTbeyeDeviceIds: {
      type: Object,
      default: () => ({}),
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    goBack: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    tab: null,
    selectedItem: [],
    showModal: false,
    messageErrorDialog: '',
    displayOptionItems: [
      { label: '時間', value: 'timestamp', isDisabled: true },
      { label: 'ユーザーID ', value: 'userId' },
      { label: 'ユーザー名', value: 'userName' },
      { label: 'ユーザー名ふりがな ', value: 'userFurigana' },
      { label: 'ゲートID', value: 'gateId' },
      { label: 'ゲート名', value: 'gateName' },
      { label: 'カード番号', value: 'deviceId' },
      { label: '照合機器タイプ ', value: 'deviceType' },
      { label: 'ログID', value: 'accessLogId' },
      { label: 'ログ名称', value: 'logLabel' },
      { label: 'ゲートログNo ', value: 'sequenceNo' },
      { label: '電池電圧 ', value: 'voltage' },
      { label: '照合位置情報 ', value: 'location' },
      { label: 'メールアドレス ', value: 'email' },
      { label: '備考１ ', value: 'option1' },
      { label: '備考2 ', value: 'option2' },
    ],
    searchTargetItems: [
      { label: 'ユーザー名', value: 'userName' },
      { label: 'ゲート名', value: 'gateName' },
      { label: 'ログ名称', value: 'logLabel' },
      { label: 'ユーザーID', value: 'userId' },
      { label: 'カード番号', value: 'deviceId' },
      { label: 'メールアドレス', value: 'email' },
      { label: '備考1', value: 'option1' },
      { label: '備考2', value: 'option2' },
    ],
    selectedDisplayOption: [
      'timestamp', 'userName', 'gateName', 'logLabel', 'deviceId',
    ],
    queryOptions: {},
    searchKey: '',
    page: 1,
    perPage: 100,
    perPageValue: 100,
    dateStart: pre30days.toISOString().substr(0, 10),
    dateEnd: new Date().toISOString().substr(0, 10),
    isDateStart: false,
    isDateEnd: false,
    dateStartExportCSV: '',
    dateEndExportCSV: '',
    typeExportCSV: '0',
    isDateStartCSV: false,
    isDateEndCSV: false,
    timeStartGate: '00:00',
    timeEndGate: '23:59',
    dateStartGate: '',
    dateEndGate: '',
    timeStartGateCSV: '00:00',
    timeEndGateCSV: '23:59',
    dateStartGateCSV: '',
    dateEndGateCSV: '',
    logType: '0',
    timeStart: '00:00',
    timeEnd: '23:59',
    replayStartDate: 0,
    showTbeyeModal: false,
    isRerender: false,
    showTbeyeVideo: {},
    showOption: false,
    isDisableLog: localStorage.isAdmin === 'true' ? false : true,
    timeWorktime: '00:00',
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    hourWorktime: '00',
    minuteWorktime: '00',
    minutes: [],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [];
        if (this.selectedDisplayOption.includes('timestamp')) {
          headers.push({
            text: '時間',
            align: 'start',
            sortable: true,
            value: 'timestamp',
          });
        }
        if (this.selectedDisplayOption.includes('userId')) {
          headers.push({
            text: 'ユーザーID',
            align: 'start',
            sortable: true,
            value: 'userId',
          });
        }
        if (this.selectedDisplayOption.includes('userName')) {
          headers.push({
            text: 'ユーザー名',
            align: 'start',
            sortable: true,
            value: 'userName',
          });
        }
        if (this.selectedDisplayOption.includes('userFurigana')) {
          headers.push({
            text: 'ユーザー名ふりがな',
            align: 'start',
            sortable: true,
            value: 'userFurigana',
          });
        }
        if (this.selectedDisplayOption.includes('gateId')) {
          headers.push({
            text: 'ゲートID',
            align: 'start',
            sortable: true,
            value: 'gateId',
          });
        }
        if (this.selectedDisplayOption.includes('gateName')) {
          headers.push({
            text: 'ゲート名',
            align: 'start',
            sortable: true,
            value: 'gateName',
          });
        }
        if (this.selectedDisplayOption.includes('deviceId')) {
          headers.push({
            text: 'カード番号',
            align: 'start',
            sortable: true,
            value: 'deviceId',
          });
        }
        if (this.selectedDisplayOption.includes('deviceType')) {
          headers.push({
            text: '照合機器タイプ',
            align: 'start',
            sortable: true,
            value: 'deviceType',
          });
        }
        if (this.selectedDisplayOption.includes('accessLogId')) {
          headers.push({
            text: 'ログID',
            align: 'start',
            sortable: true,
            value: 'accessLogId',
          });
        }
        if (this.selectedDisplayOption.includes('logLabel')) {
          headers.push({
            text: 'ログ名称',
            align: 'start',
            sortable: true,
            value: 'logLabel',
          });
        }
        if (this.selectedDisplayOption.includes('sequenceNo')) {
          headers.push({
            text: 'ゲートログNo',
            align: 'start',
            sortable: true,
            value: 'sequenceNo',
          });
        }
        if (this.selectedDisplayOption.includes('voltage')) {
          headers.push({
            text: '電池電圧',
            align: 'start',
            sortable: true,
            value: 'voltage',
          });
        }
        if (this.selectedDisplayOption.includes('location')) {
          headers.push({
            text: '照合位置情報',
            align: 'start',
            sortable: true,
            value: 'location',
          });
        }
        if (this.selectedDisplayOption.includes('email')) {
          headers.push({
            text: 'メールアドレス',
            align: 'start',
            sortable: true,
            value: 'email',
          });
        }
        if (this.selectedDisplayOption.includes('option1')) {
          headers.push({
            text: '備考１',
            align: 'start',
            sortable: true,
            value: 'option1',
          });
        }
        if (this.selectedDisplayOption.includes('option2')) {
          headers.push({
            text: '備考2',
            align: 'start',
            sortable: true,
            value: 'option2',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    isDisable: {
      get(): boolean {
        return false;
      },
      set(val: boolean): void {
        return;
      },
    },
    isExportCSVWorkTimeType: {
      get(): boolean {
        if (this.typeExportCSV === '0') {
          return true;
        } else {
          return false;
        }
      },
      set(val: boolean): void {
        return;
      },
    },
    isDisableSearch: {
      get(): boolean {
        if (this.isDisableLog === true || this.isGetDataList === true) {
          return true;
        } else {
          return false;
        }
      },
      set(val: boolean): void {
        return;
      },
    },
    groupNames: {
      get(): Array<{ text: string; value: string }> {
        return this.groups.map((group) => ({
          text: group.groupName,
          value: group.groupId,
        }));
      },
      set(): void {
        return;
      },
    },
    // logType: {
    //   get(): string {
    //     return this.activeTab.toString();
    //   },
    //   set(val: any): void {
    //     this.$emit('value', val);
    //     return;
    //   },
    // },
  },
  watch: {
    page(newPage: number) {
      if (newPage > 1) {
        this.perPage = newPage * this.perPageValue ;
        this.queryOptions = this.optionGate;
        this.queryOptions.page = newPage;
        this.queryOptions.logType = this.logType;
        this.queryOptions.perPage = this.perPageValue;
        // this.queryOptions.text = this.searchKey;
        // this.queryOptions.dateStart = this.dateStartGate + ' ' + this.timeStartGate;
        // this.queryOptions.dateEnd = this.dateEndGate + ' ' + this.timeEndGate;
        // this.queryOptions.targets = [];
        this.$emit('getGateLogList', this.queryOptions);
      }
    },
    goBack(value: any) {
      this.queryOptions.text = '';
      if (typeof localStorage.gateName !== undefined && localStorage.gateName !== 'undefined' &&
        localStorage.gateName !== '' && this.goBack === '/gates') {
          this.queryOptions.text = localStorage.gateName;
        }
      if (typeof localStorage.userName !== undefined && localStorage.userName !== 'undefined' &&
        localStorage.userName !== '' && this.goBack === '/user') {
          this.queryOptions.text = localStorage.userName;
        }
      this.queryOptions.page = 1;
      this.queryOptions.dateStart = '';
      this.queryOptions.dateEnd = '';
      this.queryOptions.targets = ['userName', 'gateName', 'logLabel', 'userId', 'deviceId'];
      this.queryOptions.logType = '0';
      this.queryOptions.perPage = this.perPageValue;
      this.$emit('getGateLogList', this.queryOptions);
    },
    minuteWorktime(newValue: string) {
      this.$emit('getNewTimeStart', this.formatTime(this.hourWorktime, newValue));
      this.timeWorktime = this.formatTime(this.hourWorktime, newValue);
    },
    hourWorktime(newValue: string) {
      this.timeWorktime = this.formatTime(newValue, this.minuteWorktime);
      this.$emit('getNewTimeStart', this.timeWorktime);
    },
    showTbeyeModal(newVal: boolean) {
      if (newVal === true) {
        // 初回表示時にカスタムエレメントが二重に表示されてしまいバグるので時間差によって強制的に表示
        setTimeout(() => {
          this.isRerender = true;
        }, 100);
      } else {
        this.isRerender = false;
        this.showTbeyeVideo = {};
      }
    },
  },
  methods: {
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    changePerPageLog(value: any) {
      this.page = 1;
      this.perPageValue = value;
      this.perPage = 1 * this.perPageValue ;
      this.queryOptions = this.optionGate;
      this.queryOptions.page = 1;
      this.queryOptions.logType = this.logType;
      this.queryOptions.perPage = this.perPageValue;
      this.$emit('searchGateLog', this.queryOptions);
    },
    clickModal() {
      this.dateStartExportCSV = this.dateStartGate;
      this.dateStart = this.dateStartGate;
      this.dateEnd = this.dateEndGate;
      this.timeStart = this.timeStartGate;
      this.timeEnd = this.timeEndGate;
      this.dateEndExportCSV = this.dateEndGate;
      this.timeStartGateCSV = this.timeStartGate;
      this.timeEndGateCSV = this.timeEndGate;
      this.showModal = true;
    },
    getNewTimeStart(time: string) {
      this.timeStartGate = time;
    },
    getNewTimeEnd(time: string) {
      this.timeEndGate = time;
    },
    getNewDateStart(date: string) {
      this.dateStartGate = date;
    },
    getNewDateEnd(date: string) {
      this.dateEndGate = date;
    },
    getNewTimeStartCSV(time: string) {
      this.timeStartGateCSV = time;
    },
    getNewTimeEndCSV(time: string) {
      this.timeEndGateCSV = time;
    },
    getNewDateStartCSV(date: string) {
      this.dateStartGateCSV = date;
    },
    getNewDateEndCSV(date: string) {
      this.dateEndGateCSV = date;
    },
    formatDateTime(date: Date): string {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
    CheckColumn(data: any) {
      localStorage.setItem('checkedLogGateList', JSON.stringify(data));
    },
    ChangeValue() {
      const element: HTMLElement = document.getElementsByClassName('btnSearch')[0] as HTMLElement;
      element.click();
    },
    safieDeviceAndTimestampLinkTo(deviceId: string, unixtimestamp: number): void {
      window.open(`${Config.SAFIE_STREAM_URL}/${deviceId}?timestamp=${unixtimestamp}000`, '_blank');
    },
    openToLink(url: string): void {
      window.open(url, '_blank');
    },
    rerender(): void {
      this.isRerender = false;
      setTimeout(() => {
        this.isRerender = true;
      }, 100);
    },
    tbeyeDeviceAndTimestamp(tbeyeData: any, unixtimestamp: number): void {
      this.replayStartDate = Number(`${unixtimestamp}000`);
      this.showTbeyeVideo = tbeyeData;
      this.showTbeyeModal = true;
    },
    searchGateLog(data: any) {
      console.log(this.timeStartGate);
      data.page = 1;
      data.dateStart = this.dateStartGate + ' ' + this.timeStartGate;
      data.dateEnd = this.dateEndGate + ' ' + this.timeEndGate;
      data.logType = this.logType;
      data.perPage = this.perPageValue;
      this.$emit('searchGateLog', data);
    },
  },
});
