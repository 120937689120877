import { RoomRoomLog, UserRoomLog } from '@/types/alligate';
import Vue from 'vue';
import * as Config from '@/config';

/**
 * 部屋の全入退室ログを取得する
 * @param {Vue} vue Vueインスタンス
 * @param {string} roomId 部屋ID
 * @param {string} date 日付
 * @returns {Promsie<RoomRoomLog[]>} 取得した屋の全入退室ログ
 */
export async function getAllRoomRoomLogs(vue: Vue, roomId: string, date: string): Promise<RoomRoomLog[]> {
  try {
    const roomRoomLogs = await getAllRoomRoomLogsIter(vue, roomId, date, 1);
    return roomRoomLogs;
  } catch (err) {
    console.log('|||', err);
    switch ((err as any).response.status) {
      case 403:
          throw(new Error('部屋情報の取得に失敗しました。部屋情報を取得する権限がありません。'));
      case 404:
          // 取得時404エラーの場合は、データ無しなので正常終了。
          return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 部屋の全入退室ログを取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {string} roomId 部屋ID
 * @param {string} date 日付
 * @param {number} page ページ番号
 * @return {Promise<RoomRoomLog[]>} 取得した部屋の全入退室ログ
 */
async function getAllRoomRoomLogsIter(vue: Vue, roomId: string, date: string, page: number): Promise<RoomRoomLog[]> {
  const query = `?date=${date}&limit=1000&page=${page}`;
  try {
    const response = await vue.$axios.get(`${Config.LOG_ROOM_API}/${roomId}${query}`);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.users;
    } else {
      const nextUsers = await getAllRoomRoomLogsIter(vue, roomId, date, page + 1);
      return response.data.users.concat(nextUsers);
    }
  } catch (err) {
    throw err;
  }
}
/**
 * 部屋の入退室ログを取得する
 * @param {Vue} vue Vueインスタンス
 * @param {string} roomId 部屋ID
 * @param {Object} query クエリ (クエリ名がキー、クエリ値がバリューのオブジェクト)
 * @returns {{
 *  maxRecords: number,
 *  users: RoomRoomLog[],
 * }} 結果の配列と必要情報
 */
export async function getRoomRoomLogs(vue: Vue, roomId: string, query: { [name: string]: string })
  : Promise<{ maxRecords: number, users: RoomRoomLog[]}> {
  let q = '';
  Object.keys(query).forEach((queryName, index) => {
    const op = index === 0 ? '?' : '&';
    q += `${op}${queryName}=${query[queryName]}`;
  });
  try {
    const response = await vue.$axios.get(`${Config.LOG_ROOM_API}/${roomId}${q}`);
    return {
      maxRecords: response.data.maxRecords,
      users: response.data.users,
    };
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('履歴情報を取得する権限がありません。');
      case 404:
        if (query.userId) {
          // 取得時404エラーの場合は、データ無しなので正常終了.
          return {
            maxRecords: 0,
            users: [],
          };
        } else {
          throw new Error('指定された部屋が存在しません。');
        }
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * ユーザの入退室ログを取得する
 * @param {Vue} vue Vueインスタンス
 * @param {string} userId ユーザID
 * @param {Object} query クエリ (クエリ名がキー、クエリ値がバリューのオブジェクト)
 * @returns {{
 *  maxRecords: number,
 *  rooms: UserRoomLog[],
 * }} 結果の配列と必要情報
 */
export async function getUserRoomLogs(vue: Vue, userId: string, query: { [name: string]: string })
  : Promise<{ maxRecords: number, rooms: UserRoomLog[]}> {
  let q = '';
  Object.keys(query).forEach((queryName, index) => {
    const op = index === 0 ? '?' : '&';
    q += `${op}${queryName}=${query[queryName]}`;
  });
  try {
    const response = await vue.$axios.get(`${Config.LOG_ROOM_API}/user/${encodeURIComponent(encodeURIComponent(userId))}${q}`);
    return {
      maxRecords: response.data.maxRecords,
      rooms: response.data.rooms,
    };
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('履歴情報を取得する権限がありません。');
      case 404:
        if (query.roomId) {
          return {
            maxRecords: 0,
            rooms: [],
          };
        } else {
          throw new Error('ユーザが存在しません。');
        }
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}
