


























































































































































































































































































import Vue, { PropType } from 'vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import Search, { SearchTargetItem, SearchValue } from '@/components/molecules/Search/Search.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import { DataTableHeader } from 'vuetify';
import { FaceImage, LocalStorageFaceImage, FaceImageFile, PCode, User, ProgressModalInfo} from '@/types/alligate-zenescan';
import ZenescanFaceImageBulkAdd from '@/components/organisms/ZenescanFaceImageBulkAdd/ZenescanFaceImageBulkAdd.vue';
import ZenescanFaceImageBulkEdit from '@/components/organisms/ZenescanFaceImageBulkEdit/ZenescanFaceImageBulkEdit.vue';
import Crypto from 'crypto';
import ProgressModal from '@/components/molecules/ProgressModal/ProgressModal.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';

export interface DisplayFaceImage {
  faceImageId: string; // 顔写真ID
  faceImage: string;
  pCodeId: string; // 個人コード
  pCodeName: string; // 個人コード名
  pCodeType: number; // 個人コードの種別
  pCodeTypeName: string; // 個人コードの種別名
  userId: string; // ユーザーID
  userName: string; // ユーザー名
  isValid: boolean; // 有効無効
}

interface DataType {
  deleteTargetZenescanFaceImageId: string;
  syncTargetZenescanFaceImageId: string;
  isDisplaySuccessMessage: boolean;
  isDisplayErrorMessage: boolean;
  isDisplayDeleteConfirm: boolean;
  isDisplaySyncConfirm: boolean;
  isSelectDeleteConfirm: boolean;
  perPageItemLength: number;
  // page: number;
  headers: DataTableHeader[];
  searchTargetItems: SearchTargetItem[];
  actionItems: ActionItem[];
  selectedPCodeIds: string[];
  selectedFaceImageIds: string[];
  selectedItem: DisplayFaceImage[];
  showAddFaceImageFormModal: boolean;
  showEditFaceImageFormModal: boolean;
  addFaceImageFiles: FaceImageFile[];
  showProgreeModal: boolean;
  isSelectGetConfirm: boolean;
  getFaceImageId: string;
}

export interface ImportFileData {
  fileName: string;
  pCodeId: string;
  pCodeType: number | undefined;
  userId: string;
  userName: string;
  user: string;
  noDataFlag: boolean;
}

export interface AddFileData {
  imageFiles: File[];
  importFile: File;
}

interface TypeSelect {
  text: string;
  value: string;
}

export default Vue.extend({
  name: 'ZenescanFaceImageList',
  components: {
    InputForm,
    Search,
    SuccessButton,
    CancelButton,
    ActionButton,
    Modal,
    Alert,
    ZenescanFaceImageBulkAdd,
    ZenescanFaceImageBulkEdit,
    ProgressModal,
    Pagination,
    SelectPerPage,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => true,
    },
    isLoadLocalStorageFaceImage: {
      type: Boolean,
      default: () => false,
    },
    isLoadingZenescanFaceImage: {
      type: Boolean,
      default: () => false,
    },
    successMessageLogsModal: {
      type: String,
      default: '',
    },
    errorMessageLogsModal: {
      type: String,
      default: '',
    },
    faceImage: {
      type: Object as PropType<FaceImage>,
      default: () => {
        return {
          faceImageId: '',
          md5: '',
          pCodeId: '',
          pCode: null,
        };
      },
    },
    faceImages: {
      type: Array as PropType<FaceImage[]>,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['pCodeId', 'userId', 'userName'],
      }),
    },
    dummyImage: {
      type: String,
      default: '',
    },
    downLoadImages: {
      type: Array as PropType<LocalStorageFaceImage[]>,
      default: () => [],
    },
    addFiles: {
      type: Object as PropType<AddFileData>,
      default: () => {
        return {
          imageFiles: [] as File[],
          importFile: null,
        };
      },
    },
    typeItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    pCodeItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    userItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [{ text: 'ロード中', value: 'select1' }],
    },
    pCodes: {
      type: Array as PropType<PCode[]>,
      default: () => [],
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    progressModalInfo: {
      type: Object as PropType<ProgressModalInfo>,
      default: () => {
        return {
          showModal: false,
          indeterminate: false,
          title: '',
          message: '',
          errorMessage: '',
          totalCount: 0,
          finishedCount: 0,
          errorCount: 0,
          errorContents: [],
        };
      },
    },
    isSelectedClear: {
      type: Boolean,
      default: false,
    },
    faceImageTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    isDisabledPagination: {
      type: Boolean,
      default: true,
    },
  },
  data: (): DataType => {
    return {
      deleteTargetZenescanFaceImageId: '',
      syncTargetZenescanFaceImageId: '',
      isDisplaySuccessMessage: false,
      isDisplayErrorMessage: false,
      isDisplayDeleteConfirm: false,
      isDisplaySyncConfirm: false,
      isSelectDeleteConfirm: false,
      showAddFaceImageFormModal: false,
      showEditFaceImageFormModal: false,
      showProgreeModal: false,
      /** 1page毎の表示件数 */
      perPageItemLength: 25,
      // page: 1,
      headers: [{
        text: 'カード番号',
        align: 'start',
        sortable: true,
        value: 'pCodeId',
      }, {
        text: '照合タイプ',
        align: 'start',
        sortable: false,
        value: 'pCodeTypeName',
      }, {
        text: 'ユーザーID',
        align: 'start',
        sortable: false,
        value: 'userId',
      }, {
        text: 'ユーザー名',
        align: 'start',
        sortable: false,
        value: 'userName',
      }, {
        text: '顔写真',
        align: 'start',
        sortable: false,
        value: 'faceImageBase64',
      }, {
        text: '',
        align: 'end',
        sortable: false,
        value: 'icons',
      }],
      searchTargetItems: [
        { label: 'カード番号', value: 'pCodeId' },
        { label: 'ユーザーID', value: 'userId' },
        { label: 'ユーザー名', value: 'userName' },
      ],
      actionItems: [
        { title: '有効化する', emitName: 'click-enable' },
        { title: '無効化する', emitName: 'click-disable' },
        { title: '顔写真を削除する', emitName: 'click-delete' },
      ],
      selectedPCodeIds: [],
      selectedFaceImageIds: [],
      selectedItem: [],
      addFaceImageFiles: [],
      isSelectGetConfirm: false,
      getFaceImageId: '',
    };
  },
  watch: {
    successMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplaySuccessMessage = true;
      } else {
        this.isDisplaySuccessMessage = false;
      }
    },
    errorMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplayErrorMessage = true;
      } else {
        this.isDisplayErrorMessage = false;
      }
    },
    selectedItem(val: DisplayFaceImage[]) {
      this.selectedPCodeIds = val.map((item) => item.pCodeId);
      this.$emit('select-pcode', this.selectedPCodeIds);
      this.selectedFaceImageIds = val.map((item) => item.faceImageId);
      this.$emit('select-face-image', this.selectedFaceImageIds);
    },
    isSelectedClear(val: boolean) {
      this.$emit('is-selected-clear-start', val);
      if (val === true) {
        this.selectedItem = [];
        this.$emit('selected-clear');
      }
    },
  },
  mounted() {
    if (this.isLoadLocalStorageFaceImage) {
      // ローカルストレージに存在しない場合、すべて追加
      if (!localStorage.faceImages) {
        if (this.downLoadImages.length > 0) {
          localStorage.setItem('faceImages', JSON.stringify(this.downLoadImages));
        }
      // ローカルストレージに存在する場合、this.faceImagesと比較してローカルストレージを更新
      } else {
        const resLocalStorage = localStorage.getItem('faceImages');
        let oldLocalStorage: LocalStorageFaceImage[] = [];
        if (resLocalStorage) {
            oldLocalStorage = JSON.parse(resLocalStorage);
          }
        const newLocalStorage: LocalStorageFaceImage[] = [];
        this.faceImages.forEach((item) => {
          const targetImage = oldLocalStorage.find((l) => l.faceImageId === item.faceImageId);
          const downLoadImage = this.downLoadImages.find((d) => d.faceImageId === item.faceImageId);
          if (downLoadImage) {
            newLocalStorage.push(downLoadImage);
          } else if (targetImage) {
            newLocalStorage.push(targetImage);
          }
        });
        if (newLocalStorage.length > 0) {
          localStorage.setItem('faceImages', JSON.stringify(newLocalStorage));
        }
      }
    }
  },
  computed: {
    innerSearchValue: {
      get(): SearchValue {
        return this.searchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-search-value', val);
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  methods: {
    clickConfirmDeleteZenescanFaceImage(ID: string): void {
      this.$emit('reset-msg');
      this.deleteTargetZenescanFaceImageId = ID;
      this.isDisplayDeleteConfirm = true;
    },
    deleteComfirmZenescanFaceImage(isConfirm: boolean): void {
      if (isConfirm === true) {
        this.$emit('click-delete-face-image', this.deleteTargetZenescanFaceImageId);
        this.$emit('reload-face-images');
      }
      this.deleteTargetZenescanFaceImageId = '';
      this.isDisplayDeleteConfirm = false;
    },
    clickShowDialogConfirmDeleteZenescanFaceImage(): void {
      this.$emit('reset-msg');
      this.isSelectDeleteConfirm = true;
    },
    deleteShowDialogCComfirmZenescanFaceImage(isConfirm: boolean): void {
      if (isConfirm === true) {
        this.$emit('show-dialog-confirm', {
          faceImageIds: this.selectedFaceImageIds,
          pCodeIds: this.selectedPCodeIds,
          type: 2,
        });
      }
      this.isSelectDeleteConfirm = false;
    },
    clickAddFaceImage() {
      this.$emit('start-add-face-image');
      this.addFiles.imageFiles = [];
      this.addFiles.importFile = null;
      this.addFaceImageFiles = [];
      this.showAddFaceImageFormModal = true;
    },
    clickNext() {
      if (this.addFiles.imageFiles && this.addFiles.imageFiles.length > 0) {
        let importData: ImportFileData[] = [];
        this.importExcelData().then((res) => importData = res)
        .then(() => {
          let retImageFiles: FaceImageFile[] = [];
          if (importData.length === 0) {
            retImageFiles = this.addFiles.imageFiles.map((item) => {
              return {
                imageFile: item,
                faceImageId: '',
                // pCodeId: 'Z' + Crypto.randomBytes(9).toString('hex').toUpperCase(),
                pCodeId: Crypto.randomBytes(10).toString('hex').slice(0, 19).toUpperCase(),
                pCodeType: 2,
                userId: '',
                userName: '',
                user: '',
                options: '',
                noDataFlag: false,
              };
            });
          } else {
            importData.forEach((d) => {
              const imageFile = this.addFiles.imageFiles.find((item) => item.name === d.fileName);
              if (imageFile) {
                retImageFiles.push({
                  imageFile,
                  faceImageId: '',
                  pCodeId: d.pCodeId,
                  pCodeType: d.pCodeType ? d.pCodeType : 0,
                  userId: d.userId,
                  userName: d.userName,
                  user: d.user,
                  options: '',
                  noDataFlag: d.noDataFlag,
                });
              }
            });
          }
          this.addFaceImageFiles = retImageFiles;
          this.showEditFaceImageFormModal = true;
          if (retImageFiles.length > 0) {
            this.$emit('start-edit-face-image');
            this.showEditFaceImageFormModal = true;
          } else {
            this.$emit('end-no-match-face-image');
            this.showEditFaceImageFormModal = false;
          }
        });
      }
    },
    clickAdd() {
      this.$emit('end-add-face-image');
    },
    importExcelData(): Promise<ImportFileData[]> {
      return new Promise((resolve) => {
        const importFileData: ImportFileData[] = [];
        if (this.addFiles.importFile !== null) {
          const reader = new FileReader();
          reader.onload = (): void => {
            const read =  reader.result as string;
            const XLSX = require('xlsx');
            const workbook = XLSX.read(read, {type: 'array'});
            const sheet1name = workbook.SheetNames[0];
            const faceImageData = workbook.Sheets[sheet1name];
            const faceImageSheetArray = XLSX.utils.sheet_to_json(faceImageData, {header: 1});
            for (const rows of faceImageSheetArray) {
              let fileName;
              let pCodeType;
              let pCodeId;
              let userId;
              let userName;
              let user;
              let noDataFlag = false;
              if (rows.length > 2 && rows[0] !== undefined && rows[1] !== undefined) {
                fileName = rows[0];
                if (rows[1] === 0 || rows[1] === 2) {
                  pCodeType = rows[1];
                }
                if (rows[1] === 0 && rows[2] !== undefined) {
                  const targetPcode = this.pCodes.find((p) => p.pCodeId === rows[2]);
                  if (targetPcode) {
                    // 個人コードが存在し、入力値と実際の個人コード種別が一致したものが有効
                    if (targetPcode.type === 0) {
                      pCodeId = rows[2];
                      if (targetPcode.user) {
                        userId = targetPcode.user.userId;
                        userName = targetPcode.user.name;
                        user = targetPcode.user.name === '' ?
                          targetPcode.user.userId : `${targetPcode.user.name}(${targetPcode.user.userId})`;
                      } else if (targetPcode.userId && targetPcode.userId.length > 0) {
                        const targetUser = this.users.find((u) => u.userId === targetPcode.userId);
                        if (targetUser) {
                          userId = targetUser.userId;
                          userName = targetUser.name;
                          user = targetUser.name === '' ?
                            targetUser.userId : `${targetUser.name}(${targetUser.userId})`;
                        }
                      }
                    }
                  } else {
                    noDataFlag = true;
                    pCodeId = rows[2];
                    userId = '-';
                    userName = '-';
                    user = '-';
                  }
                } else if (rows[1] === 2) {
                  // pCodeId = 'Z' + Crypto.randomBytes(9).toString('hex').toUpperCase();
                  pCodeId = Crypto.randomBytes(10).toString('hex').slice(0, 19).toUpperCase();
                  if (rows[3]) {
                    const targetUser = this.users.find((u) => u.userId === rows[3]);
                    userId = '';
                    userName = '';
                    user = '';
                    if (targetUser) {
                      userId = targetUser.userId;
                      userName = targetUser.name;
                      user = targetUser.name === '' ? targetUser.userId : `${targetUser.name}(${targetUser.userId})`;
                    } else {
                      noDataFlag = true;
                      userId = rows[3];
                      userName = '-';
                      user = userId;
                    }
                  }
                }
                if (fileName && (pCodeType === 0 || pCodeType === 2) && pCodeId && userId && userName && user) {
                  importFileData.push({
                    fileName,
                    pCodeType,
                    pCodeId,
                    userId,
                    userName,
                    user,
                    noDataFlag,
                  });
                }
              }
            }
            resolve(importFileData);
          };
          reader.readAsArrayBuffer(this.addFiles.importFile);
        } else {
          resolve(importFileData);
        }
      });
    },
    updateItemsPerPage(val: number) {
      this.$emit('update-items-per-page', val);
    },
    updatePage(val: number) {
      this.$emit('update-page', val);
    },
    clickSearch(event: any) {
      this.$emit('search', event);
    },
    clickFaceImageFile(faceImageId: string) {
      this.getFaceImageId = faceImageId;
      this.isSelectGetConfirm = true;
    },
    getShowDialogComfirmFaceImageFile(isConfirm: boolean): void {
      if (isConfirm === true) {
        this.$emit('click-open-face-image', this.getFaceImageId);
      }
      this.isSelectGetConfirm = false;
    },
  },
});
