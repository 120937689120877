






















































import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';

export interface DisplayZenescanLogin {
  faceDbId: string;
  ipAddress: string;
  userName: string;
  userPwd: string;
}

export default Vue.extend({
  name: 'ZenescanLogin',
  components: {
    InputForm,
    SuccessButton,
    CancelButton,
    Modal,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Object as PropType<DisplayZenescanLogin>,
      default: () => {
        return {
          faceDbId: '',
          ipAddress: '',
          userName: '',
          userPwd: '',
        };
      },
    },
  },
  computed: {
  },
  methods: {
    close(): void {
      this.$emit('reset-msg');
      this.$emit('close');
      return;
    },
    /** 渡された要素が一個以上かチェックする */
    isValidateLengthOneMore(values: string[]): string | boolean {
      if (values && values.length > 0) {
        return true;
      } else {
        return '必須項目のため、入力が必須です。';
      }
    },
    clickSaveZenescanLogin(): void {
      const validate = (this.$refs.formZenescanLogin as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('click-save-zenescan-login', this.value);
      }
    },
  },
});
