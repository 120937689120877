var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "mr-0 mb-4", attrs: { justify: "start" } },
            [
              _c("icon-button", {
                attrs: { text: "戻る", icon: "mdi-arrow-left" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "pa-6 pr-10 mb-5", attrs: { elevation: "3" } },
            [_c("ControlSettingForm")],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mr-0 mb-4", attrs: { justify: "end" } },
            [
              _c("icon-button", {
                attrs: { text: "登録", icon: "mdi-content-save-all-outline" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }