var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-action-button" },
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "text-bold",
                          attrs: {
                            color: "grey darken-1",
                            outlined: "",
                            disabled: _vm.disabled
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            _vm._l(_vm.actionItems, function(item, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit(item.emitName, $event)
                    }
                  }
                },
                [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }