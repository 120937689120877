var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table allig-user-table" },
    [
      _vm.loading === false
        ? _c(
            "v-row",
            [
              _vm.successMessageLogs !== ""
                ? _c(
                    "v-col",
                    { staticClass: "mb-4 font-weight-bold mt-1 bg-success" },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.successMessageLogs) + "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.errorMessageLogs !== ""
                ? _c(
                    "v-col",
                    { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.errorMessageLogs) + "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "ml-1 mt-2", attrs: { justify: "start" } },
            [
              _c("CancelButton", {
                attrs: { text: "戻る" },
                on: {
                  click: function($event) {
                    return _vm.$emit("goPageSetting")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-row", {
            staticStyle: { height: "5px" },
            attrs: { "no-gutters": "" }
          }),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "info-export" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "600" },
                  model: {
                    value: _vm.showModalImport,
                    callback: function($$v) {
                      _vm.showModalImport = $$v
                    },
                    expression: "showModalImport"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v(" CSVファイルエクスポート ")]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row-message" },
                        [
                          _vm.successMessageLogs !== ""
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-1 bg-success"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.successMessageLogs) +
                                      "\n                "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageLogs !== ""
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mb-4 font-weight-bold mt-1 bg-error"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.errorMessageLogs) +
                                      "\n                "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-row", {
                        staticStyle: { height: "5px" },
                        attrs: { "no-gutters": "" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "pa-4" },
                        [
                          _vm.errorMessageLogs === ""
                            ? _c(
                                "v-progress-linear",
                                {
                                  attrs: {
                                    height: "25",
                                    color: "green darken-1",
                                    striped: ""
                                  },
                                  model: {
                                    value: _vm.progress,
                                    callback: function($$v) {
                                      _vm.progress = $$v
                                    },
                                    expression: "progress"
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(Math.ceil(_vm.progress)) + "%"
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.success
                        ? _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-2",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("closeModalImport")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                OK\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-subtitle-2 border-left-blue font-weight-bold mt-10"
                    },
                    [
                      _vm._v(
                        "\n            ログインユーザー 一括登録\n          "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card", { staticClass: "pa-5" }, [
                _c("div", { staticClass: "alert alert-info col" }, [
                  _vm._v(
                    "\n            Google ログインユーザーの登録を一括で実施することができます。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n            登録するために必要なテンプレートCSVファイルは、”テンプレートのダウンロード”から取得してください。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n            このアップロードでは、既に登録されているユーザー情報を変更することはできません。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n            変更したいユーザーについては、削除を行ってから実施してください。\n          "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "fallback mt-5" },
                  [
                    _c("p", { staticClass: "desc" }, [
                      _vm._v("自分のコンピューターからファイルを選択する ：")
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "btn-upload", attrs: { for: "file_csv" } },
                      [
                        _vm._v(
                          "\n              CSVファイルを選択\n              "
                        ),
                        _c("input", {
                          ref: "myFiles",
                          staticClass: "custom-file-input",
                          staticStyle: { display: "none" },
                          attrs: {
                            type: "file",
                            id: "file_csv",
                            multiple: "",
                            accept: ".csv"
                          },
                          on: { change: _vm.previewFiles }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _vm.files
                      ? _c("span", { staticClass: "filename" }, [
                          _vm._v(_vm._s(_vm.files.name))
                        ])
                      : _c("span", { staticClass: "filename" }, [
                          _vm._v("選択されていません")
                        ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "error" }),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "result" } }),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-primary mr-0",
                        attrs: { outlined: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("clickImport", {
                              files: _vm.files
                            })
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                登録データのアップロード\n              "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mt-10 pa-5" },
                [
                  _c("div", { staticClass: "alert alert-info col" }, [
                    _vm._v(
                      "\n            Google ログインユーザーの登録を一括で実施するテンプレートファイルをダウンロードできます。\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary mr-0 mt-10",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("clickExport")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                登録データのアップロード\n              "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }