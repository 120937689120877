










import {Vue } from 'vue-property-decorator';
import CardDetailTemplate from '@/components/templates/CardDetailTemplate/CardDetailTemplate.vue';
import * as Config from '@/config';
import { CardDetail } from '@/types/alligate';

export interface DataType {
  loading: boolean;
  errorMessageLogs: string;
  cardDetail: CardDetail;
}
export default Vue.extend({
  name: 'CardDetailPage',
  components: {
    CardDetailTemplate,
  },
  data: (): DataType => ({
    loading: false,
    errorMessageLogs: '',
    cardDetail: {
      pCodeId: '',
      userId: '',
      type: -1,
      name: '',
      isValid: false,
    },
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    clickBackCard() {
      this.$router.push({ path: Config.CARD_LIST});
    },
    clickEditCard(value: any) {
      this.$router.push({ path: Config.CARD_EDIT, query: {pCodeId: value}});
    },
    loadData() {
      const dataQuery = this.$route.query;
      if (dataQuery.pCodeId !== undefined) {
        this.getCardData(dataQuery.pCodeId.toString());
      }
    },
    getCardData(pCodeId: string) {
      this.loading = true;
      this.$http.get(Config.CARD_LIST_API + '?pCodeId=' + encodeURIComponent(pCodeId))
        .then((response: any) => {
          this.loading = false;
          if (response.body.maxRecords > 0 && response.body.pCodes.length > 0) {
            this.cardDetail = response.body.pCodes[0];
          } else {
            this.errorMessageLogs = Config.ERROR_DEFAULT;
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          console.log('err: ', err);
          switch (err.status) {
          case 400:
            this.errorMessageLogs = Config.ERROR_400;
            break;
          case 403:
            if (err.message === 'Unauthorized') {
              this.errorMessageLogs = Config.ERROR_403;
            } else {
              this.errorMessageLogs = Config.ERROR_403_DENIED;
            }
            break;
          case 404:
            this.errorMessageLogs = Config.ERROR_404;
            break;
          case 500:
            this.errorMessageLogs = Config.ERROR_500;
            break;
          case 503:
            this.errorMessageLogs = Config.ERROR_503;
            break;
          default:
            this.errorMessageLogs = Config.ERROR_DEFAULT;
            break;
        }
        }); // catch
    },
  },
});
