var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-alert" },
    [
      _c(
        "v-alert",
        {
          attrs: {
            text: _vm.type !== "warning",
            type: _vm.type,
            dismissible: _vm.dismissible,
            transition: "slide-y-transition"
          },
          model: {
            value: _vm.innerValue,
            callback: function($$v) {
              _vm.innerValue = $$v
            },
            expression: "innerValue"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }