var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    attrs: { value: _vm.isVisible, title: "顔写真一括登録" },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "modal-body",
        fn: function() {
          return [
            _c(
              "v-form",
              {
                ref: "formZenescanFaceImage",
                staticClass: "modal-body-top",
                attrs: { "lazy-validation": "" }
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { md: 11 } },
                      [
                        _c("drag-and-drop-file-input-form", {
                          attrs: {
                            title: "顔写真",
                            inputType: "account-image-file",
                            rules: [_vm.isValidInputFile(_vm.value)],
                            alertText:
                              "ファイルサイズが4MB以上の顔写真は登録時に圧縮されます"
                          },
                          model: {
                            value: _vm.value.imageFiles,
                            callback: function($$v) {
                              _vm.$set(_vm.value, "imageFiles", $$v)
                            },
                            expression: "value.imageFiles"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { md: 11 } },
                      [
                        _c("input-form", {
                          attrs: {
                            title: "テンプレート",
                            inputType: "file",
                            accept: ".xlsx"
                          },
                          model: {
                            value: _vm.value.importFile,
                            callback: function($$v) {
                              _vm.$set(_vm.value, "importFile", $$v)
                            },
                            expression: "value.importFile"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-secondary mt-5",
                        attrs: { outlined: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("click-download-template-excel")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          テンプレートのダウンロード\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            _c("success-button", {
              attrs: { text: "次へ進む" },
              on: {
                click: function($event) {
                  return _vm.clickAddZenescanFaceImage()
                }
              }
            }),
            _vm._v(" "),
            _c("cancel-button", {
              attrs: { text: "閉じる" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }