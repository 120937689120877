var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        { staticClass: "pa-6 pr-10 mb-5", attrs: { elevation: "3" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "ml-4 mb-4 text-subtitle-2 font-weight-bold" },
                [_vm._v("\n        基本情報\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("notice-list", { attrs: { linkList: _vm.linkList } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }