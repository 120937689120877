
















































import Vue, { PropType } from 'vue';
import InputForm, { SelectItem } from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import NoticeSettingList from '@/components/organisms/NoticeSettingList/NoticeSettingList.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import NoticeSettingTarget from '@/components/organisms/NoticeSettingTarget/NoticeSettingTarget.vue';
import { NoticeSettingType, NoticeSetting } from '@/types/alligate';

/** 通知設定画面の編集/新規追加切り替え用定数(編集画面用) */
const MODE_EDIT = 'edit';
/** 通知設定画面の編集/新規追加切り替え用定数(新規追加画面用) */
const MODE_NEW = 'new';

interface DataType {
  isVisibleNoticeSettingList: boolean;
  isVisibleNoticeSettingTarget: boolean;
  selectedNoticeSettingType: NoticeSettingType;
  editNoticeSetting: NoticeSetting;
  editMode: string;
}

export default Vue.extend({
  name: 'NoticeSettingTypeList',
  components: {
    InputForm,
    TextLink,
    SuccessButton,
    CancelButton,
    Loading,
    NoticeSettingList,
    NoticeSettingTarget,
  },
  data: (): DataType => {
    return {
      isVisibleNoticeSettingList: false,
      isVisibleNoticeSettingTarget: false,
      selectedNoticeSettingType: {
        noticeSettingTypeId: '',
        name: '',
        targets: [],
        description: '',
      },
      // 編集用オブジェクト
      editNoticeSetting: {
        name: '',
        noticeSettingId: '',
        noticeSettingTypeId: '',
        option: '',
        targets: [],
        emails: [],
        isValid: false,
      },
      /** 通知設定画面開く時のモード切り替え用変数(new:新規追加, edit:追加済みのレコードの編集) */
      editMode: 'new',
    };
  },
  props: {
    isLoadingNoticeSetting: {
      type: Boolean,
      default: () => false,
    },
    isLoadBlockingNoticeSettingType: {
      type: Boolean,
      default: () => false,
    },
    isLoadBlockingNoticeSetting: {
      type: Boolean,
      default: () => true,
    },
    loadBlockingTextNoticeSetting: {
      type: String,
      default: () => '',
    },
    successMessageLogsModal: {
      type: String,
      default: '',
    },
    errorMessageLogsModal: {
      type: String,
      default: '',
    },
    noticeSettingTypes: {
      type: Array as PropType<NoticeSettingType[]>,
      default: () => [],
    },
    noticeSettings: {
      type: Array as PropType<NoticeSetting[]>,
      default: () => [],
    },
    configurableUsers: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    },
    configurableGates: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    },
    configurablePcodes: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    },
    configurableLogs: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    },
    configurableConnectStatus: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    },
  },
  created(): void {
    return ;
  },
  mounted(): void {
    return ;
  },
  methods: {
    displayNoticeSettingList(noticeSettingType: NoticeSettingType): void {
      this.$emit('reset-msg');
      this.isVisibleNoticeSettingList = true;
      this.selectedNoticeSettingType = noticeSettingType;
    },
    clickOpenNewAddNoticeSeting(noticeSettingType: NoticeSettingType): void {
      this.$emit('reset-msg');
      this.editNoticeSetting = {
        name: '',
        option: '',
        noticeSettingId: '',
        noticeSettingTypeId: noticeSettingType.noticeSettingTypeId,
        isValid: false,
        targets: [],
        emails: [],
      };
      this.selectedNoticeSettingType = noticeSettingType;
      this.editMode = MODE_NEW;
      this.isVisibleNoticeSettingTarget = true;
    },
    clickOpenEditNoticeSeting(noticeSettingId: string): void {
      this.$emit('reset-msg');
      this.editNoticeSetting = {
        name: '',
        option: '',
        noticeSettingId,
        noticeSettingTypeId: '',
        isValid: false,
        targets: [],
        emails: [],
      };
      const noticeSetting: NoticeSetting | undefined = this.noticeSettings
        .find((setting: NoticeSetting) => setting.noticeSettingId === noticeSettingId);
      if (noticeSetting === undefined) {
        return ;
      }
      // 編集画面を開いた時設定できるnoticeSettingTypeTargetを絞り込むためにコピーしとく。
      if (noticeSetting.noticeSettingTypeId !== this.selectedNoticeSettingType.noticeSettingTypeId) {
        return ;
      }
      this.editNoticeSetting = JSON.parse(JSON.stringify(noticeSetting));

      this.editMode = MODE_EDIT;
      // 値のセットが終わったら表示
      this.isVisibleNoticeSettingTarget = true;
    },
  },
});
