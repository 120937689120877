var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-card-detail" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c("v-card", { staticClass: "pa-6 pr-10 mb-5" }, [
            _c("div", { staticClass: "page-detail mb-10" }, [
              _c("div", { staticClass: "item-time-zone ml-10 mt-5" }, [
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col col-3" }, [
                      _c("div", { staticClass: "v-subheader" }, [
                        _vm._v("カード番号")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-body-2 col align-self-center" },
                      [_vm._v(" " + _vm._s(_vm.cardDetail.pCodeId) + " ")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col col-3" }, [
                      _c("div", { staticClass: "v-subheader" }, [
                        _vm._v("名称")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-body-2 col align-self-center" },
                      [_vm._v(" " + _vm._s(_vm.cardDetail.name) + " ")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col col-3" }, [
                      _c("div", { staticClass: "v-subheader" }, [
                        _vm._v("カード種別")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-body-2 col align-self-center" },
                      [
                        _vm.cardDetail.type === 0
                          ? _c("span", [_vm._v(" NFCカード ")])
                          : _vm.cardDetail.type === 1
                          ? _c("span", [_vm._v("テンキー")])
                          : _vm._e()
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col col-3" }, [
                      _c("div", { staticClass: "v-subheader" }, [
                        _vm._v("ユーザー")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-body-2 col align-self-center" },
                      [_vm._v(" " + _vm._s(_vm.cardDetail.userId) + " ")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col col-3" }, [
                      _c("div", { staticClass: "v-subheader" }, [
                        _vm._v("ステータス")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-body-2 col align-self-center" },
                      [
                        _vm.cardDetail.isValid === true
                          ? _c("span", [_vm._v("有効")])
                          : _c("span", [_vm._v("OFF")])
                      ]
                    )
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mb-10", attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-primary",
                  attrs: {
                    outlined: "",
                    disabled:
                      _vm.cardDetail.pCodeId === null ||
                      _vm.cardDetail.pCodeId === undefined ||
                      _vm.cardDetail.pCodeId === ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickEditCard", _vm.cardDetail.pCodeId)
                    }
                  }
                },
                [_vm._v("編集")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }