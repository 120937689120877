


















import Vue from 'vue';
export default Vue.extend({
  name: 'RadioButton',
  props: {
    arrayItem: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      radioGroup: 1,
    };
  },
});
