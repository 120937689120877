var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c("v-container", [
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass:
                      "text-subtitle-2 border-left-blue font-weight-bold"
                  },
                  [_vm._v("\n          ゲート情報\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-form",
              { ref: "GateAddForm", attrs: { "lazy-validation": "" } },
              [
                _c(
                  "v-card",
                  { staticClass: "pa-6 pr-10 mb-5" },
                  [
                    _c("input-form", {
                      attrs: {
                        title: "ゲート名",
                        inputType: "text",
                        rules: [
                          _vm.rulesValidate.requiredName,
                          _vm.rulesValidate.validateName
                        ]
                      },
                      model: {
                        value: _vm.gateDetail.gateName,
                        callback: function($$v) {
                          _vm.$set(_vm.gateDetail, "gateName", $$v)
                        },
                        expression: "gateDetail.gateName"
                      }
                    }),
                    _vm._v(" "),
                    _c("input-form", {
                      attrs: {
                        title: "備考1",
                        inputType: "text",
                        rules: [_vm.rulesValidate.validateOption1]
                      },
                      model: {
                        value: _vm.gateDetail.option1,
                        callback: function($$v) {
                          _vm.$set(_vm.gateDetail, "option1", $$v)
                        },
                        expression: "gateDetail.option1"
                      }
                    }),
                    _vm._v(" "),
                    _c("input-form", {
                      attrs: {
                        title: "備考2",
                        inputType: "text",
                        rules: [_vm.rulesValidate.validateOption2]
                      },
                      model: {
                        value: _vm.gateDetail.option2,
                        callback: function($$v) {
                          _vm.$set(_vm.gateDetail, "option2", $$v)
                        },
                        expression: "gateDetail.option2"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _vm.$vuetify.breakpoint.mdAndUp
                          ? _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("v-subheader", [
                                  _vm._v("\n            グループ\n          ")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.groupList,
                                color: "green",
                                "return-object": "",
                                "hide-details": "auto",
                                outlined: "",
                                dense: "",
                                "deletable-chips": "",
                                multiple: "",
                                "small-chips": "",
                                "no-data-text": "",
                                disabled: _vm.groupList.length === 0
                              },
                              model: {
                                value: _vm.gateDetail.groups,
                                callback: function($$v) {
                                  _vm.$set(_vm.gateDetail, "groups", $$v)
                                },
                                expression: "gateDetail.groups"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass:
                          "text-subtitle-2 border-left-blue font-weight-bold"
                      },
                      [_vm._v("\n          連続解錠時間\n        ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-edit-time pl-0 table-edit-time-gate" },
                  [
                    _c(
                      "v-data-table",
                      {
                        staticClass: "border-table shadow-table scroll-table",
                        attrs: {
                          headers: _vm.headers,
                          "items-per-page": -1,
                          items: _vm.filteredItems,
                          "no-data-text": "データーはありません",
                          "hide-default-footer": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(row) {
                              return [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(_vm._s(row.item.dayTypeName))
                                  ]),
                                  _vm._v(" "),
                                  row.item.allDay
                                    ? _c("td", [_vm._v("終日")])
                                    : _c("td", [
                                        _vm._v(
                                          _vm._s(row.item.startTime) +
                                            " ~ " +
                                            _vm._s(row.item.endTime)
                                        )
                                      ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "last-col" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteSpecialTime(
                                                row.item.index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "gray--text" },
                                            [_vm._v("mdi-trash-can-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("template", { slot: "body.prepend" }, [
                          _c("tr", [
                            _c(
                              "td",
                              { staticClass: "day-types" },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "table-select  mt-1",
                                  attrs: {
                                    items: _vm.dayTypes,
                                    "return-object": "",
                                    color: "green",
                                    "hide-details": "auto",
                                    "no-data-text": "",
                                    outlined: "",
                                    dense: "",
                                    autocomplete: "off"
                                  },
                                  model: {
                                    value: _vm.inputSpecialday,
                                    callback: function($$v) {
                                      _vm.inputSpecialday = $$v
                                    },
                                    expression: "inputSpecialday"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "day-time pb-1" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "input-time" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0 mt-0",
                                        attrs: { cols: "4" }
                                      },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "content-class":
                                                "background-white",
                                              "close-on-content-click": false,
                                              transition: "scale-transition",
                                              "offset-y": "",
                                              left: "",
                                              "min-width": "auto"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "input-time",
                                                            attrs: {
                                                              color: "green",
                                                              outlined: "",
                                                              dense: "",
                                                              "prepend-inner-icon":
                                                                "mdi-clock-time-four-outline",
                                                              "hide-details":
                                                                "auto",
                                                              autocomplete:
                                                                "off",
                                                              readonly: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.hoursStartSpecial,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.hoursStartSpecial = $$v
                                                              },
                                                              expression:
                                                                "hoursStartSpecial"
                                                            }
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [
                                            _vm._v(" "),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "allig-select"
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items: _vm.hours,
                                                        color: "green",
                                                        outlined: "",
                                                        dense: ""
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.hoursStartSpecial = _vm.formatTime(
                                                            _vm.hourStart,
                                                            _vm.minuteStart
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.hourStart,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.hourStart = $$v
                                                        },
                                                        expression: "hourStart"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-2 mr-2 ml-2 font-weight-bold"
                                                  },
                                                  [_vm._v(":")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "allig-select"
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items: _vm.minutes,
                                                        color: "green",
                                                        outlined: "",
                                                        dense: "",
                                                        "hide-details": "",
                                                        "full-width": ""
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.hoursStartSpecial = _vm.formatTime(
                                                            _vm.hourStart,
                                                            _vm.minuteStart
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.minuteStart,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.minuteStart = $$v
                                                        },
                                                        expression:
                                                          "minuteStart"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center mt-0",
                                        attrs: { cols: "1", pa: "0" }
                                      },
                                      [_vm._v("~")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0 mt-0",
                                        attrs: { cols: "4" }
                                      },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "content-class":
                                                "background-white",
                                              "close-on-content-click": false,
                                              transition: "scale-transition",
                                              "offset-y": "",
                                              left: "",
                                              "min-width": "auto"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "input-time",
                                                            attrs: {
                                                              color: "green",
                                                              outlined: "",
                                                              dense: "",
                                                              "prepend-inner-icon":
                                                                "mdi-clock-time-four-outline",
                                                              "hide-details":
                                                                "auto",
                                                              autocomplete:
                                                                "off",
                                                              readonly: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.hoursEndSpecial,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.hoursEndSpecial = $$v
                                                              },
                                                              expression:
                                                                "hoursEndSpecial"
                                                            }
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [
                                            _vm._v(" "),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "allig-select"
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items: _vm.hours,
                                                        color: "green",
                                                        outlined: "",
                                                        dense: ""
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.hoursEndSpecial = _vm.formatTime(
                                                            _vm.hourEnd,
                                                            _vm.minuteEnd
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.hourEnd,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.hourEnd = $$v
                                                        },
                                                        expression: "hourEnd"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-2 mr-2 ml-2 font-weight-bold"
                                                  },
                                                  [_vm._v(":")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "allig-select"
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items: _vm.minutes,
                                                        color: "green",
                                                        outlined: "",
                                                        dense: "",
                                                        "hide-details": "",
                                                        "full-width": ""
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.hoursEndSpecial = _vm.formatTime(
                                                            _vm.hourEnd,
                                                            _vm.minuteEnd
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.minuteEnd,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.minuteEnd = $$v
                                                        },
                                                        expression: "minuteEnd"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0 mt-0",
                                        attrs: { cols: "3", pa: "0" }
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass:
                                            "allig-checkbox pa-0 mt-2 ml-4",
                                          attrs: {
                                            label: "終日",
                                            "hide-details": "auto"
                                          },
                                          model: {
                                            value: _vm.allDay,
                                            callback: function($$v) {
                                              _vm.allDay = $$v
                                            },
                                            expression: "allDay"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "align-self-center" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn-secondary",
                                    attrs: { outlined: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addSpecialTime()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              追加\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm.errorAddListTime !== "" &&
                          _vm.errorGate === "" &&
                          _vm.errorTime === ""
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _c(
                                    "p",
                                    { staticClass: "error-special add hours" },
                                    [_vm._v(_vm._s(_vm.errorAddListTime))]
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorAddListTime === "" &&
                          (_vm.errorGate !== "" || _vm.errorTime !== "")
                            ? _c("tr", [
                                _c("td", [
                                  _c(
                                    "p",
                                    { staticClass: "error-special add hours" },
                                    [_vm._v(_vm._s(_vm.errorGate))]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "error-special add end-time"
                                    },
                                    [_vm._v(_vm._s(_vm.errorTime))]
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                  [
                    _vm.successMessageLogs !== "" &&
                    _vm.groupsAddFail.length > 0 &&
                    _vm.loading === false
                      ? _c(
                          "v-col",
                          {
                            staticClass:
                              "mb-4 font-weight-bold mt-4 bg-error messageRow bg-no",
                            attrs: { cols: "11", align: "right" }
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.Message.ERROR_GATE_EDIT.replace(
                                    "GROUP_NAME",
                                    _vm.groupsAddFail.toString()
                                  )
                                )
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.successMessageLogs !== "" &&
                    _vm.groupsAddFail.length === 0 &&
                    _vm.loading === false &&
                    _vm.errorMessageTime === ""
                      ? _c(
                          "v-col",
                          {
                            staticClass:
                              "mb-4 font-weight-bold mt-4 bg-success messageRow bg-no",
                            attrs: { cols: "11", align: "right" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.successMessageLogs) +
                                " "
                            ),
                            _c("br")
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.successMessageLogs !== "" &&
                    _vm.groupsAddFail.length === 0 &&
                    _vm.loading === false &&
                    _vm.errorMessageTime !== ""
                      ? _c(
                          "v-col",
                          {
                            staticClass:
                              "mb-4 font-weight-bold mt-4 bg-error messageRow bg-no",
                            attrs: { cols: "11", align: "right" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.successMessageLogs) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.errorMessageTime) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errorMessageLogs !== "" && _vm.loading === false
                      ? _c(
                          "v-col",
                          {
                            staticClass:
                              "mb-4 font-weight-bold mt-4 bg-error messageRow bg-no",
                            attrs: { cols: "11", align: "right" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.errorMessageLogs) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "messageRow",
                        attrs: { cols: "1", align: "right" }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-primary",
                            attrs: { outlined: "" },
                            on: { click: _vm.clickUpdateGate }
                          },
                          [_vm._v("\n          登録\n          ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }