


























































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GateList from '@/components/organisms/GateList/GateList.vue';
import * as Config from '@/config';
import { Group, Gate, RelationGateGroup } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';

export interface DataType {
  selectedItem: Group[];
}

export default Vue.extend({
  name: 'GroupOfTime',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
    GateList,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageGroup: {
      type: Number,
      default: 1,
    },
    passableTimeSettingId: {
      type: String,
      default: '',
    },
    perPage: {
      type: Number,
      default: 25,
    },
    ExpandedGroup: {
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    selectedItem: [],
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'グループ名',
            align: 'start',
            sortable: true,
            value: 'name',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.pageGroup;
      },
      set(val: number): void {
        this.$emit('change-page-group', {page: val, passableTimeSettingId: this.passableTimeSettingId,
        change: 'group' });
        return;
      },
    },
  },
  watch: {
    groups(val) {
      this.selectedItem = [];
    },
    selectedItem(val: Group[]) {
      this.$emit('select-groups', val.map((item) => item.groupPassageId));
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
      });
      return items;
    },
    clickDetail(ID: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: ID, goBack: 'setting'}});
    },
  },
});
