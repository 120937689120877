import Vue from 'vue';
import * as Config from '@/config';
import { Gate } from '@/types/alligate';

/**
 * 全ゲートを取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<User[]>} 取得した全ユーザ
 */
export async function getAllGates(vue: Vue): Promise<Gate[]> {
  try {
    const users = await getAllGatesIter(vue, 1);
    return users;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('ゲート情報の取得に失敗しました。ユーザ情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全ゲートを取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<Gate[]>} 取得した全ユーザ
 */
async function getAllGatesIter(vue: Vue, page: number): Promise<Gate[]> {
  const query = `?limit=1000&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.GATES_LIST_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.gates;
    } else {
      const nextUsers = await getAllGatesIter(vue, page + 1);
      return response.data.gates.concat(nextUsers);
    }
  } catch (err) {
    throw err;
  }
}
